import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, Col, Modal } from 'react-bootstrap'
import Loader from 'react-loaders'

interface ModalEliminarArchivoProps {
    showModalEliminarArchivo: boolean
    handleCloseModalEliminarArchivo: () => void
    cargando: boolean
    borrarArchivo: (idArchivo: number) => void
    idArchivo: number | undefined
}

const ModalEliminarArchivo = ({ showModalEliminarArchivo, handleCloseModalEliminarArchivo, cargando, borrarArchivo, idArchivo }: ModalEliminarArchivoProps) => {
    return (
        <Modal show={showModalEliminarArchivo} onHide={handleCloseModalEliminarArchivo} dialogClassName="modal-upload"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title>Eliminar Archivo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {cargando ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Loader active={cargando} type='ball-beat' />
                    <p style={{ fontSize: 15 }}>Eliminando..</p>
                </div>
                    : <>
                        <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                            <FontAwesomeIcon style={{ fontSize: 30, color: '#db3939', textJustify: 'auto' }} icon={faExclamationCircle} />
                            <h4>¿Esta seguro que desea eliminar el archivo de forma permanente?</h4>
                        </Col>
                        <Col style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', padding: 5 }}>
                            <Button variant='danger' onClick={() => borrarArchivo(idArchivo!)}>Borrar</Button>
                            <Button variant='primary' onClick={() => handleCloseModalEliminarArchivo()}>Cancelar</Button>
                        </Col>
                    </>}
            </Modal.Body>
        </Modal>
    )
}

export default ModalEliminarArchivo