import React, { useEffect } from "react";
import BlockUi from "react-block-ui";
import { Media, Container } from "react-bootstrap";
import { useHistory } from "react-router";
import { AppContext } from "../App";
import { ListBox } from "../ListBox";
import { CentradoVertical } from "../Utilidades";
import { useApi } from "ApiHooks";

export function SelectEmpresa() {
    let api = useApi();
    let { setEmpresaActual, mostrarError } = React.useContext(AppContext);
    let [isBlocking, setBlocking] = React.useState(true);
    let [empresas, setEmpresas] = React.useState([] as Array<any>);
    let history = useHistory();
    useEffect(() => {
        async function cargar() {
            try {
                setEmpresas(await api.getEmpresasUsuario());
                setBlocking(false);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar empresas', error);
                    mostrarError('Error al cargar empresas');
                }
            }
        }
        cargar();
        //eslint-disable-next-line
    }, []);
    return (
        <CentradoVertical>
            <Container>
                <h2>Seleccionar Empresa</h2>
                <BlockUi blocking={isBlocking}>
                    <ListBox list={empresas} itemKey="CUIT" render={(empresa: any) =>
                    (<Media>
                        <Media.Body>
                            <p><strong>{empresa.Nombre}</strong></p>
                            <p>Cuit: {empresa.CUIT}</p>
                        </Media.Body>
                    </Media>)} onSelect={(empresa: any) => {
                        setEmpresaActual(empresa.CUIT);
                        history.replace('/');
                    }} selectedKey={empresas.find((empresa: any) => empresa.PorDefecto)?.CUIT} />
                </BlockUi>
            </Container>
        </CentradoVertical>)
}