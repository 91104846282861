import Decimal from "decimal.js";
import Grilla, { GrillaRef, TipoCampo, GrillaSync } from "Grilla";
import React, { useCallback, useContext, useEffect, useReducer, useRef, useState } from "react";
import { Button, Col, Container, Form, Modal, Row, Tab, Tabs, Media } from "react-bootstrap";
import { useHistory } from "react-router";
import { AppContext, NotFoundComponent } from "../../../App";
import { EstadoCaratula } from "../../../Enums";
import { useDecodedParams, cargarDatosGrillaDeArray, convertirDatosGenericosAOption, groupBy, isNullOrWhiteSpace, obtenerSufijoModelo, optionLabelConCodigo, strCmp, toFixedDecimal } from "../../../Utilidades";
import { MySelectNoFormik } from 'BaseSelect';
import { MyModal } from "MyModal";
import BlockUi from "react-block-ui";
import * as Yup from "yup";
import { Formik, FormikProps, Field } from "formik";
import { MyAsyncSelect, MyForm, MyFormControl, MySelect, SelectOption } from "../../../FormikHooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faInfoCircle, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { DialogoConfirmar, DialogoConfirmarRef } from "../../../DialogoConfirmar";
import styled from "styled-components";
import FileSaver from "file-saver";
import Loader from "react-loaders";
import { arabToRoman } from "roman-numbers";
import { Link } from "react-router-dom";
import { useApi, TipoLock } from "ApiHooks";

function aStringSN(valor: boolean | null | undefined) {
    if (valor === null || valor === undefined) {
        return '';
    } else if (valor) {
        return 'S';
    } else {
        return 'N';
    }
}

const Centrado = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    height:100%;
`;

const SelectContainer = styled.div`
    width: '100%';
    display: 'block';
`;

const eventoCampoNumerico = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!e.ctrlKey) {
        let target = e.target as HTMLInputElement;
        if (e.key === ',') {
            e.preventDefault();
            if (!target.value.includes('.')) {
                target.value += '.';
            }
        } else {
            let allowedChars = '0123456789.';
            let invalidKey = (e.key.length === 1 && !allowedChars.includes(e.key))
                || (e.key === '.' && target.value.includes('.'));
            invalidKey && e.preventDefault();
        }
    }
};
const eventoCampoNumericoEnteros = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!e.ctrlKey) {
        let allowedChars = '0123456789';
        let invalidKey = e.key.length === 1 && !allowedChars.includes(e.key);
        invalidKey && e.preventDefault();
    }
};
const plantillaNroItem = (valor: any) => {
    if (valor === null || valor === undefined) {
        return '';
    } else {
        return `${valor}`.padStart(4, '0');
    }
}

export function ArmadoItems() {
    let { interno } = useDecodedParams() as { interno: string };
    let history = useHistory();
    let { mostrarError, userInfo } = useContext(AppContext);
    let api = useApi();
    let [cargando, updateCargando] = useState(true);
    let [notFound, updateNotFound] = useState(false);
    let [mostrarModalReporteLna, updateMostrarModalReporteLna] = useState(false);
    let [mostrarModalReporteValorCriterio, updateMostrarModalReporteValorCriterio] = useState(false);
    let [mostrarModalReporteIntervenciones, updateMostrarModalReporteIntervenciones] = useState(false);
    let [mostrarModalReporteCancelaciones, updateMostrarModalReporteCancelaciones] = useState(false);
    let [mostrarModalAvisos, updateMostrarModalAvisos] = useState(false);
    let [mostrarModalCertificaciones, updateMostrarModalCertificaciones] = useState(false);
    let [mostrarModalMensajeHayAvisos, updateMostrarModalMensajeHayAvisos] = useState(false);
    let [mostrarModalExito, updateMostrarModalExito] = useState(false);
    let [mostrarMensajeLiquidacionRecalculada, updateMostrarMensajeLiquidacionRecalculada] = useState(false);
    let dialogoRef = useRef<DialogoConfirmarRef>(null);
    let [mensajeDialogo, updateMensajeDialogo] = React.useState('');
    //let timeout = useRef<any>(null);
    let [paises, updatePaises] = useState<any[]>([]);
    let [avisos, updateAvisos] = useState<any[]>([]);
    let [certificaciones, updateCertificaciones] = useState<any[]>([]);
    let [reporteIntervenciones, updateReporteIntervenciones] = useState<any[]>([]);
    let [reporteCancelaciones, updateReporteCancelaciones] = useState<any[]>([]);
    let [generandoExcel, updateGenerandoExcel] = useState(false);
    let [estado, updateEstado] = useReducer((estado: any, accion: any) => {
        function recalcularItemsCeroLiquidacion(liquidacion: any[]) {
            let nuevaLiquidacion = liquidacion.filter((liq: any) => liq.NroItem !== 0);
            let itemsCero = liquidacion.filter((liq: any) => liq.NroItem === 0);
            let nuevosItemsCero = [];
            for (let [clave, grupo] of groupBy(nuevaLiquidacion, (liq: any) => ({ Codigo: liq.Codigo, PGC: liq.PGC }))) {
                let itemLiqCero = itemsCero.find((liq: any) => liq.NroItem === 0 && liq.Codigo === clave.Codigo && liq.PGC === clave.PGC);
                if (itemLiqCero) {
                    let baseImponible = grupo.reduce((total: Decimal, liq: any) => total.plus(liq.BaseImponible), new Decimal(0));
                    let total = grupo.reduce((total: Decimal, liq: any) => total.plus(liq.Total), new Decimal(0));
                    let porcentaje = new Decimal(0);
                    if (baseImponible.comparedTo(0) === 1) {
                        porcentaje = total.div(baseImponible).mul(100);
                    }
                    itemLiqCero.BaseImponible = toFixedDecimal(baseImponible, 2);
                    itemLiqCero.Total = toFixedDecimal(total, 2);
                    itemLiqCero.Porcentaje = toFixedDecimal(porcentaje, 2);
                    nuevosItemsCero.push(itemLiqCero);
                } else {
                    itemLiqCero = {
                        NroClienteAlpha: userInfo.nroClienteAlpha,
                        EmpresaId: userInfo.empresaActual,
                        CaratulaId: estado.caratula.Id,
                        Carpeta: estado.caratula.Carpeta,
                        Codigo: clave.Codigo,
                        Descripcion: grupo[0].Descripcion,
                        Motivo: '',
                        NroItem: 0,
                        PGC: clave.PGC,
                    };
                    let baseImponible = grupo.reduce((total: Decimal, liq: any) => total.plus(liq.BaseImponible), new Decimal(0));
                    let total = grupo.reduce((total: Decimal, liq: any) => total.plus(liq.Total), new Decimal(0));
                    let porcentaje = new Decimal(0);
                    if (baseImponible.comparedTo(0) === 1) {
                        porcentaje = total.div(baseImponible).mul(100);
                    }
                    itemLiqCero.BaseImponible = toFixedDecimal(baseImponible, 2);
                    itemLiqCero.Total = toFixedDecimal(total, 2);
                    itemLiqCero.Porcentaje = toFixedDecimal(porcentaje, 2);
                    nuevosItemsCero.push(itemLiqCero);
                }
            }
            return nuevaLiquidacion.concat(nuevosItemsCero);
        }
        if (accion.tipo === 'cargar') {
            return {
                caratula: accion.caratula, opciones: accion.opciones,
                subitems: accion.subitems.map((item: any) => ({ ...item, Clave: item.NroItem + '-' + item.NroSubitem })) ?? [],
                infComps: accion.infComps?.map((item: any) => ({ ...item, Clave: item.NroItem + '-' + item.Codigo })) ?? [],
                cancelaciones: accion.cancelaciones?.map((item: any, indice: number) => ({ ...item, Clave: indice })) ?? [],
                ventajas: accion.ventajas?.map((item: any) => ({ ...item, Clave: item.NroItem + '-' + item.Codigo })) ?? [],
                mapeoSubitems: accion.mapeoSubitems ?? [],
                items: accion.items ?? [], itemActual: 0, cotizaciones: accion.cotizaciones ?? [],
                cancelacionesHabilitadas: accion.cancelacionesHabilitadas, liquidacion: [],
                cargandoLiquidacion: true, errorLiquidacion: false
            }
        } else if (accion.tipo === 'setItems') {
            return {
                ...estado, items: accion.items,
                subitems: accion.subitems.map((item: any) => ({ ...item, Clave: item.NroItem + '-' + item.NroSubitem })), itemActual: accion.nuevoItemActual
            };
        } else if (accion.tipo === 'setItemActual') {
            return { ...estado, itemActual: accion.nuevoItemActual };
        } else if (accion.tipo === 'insertInfComp') {
            let nuevasInfComps = Array.from(estado.infComps);
            if (estado.itemActual === 0) {
                for (let item of estado.items) {
                    if (item.NroItem !== 0 && !estado.infComps.find((infComp: any) =>
                        infComp.NroItem === item.NroItem && infComp.Codigo === accion.infComp.Codigo)) {
                        nuevasInfComps.push({
                            CaratulaId: estado.caratula.Id,
                            Carpeta: estado.caratula.Carpeta,
                            Codigo: accion.infComp.Codigo,
                            Valor: accion.infComp.Valor,
                            NroItem: item.NroItem,
                            Clave: item.NroItem + '-' + accion.infComp.Codigo
                        });
                    }
                }
            } else {
                nuevasInfComps.push({
                    CaratulaId: estado.caratula.Id,
                    Carpeta: estado.caratula.Carpeta,
                    Codigo: accion.infComp.Codigo,
                    Valor: accion.infComp.Valor,
                    NroItem: estado.itemActual,
                    Clave: estado.itemActual + '-' + accion.infComp.Codigo
                });
            }
            return { ...estado, infComps: nuevasInfComps };
        } else if (accion.tipo === 'deleteInfComp') {
            let nuevasInfComps = Array.from(estado.infComps);
            if (accion.infComp.NroItem === 0) {
                nuevasInfComps = nuevasInfComps.filter((infComp: any) => infComp.Codigo !== accion.infComp.Codigo);
            } else {
                nuevasInfComps = nuevasInfComps.filter((infComp: any) => !(infComp.NroItem === accion.infComp.NroItem && infComp.Codigo === accion.infComp.Codigo));
            }
            return { ...estado, infComps: nuevasInfComps };
        } else if (accion.tipo === 'deleteAllInfComps') {
            let nuevasInfComps = Array.from(estado.infComps);
            if (estado.itemActual === 0) {
                for (let elemento of getListaInfCompsItemCero(estado)) {
                    nuevasInfComps = nuevasInfComps.filter((infComp: any) => infComp.Codigo !== elemento.Codigo);
                }
            } else {
                nuevasInfComps = nuevasInfComps.filter((infComp: any) => infComp.NroItem !== estado.itemActual);
            }
            return { ...estado, infComps: nuevasInfComps };
        } else if (accion.tipo === 'insertVentaja') {
            let nuevasVentajas = Array.from(estado.ventajas);
            if (estado.itemActual === 0) {
                for (let item of estado.items) {
                    if (!estado.ventajas.find((ventaja: any) => ventaja.Codigo === accion.ventaja.Codigo && ventaja.NroItem === item.NroItem)) {
                        nuevasVentajas.push({
                            CaratulaId: estado.caratula.Id,
                            Carpeta: estado.caratula.Carpeta,
                            Codigo: accion.ventaja.Codigo,
                            NroItem: item.NroItem,
                            Clave: item.NroItem + '-' + accion.ventaja.Codigo
                        });
                    }
                }
            } else {
                nuevasVentajas.push({
                    CaratulaId: estado.caratula.Id,
                    Carpeta: estado.caratula.Carpeta,
                    Codigo: accion.ventaja.Codigo,
                    NroItem: estado.itemActual,
                    Clave: estado.itemActual + '-' + accion.ventaja.Codigo
                });
            }
            return { ...estado, ventajas: nuevasVentajas };
        } else if (accion.tipo === 'deleteVentaja') {
            let nuevasVentajas = Array.from(estado.ventajas);
            if (accion.ventaja.NroItem === 0) {
                nuevasVentajas = nuevasVentajas.filter((ventaja: any) => ventaja.Codigo !== accion.ventaja.Codigo);
            } else {
                nuevasVentajas = nuevasVentajas.filter((ventaja: any) => !((ventaja.NroItem === 0 ||
                    ventaja.NroItem === accion.ventaja.NroItem) && ventaja.Codigo === accion.ventaja.Codigo));
            }
            return { ...estado, ventajas: nuevasVentajas };
        } else if (accion.tipo === 'insertCancelacion') {
            let nuevasCancelaciones = Array.from(estado.cancelaciones);
            if (accion.cancelacionModificada) {
                let indice = nuevasCancelaciones.findIndex((canc: any) => {
                    if (accion.cancelacionModificada.Id) {
                        return canc.Id === accion.cancelacionModificada.Id;
                    } else {
                        return canc.Indice === accion.cancelacionModificada.Indice;
                    }
                });
                if (indice > -1) {
                    nuevasCancelaciones.splice(indice, 1);
                }
                nuevasCancelaciones.push({
                    ...accion.cancelacion,
                    Id: accion.cancelacionModificada.Id,
                    CaratulaId: estado.caratula.Id,
                    Carpeta: estado.caratula.Carpeta,
                    NroClienteAlpha: userInfo.nroClienteAlpha,
                    EmpresaId: userInfo.empresaActual,
                    NroItem: accion.cancelacionModificada.NroItem,
                    NroSubitem: accion.cancelacionModificada.NroSubitem
                });
            } else {
                nuevasCancelaciones.push({
                    ...accion.cancelacion,
                    CaratulaId: estado.caratula.Id,
                    Carpeta: estado.caratula.Carpeta,
                    NroClienteAlpha: userInfo.nroClienteAlpha,
                    EmpresaId: userInfo.empresaActual,
                    NroItem: estado.itemActual,
                    NroSubitem: 0
                });
            }
            return { ...estado, cancelaciones: nuevasCancelaciones.map((canc: any, indice: number) => ({ ...canc, Indice: indice })) };
        } else if (accion.tipo === 'deleteCancelacion') {
            let nuevasCancelaciones = Array.from(estado.cancelaciones);
            let indice = nuevasCancelaciones.findIndex((canc: any) => {
                if (accion.cancelacion.Id) {
                    return canc.Id === accion.cancelacion.Id;
                } else {
                    return canc.Indice === accion.cancelacion.Indice;
                }
            });
            if (indice > -1) {
                nuevasCancelaciones.splice(indice, 1);
            }
            return { ...estado, cancelaciones: nuevasCancelaciones };
        } else if (accion.tipo === 'setErrorLiquidacion') {
            return { ...estado, cargandoLiquidacion: false, errorLiquidacion: true };
        } else if (accion.tipo === 'setRecargandoLiquidacion') {
            return { ...estado, cargandoLiquidacion: true, errorLiquidacion: false };
        } else if (accion.tipo === 'setLiquidacion') {
            return {
                ...estado, liquidacion: accion.liquidacion.map((item: any) => ({ ...item, Clave: item.NroItem + '-' + item.Codigo + '-' + item.PGC })),
                items: accion.items,
                cargandoLiquidacion: false, errorLiquidacion: false
            };
        } else if (accion.tipo === 'insertLiquidacion') {
            let nuevaLiquidacion: any;
            let nuevasLiquidaciones = Array.from(estado.liquidacion);
            if (accion.liquidacionModificando) {
                nuevaLiquidacion = {
                    ...accion.liquidacionModificando,
                    BaseImponible: accion.liquidacion.BaseImponible,
                    Porcentaje: accion.liquidacion.Porcentaje,
                    Total: toFixedDecimal(new Decimal(accion.liquidacion.BaseImponible).mul(accion.liquidacion.Porcentaje).div(100), 2),
                    Motivo: accion.liquidacion.Motivo,
                    PGC: accion.liquidacion.PGC
                };
                let indice = nuevasLiquidaciones.findIndex((liq: any) => liq.NroItem === accion.liquidacionModificando.NroItem
                    && liq.Codigo === accion.liquidacionModificando.Codigo);
                nuevasLiquidaciones.splice(indice, 1, nuevaLiquidacion);
            } else {
                nuevaLiquidacion = {
                    ...accion.liquidacion,
                    Total: toFixedDecimal(new Decimal(accion.liquidacion.BaseImponible).mul(accion.liquidacion.Porcentaje).div(100), 2),
                    NroClienteAlpha: userInfo.nroClienteAlpha,
                    EmpresaId: userInfo.empresaActual,
                    CaratulaId: estado.caratula.Id,
                    Carpeta: estado.caratula.Carpeta,
                    NroItem: estado.itemActual,
                    Clave: estado.itemActual + '-' + accion.liquidacion.Codigo + '-' + accion.liquidacion.PGC
                };
                nuevasLiquidaciones.push(nuevaLiquidacion);
            }
            return { ...estado, liquidacion: recalcularItemsCeroLiquidacion(nuevasLiquidaciones) };
        } else if (accion.tipo === 'deleteLiquidacion') {
            if (accion.liquidacion.NroItem === 0) {
                return { ...estado, liquidacion: estado.liquidacion.filter((liq: any) => liq.Codigo !== accion.liquidacion.Codigo) };
            } else {
                let nuevasLiquidaciones = Array.from(estado.liquidacion);
                nuevasLiquidaciones = nuevasLiquidaciones.filter((liq: any) => liq.NroItem !== accion.liquidacion.NroItem && liq.Codigo !== accion.liquidacion.Codigo);
                //si no esta el mismo concepto en otro item con el mismo PGC eliminarlo del item cero
                if (!nuevasLiquidaciones.find((liq: any) => liq.NroItem !== 0 && liq.Codigo === accion.liquidacion.Codigo && liq.PGC === accion.liquidacion.PGC)) {
                    nuevasLiquidaciones = nuevasLiquidaciones.filter((liq: any) => !(liq.NroItem === 0 && liq.Codigo === accion.liquidacion.Codigo && liq.PGC === accion.liquidacion.PGC));
                }
                return { ...estado, liquidacion: recalcularItemsCeroLiquidacion(nuevasLiquidaciones) };
            }
        } else if (accion.tipo === 'setReporteLna') {
            return { ...estado, reporteLna: accion.valor ?? [] };
        } else if (accion.tipo === 'setReporteValorCriterio') {
            return { ...estado, reporteValorCriterio: accion.valor ?? [] };
        }
        return { ...estado };
    }, {
        caratula: null, opciones: null, subitems: [], infComps: [], cancelaciones: [], ventajas: [],
        mapeoSubitems: [], items: [], itemActual: null, cotizaciones: [], cancelacionesHabilitadas: false,
        liquidacion: [], cargandoLiquidacion: true, errorLiquidacion: false, reporteLna: [],
        reporteValorCriterio: []
    });
    let [estadoLabels, updateEstadoLabels] = useState<any>({});
    let [estadoCampos, updateEstadoCampos] = useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'reset') {
            return { ...accion.valor, huboCambio: false };
        } else {
            let nuevoEstado = { ...estado };
            nuevoEstado[accion.tipo] = accion.valor;
            nuevoEstado.huboCambio = true;
            return nuevoEstado;
        }
    }, {
        ajusteIncluir: '', ajusteDeducir: '', ajusteIncluirDol: '', ajusteDeducirDol: '',
        kgNeto: '', kgBruto: '', coeficiente: '', flete: '', seguro: '',
        insumosTemporales: '', insumosAConsumo: '', naladisa: '', estadoMerc: '', paisOrigen: '',
        paisProcedencia: '', itemAsoc: '', tipo: '', lista: '', regalias: '', standard: '', codigoDjcp: '',
        huboCambio: false
    });
    let [listasItemActual, updateListasItemActual] = useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'reset') {
            return {
                subitems: accion.subitems, infComps: accion.infComps, ventajas: accion.ventajas,
                cancelaciones: accion.cancelaciones, liquidacion: accion.liquidacion
            };
        } else if (accion.tipo === 'subitems') {
            return { ...estado, subitems: accion.subitems };
        } else if (accion.tipo === 'infComps') {
            return { ...estado, infComps: accion.infComps };
        } else if (accion.tipo === 'ventajas') {
            return { ...estado, ventajas: accion.ventajas };
        } else if (accion.tipo === 'cancelaciones') {
            return { ...estado, cancelaciones: accion.cancelaciones };
        } else if (accion.tipo === 'liquidacion') {
            return { ...estado, liquidacion: accion.liquidacion };
        }
    }, {
        subitems: [], infComps: [], ventajas: [], cancelaciones: [], liquidacion: []
    });
    let [estadoModalInfComps, updateEstadoModalInfComps] = useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrarCrear') {
            return { creando: true, modificando: false, valorModificando: null };
        } else if (accion.tipo === 'mostrarModificar') {
            return { creando: false, modificando: true, valorModificando: accion.valor };
        } else if (accion.tipo === 'esconderVentana') {
            return { creando: false, modificando: false, valorModificando: null };
        }
        return estado;
    }, { creando: false, modificando: false, valorModificando: null });
    let [estadoModalVentajas, updateEstadoModalVentajas] = useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrarCrear') {
            return { creando: true, modificando: false, valorModificando: null };
        } else if (accion.tipo === 'mostrarModificar') {
            return { creando: false, modificando: true, valorModificando: accion.valor };
        } else if (accion.tipo === 'esconderVentana') {
            return { creando: false, modificando: false, valorModificando: null };
        }
        return estado;
    }, { creando: false, modificando: false, valorModificando: null });
    let [estadoModalCancelaciones, updateEstadoModalCancelaciones] = useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrarCrear') {
            return { creando: true, modificando: false, valorModificando: null };
        } else if (accion.tipo === 'mostrarModificar') {
            return { creando: false, modificando: true, valorModificando: accion.valor };
        } else if (accion.tipo === 'esconderVentana') {
            return { creando: false, modificando: false, valorModificando: null };
        }
        return estado;
    }, { creando: false, modificando: false, valorModificando: null });
    let [estadoModalLiquidacion, updateEstadoModalLiquidacion] = useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrarCrear') {
            return { creando: true, modificando: false, valorModificando: null, total: null };
        } else if (accion.tipo === 'mostrarModificar') {
            return { creando: false, modificando: true, valorModificando: accion.valor, total: accion.valor.Total };
        } else if (accion.tipo === 'esconderVentana') {
            return { creando: false, modificando: false, valorModificando: null, total: null };
        } else if (accion.tipo === 'updateTotal') {
            return { ...estado, total: accion.valor };
        }
        return estado;
    }, { creando: false, modificando: false, valorModificando: null });




    let grillaAvisosRef = useRef<GrillaRef>(null);
    let formikInfCompRef = React.useRef<FormikProps<any>>(null);
    let formikVentajasRef = React.useRef<FormikProps<any>>(null);
    let formikCancelacionesRef = React.useRef<FormikProps<any>>(null);
    let formikLiquidacionRef = React.useRef<FormikProps<any>>(null);
    const cargarLiquidacion = useCallback(async () => {
        if (!api.isUnmounted()) {
            try {
                let respuesta = await api.getLiquidacion(interno);
                if (respuesta.Estado === EstadoCaratula.ItemsArmados) {
                    setTimeout(cargarLiquidacion, 10000);
                } else if (respuesta.Estado === EstadoCaratula.ErrorLiquidacion) {
                    updateEstado({ tipo: 'setErrorLiquidacion' });
                } else if (respuesta.Estado === EstadoCaratula.LiquidacionHecha) {
                    let liquidacion = respuesta.LiquidacionesItems;
                    respuesta = await api.getArmadoItems(interno);
                    updateEstado({ tipo: 'setLiquidacion', items: respuesta.Items, liquidacion: liquidacion });
                }
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar liquidacion', error);
                    mostrarError('Error al cargar liquidacion');
                }
            }
        }
        //eslint-disable-next-line
    }, []);
    async function cargarArmadoItems() {
        try {
            updatePaises(await api.getPaises());
            let respuesta = await api.getArmadoItems(interno);
            if (!respuesta) {
                updateNotFound(true);
                return;
            }
            let caratula = respuesta.Caratula;
            if (caratula.Estado === EstadoCaratula.Ninguno || caratula.Estado === EstadoCaratula.ArmadoItemsPendiente ||
                caratula.Estado === EstadoCaratula.ErrorArmadoItems) {
                updateNotFound(true);
                return;
            }
            let subregimenes = await api.getSubregimenes();
            let subregimen = subregimenes.find((item: any) => item.Codigo === caratula.CodigoSubregimen);
            let cotizaciones: any[] = [];
            try {
                cotizaciones = await api.getCotizacionesDivisas();
            } catch (error) {
                if (api.isCancel(error)) {
                    return;
                } else {
                    console.error('Error al cargar cotizaciones', error);
                }
            }
            updateEstado({
                tipo: 'cargar', caratula: caratula, opciones: respuesta.Opciones,
                subitems: respuesta.Subitems, items: respuesta.Items,
                infComps: respuesta.InformacionComplementaria, cancelaciones: respuesta.CancelacionesItems,
                ventajas: respuesta.Ventajas, mapeoSubitems: respuesta.MapeoSubitems,
                cancelacionesHabilitadas: subregimen?.TieneCancelacion ?? false,
                cotizaciones: cotizaciones
            });
            let listaAvisos = await api.getAvisosArmadoItems(interno);
            updateAvisos(listaAvisos?.sort((avisoA: any, avisoB: any) => avisoA.NroItem - avisoB.NroItem)
                ?.map((a: any, indice: number) => ({ ...a, Clave: indice })) ?? []);
            let listaCert = await api.getCertificacionesArmadoItems(interno);
            updateCertificaciones(listaCert?.sort((certA: any, certB: any) => certA.NroItem - certB.NroItem)
                ?.map((a: any, indice: number) => ({ ...a, Clave: indice })) ?? []);
            await cargarLiquidacion();
            updateCargando(false);
            if (listaAvisos?.length > 0 || listaCert?.length > 0) {
                updateMostrarModalMensajeHayAvisos(true);
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al cargar armado items', error);
                mostrarError('Error al cargar armado items');
            }
        }
    }
    useEffect(() => {
        cargarArmadoItems();
        //eslint-disable-next-line
    }, []);

    function getListaInfCompsItemCero(estadoActual: any) {
        let cantidadItems = estadoActual.items.filter((item: any) => item.NroItem !== 0).length;
        let lista = [];
        for (let [clave, grupo] of groupBy(estadoActual.infComps, (infComp: any) => ({ Codigo: infComp.Codigo, Valor: infComp.Valor }))) {
            if (grupo.length === cantidadItems) {
                lista.push({ Codigo: clave.Codigo, Valor: clave.Valor, NroItem: 0 });
            }
        }
        return lista;
    }
    function cargarControles(borrarCampos: boolean) {
        if (estado.itemActual === null || estado.itemActual === undefined) {
            updateEstadoLabels({});
            if (borrarCampos) {
                updateEstadoCampos({
                    tipo: 'reset', valor: {
                        ajusteIncluir: '', ajusteDeducir: '', kgNeto: '', kgBruto: '', coeficiente: '', flete: '', seguro: '',
                        insumosTemporales: '', insumosAConsumo: '', naladisa: '', estadoMerc: '', paisOrigen: '',
                        paisProcedencia: '', itemAsoc: '', tipo: '', lista: '', regalias: '', standard: '', codigoDjcp: ''
                    }
                });
            }
        } else if (estado.itemActual === 0) {
            let totalFob = new Decimal(0);
            let totalFobDol = new Decimal(0);
            let totalFlete = new Decimal(0);
            let totalFleteDol = new Decimal(0);
            let totalSeguro = new Decimal(0);
            let totalSeguroDol = new Decimal(0);
            let totalInsumosConsumo = new Decimal(0);
            let totalInsumosTemporales = new Decimal(0);
            let totalCantidadDeclarada = new Decimal(0);
            let totalKilosNeto = new Decimal(0);
            let totalKilosBruto = new Decimal(0);
            for (let item of estado.items) {
                if (item.NroItem !== 0) {
                    totalFob = totalFob.plus(item.TotalFob ?? 0);
                    totalFobDol = totalFobDol.plus(item.TotalFob_DOL ?? 0);
                    totalFlete = totalFlete.plus(item.Flete ?? 0);
                    totalFleteDol = totalFleteDol.plus(item.Flete_DOL ?? 0);
                    totalSeguro = totalSeguro.plus(item.Seguro ?? 0);
                    totalSeguroDol = totalSeguroDol.plus(item.Seguro_DOL ?? 0);
                    totalInsumosConsumo = totalInsumosConsumo.plus(item.InsumosAConsumo ?? 0);
                    totalInsumosTemporales = totalInsumosTemporales.plus(item.InsumosTemporales ?? 0);
                    totalCantidadDeclarada = totalCantidadDeclarada.plus(item.CantidadDeclarada ?? 0);
                    totalKilosNeto = totalKilosNeto.plus(item.KgNeto ?? 0);
                    totalKilosBruto = totalKilosBruto.plus(item.KgBruto ?? 0);
                }
            }
            let totalAPagar = estado.liquidacion.filter((liq: any) => liq.NroItem === 0 && liq.PGC === 'P')
                .reduce((total: Decimal, liq: any) => total.plus(liq.Total), new Decimal(0));
            let totalACobrar = estado.liquidacion.filter((liq: any) => liq.NroItem === 0 && liq.PGC === 'C')
                .reduce((total: Decimal, liq: any) => total.plus(liq.Total), new Decimal(0));
            let totalAGarantizar = estado.liquidacion.filter((liq: any) => liq.NroItem === 0 && liq.PGC === 'G')
                .reduce((total: Decimal, liq: any) => total.plus(liq.Total), new Decimal(0));
            let itemCero = estado.items.find((item: any) => item.NroItem === 0);
            updateEstadoLabels({
                fob: toFixedDecimal(totalFob, 2),
                fobDol: toFixedDecimal(totalFobDol, 2),
                flete: toFixedDecimal(totalFlete, 2),
                fleteDol: toFixedDecimal(totalFleteDol, 2),
                seguro: toFixedDecimal(totalSeguro, 2),
                seguroDol: toFixedDecimal(totalSeguroDol, 2),
                cantidadDeclarada: toFixedDecimal(totalCantidadDeclarada, 2),
                valorAduana: toFixedDecimal(itemCero.BaseImponible, 2),
                baseIva: toFixedDecimal(itemCero.BaseIva, 2),
                totalAPagar: toFixedDecimal(totalAPagar, 2),
                totalACobrar: toFixedDecimal(totalACobrar, 2),
                totalAGarantizar: toFixedDecimal(totalAGarantizar, 2)
            });
            if (borrarCampos) {
                updateEstadoCampos({
                    tipo: 'reset', valor: {
                        ajusteIncluir: toFixedDecimal(itemCero.AjusteIncluir ?? 0, 2),
                        ajusteDeducir: toFixedDecimal(itemCero.AjusteDeducir ?? 0, 2),
                        ajusteIncluirDol: toFixedDecimal(itemCero.AjusteIncluir_DOL ?? 0, 2),
                        ajusteDeducirDol: toFixedDecimal(itemCero.AjusteDeducir_DOL ?? 0, 2),
                        flete: toFixedDecimal(totalFlete, 2),
                        seguro: toFixedDecimal(totalSeguro, 2),
                        kgNeto: toFixedDecimal(totalKilosNeto, 2),
                        kgBruto: toFixedDecimal(totalKilosBruto, 2),
                        coeficiente: '',
                        insumosTemporales: toFixedDecimal(totalInsumosTemporales, 2),
                        insumosAConsumo: toFixedDecimal(totalInsumosConsumo, 2),
                        naladisa: itemCero.Naladisa, estadoMerc: itemCero.CodigoEstadoMercaderia,
                        paisOrigen: itemCero.CodigoPaisOrigen,
                        paisProcedencia: itemCero.CodigoPaisProcedencia,
                        itemAsoc: itemCero.NroItemAsociado,
                        tipo: itemCero.TipoItem, lista: itemCero.Lista,
                        regalias: aStringSN(itemCero.Regalias),
                        standard: aStringSN(itemCero.Standard), codigoDjcp: ''
                    }
                });
            }
        } else {
            let item = estado.items.find((item: any) => item.NroItem === estado.itemActual);
            let totalAPagar = estado.liquidacion.filter((liq: any) => liq.NroItem === estado.itemActual && liq.PGC === 'P')
                .reduce((total: Decimal, liq: any) => total.plus(liq.Total), new Decimal(0));
            let totalACobrar = estado.liquidacion.filter((liq: any) => liq.NroItem === estado.itemActual && liq.PGC === 'C')
                .reduce((total: Decimal, liq: any) => total.plus(liq.Total), new Decimal(0));
            let totalAGarantizar = estado.liquidacion.filter((liq: any) => liq.NroItem === estado.itemActual && liq.PGC === 'G')
                .reduce((total: Decimal, liq: any) => total.plus(liq.Total), new Decimal(0));
            updateEstadoLabels({
                fob: toFixedDecimal(item.TotalFob, 2),
                fobDol: toFixedDecimal(item.TotalFob_DOL, 2),
                flete: toFixedDecimal(item.Flete ?? 0, 2),
                fleteDol: toFixedDecimal(item.Flete_DOL ?? 0, 2),
                seguro: toFixedDecimal(item.Seguro ?? 0, 2),
                seguroDol: toFixedDecimal(item.Seguro_DOL ?? 0, 2),
                cantidadDeclarada: toFixedDecimal(item.CantidadDeclarada, 2),
                valorAduana: toFixedDecimal(item.BaseImponible, 2),
                baseIva: toFixedDecimal(item.BaseIva, 2),
                ncm: item.Ncm,
                unidadDeclarada: item.CodigoUnidadDeclarada,
                unidadEstadistica: item.CodigoUnidadEstadistica,
                totalAPagar: toFixedDecimal(totalAPagar, 2),
                totalACobrar: toFixedDecimal(totalACobrar, 2),
                totalAGarantizar: toFixedDecimal(totalAGarantizar, 2)
            });
            if (borrarCampos) {
                let stringRegalias = aStringSN(item.Regalias);
                if (isNullOrWhiteSpace(stringRegalias)) {
                    stringRegalias = 'N';
                }
                let stringStandard = aStringSN(item.Standard);
                if (isNullOrWhiteSpace(stringStandard)) {
                    stringStandard = 'N';
                }
                updateEstadoCampos({
                    tipo: 'reset', valor: {
                        flete: toFixedDecimal(item.Flete ?? 0, 2),
                        seguro: toFixedDecimal(item.Seguro ?? 0, 2),
                        ajusteIncluir: toFixedDecimal(item.AjusteIncluir ?? 0, 2),
                        ajusteDeducir: toFixedDecimal(item.AjusteDeducir ?? 0, 2),
                        ajusteIncluirDol: toFixedDecimal(item.AjusteIncluir_DOL ?? 0, 2),
                        ajusteDeducirDol: toFixedDecimal(item.AjusteDeducir_DOL ?? 0, 2),
                        kgNeto: toFixedDecimal(item.KgNeto, 2),
                        kgBruto: toFixedDecimal(item.KgBruto, 2),
                        coeficiente: toFixedDecimal(item.CoeficienteExpo, 5),
                        insumosTemporales: toFixedDecimal(item.InsumosAConsumo, 2),
                        insumosAConsumo: toFixedDecimal(item.InsumosTemporales, 2),
                        naladisa: item.Naladisa, estadoMerc: item.CodigoEstadoMercaderia,
                        paisOrigen: item.CodigoPaisOrigen,
                        paisProcedencia: item.CodigoPaisProcedencia,
                        itemAsoc: item.NroItemAsociado,
                        tipo: item.TipoItem, lista: item.Lista,
                        regalias: stringRegalias,
                        standard: stringStandard, codigoDjcp: item.CodigoEAN
                    }
                });
            }
        }
    }
    function grabarDatosIngresadosALosItems(nuevoItemActual?: number) {
        let nuevosItems = estado.items.map((item: any) => ({ ...item }));
        let nuevosSubitems = estado.subitems.map((subitem: any) => ({ ...subitem }));
        if (!estadoCampos.huboCambio || estado.itemActual === null || estado.itemActual === undefined || estado.caratula?.Bloqueada) {
            updateEstado({ tipo: 'setItems', items: nuevosItems, subitems: nuevosSubitems, nuevoItemActual: nuevoItemActual ?? estado.itemActual });
            return { items: nuevosItems, subitems: nuevosSubitems };
        }
        let itemCero = nuevosItems.find((item: any) => item.NroItem === 0);
        if (estado.itemActual === 0) {
            itemCero.CodigoPaisOrigen = estadoCampos.paisOrigen;
            itemCero.CodigoPaisProcedencia = estadoCampos.paisProcedencia;
            itemCero.CodigoEstadoMercaderia = estadoCampos.estadoMerc;
            let fleteValido = false;
            if (isNullOrWhiteSpace(estadoCampos.flete)) {
                itemCero.Flete = 0;
            } else {
                let flete = toFixedDecimal(estadoCampos.flete, 2);
                if (!isNullOrWhiteSpace(estadoCampos.flete)) {
                    fleteValido = true;
                    itemCero.Flete = flete;
                }
            }
            let seguroValido = false;
            if (isNullOrWhiteSpace(estadoCampos.seguro)) {
                itemCero.Seguro = 0;
            } else {
                let seguro = toFixedDecimal(estadoCampos.seguro, 2);
                if (!isNullOrWhiteSpace(estadoCampos.seguro)) {
                    seguroValido = true;
                    itemCero.Seguro = seguro;
                }
            }
            let ajusteIncluirValido = false;
            if (isNullOrWhiteSpace(estadoCampos.ajusteIncluir)) {
                itemCero.AjusteIncluir = 0;
            } else {
                let ajusteIncluir = toFixedDecimal(estadoCampos.ajusteIncluir, 2);
                if (!isNullOrWhiteSpace(estadoCampos.ajusteIncluir)) {
                    ajusteIncluirValido = true;
                    itemCero.AjusteIncluir = ajusteIncluir;
                }
            }
            let ajusteDeducirValido = false;
            if (isNullOrWhiteSpace(estadoCampos.ajusteDeducir)) {
                itemCero.AjusteDeducir = 0;
            } else {
                let ajusteDeducir = toFixedDecimal(estadoCampos.ajusteDeducir, 2);
                if (!isNullOrWhiteSpace(estadoCampos.ajusteDeducir)) {
                    ajusteDeducirValido = true;
                    itemCero.AjusteDeducir = ajusteDeducir;
                }
            }
            let kgNetoValido = false;
            if (isNullOrWhiteSpace(estadoCampos.kgNeto)) {
                itemCero.KgNeto = 0;
            } else {
                let kgNeto = toFixedDecimal(estadoCampos.kgNeto, 2);
                if (!isNullOrWhiteSpace(estadoCampos.kgNeto)) {
                    //kgNetoValido = true;
                    itemCero.KgNeto = kgNeto;
                }
            }
            let kgBrutoValido = false;
            if (isNullOrWhiteSpace(estadoCampos.kgBruto)) {
                itemCero.KgBruto = 0;
            } else {
                let kgBruto = toFixedDecimal(estadoCampos.kgBruto, 2);
                if (!isNullOrWhiteSpace(estadoCampos.kgBruto)) {
                    kgBrutoValido = true;
                    itemCero.KgBruto = kgBruto;
                }
            }
            itemCero.Naladisa = estadoCampos.naladisa;
            itemCero.CodigoEAN = estadoCampos.codigoDjcp;
            itemCero.TipoItem = estadoCampos.tipo;
            itemCero.Lista = estadoCampos.lista;
            let itemAsociadoValido = false;
            if (isNullOrWhiteSpace(estadoCampos.itemAsoc)) {
                itemCero.NroItemAsociado = null;
            } else {
                let itemAsoc = parseInt(estadoCampos.itemAsoc);
                if (!isNaN(itemAsoc)) {
                    itemAsociadoValido = true;
                    itemCero.NroItemAsociado = itemAsoc;
                }
            }
            if (estadoCampos.standard?.trim()?.toUpperCase() === "S") {
                itemCero.Standard = true;
            } else if (estadoCampos.standard?.trim()?.toUpperCase() === "N") {
                itemCero.Standard = false;
            } else {
                itemCero.Standard = undefined;
            }
            if (estadoCampos.regalias?.trim()?.toUpperCase() === "S") {
                itemCero.Regalias = true;
            } else if (estadoCampos.regalias?.trim()?.toUpperCase() === "N") {
                itemCero.Regalias = false;
            } else {
                itemCero.Regalias = undefined;
            }
            for (let item of nuevosItems) {
                if (item !== itemCero) {
                    if (!isNullOrWhiteSpace(itemCero.CodigoPaisOrigen)) {
                        item.CodigoPaisOrigen = itemCero.CodigoPaisOrigen;
                    }
                    if (!isNullOrWhiteSpace(itemCero.CodigoPaisProcedencia)) {
                        item.CodigoPaisProcedencia = itemCero.CodigoPaisProcedencia;
                    }
                    if (!isNullOrWhiteSpace(itemCero.CodigoEstadoMercaderia)) {
                        item.CodigoEstadoMercaderia = itemCero.CodigoEstadoMercaderia;
                    }
                    if (!isNullOrWhiteSpace(itemCero.Naladisa)) {
                        item.Naladisa = itemCero.Naladisa;
                    }
                    if (fleteValido) {
                        let flete = new Decimal(itemCero.Flete);
                        if (flete.comparedTo(0) === 1) {
                            item.Flete = toFixedDecimal(flete.mul(item.TotalFob).div(estado.caratula.TotalFob), 2);
                        }
                    }
                    if (seguroValido) {
                        let seguro = new Decimal(itemCero.Seguro);
                        if (seguro.comparedTo(0) === 1) {
                            item.Seguro = toFixedDecimal(seguro.mul(item.TotalFob).div(estado.caratula.TotalFob), 2);
                        }
                    }
                    if (ajusteIncluirValido) {
                        let ajusteIncluir = new Decimal(itemCero.AjusteIncluir);
                        if (ajusteIncluir.comparedTo(0) === 1) {
                            item.AjusteIncluir = toFixedDecimal(ajusteIncluir.mul(item.TotalFob).div(estado.caratula.TotalFob), 2);
                        }
                    }
                    if (ajusteDeducirValido) {
                        let ajusteDeducir = new Decimal(itemCero.AjusteDeducir);
                        if (ajusteDeducir.comparedTo(0) === 1) {
                            item.AjusteDeducir = toFixedDecimal(ajusteDeducir.mul(item.TotalFob).div(estado.caratula.TotalFob), 2);
                        }
                    }
                    if (kgNetoValido) {
                        let kgNeto = new Decimal(itemCero.KgNeto);
                        if (kgNeto.comparedTo(0) === 1) {
                            let kgNetoItem = kgNeto.mul(item.TotalFob).div(estado.caratula.TotalFob);
                            item.KgNeto = toFixedDecimal(kgNetoItem, 2);
                            for (let subitem of nuevosSubitems.filter((subitem: any) => subitem.NroItem === item.NroItem)) {
                                subitem.KgNeto = toFixedDecimal(kgNetoItem.mul(subitem.TotalFob).div(item.TotalFob), 2);
                                if (subitem.CodigoUnidadEstadistica === '01') {
                                    subitem.CantidadEstadistica = subitem.KgNeto;
                                }
                            }
                        }
                    }
                    if (kgBrutoValido) {
                        let kgBruto = new Decimal(itemCero.KgBruto);
                        if (kgBruto.comparedTo(0) === 1) {
                            item.KgBruto = toFixedDecimal(kgBruto.mul(item.TotalFob).div(estado.caratula.TotalFob), 2);
                        }
                    }
                    // if (!isNullOrWhiteSpace(itemCero.CodigoEAN)) {
                    //     item.CodigoEAN = itemCero.CodigoEAN;
                    // }
                    if (!isNullOrWhiteSpace(itemCero.Lista)) {
                        item.Lista = itemCero.Lista;
                    }
                    if (!isNullOrWhiteSpace(itemCero.TipoItem)) {
                        item.TipoItem = itemCero.TipoItem;
                    }
                    if (itemAsociadoValido) {
                        item.NroItemAsociado = itemCero.NroItemAsociado;
                    }
                    if (itemCero.Standard !== null && itemCero.Standard !== undefined) {
                        item.Standard = itemCero.Standard;
                    }
                    if (itemCero.Regalias !== null && itemCero.Regalias !== undefined) {
                        item.Regalias = itemCero.Regalias;
                    }
                }
            }

        } else {
            let item = nuevosItems.find((item: any) => item.NroItem === estado.itemActual);
            item.CodigoPaisOrigen = estadoCampos.paisOrigen;
            item.CodigoPaisProcedencia = estadoCampos.paisProcedencia;
            item.CodigoEstadoMercaderia = estadoCampos.estadoMerc;
            item.Naladisa = estadoCampos.naladisa;
            if (nuevosSubitems.filter((subitem: any) => subitem.NroItem === estado.itemActual).length === 1) {
                item.CodigoEAN = estadoCampos.codigoDjcp;
            }
            item.TipoItem = estadoCampos.tipo;
            item.Lista = estadoCampos.lista;
            let flete = toFixedDecimal(estadoCampos.flete, 2);
            if (!isNullOrWhiteSpace(flete)) {
                item.Flete = flete;
                itemCero.Flete = nuevosItems.filter((item: any) => item.NroItem !== 0).reduce((total: Decimal, item: any) => total.plus(item.Flete), new Decimal(0));
            }
            let seguro = toFixedDecimal(estadoCampos.seguro, 2);
            if (!isNullOrWhiteSpace(flete)) {
                item.Seguro = seguro;
                itemCero.Seguro = nuevosItems.filter((item: any) => item.NroItem !== 0).reduce((total: Decimal, item: any) => total.plus(item.Seguro), new Decimal(0));
            }
            let ajusteIncluir = toFixedDecimal(estadoCampos.ajusteIncluir, 2);
            if (!isNullOrWhiteSpace(ajusteIncluir)) {
                item.AjusteIncluir = ajusteIncluir;
                itemCero.AjusteIncluir = 0;
            }
            let ajusteDeducir = toFixedDecimal(estadoCampos.ajusteDeducir, 2);
            if (!isNullOrWhiteSpace(ajusteDeducir)) {
                item.AjusteDeducir = ajusteDeducir;
                itemCero.AjusteDeducir = 0;
            }
            let kgNeto = toFixedDecimal(estadoCampos.kgNeto, 2);
            if (!isNullOrWhiteSpace(kgNeto)) {
                item.KgNeto = kgNeto;
                itemCero.KgNeto = 0;
                for (let subitem of nuevosSubitems.filter((subitem: any) => subitem.NroItem === item.NroItem)) {
                    subitem.KgNeto = toFixedDecimal(new Decimal(kgNeto).mul(subitem.TotalFob).div(item.TotalFob), 2);
                    if (subitem.CodigoUnidadEstadistica === '01') {
                        subitem.CantidadEstadistica = subitem.KgNeto;
                    }
                }
            }
            let kgBruto = toFixedDecimal(estadoCampos.kgBruto, 2);
            if (!isNullOrWhiteSpace(kgBruto)) {
                item.KgBruto = kgBruto;
                itemCero.KgBruto = 0;
            }
            if (!isNullOrWhiteSpace(estadoCampos.itemAsoc)) {
                let itemAsoc = parseInt(estadoCampos.itemAsoc);
                if (!isNaN(itemAsoc)) {
                    item.NroItemAsociado = itemAsoc;
                }
            }
            if (estadoCampos.standard?.trim()?.toUpperCase() === "S") {
                item.Standard = true;
                if (!itemCero.Standard) {
                    itemCero.Standard = undefined;
                }
            } else {
                item.Standard = false;
                if (itemCero.Standard) {
                    itemCero.Standard = undefined;
                }
            }
            if (estadoCampos.regalias?.trim()?.toUpperCase() === "S") {
                item.Regalias = true;
                if (!itemCero.Regalias) {
                    itemCero.Regalias = undefined;
                }
            } else {
                item.Regalias = false;
                if (itemCero.Regalias) {
                    itemCero.Regalias = undefined;
                }
            }
        }
        for (let item of nuevosItems) {
            try {
                if (!isNullOrWhiteSpace(estado.caratula.CodigoDivisaSeguro)) {
                    let divisaSeg = estado.cotizaciones.find((cot: any) => cot.CodigoSIM === estado.caratula.CodigoDivisaSeguro);
                    if (divisaSeg) {
                        item.Seguro_DOL = toFixedDecimal(new Decimal(item.Seguro).mul(divisaSeg.TipoPaseVenta), 2);
                    } else {
                        item.Seguro_DOL = toFixedDecimal(new Decimal(item.Seguro).mul(estado.caratula.TipoCambioSeguro), 2);
                    }
                }
                if (!isNullOrWhiteSpace(estado.caratula.CodigoDivisaFlete)) {
                    let divisaFlete = estado.cotizaciones.find((cot: any) => cot.CodigoSIM === estado.caratula.CodigoDivisaFlete);
                    if (divisaFlete) {
                        item.Flete_DOL = toFixedDecimal(new Decimal(item.Flete).mul(divisaFlete.TipoPaseVenta), 2);
                    } else {
                        item.Flete_DOL = toFixedDecimal(new Decimal(item.Flete).mul(estado.caratula.TipoCambioFlete), 2);
                    }
                }
                let divisaFob = estado.cotizaciones.find((cot: any) => cot.CodigoSIM === estado.caratula.CodigoDivisaFob);
                if (divisaFob) {
                    item.TotalFob_DOL = toFixedDecimal(new Decimal(item.TotalFob).mul(divisaFob.TipoPaseVenta), 2);
                    if (!isNullOrWhiteSpace(item.AjusteIncluir)) {
                        item.AjusteIncluir_DOL = toFixedDecimal(new Decimal(item.AjusteIncluir).mul(divisaFob.TipoPaseVenta), 2);
                    }
                    if (!isNullOrWhiteSpace(item.AjusteDeducir)) {
                        item.AjusteDeducir_DOL = toFixedDecimal(new Decimal(item.AjusteDeducir).mul(divisaFob.TipoPaseVenta), 2);
                    }
                } else {
                    item.TotalFob_DOL = toFixedDecimal(new Decimal(item.TotalFob).mul(estado.caratula.TipoCambioFob), 2);
                    if (!isNullOrWhiteSpace(item.AjusteIncluir)) {
                        item.AjusteIncluir_DOL = toFixedDecimal(new Decimal(item.AjusteIncluir).mul(estado.caratula.TipoCambioFob), 2);
                    }
                    if (!isNullOrWhiteSpace(item.AjusteDeducir)) {
                        item.AjusteDeducir_DOL = toFixedDecimal(new Decimal(item.AjusteDeducir).mul(estado.caratula.TipoCambioFob), 2);
                    }
                }
            } catch (error) {
                console.error('Error al calcular precios en dolares', error);
            }
        }
        updateEstado({ tipo: 'setItems', items: nuevosItems, subitems: nuevosSubitems, nuevoItemActual: nuevoItemActual ?? estado.itemActual });
        //updateEstado es asincrono por lo tanto se retornan los items y los subitems para la funcion que los guarda
        return { items: nuevosItems, subitems: nuevosSubitems };
    }

    useEffect(() => {
        cargarControles(true);
        //eslint-disable-next-line
    }, [estado.itemActual]);
    useEffect(() => {
        cargarControles(false);
        //eslint-disable-next-line
    }, [estado.items, estado.liquidacion]);
    useEffect(() => {
        if (estado.itemActual !== null && estado.itemActual !== undefined) {
            if (estado.itemActual === 0) {
                updateListasItemActual({ tipo: 'infComps', infComps: getListaInfCompsItemCero(estado) });
            } else {
                updateListasItemActual({ tipo: 'infComps', infComps: estado.infComps.filter((infComp: any) => infComp.NroItem === estado.itemActual) });
            }
        }
        //eslint-disable-next-line
    }, [estado.itemActual, estado.infComps]);
    useEffect(() => {
        if (estado.itemActual !== null && estado.itemActual !== undefined) {
            updateListasItemActual({ tipo: 'ventajas', ventajas: estado.ventajas.filter((ventaja: any) => ventaja.NroItem === estado.itemActual) });
        }
    }, [estado.itemActual, estado.ventajas]);
    useEffect(() => {
        if (estado.itemActual !== null && estado.itemActual !== undefined) {
            if (estado.itemActual === 0) {
                updateListasItemActual({ tipo: 'cancelaciones', cancelaciones: estado.cancelaciones });
            } else {
                updateListasItemActual({ tipo: 'cancelaciones', cancelaciones: estado.cancelaciones.filter((canc: any) => canc.NroItem === estado.itemActual) });
            }
        }
    }, [estado.itemActual, estado.cancelaciones]);
    useEffect(() => {
        if (estado.itemActual !== null && estado.itemActual !== undefined) {
            if (estado.itemActual === 0) {
                updateListasItemActual({ tipo: 'subitems', subitems: estado.subitems });
            } else {
                updateListasItemActual({ tipo: 'subitems', subitems: estado.subitems.filter((subitem: any) => subitem.NroItem === estado.itemActual) });
            }
        }
    }, [estado.itemActual, estado.subitems]);
    useEffect(() => {
        if (estado.itemActual !== null && estado.itemActual !== undefined) {
            updateListasItemActual({
                tipo: 'liquidacion',
                liquidacion: estado.liquidacion.filter((liq: any) => liq.NroItem === estado.itemActual).sort((liqA: any, liqB: any) => strCmp(liqA.Codigo, liqB.Codigo))
            });
        }
    }, [estado.itemActual, estado.liquidacion]);
    useEffect(() => {
        grillaAvisosRef.current?.recargar();
    }, [avisos, mostrarModalAvisos, paises]);
    useEffect(() => {
        let reporte = estado.subitems.filter((subitem: any) => subitem.AnexoLNA !== null && subitem.AnexoLNA !== undefined)
            .map((subitem: any) => {
                let modelo = obtenerSufijoModelo(subitem.Sufijos);
                if (isNullOrWhiteSpace(modelo)) {
                    modelo = subitem.IdArticulo;
                }
                return {
                    Clave: subitem.Clave, AnexoLNA: subitem.AnexoLNA, Ncm: subitem.Ncm, IdArticulo: modelo,
                    Descripcion: subitem.Descripcion, NroItem: subitem.NroItem
                };
            }).sort((a: any, b: any) => a.NroItem - b.NroItem);
        updateEstado({ tipo: 'setReporteLna', valor: reporte });
        reporte = estado.subitems.filter((subitem: any) => subitem.TieneValorCriterio).map((subitem: any) => ({
            ...subitem,
            NombrePaisOrigen: paises.find((p: any) => p.Codigo === subitem.CodigoPaisOrigen)?.Descripcion ?? subitem.CodigoPaisOrigen
        })).sort((a: any, b: any) => a.NroItem - b.NroItem);
        updateEstado({ tipo: 'setReporteValorCriterio', valor: reporte });
    }, [paises, estado.subitems]);
    let camposGrillaItems = [{ titulo: 'Nro. Item', propiedad: 'NroItem', clave: true, plantillaFormato: plantillaNroItem },
    { titulo: 'NCM', propiedad: 'Ncm' }, { titulo: 'Unidad', propiedad: 'CodigoUnidadDeclarada' },
    { titulo: 'Estadistica', propiedad: 'CodigoUnidadEstadistica' }, { titulo: 'Origen', propiedad: 'CodigoPaisOrigen' },
    { titulo: 'Procedencia', propiedad: 'CodigoPaisProcedencia' }, { titulo: 'Lista', propiedad: 'Lista' },
    { titulo: 'Filler', propiedad: 'Filler' }, { titulo: 'Descripción María', propiedad: 'Descripcion' }];
    let camposGrillaSubitems = [{ propiedad: 'Clave', visible: false, clave: true },
    { titulo: 'Nro. Item', propiedad: 'NroItem', plantillaFormato: plantillaNroItem },
    { titulo: 'Nro. Subitem', propiedad: 'NroSubitem', plantillaFormato: plantillaNroItem },
    { titulo: 'Unitario', propiedad: 'PrecioUnitario', tipo: TipoCampo.Number, decimales: 5 },
    { titulo: 'Cantidad', propiedad: 'CantidadDeclarada', tipo: TipoCampo.Number },
    { titulo: 'FOB', propiedad: 'TotalFob', tipo: TipoCampo.Number }, { titulo: "Estadistica", propiedad: 'CodigoUnidadEstadistica' },
    { titulo: 'Articulo', propiedad: 'IdArticulo' }, { titulo: 'Sufijos', propiedad: 'Sufijos' }];
    let camposGrillaCancelaciones = [{ propiedad: 'Clave', visible: false, clave: true },
    { titulo: 'Nro. Item', propiedad: 'NroItem' }, { titulo: 'Dit', propiedad: 'Dit' },
    { titulo: 'NCM', propiedad: 'Ncm' }, { titulo: 'Cantidad', propiedad: 'Cantidad', tipo: TipoCampo.Number },
    { titulo: 'Factor Ponderación', propiedad: 'FactorPonderacion', tipo: TipoCampo.Number },
    { titulo: 'Expediente', propiedad: 'Expctit' }, { titulo: 'Nro. Item Canc', propiedad: 'NroItemCancelado' },
    { titulo: 'Nro. Subitem Canc', propiedad: 'NroSubitemCancelado' }];
    let camposGrillaLiquidacion = [{ propiedad: 'Clave', visible: false, clave: true },
    { propiedad: 'Codigo', titulo: 'Codigo' }, { propiedad: 'Descripcion', titulo: 'Descripción' },
    { propiedad: 'Porcentaje', titulo: '%', tipo: TipoCampo.Number },
    { propiedad: 'BaseImponible', titulo: 'Base Imp.', tipo: TipoCampo.Number },
    { propiedad: 'Total', titulo: 'Total', tipo: TipoCampo.Number }, { propiedad: 'PGC', titulo: 'PGC' },
    { propiedad: 'Motivo', titulo: 'Motivo' }];
    let camposGrillaAvisos = [{ propiedad: 'Clave', visible: false, clave: true },
    { propiedad: 'NroItem', titulo: 'Nro. Item', plantillaFormato: plantillaNroItem },
    { propiedad: 'TextoAviso', titulo: 'Texto Aviso' }, { propiedad: 'Ncm', titulo: 'Ncm' },
    { propiedad: 'PaisOrigen', titulo: 'Pais Origen' }, { propiedad: 'Catalogo', titulo: 'Catalogo' },
    { propiedad: 'Articulo', titulo: 'Articulo' }];
    let camposGrillaCertificaciones = [{ propiedad: 'Clave', visible: false, clave: true },
    { propiedad: 'NroItem', titulo: 'Nro. Item', plantillaFormato: plantillaNroItem },
    { propiedad: 'Tipo', titulo: 'Tipo' }, { propiedad: 'Numero', titulo: 'Número' },
    { propiedad: 'Descripcion', titulo: 'Descripción' },
    { propiedad: 'FechaVencimiento', titulo: 'Fecha Vencimiento', tipo: TipoCampo.Date },
    { propiedad: 'DiasAVencimiento', titulo: 'Días A Vencimiento', tipo: TipoCampo.Number, decimales: 0 },
    { propiedad: 'Catalogo', titulo: 'Catalogo' },
    { propiedad: 'Articulo', titulo: 'Articulo' }];
    let camposGrillaReporteLna = [{ propiedad: 'Clave', visible: false, clave: true },
    { propiedad: 'AnexoLNA', titulo: 'Anexo', plantillaFormato: arabToRoman },
    { propiedad: 'NroItem', titulo: 'Nro. Item', plantillaFormato: plantillaNroItem },
    { propiedad: 'Ncm', titulo: 'Posición' }, { propiedad: 'IdArticulo', titulo: 'Cod. Articulo' },
    { propiedad: 'Descripcion', titulo: 'Descripción' }];
    let camposGrillaReporteValorCriterio = [{ propiedad: 'Clave', visible: false, clave: true },
    { propiedad: 'NroItem', titulo: 'Nro. Item', plantillaFormato: plantillaNroItem },
    { propiedad: 'NombrePaisOrigen', titulo: 'País Origen' },
    { propiedad: 'Ncm', titulo: 'Posición' }, { propiedad: 'IdArticulo', titulo: 'Cod. Articulo' },
    { propiedad: 'Descripcion', titulo: 'Descripción' }];
    let camposGrillaReporteIntervenciones = [
        { propiedad: 'Intervenciones', titulo: 'Intervenciones' },
        { propiedad: 'Orden', titulo: 'Orden', clave: true },
        { propiedad: 'Articulo', titulo: 'Articulo' }, { propiedad: 'Ncm', titulo: 'Ncm' },
        { propiedad: 'NombreUnidadDeclarada', titulo: 'Unidad Declarada' },
        { propiedad: 'CantidadDeclarada', titulo: 'Cantidad Declarada' }, { propiedad: 'TotalFob', titulo: 'Total FOB' },
        { propiedad: 'KgNeto', titulo: 'Kg Neto' }, { propiedad: 'Sufijos', titulo: "Sufijos" },
        { propiedad: 'NombrePaisOrigen', titulo: 'Pais Origen' }, { propiedad: 'CodigoEAN', titulo: 'DJCP' },
        { propiedad: 'Filler', titulo: 'Filler' }, { propiedad: 'NroItemMaria', titulo: 'Nro. Item', plantillaFormato: plantillaNroItem },
        { propiedad: 'NroSubitemMaria', titulo: 'Nro. Subitem', plantillaFormato: plantillaNroItem },
        { propiedad: 'DescripcionMaria', titulo: 'Descripcion Maria' }, { propiedad: 'Ventajas', titulo: 'Ventajas' },
    ];
    let camposGrillaReporteCancelaciones = [{ propiedad: 'Orden', clave: true, visible: false },
    { titulo: 'Destinación Item' }, { titulo: 'Dit' },
    { propiedad: 'NroItemMaria', titulo: 'Nro. Item', plantillaFormato: plantillaNroItem },
    { propiedad: 'NroSubitemMaria', titulo: 'Nro. Subitem', plantillaFormato: plantillaNroItem },
    { propiedad: 'Ncm', titulo: 'NCM' }, { propiedad: 'CantidadDeclarada', titulo: 'Cantidad', tipo: TipoCampo.Number },
    { titulo: 'Certificado' }, { titulo: 'Factor Ponderación' }];
    async function guardar(mostrarModalAlTerminar?: boolean) {
        if (estado.caratula?.Bloqueada) {
            return true;
        }
        const { items, subitems } = grabarDatosIngresadosALosItems();
        updateCargando(true);
        try {
            let puedeGuardar = await api.obtenerLock(TipoLock.Caratula, interno);
            if (puedeGuardar) {
                if (estado.liquidacion.length > 0) {
                    //no tocar las bases imponibles
                    api.insertLiquidacion(estado.caratula.Id, estado.liquidacion, null);
                }
                let respuesta = await api.insertArmadoItems(estado.caratula.Id, items, subitems,
                    estado.mapeoSubitems, estado.infComps, estado.cancelaciones, estado.ventajas);
                let liquidacionRecalculada = respuesta.LiquidacionRecalculada;
                await api.eliminarLock(TipoLock.Caratula, interno);
                if (liquidacionRecalculada) {
                    updateEstado({ tipo: 'setRecargandoLiquidacion' });
                    await cargarLiquidacion();
                }
                respuesta = await api.getAvisosArmadoItems(interno);
                updateAvisos(respuesta?.sort((avisoA: any, avisoB: any) => avisoA.NroItem - avisoB.NroItem) ?? []);
                respuesta = await api.getCertificacionesArmadoItems(interno);
                updateCertificaciones(respuesta?.sort((certA: any, certB: any) => certA.NroItem - certB.NroItem)
                    ?.map((a: any, indice: number) => ({ ...a, Clave: indice })) ?? []);
                updateCargando(false);
                if (mostrarModalAlTerminar) {
                    updateMostrarMensajeLiquidacionRecalculada(liquidacionRecalculada);
                    updateMostrarModalExito(true);
                }
                return true;
            } else {
                updateCargando(false);
                mostrarError(`No se pueden guardar los cambios porque otra persona la está utilizando la caratula ${interno}`);
                return false;
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al guardar armado de items', error);
                updateCargando(false);
                mostrarError('Error al guardar armado de items');
            }
            return false;
        }
    }
    async function exportarItemsAExcel() {
        try {
            updateGenerandoExcel(true);
            let { fileName, excel } = await api.exportarArmadoItemsAExcel(interno);
            updateGenerandoExcel(false);
            FileSaver.saveAs(excel, fileName);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error(error);
                mostrarError('Error al exportar reporte excel armado de items');
            }
            if (!api.isUnmounted()) {
                updateGenerandoExcel(false);
            }
        }
    }
    async function exportarAvisosAExcel() {
        try {
            updateGenerandoExcel(true);
            let { fileName, excel } = await api.exportarAvisosArmadoItemsAExcel(interno);
            updateGenerandoExcel(false);
            FileSaver.saveAs(excel, fileName);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error(error);
                mostrarError('Error al exportar reporte avisos excel');
            }
            if (!api.isUnmounted()) {
                updateGenerandoExcel(false);
            }
        }
    }
    async function exportarCertificacionesAExcel() {
        try {
            updateGenerandoExcel(true);
            let { fileName, excel } = await api.exportarCertificacionesArmadoItemsAExcel(interno);
            updateGenerandoExcel(false);
            FileSaver.saveAs(excel, fileName);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error(error);
                mostrarError('Error al exportar reporte certificaciones excel');
            }
            if (!api.isUnmounted()) {
                updateGenerandoExcel(false);
            }
        }
    }
    async function exportarReporteLnaAExcel(ordenarPorAnexo: boolean) {
        try {
            updateGenerandoExcel(true);
            let { excel, fileName } = await api.exportarReporteLnaArmadoItemsAExcel(interno, ordenarPorAnexo);
            updateGenerandoExcel(false);
            FileSaver.saveAs(excel, fileName);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error(error);
                mostrarError('Error al exportar reporte items con lna a Excel');
            }
            if (!api.isUnmounted()) {
                updateGenerandoExcel(false);
            }
        }
    }
    async function exportarReporteValorCriterioAExcel() {
        try {
            updateGenerandoExcel(true);
            let { excel, fileName } = await api.exportarReporteValorCriterioArmadoItemsExcel(interno);
            updateGenerandoExcel(false)
            FileSaver.saveAs(excel, fileName);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error(error);
                mostrarError('Error al exportar reporte items con valor criterio a Excel');
            }
            if (!api.isUnmounted()) {
                updateGenerandoExcel(false);
            }
        }
    }
    async function cargarReporteIntervenciones() {
        if (await guardar(false)) {
            try {
                updateCargando(true);
                let respuesta = await api.getReporteSubitemsCaratulaConIntervenciones(interno);
                let unidades = await api.getUnidades();
                let reporte = respuesta?.map((subitem: any) => ({
                    ...subitem,
                    NombrePaisOrigen: paises.find((p: any) => p.Codigo === subitem.CodigoPaisOrigen)?.Descripcion ?? subitem.CodigoPaisOrigen,
                    NombreUnidadDeclarada: unidades.find((u: any) => u.Codigo === subitem.CodigoUnidadDeclarada)?.Descripcion ?? subitem.CodigoUnidadDeclarada
                })) ?? [];
                updateReporteIntervenciones(reporte);
                updateCargando(false);
                updateMostrarModalReporteIntervenciones(true);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error(error);
                    mostrarError('Error al cargar reporte intervenciones');
                    updateCargando(false);
                }
            }
        }
    }
    async function exportarReporteIntervencionesAExcel() {
        try {
            updateGenerandoExcel(true);
            let { excel, fileName } = await api.exportarReporteSubitemsCaratulaConIntervencionesAExcel(interno);
            updateGenerandoExcel(false);
            FileSaver.saveAs(excel, fileName);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error(error);
                mostrarError('Error al exportar reporte intervenciones subitems a Excel');
            }
            if (!api.isUnmounted()) {
                updateGenerandoExcel(false);
            }
        }
    }
    async function cargarReporteCancelaciones() {
        try {
            updateCargando(true);
            let respuesta = await api.getReporteParaCancelaciones(interno);
            updateReporteCancelaciones(respuesta ?? []);
            updateCargando(false);
            updateMostrarModalReporteCancelaciones(true);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error(error);
                mostrarError('Error al cargar reporte cancelaciones');
                updateCargando(false);
            }
        }
    }
    async function exportarReporteCancelacionesAExcel() {
        try {
            updateGenerandoExcel(true);
            let { excel, fileName } = await api.exportarReporteParaCancelacionesAExcel(interno);
            updateGenerandoExcel(false);
            FileSaver.saveAs(excel, fileName);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error(error);
                mostrarError('Error al exportar reporte para cancelaciones a Excel');
            }
            if (!api.isUnmounted()) {
                updateGenerandoExcel(false);
            }
        }
    }
    return notFound ? <NotFoundComponent></NotFoundComponent> : <>
        <h2>Armado de items de <Link to={'/caratulas/' + encodeURIComponent(interno)}>{interno}</Link></h2>
        <BlockUi blocking={cargando} keepInView>
            <Container fluid>
                <Row>
                    <Col xs={3} style={{ backgroundColor: '#d7e4f2' }}>
                        <h4>Resumen item {estado.itemActual === null || estado.itemActual === undefined ? '' : `${estado.itemActual}`.padStart(4, '0')}</h4>
                        <Form>
                            <Form.Group as={Row} controlId="txtTotalFob">
                                <Form.Label column>
                                    FOB ({estado.caratula?.CodigoDivisaFob ?? ''})
                                </Form.Label>
                                <Col>
                                    <Form.Control className="text-right" readOnly value={estadoLabels?.fob ?? ''}></Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="txtFlete">
                                <Form.Label column>
                                    Flete {isNullOrWhiteSpace(estado.caratula?.CodigoDivisaFlete) ? '' : '(' + estado.caratula.CodigoDivisaFlete + ')'}
                                </Form.Label>
                                <Col>
                                    <Form.Control className="text-right" disabled={estado.itemActual === null || estado.itemActual === undefined || estado.caratula?.Bloqueada} onKeyPress={eventoCampoNumerico}
                                        value={estadoCampos.flete} onChange={(e: any) => updateEstadoCampos({ tipo: 'flete', valor: e.target.value })}></Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="txtSeguro">
                                <Form.Label column>
                                    Seguro {isNullOrWhiteSpace(estado.caratula?.CodigoDivisaSeguro) ? '' : '(' + estado.caratula.CodigoDivisaSeguro + ')'}
                                </Form.Label>
                                <Col>
                                    <Form.Control className="text-right" disabled={estado.itemActual === null || estado.itemActual === undefined || estado.caratula?.Bloqueada} onKeyPress={eventoCampoNumerico}
                                        value={estadoCampos.seguro} onChange={(e: any) => updateEstadoCampos({ tipo: 'seguro', valor: e.target.value })}></Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="txtAjusteIncluir">
                                <Form.Label column>
                                    Ajuste a Incluir
                                </Form.Label>
                                <Col>
                                    <Form.Control className="text-right" disabled={estado.itemActual === null || estado.itemActual === undefined || estado.caratula?.Bloqueada} onKeyPress={eventoCampoNumerico}
                                        value={estadoCampos.ajusteIncluir} onChange={(e: any) => updateEstadoCampos({ tipo: 'ajusteIncluir', valor: e.target.value })}></Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="txtAjusteDeducir">
                                <Form.Label column>
                                    Ajuste a Deducir
                                </Form.Label>
                                <Col>
                                    <Form.Control className="text-right" disabled={estado.itemActual === null || estado.itemActual === undefined || estado.caratula?.Bloqueada} onKeyPress={eventoCampoNumerico}
                                        value={estadoCampos.ajusteDeducir} onChange={(e: any) => updateEstadoCampos({ tipo: 'ajusteDeducir', valor: e.target.value })}></Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="txtTotalFobDol">
                                <Form.Label column>
                                    FOB US$
                                </Form.Label>
                                <Col>
                                    <Form.Control className="text-right" readOnly value={estadoLabels?.fobDol ?? ''}></Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="txtFleteDol">
                                <Form.Label column>
                                    Flete US$
                                </Form.Label>
                                <Col>
                                    <Form.Control className="text-right" readOnly value={estadoLabels?.fleteDol ?? ''}></Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="txtSeguroDol">
                                <Form.Label column >
                                    Seguro US$
                                </Form.Label>
                                <Col >
                                    <Form.Control className="text-right" readOnly value={estadoLabels?.seguroDol ?? ''}></Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="txtAjusteIncluirDol">
                                <Form.Label column >
                                    Ajuste a Incluir US$
                                </Form.Label>
                                <Col >
                                    <Form.Control className="text-right" readOnly value={estadoCampos.AjusteIncluirDol}></Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="txtAjusteDeducirDol">
                                <Form.Label column >
                                    Ajuste a Deducir US$
                                </Form.Label>
                                <Col >
                                    <Form.Control className="text-right" readOnly value={estadoCampos.AjusteDeducirDol}></Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="txtCoeficiente">
                                <Form.Label column >
                                    Coeficiente
                                </Form.Label>
                                <Col >
                                    <Form.Control disabled onKeyPress={eventoCampoNumerico} className="text-right"
                                        value={estadoCampos.coeficiente} onChange={(e: any) => updateEstadoCampos({ tipo: 'coeficiente', valor: e.target.value })}></Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="txtInsumosTemporales">
                                <Form.Label column >
                                    Insumos Temporal
                                </Form.Label>
                                <Col>
                                    <Form.Control className="text-right" disabled onKeyPress={eventoCampoNumerico}
                                        value={estadoCampos.insumosTemporales} onChange={(e: any) => updateEstadoCampos({ tipo: 'insumosTemporales', valor: e.target.value })}></Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="txtInsumosAConsumo">
                                <Form.Label column >
                                    Insumos A Consumo
                                </Form.Label>
                                <Col >
                                    <Form.Control className="text-right" disabled onKeyPress={eventoCampoNumerico}
                                        value={estadoCampos.insumosAConsumo} onChange={(e: any) => updateEstadoCampos({ tipo: 'insumosAConsumo', valor: e.target.value })}></Form.Control>
                                </Col>
                            </Form.Group>
                        </Form>
                        <h4>Aduana</h4>
                        <Form>
                            <Form.Group as={Row} controlId="txtValorEnAduana">
                                <Form.Label column>
                                    Valor en Aduana
                                </Form.Label>
                                <Col>
                                    <Form.Control className="text-right" disabled
                                        value={estadoLabels?.valorAduana ?? ''}></Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="txtBaseIva">
                                <Form.Label column>
                                    Base IVA
                                </Form.Label>
                                <Col>
                                    <Form.Control className="text-right" disabled
                                        value={estadoLabels?.baseIva ?? ''}></Form.Control>
                                </Col>
                            </Form.Group>
                        </Form>
                        <h4>Totales</h4>
                        <Form>
                            <Form.Group as={Row} controlId="txtTotalAPagar">
                                <Form.Label column>
                                    Total A Pagar
                                </Form.Label>
                                <Col>
                                    <Form.Control className="text-right" disabled
                                        value={estadoLabels?.totalAPagar ?? ''}></Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="txtTotalAGarantizar">
                                <Form.Label column>
                                    Total A Garantizar
                                </Form.Label>
                                <Col>
                                    <Form.Control className="text-right" disabled
                                        value={estadoLabels?.totalAGarantizar ?? ''}></Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="txtTotalACobrar">
                                <Form.Label column>
                                    Total A Cobrar
                                </Form.Label>
                                <Col>
                                    <Form.Control className="text-right" disabled
                                        value={estadoLabels?.totalACobrar ?? ''}></Form.Control>
                                </Col>
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col>
                        <Button variant="success" className="mr-2" onClick={async () => {
                            if (await guardar(false)) {
                                exportarItemsAExcel();
                            }
                        }}>
                            <FontAwesomeIcon icon={faFileExcel} />
                            <span>Exportar a Excel</span>
                        </Button>
                        <Button className="mr-2" disabled={avisos.length === 0} onClick={async () => {
                            if (await guardar(false)) {
                                updateMostrarModalAvisos(true);
                            }
                        }}>Avisos</Button>
                        <Button className="mr-2" disabled={certificaciones.length === 0} onClick={async () => {
                            if (await guardar(false)) {
                                updateMostrarModalCertificaciones(true);
                            }
                        }}>Certificaciones</Button>
                        <Button className="mr-2" disabled={estado.reporteLna?.length === 0} onClick={async () => {
                            if (await guardar(false)) {
                                updateMostrarModalReporteLna(true);
                            }
                        }}>LNA</Button>
                        <Button className="mr-2" disabled={estado.reporteValorCriterio?.length === 0} onClick={async () => {
                            if (await guardar(false)) {
                                updateMostrarModalReporteValorCriterio(true);
                            }
                        }}>Valor Criterio</Button>
                        <Button className="mr-2" onClick={cargarReporteIntervenciones}>Intervenciones</Button>
                        <Button onClick={cargarReporteCancelaciones}>Reporte para Cancelaciones</Button>
                        <h4>Listado de items</h4>
                        <GrillaSync habilitarFoco campos={camposGrillaItems}
                            datos={estado.items} eventoCambioFoco={(item: any) => {
                                if (item) {
                                    grabarDatosIngresadosALosItems(item.NroItem);
                                }
                            }}></GrillaSync>
                        <div className="mb-2" style={{ backgroundColor: '#f0f0f0', paddingLeft: '15px', paddingRight: '15px' }}>
                            <Form>

                                <Form.Row>
                                    <h4 style={{ color: '#1aafef' }}>Item seleccionado {estado.itemActual === null || estado.itemActual === undefined ? '' : `${estado.itemActual}`.padStart(4, '0')}</h4>
                                </Form.Row>

                                <Form.Row>
                                    <Col>
                                        <Form.Row>
                                            <Form.Group as={Col} controlId="txtNcm">
                                                <Form.Label>Posición SIM/DC</Form.Label>
                                                <Form.Control plaintext readOnly className="controlArmadoItems" value={estadoLabels.ncm ?? '-'}></Form.Control>
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="txtNaladisa">
                                                <Form.Label>NALADISA/GATT</Form.Label>
                                                <Form.Control value={estadoCampos.naladisa ?? ''} className="controlArmadoItems" disabled={estado.itemActual === null || estado.itemActual === undefined || estado.caratula?.Bloqueada} onChange={(e: any) => updateEstadoCampos({ tipo: 'naladisa', valor: e.target.value })}></Form.Control>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group className="mr-2 w-100">
                                                <Form.Label htmlFor="cboPaisOrigen">Pais Origen/Prov</Form.Label>
                                                <SelectContainer>
                                                    <MySelectNoFormik id="cboPaisOrigen" className="controlArmadoItems" isDisabled={estado.itemActual === null || estado.itemActual === undefined || estado.caratula?.Bloqueada} value={estadoCampos.paisOrigen} onValueChange={valor => {
                                                        let str = valor as string;
                                                        updateEstadoCampos({ tipo: 'paisOrigen', valor: str });
                                                    }} options={() => api.getPaises().then(convertirDatosGenericosAOption)}
                                                        getOptionLabel={optionLabelConCodigo}></MySelectNoFormik>
                                                </SelectContainer>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group controlId="txtCantidadDeclarada" className="mr-2 w-100">
                                                <Form.Label>Cantidad Declarada Total</Form.Label>
                                                <Form.Control readOnly className="controlArmadoItems text-right" value={estadoLabels.cantidadDeclarada ?? ''}></Form.Control>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group controlId="txtCantidadDeclarada" className="mr-2 w-100">
                                                <Form.Label>Cantidad Estadistica</Form.Label>
                                                <Form.Control readOnly className="controlArmadoItems text-right" value={estadoLabels.cantidadEstadistica ?? ''}></Form.Control>
                                            </Form.Group>
                                        </Form.Row>
                                    </Col>
                                    <Col>
                                        <Form.Row>
                                            <Form.Group className="mr-2 w-100">
                                                <Form.Label htmlFor="cboEstadoMerc">Estado Mercadería</Form.Label>
                                                <SelectContainer>
                                                    <MySelectNoFormik id="cboEstadoMerc" className="controlArmadoItems" isDisabled={estado.itemActual === null || estado.itemActual === undefined || estado.caratula?.Bloqueada} value={estadoCampos.estadoMerc} onValueChange={valor => {
                                                        let str = valor as string;
                                                        updateEstadoCampos({ tipo: 'estadoMerc', valor: str });
                                                    }} options={() => api.getEstadosMercaderia().then(convertirDatosGenericosAOption)}
                                                        getOptionLabel={optionLabelConCodigo}></MySelectNoFormik>
                                                </SelectContainer>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group className="mr-2 w-100">
                                                <Form.Label htmlFor="cboPaisProcedencia">Pais Destino/Proc</Form.Label>
                                                <SelectContainer>
                                                    <MySelectNoFormik id="cboPaisProcedencia" className="controlArmadoItems" isDisabled={estado.itemActual === null || estado.itemActual === undefined || estado.caratula?.Bloqueada} value={estadoCampos.paisProcedencia} onValueChange={valor => {
                                                        let str = valor as string;
                                                        updateEstadoCampos({ tipo: 'paisProcedencia', valor: str });
                                                    }} options={() => api.getPaises().then(convertirDatosGenericosAOption)}
                                                        getOptionLabel={optionLabelConCodigo}></MySelectNoFormik>
                                                </SelectContainer>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group className="mr-2 w-100">
                                                <Form.Label htmlFor="cboUnidadDeclarada">Unidad</Form.Label>
                                                <SelectContainer>
                                                    <MySelectNoFormik id="cboUnidadDeclarada" className="controlArmadoItems" isDisabled value={estadoLabels.unidadDeclarada}
                                                        options={() => api.getUnidades().then(convertirDatosGenericosAOption)}
                                                        getOptionLabel={optionLabelConCodigo}></MySelectNoFormik>
                                                </SelectContainer>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group className="mr-2 w-100">
                                                <Form.Label htmlFor="cboUnidadEstadistica">Unidad Estadistica</Form.Label>
                                                <SelectContainer>
                                                    <MySelectNoFormik id="cboUnidadEstadistica" className="controlArmadoItems" isDisabled value={estadoLabels.unidadEstadistica}
                                                        options={() => api.getUnidades().then(convertirDatosGenericosAOption)}
                                                        getOptionLabel={optionLabelConCodigo}></MySelectNoFormik>
                                                </SelectContainer>
                                            </Form.Group>
                                        </Form.Row>
                                    </Col>
                                    <Col>
                                        <Form.Row>
                                            <Form.Group controlId="txtItemAsoc" className="mr-2 w-100">
                                                <Form.Label>Item Asoc</Form.Label>
                                                <Form.Control className="controlArmadoItems text-right" disabled={estado.itemActual === null || estado.itemActual === undefined || estado.caratula?.Bloqueada} value={estadoCampos.itemAsoc ?? ''}
                                                    onKeyPress={eventoCampoNumericoEnteros} onChange={(e: any) => updateEstadoCampos({ tipo: 'itemAsoc', valor: e.target.value })}></Form.Control>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group className="mr-2 w-100">
                                                <Form.Label htmlFor="cboLista">Lista</Form.Label>
                                                <SelectContainer> <MySelectNoFormik id="cboLista" className="controlArmadoItems" isDisabled={estado.itemActual === null || estado.itemActual === undefined || estado.caratula?.Bloqueada} value={estadoCampos.lista} onValueChange={valor => {
                                                    let str = valor as string;
                                                    updateEstadoCampos({ tipo: 'lista', valor: str });
                                                }} options={() => api.getLista().then(convertirDatosGenericosAOption)}
                                                    getOptionLabel={optionLabelConCodigo}></MySelectNoFormik></SelectContainer>

                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group controlId="txtDJCP" className="mr-2 w-100">
                                                <Form.Label>Cod DJCP (CIP)</Form.Label>
                                                <Form.Control className="controlArmadoItems"
                                                    disabled={estado.itemActual === null || estado.itemActual === undefined || estado.itemActual === 0 || estado.caratula?.Bloqueada || listasItemActual.subitems?.length > 1}
                                                    value={estadoCampos.codigoDjcp} maxLength={10}
                                                    onChange={(e: any) => updateEstadoCampos({ tipo: 'codigoDjcp', valor: e.target.value?.trim()?.substring(0, 10) })}></Form.Control>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group controlId="txtKgNeto" className="mr-2 w-100">
                                                <Form.Label>Kg Neto</Form.Label>
                                                <Form.Control className="controlArmadoItems text-right" disabled={estado.itemActual === null || estado.itemActual === undefined || estado.caratula?.Bloqueada} value={estadoCampos.kgNeto}
                                                    onKeyPress={eventoCampoNumerico} onChange={(e: any) => updateEstadoCampos({ tipo: 'kgNeto', valor: e.target.value })}></Form.Control>
                                            </Form.Group>
                                        </Form.Row>
                                    </Col>
                                    <Col>
                                        <Form.Row>
                                            <Form.Group controlId="txtTipo" className="w-100">
                                                <Form.Label>Tipo</Form.Label>
                                                <Form.Control className="controlArmadoItems" disabled={estado.itemActual === null || estado.itemActual === undefined || estado.caratula?.Bloqueada} value={estadoCampos.tipo}
                                                    onChange={(e: any) => updateEstadoCampos({ tipo: 'tipo', valor: e.target.value })}></Form.Control>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group controlId="cboRgls" className="w-100">
                                                <Form.Label>Regalias</Form.Label>
                                                <SelectContainer>
                                                    <MySelectNoFormik id="cboRgls" className="controlArmadoItems" isDisabled={estado.itemActual === null || estado.itemActual === undefined || estado.caratula?.Bloqueada} value={estadoCampos.regalias} onValueChange={valor => {
                                                        let str = valor as string;
                                                        updateEstadoCampos({ tipo: 'regalias', valor: str });
                                                    }} options={[{ value: 'S', label: 'S' }, { value: 'N', label: 'N' }]}></MySelectNoFormik>
                                                </SelectContainer>

                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group controlId="cboStd" className="w-100">
                                                <Form.Label>Standard</Form.Label>
                                                <SelectContainer>
                                                    <MySelectNoFormik id="cboStd" className="controlArmadoItems" isDisabled={estado.itemActual === null || estado.itemActual === undefined || estado.caratula?.Bloqueada} value={estadoCampos.standard} onValueChange={valor => {
                                                        let str = valor as string;
                                                        updateEstadoCampos({ tipo: 'standard', valor: str });
                                                    }} options={[{ value: 'S', label: 'S' }, { value: 'N', label: 'N' }]}></MySelectNoFormik>
                                                </SelectContainer>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group controlId="txtKgBruto" className="w-100">
                                                <Form.Label>Kg Bruto</Form.Label>
                                                <Form.Control className="controlArmadoItems text-right" disabled={estado.itemActual === null || estado.itemActual === undefined || estado.caratula?.Bloqueada} value={estadoCampos.kgBruto}
                                                    onKeyPress={eventoCampoNumerico} onChange={(e: any) => updateEstadoCampos({ tipo: 'kgBruto', valor: e.target.value })}></Form.Control>
                                            </Form.Group>
                                        </Form.Row>
                                    </Col>
                                </Form.Row>
                            </Form>
                        </div>
                        <Tabs id="tabsArmadoItems" defaultActiveKey="infAdic">
                            <Tab eventKey="infAdic" title="Información Adicional">
                                <Button className="mt-2 mb-2 mr-2" disabled={estado.itemActual === null || estado.itemActual === undefined || estado.caratula?.Bloqueada}
                                    onClick={() => updateEstadoModalInfComps({ tipo: 'mostrarCrear' })}>
                                    <FontAwesomeIcon icon={faPlus} />
                                    <span>Agregar</span>
                                </Button>
                                <Button className="mt-2 mb-2 mr-2" variant="danger" disabled={estado.itemActual === null || estado.itemActual === undefined || estado.caratula?.Bloqueada}
                                    onClick={() => updateEstado({ tipo: 'deleteAllInfComps' })}>
                                    <FontAwesomeIcon icon={faTrash} />
                                    <span>Eliminar Todas</span>
                                </Button>
                                <GrillaSync
                                    campos={[{ propiedad: 'Clave', visible: false, clave: true }, { titulo: 'Item', propiedad: 'NroItem' },
                                    { titulo: 'Codigo', propiedad: 'Codigo' }, { titulo: 'Valor', propiedad: 'Valor' }]}
                                    datos={listasItemActual.infComps} eventoEliminar={(infComp: any) => {
                                        updateMensajeDialogo('¿Está seguro que desea eliminar esta información complementaria?');
                                        dialogoRef.current!.mostrar().then(() => updateEstado({ tipo: 'deleteInfComp', infComp: infComp })).catch(() => { });
                                    }}
                                    deshabilitarBotonEliminar={estado.itemActual === null || estado.itemActual === undefined || estado.caratula?.Bloqueada} />
                            </Tab>
                            <Tab eventKey="ventajas" title="Ventajas">
                                <div className="mt-2">
                                    <GrillaSync
                                        campos={[{ propiedad: 'Clave', visible: false, clave: true }, { titulo: 'Item', propiedad: 'NroItem' },
                                        { titulo: 'Codigo', propiedad: 'Codigo' }]}
                                        datos={listasItemActual.ventajas}
                                        eventoAgregar={() => updateEstadoModalVentajas({ tipo: 'mostrarCrear' })}
                                        deshabilitarBotonAgregar={estado.itemActual === null || estado.itemActual === undefined || estado.caratula?.Bloqueada}
                                        eventoEliminar={(ventaja: any) => {
                                            updateMensajeDialogo('¿Está seguro que desea eliminar esta ventaja?');
                                            dialogoRef.current!.mostrar().then(() => updateEstado({ tipo: 'deleteVentaja', ventaja: ventaja })).catch(() => { });
                                        }} deshabilitarBotonEliminar={estado.itemActual === null || estado.itemActual === undefined || estado.caratula?.Bloqueada} />
                                </div>
                            </Tab>
                            <Tab eventKey="subitems" title="Subitems">
                                <GrillaSync campos={camposGrillaSubitems} datos={listasItemActual.subitems} />
                            </Tab>
                            <Tab eventKey="liquidacion" title="Liquidación">
                                {estado.cargandoLiquidacion ? (<Centrado>
                                    <p className="lead">Calculando liquidación...</p>
                                </Centrado>) :
                                    estado.errorLiquidacion ? (<Centrado>
                                        <p className="text-danger lead">Error al calcular liquidación</p>
                                    </Centrado>) :
                                        (<div className="mt-2">
                                            {/*eventoDetalle={(liq: any) => updateEstadoModalLiquidacion({ tipo: 'mostrarModificar', valor: liq })}*/}
                                            <GrillaSync  campos={camposGrillaLiquidacion}
                                                datos={listasItemActual.liquidacion}
                                                eventoAgregar={() => updateEstadoModalLiquidacion({ tipo: 'mostrarCrear' })}
                                                deshabilitarBotonAgregar={true ||  estado.itemActual === null || estado.itemActual === undefined || estado.itemActual === 0 || estado.caratula?.Bloqueada}
                                                eventoDetalle={(liq: any) =>  {console.log(liq)}}
                                                deshabilitarEventoDetalle={estado.itemActual === null || estado.itemActual === undefined || estado.itemActual === 0 || estado.caratula?.Bloqueada}
                                                eventoEliminar={(liq: any) => {
                                                    if (liq.NroItem === 0) {
                                                        updateMensajeDialogo('¿Está seguro que desea eliminar este concepto de todos los items?');
                                                    } else {
                                                        updateMensajeDialogo('¿Está seguro que desea eliminar este concepto del item actual?');
                                                    }
                                                    dialogoRef.current!.mostrar().then(() => updateEstado({ tipo: 'deleteLiquidacion', liquidacion: liq })).catch(() => { });
                                                }} deshabilitarBotonEliminar={estado.itemActual === null || estado.itemActual === undefined || estado.caratula?.Bloqueada}
                                            />
                                        </div>)}
                            </Tab>
                            {estado.cancelacionesHabilitadas && <Tab eventKey="cancelaciones" title="Cancelaciones">
                                <div className="mt-2">
                                    <GrillaSync campos={camposGrillaCancelaciones}
                                        datos={listasItemActual.cancelaciones}
                                        eventoAgregar={() => updateEstadoModalCancelaciones({ tipo: 'mostrarCrear' })}
                                        deshabilitarBotonAgregar={estado.itemActual === null || estado.itemActual === undefined || estado.itemActual === 0 || estado.caratula?.Bloqueada}
                                        eventoDetalle={(canc: any) => updateEstadoModalCancelaciones({ tipo: 'mostrarModificar', valor: canc })}
                                        deshabilitarEventoDetalle={estado.itemActual === null || estado.itemActual === undefined || estado.caratula?.Bloqueada}
                                        eventoEliminar={(canc: any) => {
                                            updateMensajeDialogo('¿Está seguro que desea eliminar esta cancelación?');
                                            dialogoRef.current!.mostrar().then(() => updateEstado({ tipo: 'deleteCancelacion', cancelacion: canc })).catch(() => { });
                                        }} deshabilitarBotonEliminar={estado.itemActual === null || estado.itemActual === undefined || estado.caratula?.Bloqueada} />
                                </div>
                            </Tab>}
                        </Tabs>
                    </Col>
                </Row>
                <Button variant="danger" className="mt-2 mb-2" onClick={() => history.goBack()}>Cancelar</Button>
                <Button disabled={estado.caratula?.Bloqueada} onClick={() => guardar(true)} className="mt-2 ml-2 mb-2">Guardar</Button>
            </Container>
        </BlockUi>
        <MyModal show={mostrarModalExito} onHide={() => updateMostrarModalExito(false)}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="text-success lead">Se han guardado los cambios exitosamente</p>
                    {mostrarMensajeLiquidacionRecalculada && <p className="text-warning">Se ha recalculado la liquidación</p>}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => updateMostrarModalExito(false)}>Cerrar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={mostrarModalMensajeHayAvisos} onHide={() => updateMostrarModalMensajeHayAvisos(false)}>
            <Modal.Dialog>
                <Modal.Body>
                    <Media>
                        <div className="m-2" style={{ fontSize: '2em' }}>
                            <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
                        </div>
                        <Media.Body>
                            {avisos.length > 0 && <>
                                <p className="lead">Se han encontrado articulos afectados por Avisos. Haga click en el boton de Avisos para verlos</p>
                            </>}
                            {certificaciones.length > 0 && <>
                                <p className="lead">Se han encontrado articulos afectados por Certificaciones. Haga click en el boton de Certificaciones para verlos</p>
                            </>}
                        </Media.Body>
                    </Media>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => updateMostrarModalMensajeHayAvisos(false)}>Cerrar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={estadoModalInfComps.creando || estadoModalInfComps.modificando}
            onHide={() => updateEstadoModalInfComps({ tipo: 'esconderVentana' })}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    <h2>{estadoModalInfComps.creando ? 'Crear Inf. Complementaria de Item' : 'Modificar Inf. Complementaria de Item'}</h2>
                </Modal.Header>
                <Modal.Body>
                    <Formik innerRef={formikInfCompRef} validationSchema={Yup.object({
                        'Codigo': Yup.string().nullable().required('Debe ingresar el codigo'),
                        'Valor': Yup.string().nullable().required('Debe ingresar el valor').max(20, 'El valor debe tener 20 caracteres o menos')
                    })} initialValues={{
                        'Codigo': estadoModalInfComps.valorModificando?.Codigo ?? '',
                        'Valor': estadoModalInfComps.valorModificando?.Valor ?? '',
                    }} onSubmit={(nuevaInfComp: { Codigo: string, Valor: string }, actions) => {
                        let infCompsExistentes = estado.infComps.filter((infComp: any) => infComp.NroItem === estado.itemActual).map((infComp: any) => infComp.Codigo);
                        if (estadoModalInfComps.modificando) {
                            let indice = infCompsExistentes.indexOf(estadoModalInfComps.valorModificando.Codigo);
                            if (indice > -1) {
                                infCompsExistentes.splice(indice, 1);
                            }
                        }
                        if (infCompsExistentes.includes(nuevaInfComp.Codigo)) {
                            actions.setFieldError('Codigo', `La inf. complementaria con codigo ${nuevaInfComp.Codigo} ya fue ingresada`);
                        } else {
                            updateEstado({ tipo: 'insertInfComp', infComp: nuevaInfComp });
                            updateEstadoModalInfComps({ tipo: 'esconderVentana' });
                        }
                        actions.setSubmitting(false);
                    }}>
                        <MyForm>
                            <Form.Group>
                                <MySelect name="Codigo" label="Codigo Inf. Complementaria"
                                    options={() => api.getInformacionComplementaria().then(convertirDatosGenericosAOption)}
                                    getOptionLabel={(option: SelectOption) => option.value}></MySelect>
                            </Form.Group>
                            <Form.Group>
                                <MyFormControl type="text" name="Valor" label="Valor"></MyFormControl>
                            </Form.Group>
                        </MyForm>
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateEstadoModalInfComps({ tipo: 'esconderVentana' })}>
                        Cancelar
                    </Button>
                    <Button onClick={() => formikInfCompRef.current?.submitForm()}>
                        Ingresar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={estadoModalVentajas.creando || estadoModalVentajas.modificando}
            onHide={() => updateEstadoModalVentajas({ tipo: 'esconderVentana' })}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    <h2>{estadoModalVentajas.creando ? 'Crear Ventaja de Item' : 'Modificar Ventaja de Item'}</h2>
                </Modal.Header>
                <Modal.Body>
                    <Formik innerRef={formikVentajasRef} validationSchema={Yup.object({
                        'Codigo': Yup.string().nullable().required('Debe ingresar el codigo'),
                    })} initialValues={{
                        'Codigo': estadoModalVentajas.valorModificando?.Codigo ?? '',
                    }} onSubmit={(nuevaVentaja: { Codigo: string }, actions) => {
                        let ventajasExistentes = estado.ventajas.filter((ventaja: any) => ventaja.NroItem === estado.itemActual).map((ventaja: any) => ventaja.Codigo);
                        if (estadoModalInfComps.modificando) {
                            let indice = ventajasExistentes.indexOf(estadoModalVentajas.valorModificando.Codigo);
                            if (indice > -1) {
                                ventajasExistentes.splice(indice, 1);
                            }
                        }
                        if (ventajasExistentes.includes(nuevaVentaja.Codigo)) {
                            actions.setFieldError('Codigo', `La ventaja con codigo ${nuevaVentaja.Codigo} ya fue ingresada`);
                        } else {
                            updateEstado({ tipo: 'insertVentaja', ventaja: nuevaVentaja });
                            updateEstadoModalVentajas({ tipo: 'esconderVentana' });
                        }
                        actions.setSubmitting(false);
                    }}>
                        <MyForm>
                            <Form.Group>
                                <MySelect name="Codigo" label="Codigo Ventaja"
                                    options={() => api.getVentajas().then(convertirDatosGenericosAOption)}
                                    getOptionLabel={(option: SelectOption) => option.value}></MySelect>
                            </Form.Group>
                        </MyForm>
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateEstadoModalVentajas({ tipo: 'esconderVentana' })}>
                        Cancelar
                    </Button>
                    <Button onClick={() => formikVentajasRef.current?.submitForm()}>
                        Ingresar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={estadoModalCancelaciones.creando || estadoModalCancelaciones.modificando}
            onHide={() => updateEstadoModalCancelaciones({ tipo: 'esconderVentana' })}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    <h2>{estadoModalCancelaciones.creando ? 'Crear Cancelación de Item' : 'Modificar Cancelación de Item'}</h2>
                </Modal.Header>
                <Modal.Body>
                    <Formik innerRef={formikCancelacionesRef} validationSchema={Yup.object({
                        'Dit': Yup.string().nullable().required('Debe ingresar el dit'),
                        'Ncm': Yup.string().nullable().required('Debe ingresar el NCM'),
                        'Cantidad': Yup.number().nullable().required('Debe ingresar la cantidad')
                            .typeError('Debe ingresar un número')
                            .moreThan(0, 'La cantidad debe ser mayor a cero'),
                        'FactorPonderacion': Yup.number().nullable().required('Debe ingresar el factor de ponderación')
                            .typeError('Debe ingresar un número')
                            .moreThan(0, 'El factor de ponderación debe ser mayor a cero'),
                        'Expctit': Yup.string().nullable(),
                        'NroItemCancelado': Yup.number().nullable()
                            .required('Debe ingresar el nro de item a cancelar')
                            .typeError('Debe ingresar un número')
                            .integer('El nro de item a cancelar debe ser entero')
                            .min(1, 'El numero de item a cancelar debe ser mayor a cero'),
                        'NroSubitemCancelado': Yup.number().nullable()
                            .required('Debe ingresar el nro de subitem a cancelar')
                            .typeError('Debe ingresar un número')
                            .integer('El nro de subitem a cancelar debe ser entero')
                            .min(0, 'El numero de item a cancelar debe ser mayor o igual a cero')
                    })} initialValues={{
                        'Dit': estadoModalCancelaciones.valorModificando?.Dit,
                        'Ncm': estadoModalCancelaciones.valorModificando?.Ncm,
                        'Cantidad': estadoModalCancelaciones.valorModificando?.Cantidad,
                        'FactorPonderacion': estadoModalCancelaciones.valorModificando?.FactorPonderacion ?? 100,
                        'Expctit': estadoModalCancelaciones.valorModificando?.Expctit,
                        'NroItemCancelado': estadoModalCancelaciones.valorModificando?.NroItemCancelado,
                        'NroSubitemCancelado': estadoModalCancelaciones.valorModificando?.NroSubitemCancelado
                    }} onSubmit={async (nuevaCancelacion: {
                        Dit: string, Ncm: string, Cantidad: number, FactorPonderacion: number,
                        Expctit?: string, NroItemCancelado: number, NroSubitemCancelado: number
                    }, actions) => {
                        try {
                            let ncmValidado = await api.validarNcmArreglandoPuntos(nuevaCancelacion.Ncm);
                            if (ncmValidado) {
                                updateEstado({
                                    tipo: 'insertCancelacion',
                                    cancelacion: nuevaCancelacion,
                                    cancelacionModificada: estadoModalCancelaciones.valorModificando
                                });
                                updateEstadoModalCancelaciones({ tipo: 'esconderVentana' });
                            } else {
                                actions.setFieldError('Ncm', 'El NCM no es válido');
                                return;
                            }
                        } catch (error) {
                            if (!api.isCancel(error)) {
                                console.error('Error al validar cancelación', error);
                                mostrarError('Error al validar cancelación');
                            }
                        }
                    }}>
                        <MyForm>
                            <Form.Group>
                                <MyFormControl type="text" name="Dit" label="Declaración a Cancelar"></MyFormControl>
                            </Form.Group>
                            <Form.Group>
                                <MyFormControl type="number" name="NroItemCancelado" label="Nro. Item"></MyFormControl>
                            </Form.Group>
                            <Form.Group>
                                <MyFormControl type="number" name="NroSubitemCancelado" label="Nro. Subitem"></MyFormControl>
                            </Form.Group>
                            <Form.Group>
                                <MyAsyncSelect name="Ncm" label="NCM" loadOptions={api.cargarNcm()}></MyAsyncSelect>
                            </Form.Group>
                            <Form.Group>
                                <MyFormControl type="number" name="Cantidad" label="Cantidad"></MyFormControl>
                            </Form.Group>
                            <Form.Group>
                                <MyFormControl type="number" name="FactorPonderacion" label="Factor Ponderación"></MyFormControl>
                            </Form.Group>
                            <Form.Group>
                                <MyFormControl type="text" name="Expctit" label="Expediente"></MyFormControl>
                            </Form.Group>
                        </MyForm>
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateEstadoModalCancelaciones({ tipo: 'esconderVentana' })}>
                        Cancelar
                    </Button>
                    <Button onClick={() => formikCancelacionesRef.current?.submitForm()}>
                        Ingresar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={estadoModalLiquidacion.creando || estadoModalLiquidacion.modificando}
            onHide={() => updateEstadoModalLiquidacion({ tipo: 'esconderVentana' })}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    <h2>{estadoModalLiquidacion.creando ? 'Crear Liquidación de Item' : 'Modificar Liquidación de Item'}</h2>
                </Modal.Header>
                <Modal.Body>
                    <Formik innerRef={formikLiquidacionRef} validationSchema={Yup.object({
                        'Codigo': Yup.string().nullable().required('Debe seleccionar el concepto'),
                        'Descripcion': Yup.string().nullable(),
                        'BaseImponible': Yup.number().nullable()
                            .required('Debe ingresar la base imponible')
                            .typeError('Debe ingresar un número')
                            .moreThan(0, 'La base imponible debe ser mayor a cero'),
                        'Porcentaje': Yup.number().nullable()
                            .required('Debe ingresar el porcentaje')
                            .typeError('Debe ingresar un número')
                            .moreThan(0, 'El porcentaje debe ser mayor a cero'),
                        'Motivo': Yup.string().nullable(),
                        'PGC': Yup.string().nullable().typeError('Debe ingresar un número')
                            .required('Debe ingresar si el concepto es a pagar, a garantizar o a pagar'),
                    })} initialValues={{
                        'Codigo': estadoModalLiquidacion.valorModificando?.Codigo ?? '',
                        'Descripcion': estadoModalLiquidacion.valorModificando?.Descripcion ?? '',
                        'BaseImponible': estadoModalLiquidacion.valorModificando?.BaseImponible ?? '',
                        'Porcentaje': estadoModalLiquidacion.valorModificando?.Porcentaje ?? '',
                        'Motivo': estadoModalLiquidacion.valorModificando?.Motivo ?? '',
                        'PGC': estadoModalLiquidacion.valorModificando?.PGC ?? '',
                    }} onSubmit={(nuevaLiquidacion: {
                        Codigo: string, Descripcion: string, BaseImponible: number, Porcentaje: number,
                        Motivo?: string, PGC: string
                    }, actions) => {
                        let conceptosExistentes = estado.liquidacion.filter((liq: any) => liq.NroItem === estado.itemActual).map((liq: any) => liq.Codigo);
                        if (estadoModalLiquidacion.modificando) {
                            let indice = conceptosExistentes.indexOf(estadoModalLiquidacion.valorModificando.Codigo);
                            if (indice > -1) {
                                conceptosExistentes.splice(indice, 1);
                            }
                        }
                        if (conceptosExistentes.includes(nuevaLiquidacion.Codigo)) {
                            actions.setFieldError('Codigo', `La liquidación con codigo ${nuevaLiquidacion.Codigo} ya fue ingresada`);
                        } else {
                            updateEstado({
                                tipo: 'insertLiquidacion', liquidacion: nuevaLiquidacion,
                                liquidacionModificando: estadoModalLiquidacion.valorModificando
                            });
                            updateEstadoModalLiquidacion({ tipo: 'esconderVentana' });
                        }
                        actions.setSubmitting(false);
                    }}>
                        <MyForm>
                            <Form.Group>
                                <MySelect name="Codigo" label="Concepto"
                                    isDisabled={estadoModalLiquidacion.modificando}
                                    options={() => api.getConceptosLiquidacion().then(convertirDatosGenericosAOption)}
                                    getOptionLabel={optionLabelConCodigo}
                                    onOptionChange={option => {
                                        let selectedOption = option as SelectOption;
                                        formikLiquidacionRef.current?.setFieldValue('Descripcion', selectedOption?.label);
                                    }}></MySelect>
                                <Field type="hidden" name="Descripcion"></Field>
                            </Form.Group>
                            <Form.Group>
                                <MyFormControl name="BaseImponible" label="Base" type="number" onValueChangeEnHook={valor => {
                                    try {
                                        updateEstadoModalLiquidacion({
                                            tipo: 'updateTotal',
                                            valor: toFixedDecimal(new Decimal(valor).mul(formikLiquidacionRef.current?.values?.Porcentaje).div(100), 2)
                                        })
                                    } catch (error) {

                                        const {total} = estadoModalLiquidacion;
                                        if (total!=='') {
                                           updateEstadoModalLiquidacion({ tipo: 'updateTotal', valor: '' });
                                    }

                                    }
                                }}></MyFormControl>
                            </Form.Group>
                            <Form.Group>
                                <MyFormControl name="Porcentaje" label="Porcentaje" type="number" onValueChangeEnHook={valor => {
                                    try {
                                        updateEstadoModalLiquidacion({
                                            tipo: 'updateTotal',
                                            valor: toFixedDecimal(new Decimal(valor).mul(formikLiquidacionRef.current?.values?.BaseImponible).div(100), 2)
                                        })
                                    } catch (error) {

                                        const {total} = estadoModalLiquidacion;
                                        if (total!=='') {
                                            updateEstadoModalLiquidacion({ tipo: 'updateTotal', valor: '' });
                                        }
                                    }

                                }}></MyFormControl>
                            </Form.Group>
                            <Form.Group controlId="txtTotalLiquidacion">
                                <Form.Label>Total</Form.Label>
                                <Form.Control disabled value={estadoModalLiquidacion.total ?? ''} className="text-right"></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <MySelect name="PGC" label="PGC" options={[{ value: 'P', label: 'P (Pagar)' },
                                { value: 'G', label: 'G (A Garantizar)' }, { value: 'C', label: 'C (A Cobrar)' }]}></MySelect>
                            </Form.Group>
                            <Form.Group>
                                <MyFormControl name="Motivo" label="Motivo" type="text"></MyFormControl>
                            </Form.Group>
                        </MyForm>
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateEstadoModalVentajas({ tipo: 'esconderVentana' })}>
                        Cancelar
                    </Button>
                    <Button onClick={() => formikLiquidacionRef.current?.submitForm()}>
                        Ingresar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={mostrarModalAvisos} onHide={() => updateMostrarModalAvisos(false)}>
            <Modal.Dialog size="xl">
                <Modal.Header closeButton>
                    <h2>Avisos</h2>
                </Modal.Header>
                <Modal.Body>
                    <Button variant="success" className="mb-2" onClick={exportarAvisosAExcel}>
                        <FontAwesomeIcon icon={faFileExcel} />
                        <span>Exportar a Excel</span>
                    </Button>
                    <Grilla ref={grillaAvisosRef} campos={camposGrillaAvisos}
                        cargarDatos={cargarDatosGrillaDeArray(avisos)} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateMostrarModalAvisos(false)}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={mostrarModalCertificaciones} onHide={() => updateMostrarModalCertificaciones(false)}>
            <Modal.Dialog size="xl">
                <Modal.Header closeButton>
                    <h2>Certificaciones</h2>
                </Modal.Header>
                <Modal.Body>
                    <Button variant="success" className="mb-2" onClick={exportarCertificacionesAExcel}>
                        <FontAwesomeIcon icon={faFileExcel} />
                        <span>Exportar a Excel</span>
                    </Button>
                    <GrillaSync datos={certificaciones} campos={camposGrillaCertificaciones} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateMostrarModalCertificaciones(false)}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={mostrarModalReporteValorCriterio} onHide={() => updateMostrarModalReporteValorCriterio(false)}>
            <Modal.Dialog size="xl">
                <Modal.Header closeButton>
                    <h2>Reporte Items con Valor Criterio</h2>
                </Modal.Header>
                <Modal.Body>
                    <Button variant="success" className="mb-2" onClick={exportarReporteValorCriterioAExcel}>
                        <FontAwesomeIcon icon={faFileExcel} />
                        <span>Exportar a Excel</span>
                    </Button>
                    <GrillaSync campos={camposGrillaReporteValorCriterio}
                        datos={estado.reporteValorCriterio} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateMostrarModalReporteValorCriterio(false)}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={mostrarModalReporteLna} onHide={() => updateMostrarModalReporteLna(false)}>
            <Modal.Dialog size="xl">
                <Modal.Header closeButton>
                    <h2>Reporte Items con LNA</h2>
                </Modal.Header>
                <Modal.Body>
                    <Button variant="success" className="mb-2 mr-2" onClick={() => exportarReporteLnaAExcel(false)}>
                        <FontAwesomeIcon icon={faFileExcel} />
                        <span>Exportar Resultados por Item</span>
                    </Button>
                    <Button variant="success" className="mb-2" onClick={() => exportarReporteLnaAExcel(true)}>
                        <FontAwesomeIcon icon={faFileExcel} />
                        <span>Exportar Resultados por Anexo</span>
                    </Button>
                    <GrillaSync campos={camposGrillaReporteLna} datos={estado.reporteLna} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateMostrarModalReporteLna(false)}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={mostrarModalReporteCancelaciones} onHide={() => updateMostrarModalReporteCancelaciones(false)}>
            <Modal.Dialog size="xl">
                <Modal.Header closeButton>
                    <h2>Reporte para Cancelaciones</h2>
                </Modal.Header>
                <Modal.Body>
                    <Button variant="success" className="mb-2 mr-2" onClick={exportarReporteCancelacionesAExcel}>
                        <FontAwesomeIcon icon={faFileExcel} />
                        <span>Exportar a Excel</span>
                    </Button>
                    <GrillaSync campos={camposGrillaReporteCancelaciones} datos={reporteCancelaciones} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateMostrarModalReporteCancelaciones(false)}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={mostrarModalReporteIntervenciones} onHide={() => updateMostrarModalReporteIntervenciones(false)}>
            <Modal.Dialog size="xl">
                <Modal.Header closeButton>
                    <h2>Reporte Intervenciones</h2>
                </Modal.Header>
                <Modal.Body>
                    <Button variant="success" className="mb-2 mr-2" onClick={exportarReporteIntervencionesAExcel}>
                        <FontAwesomeIcon icon={faFileExcel} />
                        <span>Exportar a Excel</span>
                    </Button>
                    <GrillaSync campos={camposGrillaReporteIntervenciones} datos={reporteIntervenciones} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateMostrarModalReporteIntervenciones(false)}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={generandoExcel}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="lead">Generando Excel...</p>
                    <div className="loader-container">
                        <Loader type="ball-spin-fade-loader" active></Loader>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        api.cancelCurrentTokens();
                    }}>Cancelar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <DialogoConfirmar ref={dialogoRef} mensaje={mensajeDialogo} textoBotonCancelar="No" textoBotonConfirmar="Sí" />
    </>
}