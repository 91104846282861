import { DateTime } from "luxon";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { EstadoCaratula } from "../../../Enums";
import { AppContext, NotFoundComponent } from "../../../App";
import BlockUi from "react-block-ui";
import { Button, Table, Modal } from "react-bootstrap";
import { useDecodedParams } from "Utilidades";
import Loader from "react-loaders";
import { MyModal } from "../../../MyModal";
import { useApi } from "ApiHooks";

export function MensajesArmadoItems() {
    let { interno } = useDecodedParams() as { interno: string };
    let history = useHistory();
    let { mostrarError } = useContext(AppContext);
    let api = useApi();
    let [cargando, updateCargando] = useState(true);
    let [notFound, updateNotFound] = useState(false);
    let [mensajes, updateMensajes] = useState<any[]>([]);
    let [errores, updateErrores] = useState<any[]>([]);
    let [mostrarSpinner, updateMostrarSpinner] = useState(true);
    let [mostrarErrores, updateMostrarErrores] = useState(false);
    let timeout = useRef<any>({ valor: null });
    function* distinctByMensaje(mensajes: any[]) {
        let set = new Set();
        for (let item of mensajes) {
            if (!set.has(item.Mensaje)) {
                set.add(item.Mensaje);
                yield item;
            }
        }
    }
    const funcionOrdenarFechaDescendente = (a: any, b: any) => {
        let dateA = DateTime.fromISO(a.Fecha);
        let dateB = DateTime.fromISO(b.Fecha);
        if (dateB > dateA) {
            return 1;
        } else if (dateB.equals(dateA)) {
            return 0;
        } else {
            return -1;
        }
    };
    let cargar = useCallback(async () => {
        if (!api.isUnmounted()) {
            try {
                let estadoCaratula = await api.getEstadoCaratula(interno);
                if (!estadoCaratula) {
                    updateNotFound(true);
                    return;
                }
                let mensajes = await api.getMensajesArmadoItems(interno);
                updateErrores(mensajes.filter((mensaje: any) => mensaje.EsError).sort(funcionOrdenarFechaDescendente).map((item: any, indice: number) => ({ ...item, Indice: indice })));
                updateMensajes(Array.from(distinctByMensaje(mensajes)).sort(funcionOrdenarFechaDescendente).map((item: any, indice: number) => ({ ...item, Indice: indice })));
                if (estadoCaratula.Estado === EstadoCaratula.ItemsArmados || estadoCaratula.Estado === EstadoCaratula.LiquidacionHecha) {
                    history.replace(`/caratulas/${encodeURIComponent(interno)}/armadoItems/edit`);
                } else if (estadoCaratula.Estado === EstadoCaratula.ErrorArmadoItems) {
                    updateCargando(false);
                    updateMostrarSpinner(false);
                } else {
                    updateCargando(false);
                    timeout.current.valor = setTimeout(cargar, 5000);
                }
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar estado de caratula', error);
                    mostrarError('Error al cargar estado de caratula');
                }
            }
        }
        //eslint-disable-next-line
    }, []);
    useEffect(() => {
        cargar();
        //eslint-disable-next-line
    }, []);
    return notFound ? <NotFoundComponent></NotFoundComponent> : (<>
        <h2>Mensajes de armado de items de <Link to={'/caratulas/' + encodeURIComponent(interno)}>{interno}</Link></h2>
        <BlockUi blocking={cargando}>
            <div className="d-flex">
                {errores.length > 0 && <Button variant="danger" className="mb-2" onClick={() => updateMostrarErrores(true)}>Ver errores</Button>}
                <div className="flex-grow-1"></div>
                <div style={{ width: '35px', height: '40px' }}>
                    {mostrarSpinner && <Loader type="ball-spin-fade-loader" active></Loader>}
                </div>
            </div>
            <Table>
                <thead>
                    <tr>
                        <th>Mensaje</th>
                        <th>Fecha</th>
                    </tr>
                </thead>
                <tbody>
                    {mensajes.map((item: any) => {
                        return <tr key={item.Indice} className={item.EsError ? 'font-weight-bold text-danger' : ''}>
                            <td> {item.Mensaje}</td>
                            <td>{DateTime.fromISO(item.Fecha).toLocaleString({
                                ...DateTime.DATETIME_SHORT,
                                'day': '2-digit', 'month': '2-digit', 'hour': '2-digit', 'minute': '2-digit'
                            })}</td>
                        </tr>
                    })}
                </tbody>
            </Table>
        </BlockUi>
        <MyModal show={mostrarErrores} onHide={() => updateMostrarErrores(false)}>
            <Modal.Dialog size="xl">
                <Modal.Header closeButton>
                    Errores Armado Items
                </Modal.Header>
                <Modal.Body>
                    <div className="table-responsive" style={{ height: '40vh' }}>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Mensaje</th>
                                    <th>Orden</th>
                                    <th>Ncm</th>
                                    <th>Sufijos</th>
                                    <th>Unidad Estadistica</th>
                                    <th>Cantidad Estadistica</th>
                                    <th>Kg Neto</th>
                                    <th>Fecha</th>
                                </tr>
                            </thead>
                            <tbody>
                                {errores.map((item: any) => {
                                    return <tr key={item.Indice}>
                                        <td>{item.Mensaje}</td>
                                        <td> {item.Orden}</td>
                                        <td> {item.Ncm}</td>
                                        <td> {item.Sufijos}</td>
                                        <td> {item.CodigoUnidadEstadistica}</td>
                                        <td className="text-right"> {item.CantidadEstadistica}</td>
                                        <td className="text-right"> {item.KgNeto}</td>
                                        <td>{DateTime.fromISO(item.Fecha).toLocaleString({
                                            ...DateTime.DATETIME_SHORT,
                                            'day': '2-digit', 'month': '2-digit', 'hour': '2-digit', 'minute': '2-digit'
                                        })}</td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateMostrarErrores(false)}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
    </>);
}