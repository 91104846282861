import { useContext, useRef } from "react";
import { isNullOrWhiteSpace, sanitizarNombreInternoParaArchivo } from "Utilidades";
import axios from 'axios';
import { useCancel } from "SintiaHooks";
import { AppContext } from "App";
//import $script from "scriptjs";

//const clientId = '1288412719-co5lcgs7t3v9v7jdkf6l5lsj3d99r6o0.apps.googleusercontent.com';
//const scopes = ['https://www.googleapis.com/auth/drive'];
const mimeTypeTexto = "text/plain";
const mimeTypeCarpeta = 'application/vnd.google-apps.folder';


export class WrongUserGoogleDriveLoginError extends Error {
    constructor(message?: string) {
        // Pasa los argumentos restantes (incluidos los específicos del proveedor) al constructor padre
        super(message);

        // Mantiene un seguimiento adecuado de la pila para el lugar donde se lanzó nuestro error (solo disponible en V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, WrongUserGoogleDriveLoginError)
        }

        this.name = 'WrongUserLoginError';
    }
}

export class GoogleDriveLoginError extends Error {
    innerError: any;
    constructor(message?: string, innerError?: any) {
        // Pasa los argumentos restantes (incluidos los específicos del proveedor) al constructor padre
        super(message);

        // Mantiene un seguimiento adecuado de la pila para el lugar donde se lanzó nuestro error (solo disponible en V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, GoogleDriveLoginError)
        }

        this.name = 'GoogleDriveLoginError';
        this.innerError = innerError;
    }
}

export class GoogleDriveFileNotFoundError extends Error {
    innerError: any;
    constructor(message?: string, innerError?: any) {
        // Pasa los argumentos restantes (incluidos los específicos del proveedor) al constructor padre
        super(message);

        // Mantiene un seguimiento adecuado de la pila para el lugar donde se lanzó nuestro error (solo disponible en V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, GoogleDriveFileNotFoundError)
        }

        this.name = 'GoogleDriveFileNotFoundError';
        this.innerError = innerError;
    }
}

export class GoogleDriveAuthError extends Error {
    innerError: any;
    constructor(message?: string, innerError?: any) {
        // Pasa los argumentos restantes (incluidos los específicos del proveedor) al constructor padre
        super(message);

        // Mantiene un seguimiento adecuado de la pila para el lugar donde se lanzó nuestro error (solo disponible en V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, GoogleDriveAuthError)
        }

        this.name = 'GoogleDriveAuthError';
        this.innerError = innerError;
    }
}

export interface GoogleDriveAuthStore {
    getAccessToken: () => Promise<string | null | undefined>,
    setAccessToken: (response: string | null | undefined) => Promise<void>,
    getLinkedUser: () => Promise<string | null | undefined>,
    setLinkedUser: (user: string | null | undefined) => Promise<void>
}

type ProgressCallback = (percentage: number) => void;

let apiLoadedPromise = new Promise<void>((resolve, reject) => {
    /*
    $script('https://apis.google.com/js/api.js', () => {

        gapi.load('client:auth2', () => {
            gapi.auth2.init({
                client_id: clientId,
                scope: scopes.join(' ')
            }).then(() => {
                resolve();
            }, error => reject(new GoogleDriveLoginError('Error al inicializar libreria Google', error)));
        }
    
    );
    });
    */
});

const googleDriveClient = axios.create({ baseURL: 'https://www.googleapis.com/drive/v3' });
googleDriveClient.interceptors.request.use(config => {
    const accessToken = ""; //gapi.client.getToken()?.access_token;
    if (!isNullOrWhiteSpace(accessToken)) {
        config.headers = {
            ...config.headers,
            'Authorization': 'Bearer ' + accessToken
        };
    }
    return config;
}, error => error);

//migrar de gapi a Google Identity Services antes del 31 de marzo de 2023
export function useGoogleDrive(params: {
    authStore: GoogleDriveAuthStore
}) {
    let { userInfo } = useContext(AppContext);
    let { cancelCurrentTokens, getCancelToken } = useCancel();
    const alpha2000Folder = useRef({ valor: null });
    async function login() {
        await apiLoadedPromise;
        let accessToken = await params.authStore.getAccessToken();
        const func: () => Promise<any> = async () => {
            if (accessToken) {
                //gapi.client.setToken({ access_token: accessToken });
                try {
                    //verificar que el access token funcione
                    let result = await getUserNameAndEmail();
                    let linkedUser = await params.authStore.getLinkedUser();
                    if (isNullOrWhiteSpace(linkedUser)) {
                        await params.authStore.setLinkedUser(result.email);
                    } else if (linkedUser !== result.email) {
                        throw new WrongUserGoogleDriveLoginError(`El cliente alpha actual usa el usuario de Google ${linkedUser} pero inició sesión con el usuario ${result.email}`);
                    }
                    return result;
                } catch (error) {
                    if (error instanceof GoogleDriveAuthError) {
                        accessToken = null;
                        return await func();
                    } else {
                        throw error;
                    }
                }
            } else {
                
                /*
                gapi.auth2.getAuthInstance().signOut();
                try {
                    await gapi.auth2.getAuthInstance().signIn({
                        scope: scopes.join(' ')
                    });
                } catch (result) {
                    throw new GoogleDriveLoginError('Error al iniciar sesión en Google Drive', result);
                }
                */

                let result = await getUserNameAndEmail();
                let linkedUser = await params.authStore.getLinkedUser();
                if (isNullOrWhiteSpace(linkedUser)) {
                    await params.authStore.setLinkedUser(result.email);
                } else if (linkedUser !== result.email) {
                    //gapi.auth2.getAuthInstance().signOut();
                    throw new WrongUserGoogleDriveLoginError(`El cliente alpha actual usa el usuario de Google ${linkedUser} pero inició sesión con el usuario ${result.email}`);
                }
                accessToken = ''; //gapi.client.getToken().access_token;
                await params.authStore.setAccessToken(accessToken);
                return result;
            }
        }
        return await func();
    }
    async function getUserNameAndEmail() {
        try {
            let respuesta = await googleDriveClient.get('/about', {
                params: {
                    fields: 'user(displayName,emailAddress)'
                }, cancelToken: getCancelToken()
            });
            return {
                userName: respuesta.data.user.displayName, email: respuesta.data.user.emailAddress
            }
        } catch (error) {
            if (error.response?.status === 401) {
                throw new GoogleDriveAuthError('Error de autorización en Google Drive', error);
            } else {
                throw error;
            }
        }
    }
    async function getAvailableSpace() {
        try {
            let respuesta = await googleDriveClient.get('/about', {
                params: {
                    fields: 'storageQuota'
                }, cancelToken: getCancelToken()
            });
            return {
                availableSpace: respuesta.data.storageQuota.limit - respuesta.data.storageQuota.usage
            }
        } catch (error) {
            if (error.response?.status === 401) {
                throw new GoogleDriveAuthError('Error de autorización en Google Drive', error);
            } else {
                throw error;
            }
        }
    }

    async function getAlpha2000Folder() {
        let request = {
            fields: 'nextPageToken,files(id)',
            spaces: 'drive',
            q: `mimeType = '${mimeTypeCarpeta}' and trashed = false and name = 'Alpha 2000' and 'root' in parents`,
            pageToken: null
        };
        while (true) {
            let respuesta = await googleDriveClient.get('/files',
                { params: request, cancelToken: getCancelToken() });
            for (let carpeta of respuesta.data.files) {
                //buscar archivo hash dentro de carpeta
                let respuestaArchivo = await googleDriveClient.get('/files', {
                    params: {
                        q: `mimeType = '${mimeTypeTexto}' and trashed = false and name = 'hash' and '${carpeta.id}' in parents`,
                        fields: 'files(id)',
                        spaces: 'drive',
                        pageSize: 2,
                    }, cancelToken: getCancelToken()
                });
                //solo puede haber un archivo hash
                if (respuestaArchivo.data.files.length === 1) {
                    //validar contenido de archivo
                    let respuestaHash = await googleDriveClient.get('/files/' +
                        respuestaArchivo.data.files[0].id, {
                        params: { alt: 'media' },
                        cancelToken: getCancelToken(),
                        responseType: 'blob'
                    });
                    let text = await respuestaHash.data.text();
                    if (parseInt(text) === +userInfo.nroClienteAlpha!) {
                        return carpeta.id;
                    }
                }
            }
            if (isNullOrWhiteSpace(respuesta.data.nextPageToken)) {
                break;
            } else {
                request.pageToken = respuesta.data.nextPageToken;
            }
        }
        //no existe la carpeta, crearla
        let respuesta = await googleDriveClient.post('/files',
            {
                name: 'Alpha 2000',
                mimeType: mimeTypeCarpeta,
                parents: ['root']
            }, { cancelToken: getCancelToken() });
        const idCarpeta = respuesta.data.id;
        const textEncoder = new TextEncoder();
        const buffer = textEncoder.encode(userInfo.nroClienteAlpha?.toString()).buffer;
        //crear archivo hash
        respuesta = await googleDriveClient.post('https://www.googleapis.com/upload/drive/v3/files',
            { name: 'hash', parents: [idCarpeta] },
            {
                params: { uploadType: 'resumable' },
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'X-Upload-Content-Type': mimeTypeTexto,
                    'X-Upload-Content-Length': buffer.byteLength
                }, cancelToken: getCancelToken()
            });
        let uploadUrl = respuesta.headers.location;
        await googleDriveClient.put(uploadUrl, buffer, { cancelToken: getCancelToken() });
        return idCarpeta;
    }
    async function getFolderIdCaratula(interno: string) {
        if (isNullOrWhiteSpace(userInfo.empresaActual)) {
            throw new Error('Usuario no seleccionó ninguna empresa');
        }
        if (isNullOrWhiteSpace(interno)) {
            throw new Error('Interno no puede ser vacio');
        }
        if (!alpha2000Folder.current.valor) {
            alpha2000Folder.current.valor = await getAlpha2000Folder();
        }
        let respuesta = await googleDriveClient.get('/files', {
            params: {
                fields: 'files(id)',
                spaces: 'drive',
                pageSize: 1,
                q: `mimeType = '${mimeTypeCarpeta}' and trashed = false and name = 'Caratula' and '${alpha2000Folder.current.valor}' in parents`
            }, cancelToken: getCancelToken()
        });
        const carpetaCaratulasCreada = respuesta.data.files.length === 0;
        let carpetaCaratulasId;
        if (carpetaCaratulasCreada) {
            respuesta = await googleDriveClient.post('/files',
                {
                    name: 'Caratula',
                    mimeType: mimeTypeCarpeta,
                    parents: [alpha2000Folder.current.valor]
                }, { cancelToken: getCancelToken() });
            carpetaCaratulasId = respuesta.data.id;
        } else {
            carpetaCaratulasId = respuesta.data.files[0].id;
        }
        let carpetaEmpresaId = null;
        if (!carpetaCaratulasCreada) {
            respuesta = await googleDriveClient.get('/files', {
                params: {
                    fields: 'files(id)',
                    spaces: 'drive',
                    pageSize: 1,
                    q: `mimeType = '${mimeTypeCarpeta}' and trashed = false and name = '${userInfo.empresaActual}' and '${carpetaCaratulasId}' in parents`
                }, cancelToken: getCancelToken()
            });
            if (respuesta.data.files.length > 0) {
                carpetaEmpresaId = respuesta.data.files[0].id;
            }
        }
        const carpetaEmpresaCreada = !carpetaEmpresaId;
        if (!carpetaEmpresaId) {
            respuesta = await googleDriveClient.post('/files',
                {
                    name: userInfo.empresaActual,
                    mimeType: mimeTypeCarpeta,
                    parents: [carpetaCaratulasId]
                }, { cancelToken: getCancelToken() });
            carpetaEmpresaId = respuesta.data.id;
        }
        let carpetaInternoId = null;
        if (!carpetaEmpresaCreada) {
            respuesta = await googleDriveClient.get('/files', {
                params: {
                    fields: 'files(id)',
                    spaces: 'drive',
                    pageSize: 1,
                    q: `mimeType = '${mimeTypeCarpeta}' and trashed = false and name = '${interno}' and '${carpetaEmpresaId}' in parents`
                }, cancelToken: getCancelToken()
            });
            if (respuesta.data.files.length > 0) {
                carpetaInternoId = respuesta.data.files[0].id;
            }
        }
        if (!carpetaInternoId) {
            respuesta = await googleDriveClient.post('/files',
                {
                    name: interno,
                    mimeType: mimeTypeCarpeta,
                    parents: [carpetaEmpresaId]
                }, { cancelToken: getCancelToken() });
            carpetaInternoId = respuesta.data.id;
        }
        return carpetaInternoId;
    }
    async function getFolderIdArticuloCatalogo(catalogo: string, articulo: string) {
        if (isNullOrWhiteSpace(catalogo)) {
            throw new Error('Catalogo no puede ser vacio');
        }
        if (isNullOrWhiteSpace(articulo)) {
            throw new Error('Articulo no puede ser vacio');
        }
        if (!alpha2000Folder.current.valor) {
            alpha2000Folder.current.valor = await getAlpha2000Folder();
        }
        let respuesta = await googleDriveClient.get('/files', {
            params: {
                fields: 'files(id)',
                spaces: 'drive',
                pageSize: 1,
                q: `mimeType = '${mimeTypeCarpeta}' and trashed = false and name = 'Catalogo' and '${alpha2000Folder.current.valor}' in parents`
            }, cancelToken: getCancelToken()
        });
        const carpetaCatalogosCreada = respuesta.data.files.length === 0;
        let carpetaCatalogosId;
        if (carpetaCatalogosCreada) {
            respuesta = await googleDriveClient.post('/files',
                {
                    name: 'Catalogo',
                    mimeType: mimeTypeCarpeta,
                    parents: [alpha2000Folder.current.valor]
                }, { cancelToken: getCancelToken() });
            carpetaCatalogosId = respuesta.data.id;
        } else {
            carpetaCatalogosId = respuesta.data.files[0].id;
        }
        let carpetaCatId = null;
        if (!carpetaCatalogosCreada) {
            respuesta = await googleDriveClient.get('/files', {
                params: {
                    fields: 'files(id)',
                    spaces: 'drive',
                    pageSize: 1,
                    q: `mimeType = '${mimeTypeCarpeta}' and trashed = false and name = '${catalogo}' and '${carpetaCatalogosId}' in parents`
                }, cancelToken: getCancelToken()
            });
            if (respuesta.data.files.length > 0) {
                carpetaCatId = respuesta.data.files[0].id;
            }
        }
        const carpetaCatCreada = !carpetaCatId;
        if (!carpetaCatId) {
            respuesta = await googleDriveClient.post('/files',
                {
                    name: catalogo,
                    mimeType: mimeTypeCarpeta,
                    parents: [carpetaCatalogosId]
                }, { cancelToken: getCancelToken() });
            carpetaCatId = respuesta.data.id;
        }
        let carpetaArticuloId = null;
        if (!carpetaCatCreada) {
            respuesta = await googleDriveClient.get('/files', {
                params: {
                    fields: 'files(id)',
                    spaces: 'drive',
                    pageSize: 1,
                    q: `mimeType = '${mimeTypeCarpeta}' and trashed = false and name = '${articulo}' and '${carpetaCatId}' in parents`
                }, cancelToken: getCancelToken()
            });
            if (respuesta.data.files.length > 0) {
                carpetaArticuloId = respuesta.data.files[0].id;
            }
        }
        if (!carpetaArticuloId) {
            respuesta = await googleDriveClient.post('/files',
                {
                    name: articulo,
                    mimeType: mimeTypeCarpeta,
                    parents: [carpetaCatId]
                }, { cancelToken: getCancelToken() });
            carpetaArticuloId = respuesta.data.id;
        }
        return carpetaArticuloId;
    }
    async function uploadFile(folderId: string, file: File, progressCallback: ProgressCallback) {
        let respuesta = await googleDriveClient.post('https://www.googleapis.com/upload/drive/v3/files',
            { name: file.name, parents: [folderId] },
            {
                params: { uploadType: 'resumable' },
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'X-Upload-Content-Type': file.type,
                    'X-Upload-Content-Length': file.size
                }, cancelToken: getCancelToken()
            });
        let uploadUrl = respuesta.headers.location;
        const chunkSize = 256 * 1024 * 40;
        let chunkStart = 0;
        while (chunkStart < file.size) {
            progressCallback(chunkStart / file.size * 100);
            const size = Math.min(chunkSize, file.size - chunkStart);
            const slice = file.slice(chunkStart, chunkStart + size);
            try {
                let respuesta = await googleDriveClient.put(uploadUrl, await slice.arrayBuffer(),
                    {
                        headers: {
                            'Content-Range': 'bytes ' + chunkStart.toString() + '-' + (chunkStart + size - 1).toString() + '/' + file.size.toString()
                        }, cancelToken: getCancelToken()
                    });
                progressCallback(100);
                return respuesta.data.id;
            } catch (error) {
                if (error.response?.status === 308) {
                    const range = error.response.headers.range;
                    if (!isNullOrWhiteSpace(range)) {
                        chunkStart = parseInt(range.split('-')[1]) + 1;
                    } else {
                        throw error;
                    }
                } else {
                    throw error;
                }
            }
        }
    }
    async function uploadFileCaratula(interno: string, file: File, progressCallback: ProgressCallback) {
        try {
            interno = sanitizarNombreInternoParaArchivo(interno);
            const folderId = await getFolderIdCaratula(interno);
            return await uploadFile(folderId, file, progressCallback);
        } catch (error) {
            if (error.response?.status === 401) {
                throw new GoogleDriveAuthError('Error de autorización en Google Drive', error);
            } else {
                throw error;
            }
        }
    }
    async function uploadFileArticuloCatalogo(catalogo: string, articulo: string, file: File,
        progressCallback: ProgressCallback) {
        try {
            catalogo = sanitizarNombreInternoParaArchivo(catalogo);
            articulo = sanitizarNombreInternoParaArchivo(articulo);
            const folderId = await getFolderIdArticuloCatalogo(catalogo, articulo);
            return await uploadFile(folderId, file, progressCallback);
        } catch (error) {
            if (error.response?.status === 401) {
                throw new GoogleDriveAuthError('Error de autorización en Google Drive', error);
            } else {
                throw error;
            }
        }
    }
    async function getFileLinks(fileId: string) {
        try {
            let params = { fields: 'nextPageToken,permissions(type,role)', pageToken: null };
            let permissions: any[] = [];
            //ver si archivo tiene permisos para que cualquiera lo pueda ver
            while (true) {
                let respuesta = await googleDriveClient.get(`/files/${fileId}/permissions`,
                    {
                        params: params,
                        cancelToken: getCancelToken()
                    });
                permissions = permissions.concat(respuesta.data.permissions);
                if (isNullOrWhiteSpace(respuesta.data.nextPageToken)) {
                    break;
                } else {
                    params.pageToken = respuesta.data.nextPageToken;
                }
            }
            if (!permissions.some(p => p.type === 'anyone')) {
                //crear permiso para que cualquiera pueda ver el archivo
                await googleDriveClient.post(`/files/${fileId}/permissions`,
                    {
                        type: 'anyone', role: 'reader', allowFileDiscovery: false
                    }, { cancelToken: getCancelToken() });
            }
            let respuesta = await googleDriveClient.get('/files/' + fileId, {
                params: { fields: 'webViewLink,webContentLink' },
                cancelToken: getCancelToken()
            });
            return { viewLink: respuesta.data.webViewLink, contentLink: respuesta.data.webContentLink };
        } catch (error) {
            if (error.response?.status === 404) {
                throw new GoogleDriveFileNotFoundError('Archivo no existe', error);
            } else if (error.response?.status === 401) {
                throw new GoogleDriveAuthError('Error de autorización en Google Drive', error);
            } else {
                throw error;
            }
        }
    }

    async function deleteFile(fileId: string) {
        try {
            await googleDriveClient.delete('/files/' + fileId, { cancelToken: getCancelToken() });
        } catch (error) {
            if (error.response?.status === 404) {
                throw new GoogleDriveFileNotFoundError('Archivo no existe', error);
            } else if (error.response?.status === 401) {
                throw new GoogleDriveAuthError('Error de autorización en Google Drive', error);
            } else {
                throw error;
            }
        }
    }

    async function deleteFiles(fileIds: string[]) {
        try {
            await Promise.all(fileIds.map(async f => {
                try {
                    await googleDriveClient.delete('/files/' + f, { cancelToken: getCancelToken() });
                } catch (error) {
                    if (error.response?.status !== 404) {
                        throw error;
                    }
                }
            }));
        } catch (error) {
            if (error.response?.status === 401) {
                throw new GoogleDriveAuthError('Error de autorización en Google Drive', error);
            } else {
                throw error;
            }
        }
    }

    return {
        login,
        cancelCurrentTokens,
        getAvailableSpace,
        getFileLinks,
        deleteFile,
        deleteFiles,
        uploadFileCaratula,
        uploadFileArticuloCatalogo,
    };
}

