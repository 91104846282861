import React from "react";
import { AppContext, NotFoundComponent } from "../../App";
import Grilla, { GrillaRef } from "../../Grilla";
import { arabToRoman } from "roman-numbers";
import { useQuery, useDecodedParams } from "../../Utilidades";
import { MyModal } from "../../MyModal";
import { Modal, Button } from "react-bootstrap";
import FileSaver from "file-saver";
import Loader from "react-loaders";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { useApi } from "ApiHooks";


export function ReporteLNA() {
    let [notFound, updateNotFound] = React.useState(false);
    let query = useQuery();
    let conNovedades = query.get('conNovedades') === 'true';
    let { codigoCatalogo } = useDecodedParams();
    let { mostrarError } = React.useContext(AppContext);
    let api = useApi();
    let reporte = React.useRef([]);
    let refGrilla = React.useRef<GrillaRef>(null);
    let [generandoExcel, updateGenerandoExcel] = React.useState(false);
    React.useEffect(() => {
        async function cargarReporte() {
            try {
                if (!codigoCatalogo) {
                    updateNotFound(true);
                    return;
                }
                refGrilla.current?.setBloqueado(true);
                let respuesta;
                if (conNovedades) {
                    respuesta = await api.getReporteLNAConNovedades(codigoCatalogo);
                } else {
                    respuesta = await api.getReporteLNA(codigoCatalogo);
                }
                let unidades = await api.getUnidades();
                let lista = respuesta.map((item: any) => ({
                    ...item,
                    NombreUnidadDeclarada: unidades.find((unidad: any) => unidad.Codigo === item.CodigoUnidadDeclarada)?.Descripcion ?? item.CodigoUnidadDeclarada
                }));
                reporte.current = lista;
                refGrilla.current?.setBloqueado(false);
                refGrilla.current?.recargar();
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error(error);
                    mostrarError('Error al cargar reporte LNA');
                }
            }
        };
        cargarReporte();
        //eslint-disable-next-line
    }, [conNovedades, codigoCatalogo]);
    async function exportarAExcel() {
        try {
            updateGenerandoExcel(true);
            let excel, fileName;
            if (conNovedades) {
                ({ excel, fileName } = await api.excelReporteLNAConNovedades(codigoCatalogo as string));
            } else {
                ({ excel, fileName } = await api.excelReporteLNA(codigoCatalogo as string));
            }
            updateGenerandoExcel(false);
            FileSaver.saveAs(excel, fileName);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error(error);
                mostrarError('Error al exportar reporte LNA a Excel');
            }
            if (!api.isUnmounted()) {
                updateGenerandoExcel(false);
            }
        }
    }
    let camposGrilla = [{ propiedad: 'CodigoArticulo', titulo: 'Codigo Articulo', clave: true },
    { propiedad: 'NombreUnidadDeclarada', titulo: 'Unidad Declarada' }, { propiedad: 'Ncm', titulo: 'Ncm' },
    {
        propiedad: 'AnexoLNA', titulo: 'Anexo Actual',
        plantillaFormato: (valor: any) => valor ? arabToRoman(valor) : 'No tiene LNA'
    }, {
        propiedad: 'AnexoLNAAnterior', titulo: 'Anexo Anterior',
        plantillaFormato: (valor: any) => valor ? arabToRoman(valor) : 'No tiene LNA'
    }];
    return notFound ? <NotFoundComponent></NotFoundComponent> : (<>
        <h2>{conNovedades ? 'Reporte LNA con novedades ' : 'Reporte LNA '}de catalogo {codigoCatalogo}</h2>
        <Button variant="success" className="mb-2" onClick={exportarAExcel}>
            <FontAwesomeIcon icon={faFileExcel} />
            <span>Exportar a Excel</span>
        </Button>
        <Grilla cargarDatos={(desde: number, hasta: number) => {
            return Promise.resolve({ cantidadItems: reporte.current.length, items: reporte.current.slice(desde - 1, hasta) });
        }} campos={camposGrilla} ref={refGrilla}></Grilla>
        <MyModal show={generandoExcel}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="lead">Generando Excel...</p>
                    <div className="loader-container">
                        <Loader type="ball-spin-fade-loader" active></Loader>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        api.cancelCurrentTokens();
                    }}>Cancelar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
    </>);
}