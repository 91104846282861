import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { MyForm, MyFormControl } from "FormikHooks";
import { AppContext } from "App";
import * as Yup from "yup";
import { MyModal } from "MyModal";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { GrillaSync } from "Grilla";

export type EstablecerSufijosRef = {
    mostrar: () => Promise<{ codigo: string, valor: string | null | undefined }[]>
}

export const EstablecerSufijos = React.forwardRef((props: any, ref) => {
    let { mostrarError } = React.useContext(AppContext);
    let formikRef = React.useRef<FormikProps<any>>(null);
    let [estado, updateEstado] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'esconder') {
            return {
                abierto: false, resolve: null, reject: null, sufijos: [], creando: false,
                modificando: false, valorModificando: null,
            };
        } else if (accion.tipo === 'mostrar') {
            return {
                abierto: true, resolve: accion.resolve, reject: accion.reject, sufijos: [],
                creando: false, modificando: false, valorModificando: null,
            };
        } else if (accion.tipo === 'mostrarCrear') {
            return { ...estado, creando: true, modificando: false, valorModificando: null, codigoIngresando: '' };
        } else if (accion.tipo === 'mostrarModificar') {
            return { ...estado, creando: false, modificando: true, valorModificando: accion.valor, codigoIngresando: accion.valor.codigo };
        } else if (accion.tipo === 'esconderCrearModificar') {
            return { ...estado, creando: false, modificando: false, valorModificando: null };
        } else if (accion.tipo === 'insertSufijo') {
            let nuevosSufijos = Array.from(estado.sufijos);
            let indice = nuevosSufijos.findIndex((s: any) => s.codigo === accion.valor.codigo);
            if (indice > -1) {
                nuevosSufijos.splice(indice, 1);
            }
            nuevosSufijos.push(accion.valor);
            return { ...estado, sufijos: nuevosSufijos, creando: false, modificando: false, valorModificando: null };
        } else if (accion.tipo === 'deleteSufijo') {
            let nuevosSufijos = Array.from(estado.sufijos);
            let indice = nuevosSufijos.findIndex((s: any) => s.codigo === accion.valor.codigo);
            if (indice > -1) {
                nuevosSufijos.splice(indice, 1);
            }
            return {
                ...estado, sufijos: nuevosSufijos, creando: false, modificando: false,
                valorModificando: null
            };
        } else if (accion.tipo === 'setCodigoIngresando') {
            return { ...estado, codigoIngresando: accion.valor };
        }
    }, {
        abierto: false, resolve: null, reject: null, sufijos: [], creando: false, modificando: false,
        valorModificando: null,
    });
    React.useImperativeHandle(ref, () => ({
        mostrar: () => {
            return new Promise<{ codigo: string, valor: string | null | undefined }[]>((resolve, reject) => {
                updateEstado({ tipo: 'mostrar', resolve: resolve, reject: reject });
            });
        }
    }));
    let schema = Yup.object({
        'codigo': Yup.string().nullable().required('Debe ingresar el codigo')
            .test('cantidad-caracteres', 'El codigo debe tener 2 o 4 caracteres',
                (valor: any) => valor && (valor?.length === 2 || valor?.length === 4)),
        'valor': Yup.string().when('codigo', {
            is: (valor: any) => valor !== null && valor !== undefined && valor.length === 4,
            then: Yup.string().nullable(),
            otherwise: Yup.string().nullable().required('Debe ingresar el valor')
                .max(20, 'El valor no puede tener más de 20 caracteres')
                .test('caracteres', 'El valor no puede tener ni parentesis ni guiones',
                    (valor: any) => {
                        if (!valor) {
                            return true;
                        }
                        return !valor.includes('(') && !valor.includes(')') && !valor.includes('-') && !valor.includes('－');
                    })
        })
    });
    function onSubmit(values: any, actions: FormikHelpers<any>) {
        let error = false;
        if (values.codigo.substring(0, 1) === 'Z' && values.valor.match(/[^0-9]+/)) {
            actions.setFieldError('valor', 'El valor solo puede tener digitos cuando el codigo empieza con Z');
            error = true;
        }
        if (estado.creando) {
            if (estado.sufijos.map((s: any) => s.codigo).includes(values.codigo)) {
                actions.setFieldError('codigo', 'Este codigo ya fue ingresado');
                error = true;
            } else if (estado.sufijos.map((s: any) => s.codigo.substring(0, 2)).includes(values.codigo.substring(0, 2))) {
                actions.setFieldError('codigo', `Ya fue ingresada otra opción del sufijo ${values.codigo.substring(0, 2)}`);
                error = true;
            }
        }
        if (!error) {
            updateEstado({ tipo: 'insertSufijo', valor: values });
        }
        actions.setSubmitting(false);
    }
    return (<>
        <MyModal show={estado.abierto} onHide={() => {
            estado.reject();
            updateEstado({ tipo: 'esconder' });
        }}>
            <Modal.Dialog size="xl">
                <Modal.Header closeButton>
                    Establecer Sufijos
            </Modal.Header>
                <Modal.Body>
                    <GrillaSync datos={estado.sufijos} campos={[{ propiedad: 'codigo', titulo: 'Codigo', clave: true },
                    { propiedad: 'valor', titulo: 'Valor' }]}
                        eventoAgregar={() => updateEstado({ tipo: 'mostrarCrear' })}
                        eventoDetalle={(item: any) => {
                            if (item.codigo.length === 4) {
                                mostrarError('Este sufijo no tiene valor para modificar');
                            } else {
                                updateEstado({ tipo: 'mostrarModificar', valor: item });
                            }
                        }} eventoEliminar={(item: any) => updateEstado({ tipo: 'deleteSufijo', valor: item })}></GrillaSync>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        estado.reject();
                        updateEstado({ tipo: 'esconder' });
                    }}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={() => {
                        if (estado.sufijos.length > 0) {
                            let set = new Set();
                            for (const codigo of estado.sufijos.map((s: any) => s.codigo)) {
                                if (set.has(codigo)) {
                                    mostrarError('No puede ingresar el mismo sufijo dos veces');
                                    return;
                                }
                                set.add(codigo);
                            }
                            estado.resolve(estado.sufijos);
                            updateEstado({ tipo: 'esconder' });
                        } else {
                            mostrarError('Debe ingresar al menos un sufijo');
                        }
                    }}>
                        Establecer Sufijos
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={estado.creando || estado.modificando} onHide={() => updateEstado({ tipo: 'esconderCrearModificar' })}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    {estado.creando ? 'Agregar sufijo' : 'Modificar sufijo'}
                </Modal.Header>
                <Modal.Body>
                    <Formik innerRef={formikRef} validationSchema={schema} initialValues={{
                        'codigo': estado.valorModificando?.codigo ?? '',
                        'valor': estado.valorModificando?.valor ?? ''
                    }} onSubmit={onSubmit}>
                        <MyForm blockWhenSubmitting={false}>
                            <Form.Group>
                                <MyFormControl type="text" name="codigo" label="Codigo" maxLength={4} disabled={estado.modificando}
                                    onValueChange={(valor: any) => updateEstado({ tipo: 'setCodigoIngresando', valor: valor })}></MyFormControl>
                            </Form.Group>
                            <Form.Group>
                                <MyFormControl type="text" name="valor" label="Valor" maxLength={20} disabled={estado.codigoIngresando?.trim()?.length === 4}></MyFormControl>
                            </Form.Group>
                        </MyForm>
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateEstado({ tipo: 'esconderCrearModificar' })}>
                        Cancelar
                    </Button>
                    <Button onClick={() => formikRef.current?.submitForm()}>
                        Ingresar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
    </>);
});