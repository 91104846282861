import { Decimal } from "decimal.js";
import { useLocation, useParams } from "react-router";
import { objectEquals } from "object-equals";
import { DateTime } from "luxon";
import { UserInfo } from "App";
import React from "react";

export function isEmptyObject(obj: object) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function isNullOrWhiteSpace(str: string | null | undefined) {
    return str === null || str === undefined || `${str}`.match(/^\s*$/) !== null;
}

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export function useDecodedParams() {
    let obj = useParams<{ [key: string]: string | undefined }>();
    for (const key in obj) {
        if (obj[key]) {
            //react-router hace que no se codifique el % por lo tanto no descodificarlo
            //(revisar si se migra a react-router v6)
            //obj[key] = decodeURIComponent(obj[key] as string);
            let newParamValue = obj[key] as string;
            newParamValue = newParamValue.replace(/%26/gi, '&');
            newParamValue = newParamValue.replace(/%3F/gi, '?');
            newParamValue = newParamValue.replace(/%23/gi, '#');
            newParamValue = newParamValue.replace(/%2B/gi, '+');
            newParamValue = newParamValue.replace(/%3B/gi, ';');
            newParamValue = newParamValue.replace(/%2C/gi, ',');
            newParamValue = newParamValue.replace(/%2F/gi, '/');
            newParamValue = newParamValue.replace(/%3A/gi, ':');
            newParamValue = newParamValue.replace(/%40/gi, '@');
            newParamValue = newParamValue.replace(/%3D/gi, '=');
            newParamValue = newParamValue.replace(/%24/gi, '$');
            obj[key] = newParamValue;
        }
    }
    return obj;
}

type DatoGenerico = {
    Codigo: string,
    CUIT: string | null | undefined,
    Nombre: string | null | undefined,
    Descripcion: string | null | undefined
};

export function convertirDatosGenericosAOption(lista: DatoGenerico[]) {
    return lista.map((item) => {

        if (item.CUIT) {
            return { value: item.CUIT, label: item.Nombre };
        } else if (item.Nombre) {
            return { value: item.Codigo, label: item.Nombre };
        } else {
            return { value: item.Codigo, label: item.Descripcion ?? '' }
        }


    });
}


export function convertirDatosGenericosAOptionIdDescripcion(lista: DatoGenerico[]) {
    return lista.map((item) => {
        if (item.Nombre) {
            return { value: item.Codigo, label: item.Codigo + ' - ' + item.Nombre };
        } else {
            return { value: item.Codigo, label: item.Codigo + ' - ' + item.Descripcion ?? '' }
        }


    });
}



export function optionLabelConCodigo(option: { value: string, label: string | null | undefined }) {
    return option.label ? option.value + ' - ' + option.label : option.value;
}

export function cargarDatosGrillaDeArray(array: Array<any>) {
    array = array ?? [];
    return (desde: number, hasta: number) => {
        return Promise.resolve({ cantidadItems: array.length, items: array.slice(desde - 1, hasta) });
    };
}

export function escapeRegExp(str: string) {
    return str.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
}

export function validarCUIT(cuit: string | null | undefined) {
    if (isNullOrWhiteSpace(cuit)) {
        return false;
    }
    if (cuit!.length !== 11) {
        return false;
    }
    const mult = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
    let total = 0;
    for (let i = 0; i < mult.length; i++) {
        total += parseInt(cuit![i]) * mult[i];
    }
    let resto = total % 11;
    let digito = resto === 0 ? 0 : resto === 1 ? 9 : 11 - resto;
    return parseInt(cuit![10]) === digito;
}

export function toHtmlDate(valor: string | null | undefined) {
    if (valor === null || valor === undefined) {
        return '';
    }
    try {
        return DateTime.fromISO(valor).toISODate();
    } catch (error) {
        return '';
    }
}

export function toHtmlDateLocal(valor: string | null | undefined) {
    if (valor === null || valor === undefined) {
        return '';
    }
    try {
        let fecha = DateTime.fromISO(valor, { zone: 'utc' });
        fecha = fecha.setZone('local', { keepLocalTime: true });
        return fecha.toISODate();
    } catch (error) {
        return '';
    }
}

export function toDate(valor: string | null | undefined) {
    if (valor === null || valor === undefined) {
        return '';
    }
    try {
        return DateTime.fromISO(valor).toLocaleString({
            ...DateTime.DATE_SHORT,
            'day': '2-digit', 'month': '2-digit'
        });
    } catch (error) {
        return '';
    }
}

export function toDateTime(valor: string | null | undefined) {
    if (valor === null || valor === undefined) {
        return '';
    }
    try {
        return DateTime.fromISO(valor).toLocaleString({
            ...DateTime.DATETIME_SHORT,
            'day': '2-digit', 'month': '2-digit'
        });
    } catch (error) {
        return '';
    }
}

export function toFixedDecimal(valor: number | string | Decimal | null | undefined, digitos?: number) {
    if (valor === null || valor === undefined) {
        return '';
    } else if (typeof valor === 'string' && valor.match(/^\s*$/) !== null) { //ver si la cadena está vacía
        return '';
    }
    try {
        return new Decimal(valor).toFixed(digitos);
    } catch (error) {
        return '';
    }
}

export function createQueryString(obj: Record<string, string | number | boolean | null | undefined>) {
    let array = Object.keys(obj).filter(key => obj[key] !== null && obj[key] !== undefined)
        .map(key => key + '=' + encodeURIComponent(obj[key] as string | number | boolean));
    if (array.length === 0) {
        return '';
    } else {
        return '?' + array.join('&');
    }
}

export function intersect(arrayA: Iterable<any>, arrayB: Iterable<any>) {
    let setA = new Set(arrayA);
    let setB = new Set(arrayB);
    let intersection = new Set([...setA].filter((x: any) => setB.has(x)));
    return Array.from(intersection);
}

//TODO: buscar map que permita usar funcion objectEquals
export function groupBy<TSource, TKey>(array: Array<TSource>, keySelector: (item: TSource) => TKey) {
    return array.reduce((map, item: TSource) => {
        const key = keySelector(item);
        if (typeof key === 'string') {
            return map.set(key, [...map.get(key) || [], item]);
        } else {
            const existingKey = Array.from(map.keys()).find(keyInMap => objectEquals(key, keyInMap));
            if (existingKey) {
                return map.set(existingKey, [...map.get(existingKey) || [], item]);
            } else {
                return map.set(key, [item]);
            }
        }
    }, new Map<TKey, Array<TSource>>());
}

export function strCmp(a: string, b: string) {
    return (a < b ? -1 : (a > b ? 1 : 0));
}

export function obtenerSufijoModelo(str: string | null | undefined) {
    if (isNullOrWhiteSpace(str)) {
        return '';
    }
    let match = /(AB|AI)\(([^)]+)\)/.exec(str as string);
    if (match) {
        return match[2];
    }
    return '';
}

export function reemplazarCaracteresInvalidosSufijos(str: string | null | undefined) {
    return str?.replace(/[-()@\uFF0D]+/g, ' ') ?? '';
}

export function spliceString(str: string, offset: number, text: string, removeCount: number = 0) {
    let calculatedOffset = offset < 0 ? str.length + offset : offset;
    return str.substring(0, calculatedOffset) + text + str.substring(calculatedOffset + removeCount);
}

export function isInRole(userInfo: UserInfo, role: string) {
    if (userInfo?.claims === null || userInfo?.claims === undefined) {
        return false;
    }
    //console.log('userInfo.claims.role ' + userInfo.claims.role);
    if (Array.isArray(userInfo.claims?.role)) {
        return userInfo.claims.role.includes(role);
    } else {
        return userInfo.claims.role === role;
    }
}

export function sanitizarNombreInternoParaArchivo(interno: string) {
    return interno.replace(/[\\/:*?""<>|]/g, '_');
}

const pdfWindowHtml = `
    <!DOCTYPE html>
    <html>
        <head>
            <title>Ver PDF</title>
            <style>
                #pdfElement{
                    position:absolute;
                    width:100%;
                    height:100%;
                    top:0;
                    left:0;
                }
            </style>
            <script type="text/javascript">
                window.onload = function(){
                    this.opener.postMessage({'loaded': true}, "*");
                    document.getElementById('pdfElement').src = this.opener.urlPdf;
                };
            </script>
        </head>
        <body>
            <embed id="pdfElement" type="application/pdf">
        </body>
    </html>
`;
const pdfWindowUrl = URL.createObjectURL(new Blob([pdfWindowHtml], { type: "text/html" }));

export function abrirPdf(urlPdf: string) {
    return new Promise<void>((resolve, reject) => {
        (window as any).urlPdf = urlPdf;
        let ventana = window.open(pdfWindowUrl);
        if (ventana) {
            const listener = (event: MessageEvent) => {
                if (event.data.loaded) {
                    resolve();
                } else {
                    reject('No se abrió la ventana');
                }
            }
            window.addEventListener('message', event => {
                listener(event);
                window.removeEventListener('message', listener);
            });
            setTimeout(() => {
                reject('No se abrió la ventana');
                window.removeEventListener('message', listener);
            }, 1000);
        } else {
            reject('Ventana es undefined');
        }
    });
}

export function CentradoVertical(props: React.PropsWithChildren<{}>) {
    /* llenar-espacio*/
    return <div className="d-flex align-items-center justify-content-center">
        {props.children}
    </div>
}

export function includesObject(array: any[], obj: object) {
    for (const item of array) {
        if (objectEquals(item, obj)) {
            return true;
        }
    }
    return false;
}

export function displayBytes(value: number, decimalPlaces: number = 1): string {
    if (value < 0) {
        return '-' + displayBytes(-value, decimalPlaces);
    }
    const suffixes = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let i = 0;
    let dValue = new Decimal(value);
    while (dValue.toDecimalPlaces(decimalPlaces).comparedTo(1024) === 1) {
        dValue = dValue.div(1024);
        i++;
    }
    return dValue.toFixed(decimalPlaces) + ' ' + suffixes[i];
}