import React from "react";
import { AppContext } from "App";
import { MyModal } from "MyModal";
import { Modal, Button, Form } from "react-bootstrap";
import { GrillaSync, GrillaRef } from "Grilla";
import { isNullOrWhiteSpace, convertirDatosGenericosAOption, optionLabelConCodigo } from "Utilidades";
import { DialogoConfirmar, DialogoConfirmarRef } from "DialogoConfirmar";
import BlockUi from "react-block-ui";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { MyForm, MyFormControl, MySelect } from "FormikHooks";
import { useApi } from "ApiHooks";

enum EstadoModal {
    Cerrado,
    Cargando,
    Abierto
}
export type AltaBajaMapeosEstadoMercRef = {
    mostrar: () => void
};
type ValoresFormCrear = { Valor: string, CodigoEstadoMercaderia: string };
export const AltaBajaMapeosEstadoMerc = React.forwardRef((props: any, ref: any) => {
    let { mostrarError, userInfo } = React.useContext(AppContext);
    let api = useApi();
    let dialogoRef = React.useRef<DialogoConfirmarRef>(null);
    let grillaRef = React.useRef<GrillaRef>(null);
    let [estado, updateEstado] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrarModal') {
            return { estadoModal: EstadoModal.Cargando, mapeos: [], estadosMerc: [] };
        } else if (accion.tipo === 'cerrarModal') {
            return { estadoModal: EstadoModal.Cerrado, mapeos: [], estadosMerc: [] };
        } else if (accion.tipo === 'cargarMapeos') {
            return {
                estadoModal: EstadoModal.Abierto, mapeos: accion.mapeos ?? [],
                estadosMerc: accion.estadosMerc ?? []
            };
        } else if (accion.tipo === 'setCargando') {
            return { ...estado, estadoModal: accion.valor ? EstadoModal.Cargando : EstadoModal.Abierto };
        } else if (accion.tipo === 'insertMapeo') {
            let nuevosMapeos = Array.from(estado.mapeos);
            let indice = nuevosMapeos.findIndex((m: any) => m.Valor === accion.mapeo.Valor);
            if (indice > -1) {
                nuevosMapeos.splice(indice, 1);
            }
            nuevosMapeos.push(accion.mapeo);
            return { ...estado, mapeos: nuevosMapeos };
        } else if (accion.tipo === 'eliminarMapeo') {
            return {
                ...estado, estadoModal: EstadoModal.Abierto,
                mapeos: estado.mapeos.filter((m: any) => m.Valor !== accion.valor),
            };
        }
        return estado;
    }, { estadoModal: EstadoModal.Cerrado, mapeos: [] });
    let [mostrarModalCrear, updateMostrarModalCrear] = React.useState(false);
    React.useImperativeHandle(ref, () => ({
        mostrar: () => updateEstado({ tipo: 'mostrarModal' })
    }));
    React.useEffect(() => {
        if (estado.estadoModal === EstadoModal.Cargando) {
            async function cargar() {
                try {
                    let estadosMerc = await api.getEstadosMercaderia();
                    let respuesta = await api.busquedaMapeosEstadoMercaderia();
                    updateEstado({ tipo: 'cargarMapeos', mapeos: respuesta, estadosMerc: estadosMerc });
                } catch (error) {
                    if (!api.isCancel(error)) {
                        console.error('Error al cargar mapeos', error);
                        mostrarError('Error al cargar asignaciones');
                        updateEstado({ tipo: 'cerrarModal' });
                    }
                }
            }
            cargar();
        } else if (estado.estadoModal === EstadoModal.Cerrado) {
            api.cancelCurrentTokens();
        }
        //eslint-disable-next-line
    }, [estado.estadoModal]);
    let campos = [{ titulo: 'Dato Excel', propiedad: 'Valor', clave: true },
    {
        titulo: 'Estado Mercadería', propiedad: 'CodigoEstadoMercaderia', plantillaFormato: (codigoEstadoMerc: any) => {
            if (isNullOrWhiteSpace(codigoEstadoMerc)) {
                return '';
            } else {
                return estado.estadosMerc.find((p: any) => p.Codigo === codigoEstadoMerc)?.Descripcion ?? codigoEstadoMerc;
            }
        }
    }];
    async function insertMapeo(values: ValoresFormCrear, actions: FormikHelpers<ValoresFormCrear>) {
        if (estado.mapeos.map((m: any) => m.Valor.toUpperCase()).includes(values.Valor.toUpperCase())) {
            actions.setFieldError('Valor', 'Este valor ya tiene una asignación');
        } else {
            try {
                let nuevoMapeo = { ...values, NroClienteAlpha: userInfo.nroClienteAlpha };
                await api.insertMapeos({ MapeosEstadoMercaderia: [nuevoMapeo] });
                updateEstado({ tipo: 'insertMapeo', mapeo: nuevoMapeo });
                updateMostrarModalCrear(false);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al insertar mapeo', error);
                    mostrarError('Hubo un error al crear la asignación');
                }
            }
        }
    }
    function eliminarMapeo(item: any) {
        dialogoRef.current!.mostrar().then(async () => {
            try {
                updateEstado({ tipo: 'setCargando', valor: true });
                await api.deleteMapeoEstadoMercaderia(item.Valor);
                updateEstado({ tipo: 'eliminarMapeo', valor: item.Valor });
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al eliminar mapeo', error);
                    mostrarError('Error al eliminar asignación');
                    updateEstado({ tipo: 'setCargando', valor: false });
                }
            }
        }).catch(() => { });
    }
    return <>
        <MyModal show={estado.estadoModal === EstadoModal.Cargando || estado.estadoModal === EstadoModal.Abierto}
            onHide={() => updateEstado({ tipo: 'cerrarModal' })}>
            <Modal.Dialog size="xl">
                <Modal.Header closeButton>
                    Asignaciones de estados mercadería
                </Modal.Header>
                <Modal.Body>
                    <BlockUi blocking={estado.estadoModal === EstadoModal.Cargando}>
                        <GrillaSync datos={estado.mapeos} campos={campos}
                            eventoAgregar={() => updateMostrarModalCrear(true)}
                            eventoEliminar={eliminarMapeo} ref={grillaRef}></GrillaSync>
                    </BlockUi>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateEstado({ tipo: 'cerrarModal' })}>Cerrar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={mostrarModalCrear} onHide={() => updateMostrarModalCrear(false)}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    <h2>Crear Asignación de Estado Mercadería</h2>
                </Modal.Header>
                <Formik validationSchema={Yup.object({
                    'Valor': Yup.string().nullable().required('Debe ingresar el estado mercadería como aparece en el Excel'),
                    'CodigoEstadoMercaderia': Yup.string().nullable().required('Debe seleccionar el estado mercadería que corresponde')
                })} initialValues={{
                    'Valor': '',
                    'CodigoEstadoMercaderia': ''
                }} onSubmit={insertMapeo}>
                    {({ isSubmitting, submitForm }) =>
                        <>
                            <Modal.Body>
                                <MyForm>
                                    <Form.Group>
                                        <MyFormControl autoFocus type="text" label="Estado mercadería como aparece en excel" name="Valor"></MyFormControl>
                                    </Form.Group>
                                    <Form.Group>
                                        <MySelect name="CodigoEstadoMercaderia" label="Estado mercadería correspondiente"
                                            options={() => api.getEstadosMercaderia().then(convertirDatosGenericosAOption)} getOptionLabel={optionLabelConCodigo}></MySelect>
                                    </Form.Group>
                                </MyForm>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="danger" onClick={() => updateMostrarModalCrear(false)}>
                                    Cancelar
                                </Button>
                                <Button disabled={isSubmitting} onClick={submitForm}>
                                    Ingresar
                                </Button>
                            </Modal.Footer>
                        </>}
                </Formik>
            </Modal.Dialog>
        </MyModal>
        <DialogoConfirmar ref={dialogoRef} mensaje="¿Está seguro que desea eliminar esta asignación?" textoBotonConfirmar="Sí" textoBotonCancelar="No"></DialogoConfirmar>
    </>;
});