import React from 'react'
import { Button, Col, Modal, ProgressBar } from 'react-bootstrap'
import Loader from 'react-loaders'

export interface ModalDescargarArchivoProps {
    showModalDescargandoArchivo: boolean
    handleCloseModalDescargandoArchivo: () => void
    cargando: boolean
    progress: number | undefined
    cancelarDescarga: () => void
}

const ModalDescargarArchivo = ({ showModalDescargandoArchivo, handleCloseModalDescargandoArchivo, progress, cargando, cancelarDescarga }: ModalDescargarArchivoProps) => {
    return (
        <Modal animation={false} backdrop="static" show={showModalDescargandoArchivo} onHide={handleCloseModalDescargandoArchivo} dialogClassName="modal-upload"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton={false}>
                <Modal.Title>Descargar Archivo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <>
                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 5, alignContent: 'center', alignItems: 'center' }}>
                        {progress === 0 && <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                            <p style={{ fontWeight: 'bold' }}>Obteniendo archivo..</p>
                            <Loader active={cargando} type='ball-beat' />
                        </div>}
                        <ProgressBar style={{ width: 270, height: 25 }} striped variant={progress === 100 ? "success" : "primary"} now={progress} label={<p style={{ fontSize: 15 }}>{progress === 100 ? 'Descarga completa' : `${progress}%`}</p>} />
                    </Col>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <Button onClick={cancelarDescarga} variant='danger'>Cancelar Descarga</Button>

                    </div>
                </>
            </Modal.Body>
        </Modal>
    )
}

export default ModalDescargarArchivo