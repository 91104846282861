import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../App";
import Grilla, { GrillaRef, TipoCampo } from "../../../Grilla";
import { cargarDatosGrillaDeArray, useDecodedParams } from "../../../Utilidades";
import { MyModal } from "../../../MyModal";
import { Modal, Button } from "react-bootstrap";
import FileSaver from "file-saver";
import Loader from "react-loaders";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { arabToRoman } from "roman-numbers";
import { useApi } from "ApiHooks";

export function ReporteSubitemsDetallado() {
    let { interno } = useDecodedParams() as { interno: string };
    let { mostrarError } = useContext(AppContext);
    let [cargando, updateCargando] = useState(true);
    let api = useApi();
    let refGrilla = useRef<GrillaRef>(null);
    let [reporte, updateReporte] = useState([]);
    let [generandoExcel, updateGenerandoExcel] = useState(false);
    useEffect(() => {
        async function cargar() {
            try {
                refGrilla.current?.setBloqueado(true);
                let reporte = await api.getReporteSubitemsDetallado(interno);
                updateReporte(reporte.sort((a: any, b: any) => a.Orden - b.Orden));
                updateCargando(false);
                refGrilla.current?.setBloqueado(false);
                refGrilla.current?.recargar();
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error(error);
                    mostrarError('Error al cargar reporte subitems detallado');
                }
            }
        }
        cargar();
        //eslint-disable-next-line
    }, []);
    async function exportarAExcel() {
        try {
            updateGenerandoExcel(true);
            let { fileName, excel } = await api.exportarReporteSubitemsDetalladoAExcel(interno);
            updateGenerandoExcel(false);
            FileSaver.saveAs(excel, fileName);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error(error);
                mostrarError('Error al exportar reporte subitems detallado a Excel');
            }
            if (!api.isUnmounted()) {
                updateGenerandoExcel(false);
            }
        }
    }
    let campos = [{ propiedad: 'Orden', visible: false, clave: true }, { titulo: 'Catalogo', propiedad: 'Catalogo' },
    { titulo: 'Articulo', propiedad: 'Articulo' }, { titulo: 'Descripcion Catalogo', propiedad: 'Descripcion' },
    { titulo: 'Descripcion Maria', propiedad: 'DescripcionMaria' }, { titulo: 'Ncm', propiedad: 'Ncm' },
    { titulo: 'Naladisa', propiedad: 'Naladisa' },
    { titulo: 'Unidad Declarada', propiedad: 'CodigoUnidadDeclarada' },
    { titulo: 'Cantidad', propiedad: 'CantidadDeclarada', tipo: TipoCampo.Number },
    { titulo: 'Unitario', propiedad: 'PrecioUnitario', tipo: TipoCampo.Number },
    { titulo: 'FOB', propiedad: 'TotalFob', tipo: TipoCampo.Number }, { titulo: 'Pais Origen', propiedad: 'CodigoPaisOrigen' },
    { titulo: 'Estado Mercaderia', propiedad: 'CodigoEstadoMercaderia' }, { titulo: 'Kg Neto', propiedad: 'KgNeto', tipo: TipoCampo.Number },
    { titulo: 'Sufijos', propiedad: 'Sufijos' }, { titulo: 'Filler', propiedad: 'Filler' },
    { titulo: 'Nro. Factura', propiedad: 'NroFactura' }, { titulo: 'DJCP', propiedad: 'DJCP' },
    { titulo: 'Nro. Item', propiedad: 'NroItemMaria' }, { titulo: 'Nro. Subitem', propiedad: 'NroSubitemMaria' },
    { titulo: 'Tiene LNA', propiedad: 'TieneLNA' }, { titulo: 'Anexo LNA', propiedad: 'AnexoLNA', plantillaFormato: arabToRoman },
    { titulo: 'Bien Capital', propiedad: 'BienCapital', plantillaFormato: (valor: any) => valor ? 'Sí' : 'No'  },
    { titulo: 'Dumping', propiedad: 'Dumping', plantillaFormato: (valor: any) => valor ? 'Sí' : '' },
    { titulo: 'Codigo Prorrateo', propiedad: 'CodigoProrrateo' }];
    return (<>
        <h2>Reporte de Subitems Detallado de <Link to={'/caratulas/' + encodeURIComponent(interno)}>{interno}</Link></h2>
        <Button variant="success" className="mb-2" onClick={exportarAExcel} disabled={cargando}>
            <FontAwesomeIcon icon={faFileExcel} />
            <span>Exportar a Excel</span>
        </Button>
        <Grilla ref={refGrilla} campos={campos} responsiva cargarDatos={cargarDatosGrillaDeArray(reporte)}></Grilla>
        <MyModal show={generandoExcel}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="lead">Generando Excel...</p>
                    <div className="loader-container">
                        <Loader type="ball-spin-fade-loader" active></Loader>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        api.cancelCurrentTokens();
                    }}>Cancelar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
    </>);
}