import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useRef, useState } from "react";
import { Button, Form, Container } from "react-bootstrap";
import { useHistory, useRouteMatch } from "react-router";
import { Switch } from "react-router-dom";
import { AppContext } from "App";
import Grilla, { GrillaRef } from "../../Grilla";
import { DialogoConfirmar, DialogoConfirmarRef } from "../../DialogoConfirmar";
import { isInRole } from 'Utilidades';
import ProtectedRoute from 'ProtectedRoute';
import { CrearEditarVendedor } from "Paginas/Vendedores/CrearEditarVendedor";
import { CancelToken } from "SintiaHooks";
import { useApi, TipoLock } from "ApiHooks";

export function Vendedores(props: { eventoSeleccionarVendedor?: (item: any) => void }) {
    let refGrilla = useRef<GrillaRef>(null);
    let refDialogo = useRef<DialogoConfirmarRef>(null);
    let history = useHistory();
    let { mostrarError, userInfo } = useContext(AppContext);
    let campos = [{ titulo: 'Codigo', propiedad: 'Codigo', visible: true, clave: true },
    { titulo: "Nombre", propiedad: 'Nombre' }, { titulo: "Domicilio", propiedad: 'Domicilio' },
    { titulo: "Id Tributario", propiedad: 'IdTributario' }];
    let api = useApi();
    let [busquedaActual, updateBusquedaActual] = useState('');
    let [busqueda, updateBusqueda] = useState('');
    let [mensajeEliminarVendedor, updateMensajeEliminarVendedor] = useState('');
    async function cargarDatos(desde: number, hasta: number, cancelToken: CancelToken) {
        let respuesta = await api.getVendedoresPaginado(busquedaActual, desde, hasta, cancelToken);
        return { cantidadItems: respuesta.CantidadTotal, items: respuesta.Items };
    }
    function eventoEliminar(item: any) {
        updateMensajeEliminarVendedor(`¿Está seguro que desea eliminar el vendedor ${item.Nombre}?`);
        return refDialogo.current!.mostrar().then(async () => {
            refGrilla.current?.setBloqueado(true);
            try {
                let puedeEliminar = await api.obtenerLock(TipoLock.Vendedor, item.Codigo);
                if (puedeEliminar) {
                    let { exito, error } = await api.deleteVendedor(item.Codigo);
                    if (exito) {
                        refGrilla.current?.recargar();
                    } else {
                        mostrarError(error as string);
                    }
                } else {
                    mostrarError(`No se puede eliminar el vendedor ${item.Nombre} porque otra persona lo está utilizando`);
                }
                refGrilla.current?.setBloqueado(false);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al eliminar vendedor', error);
                    mostrarError('Hubo un error al eliminar el vendedor');
                    refGrilla.current?.setBloqueado(false);
                }
            }
        }).catch(() => { });
    }
    let esOperador = isInRole(userInfo, 'Operador');
    const eventoModificarVendedor = (item: any) => history.push('/vendedores/edit/' + encodeURIComponent(item.Codigo));
    const eventoSeleccionarVendedor = props.eventoSeleccionarVendedor ?? eventoModificarVendedor;
    return (<>
        <Container>
            <h2>Vendedores</h2>
            <Form inline onSubmit={e => {
                updateBusquedaActual(busqueda);
                refGrilla.current?.recargar();
                e.preventDefault();
            }}>
                <Form.Control type="text" id="txtCodigoVendedor" value={busqueda} className="mr-2 mb-2"
                    onChange={e => updateBusqueda(e.target.value)}></Form.Control>
                <Button type="submit" className="mb-2">Buscar</Button>
            </Form>
            <Button disabled={esOperador} onClick={() => history.push('/vendedores/add')} className="mb-2 mr-2">
                <FontAwesomeIcon icon={faPlus} />
                <span>Agregar</span>
            </Button>
            <Grilla campos={campos} cargarDatos={cargarDatos}
                eventoDetalle={eventoSeleccionarVendedor}
                eventoModificar={props.eventoSeleccionarVendedor ? eventoModificarVendedor : undefined}
                eventoEliminar={eventoEliminar}
                deshabilitarBotonEliminar={esOperador} ref={refGrilla} />
        </Container>
        <DialogoConfirmar ref={refDialogo} mensaje={mensajeEliminarVendedor} textoBotonConfirmar="Sí" textoBotonCancelar="No" />    </>)
}

export function RutasVendedor(props: { eventoSeleccionarVendedor?: (item: any) => void }) {
    let { path } = useRouteMatch();
    return <Switch>
        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb', 'Operador']} path={`${path}/add`} component={CrearEditarVendedor}></ProtectedRoute>
        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path={`${path}/edit/:codigo`} component={CrearEditarVendedor}></ProtectedRoute>
        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} exact path={path}>
            <Vendedores {...props}></Vendedores>
        </ProtectedRoute>
    </Switch>
}