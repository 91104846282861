
import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { AppContext } from "App";
import { DialogoConfirmar, DialogoConfirmarRef } from "../../DialogoConfirmar";
import BlockUi from "react-block-ui";
import { MyModal } from "../../MyModal";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import { MyForm, MyFormControl } from "../../FormikHooks";
import { GrillaSync } from "Grilla";
import { strCmp } from "Utilidades";
import { useApi } from "ApiHooks";

enum EstadoModal {
    Cerrado,
    Creando,
    Modificando
}

export function ReferenciasCaratula(props: { onReferenciaSeleccionada?: (item: any) => void }) {
    let [referencias, updateReferencias] = useState<any[]>([]);
    let [cargando, updateCargando] = useState(true);
    let dialogoRef = useRef<DialogoConfirmarRef>(null);
    let { mostrarError, userInfo } = useContext(AppContext);
    let api = useApi();
    let [mensajeDialogoConfirmar, updateMensajeDialogoConfirmar] = useState('');
    let formikRef = useRef<FormikProps<any>>(null);
    let [modal, updateModal] = useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'cerrar') {
            return { estadoModal: EstadoModal.Cerrado, valorModificando: null };
        } else if (accion.tipo === 'mostrarCrear') {
            return { estadoModal: EstadoModal.Creando, valorModificando: null };
        } else if (accion.tipo === 'mostrarModificar') {
            return { estadoModal: EstadoModal.Modificando, valorModificando: accion.valor };
        }
        return estado;
    }, { estadoModal: EstadoModal.Cerrado, valorModificando: null });
    const eventoModificar = (item: any) => updateModal({ tipo: 'mostrarModificar', valor: item });
    const eventoSeleccionar = props.onReferenciaSeleccionada ?? eventoModificar;
    useEffect(() => {
        async function cargar() {
            try {
                let respuesta = await api.getTiposReferencia();
                updateReferencias(respuesta?.sort((a: any, b: any) => strCmp(a.Nombre, b.Nombre)) ?? []);
                updateCargando(false);
            } catch (error) {
                if (!api.isCancel(true)) {
                    console.error('Error al cargar tipos referencia caratula', error);
                    mostrarError('Error al cargar tipos referencia caratula');
                }
            }
        }
        cargar();
        //eslint-disable-next-line
    }, []);
    async function eventoEliminar(item: any) {
        let nombreReferencia = item.Nombre;
        updateMensajeDialogoConfirmar(`¿Está seguro que desea eliminar la referencia ${nombreReferencia}?`);
        dialogoRef.current!.mostrar().then(async () => {
            try {
                updateCargando(true);
                await api.deleteTipoReferencia(item.Id);
                updateReferencias((r: any) => r?.filter((c: any) => c.Id !== item.Id)
                    ?.sort((a: any, b: any) => strCmp(a.Nombre, b.Nombre)) ?? []);
                updateCargando(false);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al eliminar tipo referencia', error);
                    mostrarError('Error al eliminar tipo referencia');
                }
                if (!api.isUnmounted()) {
                    updateCargando(false);
                }
            }
        }).catch(() => { });
    }
    return <>
        <BlockUi blocking={cargando}>
            <GrillaSync datos={referencias} campos={[{ propiedad: 'Id', visible: false, clave: true },
            { titulo: 'Nombre', propiedad: 'Nombre' }]}
                eventoAgregar={() => updateModal({ tipo: 'mostrarCrear' })}
                eventoDetalle={eventoSeleccionar}
                eventoModificar={props.onReferenciaSeleccionada ? eventoModificar : undefined}
                eventoEliminar={eventoEliminar} />
        </BlockUi>
        <DialogoConfirmar ref={dialogoRef} mensaje={mensajeDialogoConfirmar} textoBotonCancelar="No" textoBotonConfirmar="Sí"></DialogoConfirmar>
        <MyModal show={modal.estadoModal !== EstadoModal.Cerrado}
            onHide={() => updateModal({ tipo: 'cerrar' })}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    <h2>{modal.estadoModal === EstadoModal.Modificando ? 'Modificar Referencia' : 'Crear Referencia'}</h2>
                </Modal.Header>
                <Formik innerRef={formikRef} validationSchema={Yup.object({
                    'Nombre': Yup.string().nullable().required('Debe ingresar el nombre'),
                })} initialValues={{
                    'Nombre': modal.valorModificando?.Nombre ?? '',
                }} onSubmit={async (nuevaReferencia: any, actions) => {
                    let referenciasExistentes = referencias;
                    if (modal.valorModificando) {
                        referenciasExistentes = referenciasExistentes.filter((r: any) => r.Id !== modal.valorModificando.Id);
                    }
                    if (referenciasExistentes.map((r: any) => r.Nombre).includes(nuevaReferencia.Nombre)) {
                        actions.setFieldError('Nombre', `La referencia con nombre ${nuevaReferencia.Nombre} ya fue ingresada`);
                    } else {
                        try {
                            nuevaReferencia = {
                                Id: modal.valorModificando?.Id,
                                NroClienteAlpha: userInfo.nroClienteAlpha,
                                Nombre: nuevaReferencia.Nombre
                            };
                            let respuesta = await api.insertTipoReferencia(nuevaReferencia);
                            let nuevasReferencias = Array.from(referencias);
                            if (modal.valorModificando) {
                                nuevasReferencias = nuevasReferencias.filter((r: any) => r.Id !== modal.valorModificando.Id);
                            }
                            nuevasReferencias.push(respuesta);
                            updateReferencias(nuevasReferencias.sort((a: any, b: any) => strCmp(a.Nombre, b.Nombre)));
                            updateModal({ tipo: 'cerrar' });
                        } catch (error) {
                            if (!api.isCancel(error)) {
                                console.error('Error al guardar referencia', error);
                                mostrarError('Error al guardar referencia');
                            }
                        }
                    }
                }}>{({ submitForm, isSubmitting }) => <>
                    <Modal.Body>
                        <MyForm>
                            <Form.Group>
                                <MyFormControl type="text" name="Nombre" label="Nombre"></MyFormControl>
                            </Form.Group>
                        </MyForm>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => updateModal({ tipo: 'cerrar' })}>
                            Cancelar
                        </Button>
                        <Button disabled={isSubmitting} onClick={submitForm}>
                            Ingresar
                        </Button>
                    </Modal.Footer>
                </>}
                </Formik>
            </Modal.Dialog>
        </MyModal>
    </>;
}