import React from "react";
import { MyModal } from "../../MyModal";
import { Button, Form, Modal } from "react-bootstrap";
import { AppContext } from "../../App";
import { Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";
import { MyForm, MyFormCheck, MyFormControl } from "../../FormikHooks";
import { ListBox } from "../../ListBox";
import Loader from "react-loaders";
import { isNullOrWhiteSpace } from 'Utilidades';
import { groupBy } from "../../Utilidades";
import { obtenerValorSufijo2Letras, obtenerValorSufijo4Letras } from "Sufijos";
import { useApi } from "ApiHooks";
enum EstadoModal {
    Cerrado,
    Cargando,
    Abierto
}

type Resultado = Record<string, { Valor?: string, Sobreescribir: boolean }>;

export type IngresoEstablecerSufijosAutomaticoRef = {
    mostrar: (ncm: string, sufijosArticulo: string[]) => Promise<Resultado>
}

export const IngresoEstablecerSufijosAutomatico = React.forwardRef((props: any, ref) => {
    let { mostrarError } = React.useContext(AppContext);
    let formikRef = React.useRef<FormikProps<any>>(null);
    let textBoxRef = React.useRef<any>(null);
    let api = useApi();
    function getSufijosDistinct(sufijos: any[]) {
        let sufijosDistinct: { codigo: string; letras: number; }[] = [];
        sufijos.forEach((sufijo: any) => {
            if (sufijo.Codigo.length === 2) {
                sufijosDistinct.push({ codigo: sufijo.Codigo, letras: 2 });
            } else if (sufijo.Codigo.length === 4) {
                if (!sufijosDistinct.map((item: any) => item.codigo).includes(sufijo.Codigo.substring(0, 2))) {
                    sufijosDistinct.push({ codigo: sufijo.Codigo.substring(0, 2), letras: 4 });
                }
            }
        });
        return sufijosDistinct.sort((a, b) => a.codigo > b.codigo ? 1 : (b.codigo > a.codigo ? -1 : 0));
    }
    let [estado, updateEstado] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'cerrar') {
            return { ...estado, estadoModal: EstadoModal.Cerrado, };
        } else if (accion.tipo === 'iniciar') {
            return {
                ...estado, estadoModal: EstadoModal.Cargando, ncm: accion.ncm,
                sufijoActual: 0, sufijosNuevos: {},
                resolve: accion.resolve, reject: accion.reject,
                sufijosArticulo: accion.sufijosArticulo, valorCheckSobreescribirListbox: false
            };
        }
        else if (accion.tipo === 'setSufijos') {
            return {
                ...estado, estadoModal: EstadoModal.Abierto, sufijos: accion.sufijos,
                sufijosDistinct: accion.sufijosDistinct, sufijosNuevos: {}
            };
        } else if (accion.tipo === 'ingresoSufijo2letras') {
            let sufijoActual = estado.sufijoActual;
            return {
                ...estado, sufijoActual: sufijoActual + 1,
                sufijosNuevos: {
                    ...estado.sufijosNuevos, [estado.sufijosDistinct[sufijoActual].codigo]: { Valor: accion.valor, Sobreescribir: accion.sobreescribir }
                }, valorCheckSobreescribirListbox: false
            }
        } else if (accion.tipo === 'ingresoSufijo4letras') {
            let sufijoActual = estado.sufijoActual;
            let sobreescribir = estado.valorCheckSobreescribirListbox;
            return {
                ...estado, sufijoActual: sufijoActual + 1, valorCheckSobreescribirListbox: false,
                sufijosNuevos: { ...estado.sufijosNuevos, [accion.valor]: { Sobreescribir: sobreescribir } }
            };
        } else if (accion.tipo === 'setMensaje') {
            return { ...estado, mensaje: accion.valor };
        } else if (accion.tipo === 'setCheckSobreescribirListbox') {
            return { ...estado, valorCheckSobreescribirListbox: accion.valor };
        }
    }, { estadoModal: EstadoModal.Cerrado });
    async function cargarSufijos(ncm: string, sufijosArticulo: string[]) {
        if (ncm) {
            try {
                let sufijos = await api.getSufijos(ncm);
                if (sufijos.length === 0) {
                    estado.resolve({});
                    updateEstado({ tipo: 'cerrar' });
                } else {
                    let sufijosQueFaltan: Record<string, any> = {};
                    const sufijos2Letras = sufijos.filter((s: any) => s.Codigo.length === 2);
                    const gruposSufijos4Letras = groupBy(sufijos.filter((s: any) => s.Codigo.length === 4), (s: any) => s.Codigo.substring(0, 2));
                    for (const item of sufijosArticulo) {
                        for (const sufijo of sufijos2Letras) {
                            if (isNullOrWhiteSpace(obtenerValorSufijo2Letras(sufijo.Codigo, item))) {
                                sufijosQueFaltan = { ...sufijosQueFaltan, [sufijo.Codigo]: sufijo };
                            }
                        }
                        for (const [clave, grupo] of gruposSufijos4Letras) {
                            if (isNullOrWhiteSpace(obtenerValorSufijo4Letras(clave, item))) {
                                for (const sufijo of grupo) {
                                    sufijosQueFaltan = { ...sufijosQueFaltan, [sufijo.Codigo]: sufijo };
                                }
                            }
                        }
                    }
                    let listaSufijosQueFaltan = Object.keys(sufijosQueFaltan).map(clave => sufijosQueFaltan[clave]);
                    let sufijosDistinct = getSufijosDistinct(listaSufijosQueFaltan);
                    if (sufijosDistinct.length === 0) {
                        estado.resolve({});
                        updateEstado({ tipo: 'cerrar' });
                    } else {
                        updateEstado({ tipo: 'setSufijos', sufijos: listaSufijosQueFaltan, sufijosDistinct: sufijosDistinct });
                    }
                }
            }
            catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar sufijos', error);
                    updateEstado({ tipo: 'cerrar' });
                    mostrarError('Hubo un error al cargar los sufijos');
                }
            }
        } else {
            updateEstado({ tipo: 'cerrar' });
            mostrarError('Debe ingresar el NCM');
        }
    }
    React.useEffect(() => {
        if (estado.estadoModal === EstadoModal.Cargando) {
            cargarSufijos(estado.ncm, estado.sufijosArticulo);
            return () => api.cancelCurrentTokens();
        }
        //eslint-disable-next-line 
    }, [estado.estadoModal, estado.ncm]);
    React.useEffect(() => {
        if (estado.estadoModal === EstadoModal.Abierto && estado.sufijoActual >= estado.sufijosDistinct.length) {
            estado.resolve(estado.sufijosNuevos);
            updateEstado({ tipo: 'cerrar' });
        }
        if (estado.estadoModal === EstadoModal.Cerrado && estado.reject) {
            estado.reject();
        }
        //eslint-disable-next-line 
    }, [estado.estadoModal, estado.sufijoActual]);
    React.useEffect(() => {
        formikRef.current?.setFieldValue('Ingreso', '');
        formikRef.current?.setFieldTouched('Ingreso', false);
        formikRef.current?.setFieldValue('Sobreescribir', false);
        formikRef.current?.setFieldTouched('Sobreescribir', false);
        textBoxRef.current?.focus();
    }, [estado.estadoModal, estado.sufijoActual]);
    React.useImperativeHandle(ref, () => ({
        mostrar: (ncm: string, sufijosArticulo: string[]) => {
            return new Promise<Resultado>((resolve, reject) => {
                updateEstado({
                    tipo: 'iniciar', ncm: ncm, sufijosArticulo: sufijosArticulo,
                    resolve: resolve, reject: reject,
                });
            });
        },
    }));
    let renderModalBody = () => {
        if (estado.sufijosDistinct && estado.sufijoActual < estado.sufijosDistinct.length) {
            let sufijoActual = estado.sufijosDistinct[estado.sufijoActual];
            if (sufijoActual.letras === 4) {
                let listaSufijos = estado.sufijos.filter((sufijo: any) =>
                    sufijo.Codigo.substring(0, 2) === sufijoActual.codigo);
                listaSufijos.push({ Codigo: sufijoActual.codigo + '00', Detalle: 'NINGUNO' });
                listaSufijos = listaSufijos.sort((a: any, b: any) =>
                    parseInt(a.Codigo.substring(2, 4)) - parseInt(b.Codigo.substring(2, 4)));
                return (
                    <>
                        <Modal.Body>
                            <ListBox list={listaSufijos} itemKey="Codigo" selectedKey={estado.valorDefecto} render={(sufijo: any) => (<span>{sufijo.Codigo + ' - ' + sufijo.Detalle}</span>)}
                                onSelect={(sufijo: any) => updateEstado({ tipo: 'ingresoSufijo4letras', valor: sufijo.Codigo })} />
                            <Form>
                                <Form.Group>
                                    <Form.Check custom id="checkSobreescribirListbox" label="Sobreescribir" checked={estado.valorCheckSobreescribirListbox} onChange={(e: any) => updateEstado({ tipo: 'setCheckSobreescribirListbox', valor: e.target.checked })}></Form.Check>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={() => updateEstado({ tipo: 'cerrar' })}>
                                Cancelar
                            </Button>
                        </Modal.Footer>
                    </>)
            } else {
                let schema: any;
                let sufijoActualCompleto = estado.sufijos.find((sufijo: any) => sufijo.Codigo === sufijoActual.codigo);
                if (sufijoActual.codigo.charAt(0) === 'Z') {
                    schema = Yup.object({
                        'Ingreso': Yup.number().nullable().typeError('Debe ingresar un número')
                            .required('Debe ingresar un número')
                            .integer('Debe ingresar un número entero')
                            .test('caracteres', 'El valor debe tener 20 caracteres o menos',
                                function (value: any) {
                                    let obj = this as unknown as { originalValue: string };
                                    return (obj.originalValue ?? '').length <= 20;
                                }),
                        'Sobreescribir': Yup.boolean()
                    });
                } else {
                    schema = Yup.object({
                        'Ingreso': Yup.string().nullable().required('Debe ingresar un valor').max(20, 'El valor debe tener 20 caracteres o menos')
                            .matches(/^[^()-]*$/, 'El valor no puede tener ni parentesis ni guiones'),
                        'Sobreescribir': Yup.boolean()
                    });
                }
                return (
                    <>
                        <Modal.Body>
                            <Formik initialValues={{ Ingreso: estado.valorDefecto }} validationSchema={schema}
                                onSubmit={(values: any, formikBag: FormikHelpers<any>) => {
                                    let ingreso = values.Ingreso;
                                    if (sufijoActual.codigo.charAt(0) === 'Z') {
                                        ingreso = ingreso.padStart('6', '0');
                                    }
                                    updateEstado({
                                        tipo: 'ingresoSufijo2letras',
                                        valor: ingreso,
                                        sobreescribir: values.Sobreescribir
                                    });
                                    formikBag.setSubmitting(false);
                                }} innerRef={formikRef}>
                                <MyForm blockWhenSubmitting={false}>
                                    <Form.Group>
                                        <MyFormControl ref={textBoxRef} name="Ingreso"
                                            type={sufijoActual.codigo.charAt(0) === 'Z' ? 'number' : 'text'}
                                            label={sufijoActualCompleto?.Codigo + ' - ' + sufijoActualCompleto?.Detalle}></MyFormControl>
                                    </Form.Group>
                                    <Form.Group>
                                        <MyFormCheck name="Sobreescribir" label="Sobreescribir"></MyFormCheck>
                                    </Form.Group>
                                </MyForm>
                            </Formik>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={() => updateEstado({ tipo: 'cerrar' })}>
                                Cancelar
                            </Button>
                            <Button type="button" variant="primary" onClick={() => {
                                formikRef.current?.handleSubmit();
                            }}>
                                Ingresar
                            </Button>
                        </Modal.Footer>
                    </>)
            }
        } else {
            return (<></>);
        }

    };
    return (
        <>
            <MyModal show={!!estado.mensaje} onHide={() => updateEstado({ tipo: 'setMensaje', valor: '' })}>
                <Modal.Dialog>
                    <Modal.Body>
                        <p className="lead">{estado.mensaje}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => updateEstado({ tipo: 'setMensaje', valor: '' })}>Cerrar</Button>
                    </Modal.Footer>
                </Modal.Dialog>
            </MyModal>
            <MyModal show={estado.estadoModal === EstadoModal.Cargando}>
                <Modal.Dialog>
                    <Modal.Body>
                        <p className="lead">Validando sufijos...</p>
                        <div className="loader-container">
                            <Loader type="ball-spin-fade-loader" active></Loader>
                        </div>
                    </Modal.Body>
                </Modal.Dialog>
            </MyModal>
            <MyModal show={estado.estadoModal === EstadoModal.Abierto}
                onHide={() => updateEstado({ tipo: 'cerrar' })}>
                <Modal.Dialog>
                    <Modal.Header closeButton>
                        <Modal.Title>Ingresar Sufijos</Modal.Title>
                    </Modal.Header>
                    {renderModalBody()}
                </Modal.Dialog>
            </MyModal>
        </>)
});
