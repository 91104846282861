import React, { useEffect, useRef,  useState } from "react";
import { Button, Container, Form, Col, Table, Alert } from "react-bootstrap";
import { MyForm, MyFormControl, MySelect, SelectOption } from "../../FormikHooks";
import * as Yup from "yup";
//import { useApi } from "ApiHooks";
import { useAdvAPI } from './AdvancedApi';
import { useApi } from "ApiHooks";
import { LoginAFIP, LoginAFIPRef } from './sesionafip';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, FormikProps } from "formik";

export function RatificacionHistorico() {

    let api = useAdvAPI();
    let apiSintia = useApi();
    let [optionsDespachantes, updateOptionsDespachantes] = useState<SelectOption[]>([]);
    let [despachosNoResult, updateDespachosNoResult] = useState(false);
    let [resulado, updateREsulado] = useState([]);
    let loginAFIPRef = useRef<LoginAFIPRef>(null);

    let formikRef = React.useRef<FormikProps<any>>(null);
    let huboCambios = React.useRef<{ valor: boolean }>({ valor: false });
    const eventoHuboCambios = () => {
        huboCambios.current.valor = true;
    }

    let schema = Yup.object({
            'cuitDespachante': Yup.string().nullable().required('Debe seleccionar un despachante'),
            'fechaDesde': Yup.date().required('Debe ingresar Fecha Desde'),
            'fechaHasta': Yup.date().required('Debe ingresar Fecha Hasta')
        });
    



    var d = new Date();
    let hoy = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');


    useEffect(() => {
        async function cargar() {
            try {
                let despachantes = await apiSintia.getDespachantes();
                updateOptionsDespachantes(despachantes.map((item: any) => ({ value: item.CUIT, label: item.Nombre })));

            } catch (error) {
                if (!apiSintia.isCancel(error)) {
                    console.error('Error al cargar caratulas', error);
                }
            }
        }
        cargar();
        //eslint-disable-next-line
    }, []);
    

    async function cargarDatos(pbusqueda: any) {
        

        console.log('entro a cargar datos');

        if (pbusqueda.cuitDespachante) {

            /*let logueo = false;
            loginAFIPRef.current!.mostrar().finally(() => { logueo = true; })
            console.log(logueo);*/

            updateDespachosNoResult(false);
            let respuesta = await api.getRatificacionesDespachosHistoricosPorFecha(
                pbusqueda.cuitDespachante,
                pbusqueda.fechaDesde,
                pbusqueda.fechaHasta);


                console.log('respuesta ' + respuesta);


            if (respuesta !== 'Sin datos de registros historicos') {

                    //el conocimiento del canal
                    let resultadoF = respuesta.map((respuestan : any) => { if (respuestan.ResultMessage.includes('el conocimiento del canal')) respuestan.ResultMessage = respuestan.ResultMessage.substring(respuestan.ResultMessage.indexOf('el conocimiento del canal'));
                    return respuestan; });

                    updateREsulado(resultadoF);
                }
            else {
                updateREsulado([]);
                updateDespachosNoResult(true);
            }

        }
    }

        function GrillaDespachos(props: {
            despachos: any[]
        }) {
            return <>

                    <Table responsive className="text-nowrap middle-vertical-align table table-sm table-striped table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Despacho</th>
                                <th scope="col">FechaRatificacion</th>
                                {/*<th scope="col">CuitLogin</th>*/}
                                <th scope="col">CuitDespa</th>
                                <th scope="col">ResultMessage</th>
                                <th scope="col">Canal</th>
                            </tr>
                        </thead>
                        <tbody>{props.despachos.map((t: any, indice: number) =>
                        (
                            <tr>
                                    <th  scope="row">{t.Anio + t.Aduana + t.TipoDeclaracion + t.Secuencia + t.DigitoVerificador}</th>
                                    <td>{t.FechaRatificacion}</td>
                                    {/*<td>{t.CuitLogin}</td>*/}
                                    <td>{t.CuitDespa}</td>
                                    <td>{t.ResultMessage}</td>
                                    <td>{t.Canal}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>

            </>;
        }

        let initialvalues = {
            cuitDespachante: '',
            fechaDesde: hoy,
            fechaHasta: hoy
        };

    return (
    <>
        <Container>
            <br/><p className="h3">Consulta de Ratificaciones</p><br/>
            <Formik initialValues={initialvalues} validationSchema={schema} innerRef={formikRef} onSubmit={cargarDatos}>
                    <MyForm className="col-md-12">
                        <Form.Row className="col-md-12">
                            <Form.Group as={Col}>
                                <MySelect 
                                    name="cuitDespachante" label="Despachante"
                                    options={optionsDespachantes} onValueChange={eventoHuboCambios} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="col-md-12">
                            <Form.Group as={Col} className="col-md-3" >
                                <MyFormControl name="fechaDesde" type="date" label="Fecha Desde" ></MyFormControl>
                            </Form.Group>    
                            <Form.Group as={Col} className="col-md-3" >
                                <MyFormControl name="fechaHasta" type="date" label="Fecha Hasta" ></MyFormControl>
                            </Form.Group>
                            <Form.Group className="col-md-12">
                                <Button type="submit" className="mb-2">Buscar</Button>
                            </Form.Group>
                        </Form.Row>
                        <Form.Group className="col-md-12">
                            {despachosNoResult && 
                                <Alert variant="warning">
                                    <FontAwesomeIcon icon={faExclamationTriangle}></FontAwesomeIcon>
                                        <span className="ml-3">No hay Declaraciones para los paremetros seleccionados</span>
                                </Alert>
                            }
                        </Form.Group>
                    </MyForm>
            </Formik>

            <div className="mt-2">
                        <GrillaDespachos despachos={resulado}></GrillaDespachos>
                    </div>

        </Container>

        <LoginAFIP ref={loginAFIPRef} />    
    </>
    
    )
}