import { Formik, FormikProps, Field } from "formik";
import React from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { AppContext } from "../App";
import { MyForm, MyFormCheck, MyFormControl, MyFormRadio, MySelect } from "../FormikHooks";
import { CentradoVertical, isNullOrWhiteSpace, isInRole } from "../Utilidades";
import * as Yup from 'yup';
import { useHistory } from "react-router";
import BlockUi from "react-block-ui";
import { MyModal } from "MyModal";
import { DialogoConfirmar, DialogoConfirmarRef } from "DialogoConfirmar";
import bsCustomFileInput from "bs-custom-file-input";
import { TiposOperacion, TiposOperacionRef } from "./TiposOperacion";
import { ResetearContadorAutonumerico, ResetearContadorAutonumericoRef } from "./ResetearContadorAutonumerico";
import { PestañaCaratula , SelecionFobTotalPrecioUnitario } from "Enums";
import { useApi } from "ApiHooks";

const contraseñaHabilitarAutonumerico = 'Sintia2021';
function leerArchivo(file: File) {
    return new Promise<string>((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
            let result = reader.result as string;
            result = result.replace(/data:.+?,/, '');
            resolve(result);
        };
        reader.onerror = () => reject(reader.error);
        reader.readAsDataURL(file);
    });
}

enum Autonumerico {
    PorClienteAlpha,
    PorEmpresa
}

let optionsPestañaCaratula = [{ value: PestañaCaratula.Operacion.toString(), label: 'Operación' },
{ value: PestañaCaratula.Carpeta.toString(), label: 'Carpeta' }];


let optionsFobTotalPrecioUnitario = [{ value: SelecionFobTotalPrecioUnitario.FobTotal.toString() , label: 'Fob Total' },
{ value: SelecionFobTotalPrecioUnitario.PrecioUnitario.toString() , label: 'Precio Unitario' }];


export function Configuracion() {
    let { mostrarError, userInfo } = React.useContext(AppContext);
    const esAdmin = isInRole(userInfo, 'Administrador');
    let api = useApi();
    let [cargando, updateCargando] = React.useState(true);
    let configuracion = React.useRef<any>(null);
    let configuracionAdmin = React.useRef<any>(null);
    let configuracionEmpresa = React.useRef<any>(null);
    let configuracionEmpresaAdmin = React.useRef<any>(null);
    let history = useHistory();
    let formikRef = React.useRef<FormikProps<any>>(null);
    let dialogoRef = React.useRef<DialogoConfirmarRef>(null);
    let [mensajeDialogo, updateMensajeDialogo] = React.useState('');
    let tiposOperacionRef = React.useRef<TiposOperacionRef>(null);
    let resetearContadorRef = React.useRef<ResetearContadorAutonumericoRef>(null);
    let [estadoModalLogo, updateEstadoModalLogo] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrar') {
            return { mostrar: true, logo: null, cargando: true };
        } else if (accion.tipo === 'esconder') {
            return { mostrar: false, logo: null, cargando: false };
        } else if (accion.tipo === 'setLogo') {
            return { ...estado, logo: accion.valor, cargando: false };
        } else if (accion.tipo === 'setCargando') {
            return { ...estado, cargando: accion.valor };
        }
        return { ...estado };
    }, { mostrar: false, logo: null, cargando: false });
    let [mostrarModalHabilitarAutonumerico, setMostrarModalHabilitarAutonumerico] = React.useState(false);
    let [habilitarConfiguracionAutonumerico, setHabilitarConfiguracionAutonumerico] = React.useState(false);
    let [habilitarMascara, setHabilitarMascara] = React.useState(false);
    let [habilitarBotonResetearContador, setHabilitarResetearContador] = React.useState(false);
    //let [habilitarBotonDesvincularUsuarioGoogleDrive, updateHabilitarBotonDesvincularUsuarioGoogleDrive] = React.useState(true);
    let schema = Yup.object({
        'ModificarArticuloAlGuardarSubitem': Yup.boolean().nullable(),
        'BloquearCaratulaAlTransferirAlMaria': Yup.boolean().nullable(),
        'Autonumerico': Yup.string(),
        'UsarAutonumerico': Yup.boolean().nullable(),
        'UsuarioOperadorCreaCarpeta': Yup.boolean().nullable(),
        'MascaraAutonumerico': Yup.string().when('UsarAutonumerico', {
            is: true,
            then: Yup.string().nullable().required('Debe ingresar la mascara')
                .matches(/##numero##\|(0+)\|/, 'La máscara no tiene constante número. El formato es ##numero##|00000| (la cantidad de ceros indica la cantidad de digitos minima que va a tener el número)'),
            otherwise: Yup.string().nullable()
        }),
        'ResetearContador': Yup.boolean(),
        'MostrarEstadoEnGrillaArticuloCatalogo': Yup.boolean(),
        'PestañaCaratulaPorDefecto': Yup.number().nullable()
            .required('Debe seleccionar la pestaña de caratula por defecto'),
        'MantenerDatosAlGuardarSubitem': Yup.boolean().nullable(),
        'CantidadMaximaRegistrosBusqueda': Yup.number().required('Debe ingresar un valor')
    });
    React.useEffect(() => {
        async function cargar() {
            try {
                let { Configuracion: conf, ConfiguracionAdmin: confAdmin } = await api.getConfiguracionSintia();
                configuracion.current = conf;
                configuracionAdmin.current = confAdmin;
                ({ Configuracion: conf, ConfiguracionAdmin: confAdmin } = await api.getConfiguracionEmpresa());
                configuracionEmpresa.current = conf;
                configuracionEmpresaAdmin.current = confAdmin;
                let usarAutonumerico: boolean, mascaraAutonumerico: string;
                if (configuracionAdmin.current.AutonumericoPorEmpresa) {
                    usarAutonumerico = configuracionEmpresaAdmin.current?.UsarAutonumerico ?? false;
                    mascaraAutonumerico = configuracionEmpresaAdmin.current?.MascaraAutonumerico ?? '';
                } else {
                    usarAutonumerico = configuracionAdmin.current?.UsarAutonumerico ?? false;
                    mascaraAutonumerico = configuracionAdmin.current?.MascaraAutonumerico ?? '';
                }
                formikRef.current?.resetForm({
                    values: {
                        'ModificarArticuloAlGuardarSubitem': configuracion.current.ModificarArticuloAlGuardarSubitem ?? false,
                        'BloquearCaratulaAlTransferirAlMaria': configuracion.current.BloquearCaratulaAlTransferirAlMaria ?? false,
                        'UsarAutonumerico': usarAutonumerico,
                        'UsuarioOperadorCreaCarpeta': configuracion.current.UsuarioOperadorCreaCarpeta,
                        'MascaraAutonumerico': mascaraAutonumerico,
                        'Autonumerico': `${configuracionAdmin.current.AutonumericoPorEmpresa ? Autonumerico.PorEmpresa : Autonumerico.PorClienteAlpha}`,
                        'ResetearContador': false,
                        'MostrarEstadoEnGrillaArticuloCatalogo': configuracionEmpresa.current?.MostrarEstadoEnGrillaArticuloCatalogo ?? false,
                        'PestañaCaratulaPorDefecto': configuracionEmpresa.current?.PestañaCaratulaPorDefecto ?? PestañaCaratula.Operacion,
                        'SeleccionFobTotalPrecioUnitario': configuracion.current.SeleccionFobTotalPrecioUnitario ?? 0,
                        'MantenerDatosAlGuardarSubitem': configuracionEmpresa.current?.MantenerDatosAlGuardarSubitem ?? false,
                        'CantidadMaximaRegistrosBusqueda': configuracion.current.CantidadMaximaRegistrosBusqueda
                    }
                });
                setHabilitarMascara(usarAutonumerico);
                setHabilitarResetearContador(esAdmin && usarAutonumerico && !isNullOrWhiteSpace(mascaraAutonumerico));
                //updateHabilitarBotonDesvincularUsuarioGoogleDrive(!isNullOrWhiteSpace(configuracionAdmin.current?.UsuarioGoogleDrive));
                updateCargando(false);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar configuración', error);
                    mostrarError('Error al cargar configuración');
                }
            }
        }
        cargar();
        //eslint-disable-next-line 
    }, []);
    async function submitForm(values: any) {
        try {
            let nuevaConfiguracion: any = {
                ModificarArticuloAlGuardarSubitem: values.ModificarArticuloAlGuardarSubitem,
                BloquearCaratulaAlTransferirAlMaria: values.BloquearCaratulaAlTransferirAlMaria,
                NroClienteAlpha: userInfo.nroClienteAlpha,
                CantidadMaximaRegistrosBusqueda: values.CantidadMaximaRegistrosBusqueda,
                SeleccionFobTotalPrecioUnitario: values.SeleccionFobTotalPrecioUnitario,
                UsuarioOperadorCreaCarpeta: values.UsuarioOperadorCreaCarpeta
            };
            let nuevaConfiguracionAdmin: any = {
                UsuarioGoogleDrive: values.UsuarioGoogleDrive,
                AutonumericoPorEmpresa: parseInt(values.Autonumerico) === Autonumerico.PorEmpresa
            }
            let usarAutonumericoEmpresa: boolean = false;
            let mascaraEmpresa: string | null = null;
            let mascaraTieneTipo: boolean;
            if (nuevaConfiguracionAdmin.AutonumericoPorEmpresa) {
                nuevaConfiguracionAdmin.UsarAutonumerico = null;
                nuevaConfiguracionAdmin.MascaraAutonumerico = null;
                usarAutonumericoEmpresa = values.UsarAutonumerico ?? false;
                mascaraEmpresa = values.MascaraAutonumerico ?? '';
                mascaraTieneTipo = mascaraEmpresa?.includes('##tipo##') ?? false;
            } else {
                nuevaConfiguracionAdmin.UsarAutonumerico = values.UsarAutonumerico ?? false;
                nuevaConfiguracionAdmin.MascaraAutonumerico = values.MascaraAutonumerico?.trim() ?? '';
                mascaraTieneTipo = nuevaConfiguracionAdmin.MascaraAutonumerico?.includes('##tipo##') ?? false;
            }

            await api.insertConfiguracionSintia(nuevaConfiguracion, nuevaConfiguracionAdmin);
            let { Configuracion: conf, ConfiguracionAdmin: confAdmin } = await api.insertConfiguracionEmpresa({
                UsarAutonumerico: usarAutonumericoEmpresa,
                MascaraAutonumerico: values.UsarAutonumerico ? values.MascaraAutonumerico : '',
                MostrarEstadoEnGrillaArticuloCatalogo: values.MostrarEstadoEnGrillaArticuloCatalogo,
                PestañaCaratulaPorDefecto: parseInt(values.PestañaCaratulaPorDefecto),
                MantenerDatosAlGuardarSubitem: values.MantenerDatosAlGuardarSubitem

            });
            configuracionEmpresa.current = conf;
            if (confAdmin) {
                configuracionEmpresaAdmin.current = confAdmin;
            }
            configuracion.current = nuevaConfiguracion;
            configuracionAdmin.current = nuevaConfiguracionAdmin;
            if (values.ResetearContador) {
                resetearContadorRef.current!.mostrar(mascaraTieneTipo);
            } else {
                history.replace('/');
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al guardar configuración', error);
                mostrarError("Error al guardar configuración");
            }
        }
    }
    const cerrarModalLogo = () => {
        api.cancelCurrentTokens();
        updateEstadoModalLogo({ tipo: 'esconder' });
    }
    async function cargarLogo() {
        try {
            let respuesta = await api.getLogoEmpresa();
            if (respuesta) {
                updateEstadoModalLogo({ tipo: 'setLogo', valor: URL.createObjectURL(respuesta) });
            } else {
                updateEstadoModalLogo({ tipo: 'setLogo', valor: null });
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al cargar logo', error);
                mostrarError('Error al cargar logo');
            }
        }
    }
    React.useEffect(() => {
        if (estadoModalLogo.mostrar) {
            bsCustomFileInput.init();
            cargarLogo();
        }
        //eslint-disable-next-line 
    }, [estadoModalLogo.mostrar]);
    React.useEffect(() => {
        if (estadoModalLogo.logo) {
            return () => URL.revokeObjectURL(estadoModalLogo.logo);
        }
    }, [estadoModalLogo.logo]);
    async function uploadLogo() {
        let fileInput = document.getElementById('inputLogo') as HTMLInputElement;
        if (!fileInput?.files || fileInput.files.length === 0) {
            mostrarError('Debe seleccionar un archivo para establecer el logo');
            return;
        }
        try {
            updateEstadoModalLogo({ tipo: 'setCargando', valor: true });
            let logoBase64 = await leerArchivo(fileInput.files[0]);
            let { exito, error } = await api.uploadLogoEmpresa(logoBase64);
            if (exito) {
                await cargarLogo();
            } else {
                mostrarError(error);
                updateEstadoModalLogo({ tipo: 'setCargando', valor: false });
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al establecer el logo', error);
                mostrarError('Error al establecer el logo');
                updateEstadoModalLogo({ tipo: 'setCargando', valor: false });
            }
        }
    }
    async function eliminarLogo() {
        try {
            updateEstadoModalLogo({ tipo: 'setCargando', valor: true });
            await api.uploadLogoEmpresa(null);
            updateEstadoModalLogo({ tipo: 'setLogo', valor: null });
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al eliminar el logo', error);
                mostrarError('Error al eliminar el logo');
                updateEstadoModalLogo({ tipo: 'setCargando', valor: false });
            }
        }
    }
    function radioAutonumericoOnChanged(porEmpresa: boolean) {
        let usarAutonumerico: boolean, mascaraAutonumerico: string;
        if (porEmpresa) {
            usarAutonumerico = configuracionEmpresa.current?.UsarAutonumerico ?? false;
            mascaraAutonumerico = configuracionEmpresa.current?.MascaraAutonumerico ?? '';
        } else {
            usarAutonumerico = configuracion.current?.UsarAutonumerico ?? false;
            mascaraAutonumerico = configuracion.current?.MascaraAutonumerico ?? '';
        }
        formikRef.current!.setFieldValue('UsarAutonumerico', usarAutonumerico);
        formikRef.current!.setFieldValue('MascaraAutonumerico', mascaraAutonumerico);
        setHabilitarMascara(usarAutonumerico);
        setHabilitarResetearContador(usarAutonumerico && !isNullOrWhiteSpace(mascaraAutonumerico));
    }
    return <>
        <CentradoVertical>
            <div style={{ width: '35rem' }}>
                <h2>Configuración</h2>
                <Formik innerRef={formikRef} validationSchema={schema} initialValues={{
                    ModificarArticuloAlGuardarSubitem: false,
                    BloquearCaratulaAlTransferirAlMaria: false,
                    UsarAutonumerico: false,
                    MascaraAutonumerico: '',
                    Autonumerico: `${Autonumerico.PorClienteAlpha}`,
                    ResetearContador: false,
                    MostrarEstadoEnGrillaArticuloCatalogo: false,
                    PestañaCaratulaPorDefecto: `${PestañaCaratula.Operacion}`
                }} onSubmit={submitForm}>
                    <MyForm blocking={cargando}>
                        <Field type="hidden" name="ResetearContador"></Field>
                        <Form.Group>
                            <MyFormCheck name="ModificarArticuloAlGuardarSubitem" label="Guardar cambios en articulo al guardar subitem"></MyFormCheck>
                        </Form.Group>
                        <Form.Group>
                            <MyFormCheck name="BloquearCaratulaAlTransferirAlMaria" label="Bloquear caratula al transferir al Maria"></MyFormCheck>
                        </Form.Group>
                        <Form.Group>
                            <MyFormCheck name="MostrarEstadoEnGrillaArticuloCatalogo" label="Mostrar estado de articulo en el listado de articulos de un catalogo"></MyFormCheck>
                        </Form.Group>
                        <Form.Group>
                            <MyFormCheck name="MantenerDatosAlGuardarSubitem" label="Mantener datos al guardar subitem"></MyFormCheck>
                        </Form.Group>
                        <Form.Group>
                            <MySelect name="PestañaCaratulaPorDefecto" label="Pestaña por defecto en caratula" options={optionsPestañaCaratula}></MySelect>
                        </Form.Group>

                        <Form.Group>
                            <MySelect name="SeleccionFobTotalPrecioUnitario" label="Carga de Subitems - Seleccionar:" options={optionsFobTotalPrecioUnitario}></MySelect>
                        </Form.Group>


                        <Form.Row>
                            <Form.Group>
                                <MyFormControl type="text" name="CantidadMaximaRegistrosBusqueda" label="Cantidad maxima de registros"></MyFormControl>
                            </Form.Group>
                            {/*
                            <Col xs="auto" className="d-flex">
                                <Button className="align-self-end mb-3" disabled={!esAdmin || !habilitarBotonDesvincularUsuarioGoogleDrive} onClick={() => {
                                    updateMensajeDialogo('¿Está seguro que desea desvincular el usuario de Google Drive?');
                                    dialogoRef.current?.mostrar()?.then(() => {
                                        formikRef.current?.setFieldValue('UsuarioGoogleDrive', null);
                                        updateHabilitarBotonDesvincularUsuarioGoogleDrive(false);
                                    })?.catch(() => { });
                                }}>Desvincular</Button>
                            </Col>
                            */}
                        </Form.Row>
                        <Button disabled={!esAdmin || habilitarConfiguracionAutonumerico} onClick={() => setMostrarModalHabilitarAutonumerico(true)}>Habilitar Autonumerico</Button>
                        <h4>Autonumerico</h4>
                        <Form.Group>
                            <MyFormRadio disabled={!habilitarConfiguracionAutonumerico} inline id="radioPorClienteAlpha" name="Autonumerico" label="Por Cliente Alpha" value={Autonumerico.PorClienteAlpha} onCheckedChange={checked => radioAutonumericoOnChanged(!checked)}></MyFormRadio>
                            <MyFormRadio disabled={!habilitarConfiguracionAutonumerico} inline id="radioPorEmpresa" name="Autonumerico" label="Por Empresa" value={Autonumerico.PorEmpresa} onCheckedChange={checked => radioAutonumericoOnChanged(checked)}></MyFormRadio>
                        </Form.Group>
                        <Form.Group>
                            <MyFormCheck name="UsarAutonumerico" label="Crear caratulas con autonumerico" onCheckedChange={checked => {
                                setHabilitarMascara(checked);
                                setHabilitarResetearContador(checked && !isNullOrWhiteSpace(formikRef.current!.values.MascaraAutonumerico));
                            }} disabled={!habilitarConfiguracionAutonumerico}></MyFormCheck>
                            <MyFormControl type="text" name="MascaraAutonumerico" disabled={!habilitarConfiguracionAutonumerico || !habilitarMascara} label="Máscara autonumerico" onValueChange={(valor: any) => {
                                setHabilitarResetearContador(formikRef.current!.values.UsarAutonumerico && !isNullOrWhiteSpace(valor));
                            }}></MyFormControl>
                            <p className="form-text">
                                Constantes:
                                <br></br>
                                ##anio##       (año corto Ej: 14)
                                <br></br>
                                ##aniolargo##  (año largo Ej: 2014)
                                <br></br>
                                ##mes##
                                <br></br>
                                ##numero##
                                <br></br>
                                ##tipo##
                                <br></br>
                                Mascara del autonumerico es |0000| y el formato es ##numero##|0000|
                                <br></br>
                                ejemplo:
                                ##anio##/##numero##|000000|
                            </p>

                            <MyFormCheck name="UsuarioOperadorCreaCarpeta" label="Perfil Operador puede crear Carpetas" 
                            disabled={!habilitarConfiguracionAutonumerico}></MyFormCheck>

                        </Form.Group>
                        <Button variant="danger" className="mt-2 mb-2" onClick={() => history.goBack()}>Cancelar</Button>
                        <Button className="mt-2 ml-2 mb-2" onClick={() => {
                            formikRef.current!.setFieldValue('ResetearContador', false);
                            formikRef.current!.submitForm();
                        }}>Guardar</Button>
                        <Button className="mt-2 ml-2 mb-2" disabled={!esAdmin} onClick={() => updateEstadoModalLogo({ tipo: 'mostrar' })}>Cambiar Logo</Button>
                        <br></br>
                        <Button className="mt-2 mb-2" onClick={() => tiposOperacionRef.current!.mostrar()}>Tipos de Operación</Button>
                        <Button className="mt-2 ml-2 mb-2" disabled={!habilitarConfiguracionAutonumerico || !habilitarBotonResetearContador} onClick={() => {
                            formikRef.current!.setFieldValue('ResetearContador', true);
                            formikRef.current!.submitForm();
                        }}>Establecer Contador Autonumerico</Button>
                    </MyForm>
                </Formik>
            </div>
        </CentradoVertical>
        <MyModal show={estadoModalLogo.mostrar} onHide={cerrarModalLogo}>
            <Modal.Dialog size="xl">
                <Modal.Header closeButton>
                    Establecer Logo
                </Modal.Header>
                <Modal.Body>
                    <BlockUi blocking={estadoModalLogo.cargando}>
                        {estadoModalLogo.logo ? <>
                            <h4>Logo actual</h4>
                            <img src={estadoModalLogo.logo} alt="Logo" className="mb-2" style={{ maxWidth: '100%', height: 'auto' }}></img>
                        </> : <p className="lead">La empresa no tiene logo</p>}
                        <Form>
                            <Form.File custom>
                                <Form.File.Input id="inputLogo" accept="image/*"></Form.File.Input>
                                <Form.File.Label data-browse="Seleccionar">Nuevo Logo</Form.File.Label>
                                {/* <Form.Control.Feedback type="invalid">{estado.errorArchivo}</Form.Control.Feedback> */}
                            </Form.File>
                        </Form>
                    </BlockUi>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={cerrarModalLogo}>
                        Cancelar
                    </Button>
                    <Button disabled={estadoModalLogo.cargando} onClick={() => {
                        updateMensajeDialogo('¿Está seguro que desea eliminar el logo?');
                        dialogoRef.current!.mostrar().then(eliminarLogo).catch(() => { });
                    }}>Eliminar Logo</Button>
                    <Button disabled={estadoModalLogo.cargando} onClick={uploadLogo}>Establecer Logo</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <DialogoConfirmar ref={dialogoRef} mensaje={mensajeDialogo} textoBotonCancelar="No" textoBotonConfirmar="Sí"></DialogoConfirmar>
        <TiposOperacion ref={tiposOperacionRef}></TiposOperacion>
        <ResetearContadorAutonumerico ref={resetearContadorRef}></ResetearContadorAutonumerico>
        <MyModal show={mostrarModalHabilitarAutonumerico} onHide={() => setMostrarModalHabilitarAutonumerico(false)}>
            <Modal.Dialog size="lg">
                <Modal.Header closeButton>
                    Ingresar Contraseña
                </Modal.Header>
                <Formik validationSchema={Yup.object({
                    'Valor': Yup.string().nullable().required('Debe ingresar la contraseña')
                })} initialValues={{ Valor: '' }} onSubmit={({ Valor }: { Valor: string }, helpers) => {
                    if (Valor === contraseñaHabilitarAutonumerico) {
                        setHabilitarConfiguracionAutonumerico(true);
                        setMostrarModalHabilitarAutonumerico(false);
                    } else {
                        helpers.setFieldError('Valor', 'Contraseña incorrecta');
                    }
                    helpers.setSubmitting(false);
                }}>
                    {({ submitForm, isSubmitting }) => (
                        <>
                            <Modal.Body>
                                <MyForm>
                                    <Form.Group>
                                        <p className="form-text">Ingrese la contraseña para configurar el autonumerico</p>
                                        <MyFormControl label="Contraseña" hideLabel name="Valor" type="password"></MyFormControl>
                                    </Form.Group>
                                </MyForm>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="danger" onClick={() => setMostrarModalHabilitarAutonumerico(false)}>Cerrar</Button>
                                <Button disabled={isSubmitting} onClick={submitForm}>Ingresar</Button>
                            </Modal.Footer>
                        </>
                    )}
                </Formik>
            </Modal.Dialog>
        </MyModal>
    </>;
}