import { CancelToken } from "axios";
import { DateTime } from "luxon";
import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { AppContext, userClient } from "../../App";
import Grilla, { GrillaRef, TipoCampo } from "../../Grilla";
import { MyModal } from "../../MyModal";
import Loader from "react-loaders";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import contentDisposition from "content-disposition";
import FileSaver from "file-saver";
import { createQueryString } from 'Utilidades';
import BlockUi from "react-block-ui";
import { useCancel } from "SintiaHooks";

export function ReporteContableOrdenPago() {
    let { mostrarError } = React.useContext(AppContext);
    let { getCancelToken, cancelCurrentTokens, isUnmounted, isCancel } = useCancel();
    let refGrilla = React.useRef<GrillaRef>(null);
    let [generandoExcel, updateGenerandoExcel] = React.useState(false);
    function busquedaReducer(estado: any, accion: any) {
        if (accion.tipo === 'fechaDesde') {
            return { ...estado, fechaDesde: accion.valor };
        } else if (accion.tipo === 'fechaHasta') {
            return { ...estado, fechaHasta: accion.valor };
        }
        return estado;
    }
    let busquedaInicial = { fechaDesde: DateTime.local().minus({ months: 1 }).toISODate(), fechaHasta: DateTime.local().toISODate() };
    let [busquedaActual, updateBusquedaActual] = React.useState(busquedaInicial);
    let [busqueda, updateBusqueda] = React.useReducer(busquedaReducer, { ...busquedaInicial });
    let [cargando, updateCargando] = React.useState(true);
    const cargarReporte = React.useCallback(async (desde: number, hasta: number, cancelToken: CancelToken) => {
        let fechaDesde = busquedaActual.fechaDesde;
        let fechaHasta = DateTime.fromISO(busquedaActual.fechaHasta).plus({ days: 1 }).toISODate();
        let respuesta = await userClient.get('/ReportesContable/InformeOrdenesPago' + createQueryString({
            FechaDesde: fechaDesde,
            FechaHasta: fechaHasta,
            Desde: desde,
            Hasta: hasta
        }), { cancelToken: cancelToken });
        return { cantidadItems: respuesta.data.CantidadTotal, items: respuesta.data.Items };
    }, [busquedaActual]);
    React.useEffect(() => {
        updateCargando(false);
        //eslint-disable-next-line 
    }, []);
    async function exportarAExcel() {
        try {
            updateGenerandoExcel(true);
            let fechaDesde = busquedaActual.fechaDesde;
            let fechaHasta = DateTime.fromISO(busquedaActual.fechaHasta).plus({ days: 1 }).toISODate();
            let respuesta = await userClient.get('/ReportesContable/ExcelInformeOrdenesPago' + createQueryString({
                FechaDesde: fechaDesde,
                FechaHasta: fechaHasta
            }), {
                cancelToken: getCancelToken(),
                responseType: 'blob',
            });
            updateGenerandoExcel(false);
            let parsedContentDisposition = contentDisposition.parse(respuesta.headers['content-disposition']);
            let fileName = parsedContentDisposition.parameters.filename || 'Informe Ordenes Pago.xlsx';
            FileSaver.saveAs(respuesta.data, fileName);
        } catch (error) {
            if (!isCancel(error)) {
                console.error(error);
                mostrarError('Error al exportar informe ordenes pago a Excel');
            }
            if (!isUnmounted()) {
                updateGenerandoExcel(false);
            }
        }
    }
    let camposGrilla = [{ propiedad: 'Id', visible: false, clave: true },
    { propiedad: 'FechaRecibo', titulo: 'Fecha', tipo: TipoCampo.Date },
    { propiedad: 'NombreComprobante', titulo: 'Tipo Comprobante' },
    { propiedad: 'Numero_Comprobante_Editado', titulo: 'Numero', },
    { propiedad: 'Proveedor', titulo: 'Proveedor' },
    { propiedad: 'Cliente', titulo: 'Cliente' },
    { propiedad: 'CI', titulo: 'Condición' },
    { propiedad: 'CUIT', titulo: 'CUIT' },
    { propiedad: 'Total', titulo: 'Total', tipo: TipoCampo.Number },
    { propiedad: 'Observaciones', titulo: 'Observaciones' }];
    return (<>
        <h2>Informe Ordenes Pago</h2>
        <BlockUi blocking={cargando}>
            <Form inline onSubmit={e => {
                updateBusquedaActual(busqueda);
                refGrilla.current?.recargar();
                e.preventDefault();
            }}>
                <Form.Group>
                    <Form.Label htmlFor="txtFechaDesde" className="mx-2">Fecha Desde</Form.Label>
                    <Form.Control type="date" id="txtFechaDesde" value={busqueda.fechaDesde} className="mb-2 mr-2"
                        onChange={e => updateBusqueda({ tipo: 'fechaDesde', valor: e.target.value })}></Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label htmlFor="txtFechaHasta" className="mr-2">Fecha Hasta</Form.Label>
                    <Form.Control type="date" id="txtFechaHasta" value={busqueda.fechaHasta} className="mb-2 mr-2"
                        onChange={e => updateBusqueda({ tipo: 'fechaHasta', valor: e.target.value })}></Form.Control>
                </Form.Group>
                <Button type="submit" className="ml-2 mb-2">Buscar</Button>
            </Form>
            <Button variant="success" className="mb-2" onClick={exportarAExcel}>
                <FontAwesomeIcon icon={faFileExcel} />
                <span>Exportar a Excel</span>
            </Button>
            <Grilla responsiva cargarDatos={cargarReporte} campos={camposGrilla} ref={refGrilla}></Grilla>
        </BlockUi>
        <MyModal show={generandoExcel}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="lead">Generando Excel...</p>
                    <div className="loader-container">
                        <Loader type="ball-spin-fade-loader" active></Loader>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        cancelCurrentTokens();
                    }}>Cancelar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
    </>);
}