import React, { useEffect,  useState } from "react";
import { Button, Container, Form, Col, Alert, Tabs, Tab } from "react-bootstrap";
import { MyForm, MyFormControl, MySelect } from "../../FormikHooks";
import * as Yup from "yup";
import { useAdvAPI } from './AdvancedApi';
import { useApi } from "ApiHooks";
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, FormikProps } from "formik";
import BlockUi from "react-block-ui";
import { convertirDatosGenericosAOptionIdDescripcion, convertirDatosGenericosAOption, optionLabelConCodigo } from "Utilidades";
import {GetModalManifiestos, GetModalManifiestosRef}  from './getModalManifiestos';
import {GetModalCodigosBultos, GetModalCodigosBultosRef} from './getModalCodigosBultos';
import {GetModalSetNotificacionBultos, GetModalSetNotificacionBultosRef} from './getModalSetNotificacionBultos';


export function ConsultaBultos() {

    let api = useAdvAPI();
    let apiSintia = useApi();
    //let refGrilla = useRef<GrillaRef>(null);
    //let history = useHistory();
    /*
    let apiSintia = useApi();

*/
    let [ConsultaSubError, updateConsultaSubError] =  useState(false);
    let [ConsultaSubMsg, updateConsultaSubMsg] =  useState('');

    //resulatados de busqueda
    let [nroManifiesto, updateNroManifiesto] =  useState('');
    let [nroPuerto, updatenroPuerto] =  useState('');

    let [cargando, updateCargando] = useState(false);
    const [listaManifiestos, updatelistaManifiestos] = useState([]);
    let [docTransporteNoResult, updatedocTransporteNoResult] = useState(false);
    let formikRef = React.useRef<FormikProps<any>>(null);
    let huboCambios = React.useRef<{ valor: boolean }>({ valor: false });
    let getModalManifiestosRef = React.useRef<GetModalManifiestosRef>(null);
    let getModalCodigosBultosRef = React.useRef<GetModalCodigosBultosRef>(null);
    let getModalSetNotificacionBultosRef = React.useRef<GetModalSetNotificacionBultosRef>(null);

    let [optionsAduana, updateoptionsAduana] = useState<SelectOption[]>([]);
    
    let [mostrarSeguimiento, updateMostrarSeguimiento] = useState(false);




    const eventoHuboCambios = () => {
        huboCambios.current.valor = true;
    }


    let schema = Yup.object({
        //'Manifiesto': Yup.string().nullable().required('Debe seleccionar una aduana de conexion'), 
        'Puerto':  Yup.string().nullable().required('Debe ingresar un puerto'), 
        'documentoTransporte': Yup.string().nullable().required('Debe ingresar un Doc. de Transporte')
    });


    useEffect(() => {
        formikRef.current!.setFieldValue('Manifiesto',nroManifiesto);
        cargarManifiesto(nroManifiesto);
        //eslint-disable-next-line
    }, [nroManifiesto]);


    useEffect(() => {
        formikRef.current!.setFieldValue('Puerto',nroPuerto);
        document.getElementsByName("documentoTransporte")[0].focus();
        //eslint-disable-next-line
    }, [nroPuerto]);

 
    const styles = {
        Estados: {
            margin: 'auto',
            border: '0px',
//            'font-weight': 'bold',
            'text-align': 'center',
            color:'black'
        },
        EstadosS: {
            margin: 'auto',
            border: '0px',
            'font-weight': 'bold',
            'text-align': 'center',
            color:'blue'
        },
        Seguimiento:{
            backgroundColor: '#FCEAE2',
            margin: '0px',
            padding: '.5rem',
            border: '.5px solid gray',

        }

    };//    async function cargarManifiesto(nroManifiesto: string, puerto: string, documentoTransporte: string , aduana: string){

    //F7D0AA
    //D9F2F8
    async function cargarManifiesto(nroManifiesto: string){

        try {

            //cargo manifiesto seleccionado y guardado en listaManifiestos
            var mani : any;
            mani = listaManifiestos.find((item : any) => item.Manifiesto === nroManifiesto);

            formikRef.current!.setFieldValue('Marca',mani.Marca);
            formikRef.current!.setFieldValue('CuitAgenteTransporte',mani.CuitAgenteTransporte);
            formikRef.current!.setFieldValue('NombreAgenteTranspore',mani.NombreAgenteTranspore);
            formikRef.current!.setFieldValue('NombreTransporte',mani?.NombreTranspore);
            formikRef.current!.setFieldValue('FechaArribo',mani.FechaArribo);
            formikRef.current!.setFieldValue('Via',mani.Via);
            formikRef.current!.setFieldValue('Bandera',mani.Bandera);
            formikRef.current!.setFieldValue('Deposito',mani.Deposito);
            formikRef.current!.setFieldValue('CierreIngresoDeposito',mani.CierreIngresoDeposito ?? 'N');

            //formikRef.current!.setFieldValue('Estado',mani.EstadoManifiesto);

            formikRef.current!.setFieldValue('Estado', estadosMenu.find(i => i.value ===mani.EstadoManifiesto )?.label);


            formikRef.current!.setFieldValue('Cancelado',mani.Cancelado ?? 'N');
            formikRef.current!.setFieldValue('Consolidado',mani.Consolidado ?? 'N');
            formikRef.current!.setFieldValue('Fraccionado',mani.Fraccionado ?? 'N');
            formikRef.current!.setFieldValue('Traslados',mani.Traslados ?? 'N');

            //si cierre a deposito es N, mostramos la opcion de seguimiento
            if (mani.CierreIngresoDeposito ==='N')
            {
                updateMostrarSeguimiento(true);
            } else {
                updateMostrarSeguimiento(false);
            }

            //buscamos bultos
            let _puerto = formikRef.current!.getFieldProps('Puerto').value;
            let _documentoTransporte = formikRef.current!.getFieldProps('documentoTransporte').value;
            let _aduana = formikRef.current!.getFieldProps('aduana').value;

            //busco nombre deposito

            //con el codigo de deposito debo consulta en su nombre
            let depositoNombre = await apiSintia.getDepositos(_aduana, mani.Deposito);

            if (depositoNombre.Descripcion!=null)
            {
                formikRef.current!.setFieldValue('nombreDeposito',depositoNombre.Descripcion);
            } else {
                formikRef.current!.setFieldValue('nombreDeposito','');
            }


            let resp = await api.getConsultaBultos(nroManifiesto, _puerto, _documentoTransporte ,_aduana);

            if (resp.Exito === true)
            {
                let bulto = resp.consultaBultos;

                formikRef.current!.setFieldValue('CodigoEmbalaje',bulto.CodigoEmbalaje);
                formikRef.current!.setFieldValue('CantidadTotal',Number(bulto.CantidadTotal).toFixed(2));
                formikRef.current!.setFieldValue('CantidadParcial',Number(bulto.CantidadParcial).toFixed(2));
                formikRef.current!.setFieldValue('PesoVolumenManif',Number(bulto.PesoVolumen).toFixed(2));
                formikRef.current!.setFieldValue('UnidadDeMedida',bulto.UnidadDeMedida);
                formikRef.current!.setFieldValue('NroDeBulto',bulto.NroDeBulto);
                formikRef.current!.setFieldValue('CantidadAAfectar', Number(bulto.CantidadAAfectar).toFixed(2));
                formikRef.current!.setFieldValue('TipoEmbalaje',bulto.TipoEmbalaje);
                formikRef.current!.setFieldValue('CantSobFaltantes',Number(bulto.CantSobFaltantes).toFixed(2));
                //formikRef.current!.setFieldValue('PesoVolumenManif',bulto.PesoVolumenManif);
                
            }


        } catch (error) {
            
        }


    }

    async function buscarPuertos(e : any)
    {
        try {
            //necesito saber la aduana
            //por ahora 073 traigo listado completo de compañias aereas y nada mas

            //el resto
            // pValor = '' mostrar 'No se eligio ningun filtro de puerto'
            // pValor tiene letras y encuentra items mostrar solo el listado filtrado de puertos
            // pValor tiene letras y no hay resultados (no se encontro puertos..) solo mostrar registros aereos

            let _aduana = formikRef.current!.getFieldProps('aduana').value;

            getModalCodigosBultosRef.current!.mostrar(_aduana)
                .then(resultado => { updatenroPuerto(resultado.Codigo);}).catch(() => { });
    
            
        } catch (error) {
            
        }
    }

    function setNotificaciones(){

        let _aduana = formikRef.current!.getFieldProps('aduana').value;
        let _puerto = formikRef.current!.getFieldProps('Puerto').value;
        let _documentoTransporte = formikRef.current!.getFieldProps('documentoTransporte').value;
        let _manifiesto = formikRef.current!.getFieldProps('Manifiesto').value;

        getModalSetNotificacionBultosRef.current!.mostrar(_puerto,_documentoTransporte,_aduana,_manifiesto);

    }



    async function CargarDatos(pBusqueda: any){

        try {
                updateCargando(true);

                //reseteo datos
                updateNroManifiesto('');
                updatelistaManifiestos([]);
                updatedocTransporteNoResult(false);

                let respuesta = await api.getManifiestos(pBusqueda.Puerto, pBusqueda.documentoTransporte, pBusqueda.aduana);  //api getmanifiestos
//                let respuesta = await api.getManifiestos(pBusqueda.Puerto, pBusqueda.documentoTransporte, pBusqueda.aduana);  //api getmanifiestos

                if (respuesta.consultaManifiesto?.length===0)
                {
                    // no hay nada para esos parametros
                    updateCargando(false);
                    updatedocTransporteNoResult(true);
                    return;
                }
                else if (respuesta.consultaManifiesto?.length===1)
                    {
                        updatelistaManifiestos(respuesta.consultaManifiesto);
                        updateNroManifiesto(respuesta.consultaManifiesto[0]?.Manifiesto);
                    }
                else
                {

                    
                    getModalManifiestosRef.current!.mostrar(respuesta?.consultaManifiesto.map((item: any) => ({ 
                                                                                                                 Id:item.Manifiesto, 
                                                                                                                 Manifiesto: item.Manifiesto, 
                                                                                                                 EstadoManifiesto: item.EstadoManifiesto,
                                                                                                                 Consolidado: item.Consolidado? item.Consolidado:'N',
                                                                                                                 Fraccionado: item.Fraccionado? item.Consolidado:'N'
                                                                                                                })))
                    .then(resultado => { updateNroManifiesto(resultado.nroManifiesto);}).catch(() => { });
                }

                updatelistaManifiestos(respuesta.consultaManifiesto);
                updateCargando(false);

        } catch (error) {
            updateConsultaSubError(true);
            console.log(error);
            updateConsultaSubMsg('Error:' + error?.response?.data);
        } finally
        {
            updateCargando(false);
        }

        
    }



    useEffect(() => {
        async function cargar() {
            try {

                let aduana = await apiSintia.getAduanas().then(convertirDatosGenericosAOptionIdDescripcion);
                updateoptionsAduana(aduana)
            } catch (error) {
                if (!apiSintia.isCancel(error)) {
                    console.error('Error al cargar caratulas', error);
                }
            }
        }
        cargar();
        //eslint-disable-next-line
    }, []);
    
    //estados

    let initialvalues = {
        Manifiesto:'', 
        Puerto: '', 
        documentoTransporte: '',
        CierreIngresoDeposito: ''
    };
    
    /*CUR EN CURSO
    REG REGISTRADO
    PRE PRESENTADO
    ANU ANULADO*/

    type SelectOption = { value: string, label: string | null | undefined };

    const estadosMenu: SelectOption[] = [
        {value:'CUR', label:'EN CURSO'},
        {value:'REG', label:'REGISTRADO'},
        {value:'PRE', label:'PRESENTADO'},
        {value:'ANU', label:'ANULADO'}
    ]
    
    return (
    <>
        <Container>
            <br/><p className="h3">Consulta Bultos</p><br/>
            <Formik initialValues={initialvalues} validationSchema={schema} innerRef={formikRef} onSubmit={CargarDatos}>
                <BlockUi blocking={cargando}>

                    <MyForm>

                        <Form.Group className="mr-2  mb-2 col-md-12">
                            {docTransporteNoResult && 
                                <Alert variant="warning">
                                    <FontAwesomeIcon icon={faExclamationTriangle}></FontAwesomeIcon>
                                        <span className="ml-3">210 - Titulo Inexistente</span>
                                </Alert>
                            }
                        </Form.Group>

                        <Form.Group as={Col} >
                                    {ConsultaSubError && 
                                        <Alert variant="danger" onClose={() => updateConsultaSubError(false)} dismissible>
                                        <Alert.Heading>Error al consultar Sub Cuenta</Alert.Heading>
                                            <p><b>{ConsultaSubMsg}</b></p>
                                        </Alert>
                                    }
                        </Form.Group>

                        <Form.Row>
                                <Form.Group as={Col} className="col-md-3">
                                    <MySelect 
                                        name="aduana" label="Aduana"
                                        options={optionsAduana} onValueChange={eventoHuboCambios} />
                                </Form.Group>
                                <Form.Group  as={Col} className="col-md-2 align-middle">
                                    <MyFormControl name="Manifiesto"  type="text" label="Manif de Consulta"></MyFormControl>
                                </Form.Group>
                                <Form.Group as={Col} className="col-md-1 align-middle">
                                    <MyFormControl name="Puerto"  type="text" label="Puerto"></MyFormControl>
                                </Form.Group>
                                <Form.Group as={Col}  className="col-auto align-middle" noGutters>
                                    <Button type="button" className=" align-self-end my-4" onClick={buscarPuertos} >.</Button>
                                </Form.Group>
                                <Form.Group as={Col} className="col-md-3 align-right">
                                    <MyFormControl name="documentoTransporte"  type="text" label="Documento de Transporte"></MyFormControl>
                                </Form.Group>
                                <Form.Group as={Col} className="d-flex col-auto">
                                    <Button type="submit" className="align-self-end my-4" >Buscar</Button>
                                </Form.Group>
                        </Form.Row>

                        <Form.Row>
                                <Form.Group as={Col} className="col-md-2 align-middle">
                                    <div className="input-group mb-3">
                                        <span style={styles.Estados} className="input-group-text" id="lbcancelado">Cancelado</span>
                                        <MyFormControl style={styles.Estados} type="text" name="Cancelado" aria-describedby="basic-addon3"></MyFormControl>
                                    </div>
                                </Form.Group>

                                <Form.Group as={Col} className="col-md-2 align-middle">
                                    <div className="input-group">
                                        <span style={styles.EstadosS} className="input-group-text" id="lbcierreingdep">Cierre Ing Dep</span>
                                        <MyFormControl style={styles.EstadosS} type="text" name="CierreIngresoDeposito" aria-describedby="basic-addon3"></MyFormControl>
                                    </div>
                                </Form.Group>

                                <Form.Group as={Col} className="col-md-2 align-middle ">
                                    <div className="input-group mb-2 ">
                                        <span  style={styles.Estados} className="input-group-text" id="lbConsolidado">Consolidado</span>
                                        <MyFormControl  style={styles.Estados} type="text" name="Consolidado" aria-describedby="basic-addon3"></MyFormControl>
                                    </div>
                                </Form.Group>

                                <Form.Group as={Col} className="col-md-2 align-middle ">
                                    <div className="input-group mb-2">
                                        <span  style={styles.Estados} className="input-group-text" id="lbFraccionado">Fraccionado</span>
                                        <MyFormControl  style={styles.Estados} type="text" name="Fraccionado" aria-describedby="basic-addon3"></MyFormControl>
                                    </div>
                                </Form.Group>
                                <Form.Group as={Col} className="col-md-2 align-middle">
                                    <div className="input-group mb-2">
                                        <span style={styles.Estados} className="input-group-text" id="lbcierreingdep">Traslados</span>
                                        <MyFormControl style={styles.Estados} type="text" name="Traslados" aria-describedby="basic-addon3"></MyFormControl>
                                    </div>
                                </Form.Group>

                        </Form.Row>

                        { mostrarSeguimiento? 
                            <Form.Row>
                                    <Form.Group as={Col} className="col-md-10 align-middle">
                                        <div style={styles.Seguimiento}>
                                            <span className="ml-3" >Cierre de Ingreso a Deposito esta en <b>N</b>, desea activar su seguimiento?</span> 
                                            <Button type="button" className="align-self-end ml-4" onClick={setNotificaciones} >Si</Button>
                                            <Button type="button" className="align-self-end ml-2" onClick={() => updateMostrarSeguimiento(false)}>No</Button>
                                        </div>
                                    </Form.Group>
                            </Form.Row>
                        : <></>
                        }

                        <Form.Row>
                                <Form.Group  as={Col} className="col-md-4">

                                    {/*
                                    <MySelect  isDisabled={true} name="Estado" label="Estado" hideLabel={true} options={estadosMenu} getOptionLabel={optionLabelConCodigo}></MySelect>
                                */}


                                <div className="input-group mb-2">
                                        <span className="input-group-text" id="lbEstado">Estado</span>
                                        <MyFormControl type="text" name="Estado" aria-describedby="basic-addon3"></MyFormControl>
                                </div>
                                
                               </Form.Group>
                                <Form.Group  as={Col} className="col-md-6">
                                    <div className="input-group mb-2">
                                        <span className="input-group-text" id="lbMarca">Marcas</span>
                                        <MyFormControl type="text" name="Marca" aria-describedby="basic-addon3"></MyFormControl>
                                    </div>
                                </Form.Group>
                        </Form.Row>

                        <Form.Row>
                                <Form.Group  as={Col} className="col-md-10">
                                    <div className="input-group  m-auto p-0">
                                        <span className="input-group-text" id="lbDeposito">Deposito</span>
                                        <MyFormControl type="text" name="Deposito" aria-describedby="basic-addon3"></MyFormControl>
                                        <MyFormControl type="text" name="nombreDeposito" aria-describedby="basic-addon3"></MyFormControl>
                                    </div>
                               </Form.Group>
                        </Form.Row>

                        <Tabs className="col-md-10">
                            <Tab eventKey="medioTransporte" title="Medio de transporte">
                                <br></br>
                                <Form.Group  as={Col} className="col-md-10">
                                        <div className="input-group">
                                            <span className="input-group-text" id="lbDeposito">Fecha de Arribo</span>
                                            <MyFormControl type="text" name="FechaArribo" aria-describedby="basic-addon3"></MyFormControl>
                                        </div>
                                </Form.Group>

                                <Form.Group  as={Col} className="col-md-10">
                                        <div className="input-group">
                                            <MySelect isDisabled={true} name="Via" label="Via" options={() => apiSintia.getVias().then(convertirDatosGenericosAOption)} getOptionLabel={optionLabelConCodigo}></MySelect>

                                            {/*<span className="input-group    " id="lbDeposito">Via</span>
                                            <MyFormControl type="text" name="Via" aria-describedby="basic-addon3"></MyFormControl>
                                            <MyFormControl type="text" name="nombreVia" aria-describedby="basic-addon3"></MyFormControl>*/}
                                        </div>
                                </Form.Group>

                                <Form.Group  as={Col} className="col-md-10">
                                        <div className="input-group">
                                            <span className="input-group-text" id="lbnombreTransporteeposito">Nombre transporte</span>
                                            <MyFormControl type="text" name="NombreTransporte" aria-describedby="basic-addon3"></MyFormControl>

                                        </div>
                                </Form.Group>

                                <Form.Group  as={Col} className="col-md-10">
                                        <div className="input-group">
                                        <MySelect isDisabled={true} name="Bandera" label="Bandera" options={() => apiSintia.getPaises().then(convertirDatosGenericosAOption)} getOptionLabel={optionLabelConCodigo}></MySelect>

                                            {/*<span className="input-group-text" id="lbBandera">Bandera</span>
                                            <MyFormControl type="text" name="Bandera" aria-describedby="basic-addon3"></MyFormControl>
                                        <MyFormControl type="text" name="nombreBandera" aria-describedby="basic-addon3"></MyFormControl>*/}
                                        </div>
                                </Form.Group>

                                <Form.Group  as={Col} className="col-md-10">
                                        <div className="input-group">
                                            <span className="input-group-text" id="lbAgenteTransporte">Agente de Transporte</span>
                                            <MyFormControl type="text" name="CuitAgenteTransporte" aria-describedby="basic-addon3"></MyFormControl>
                                            <MyFormControl type="text" name="NombreAgenteTranspore" aria-describedby="basic-addon3"></MyFormControl>
                                        </div>
                                </Form.Group>



                            </Tab>
                            <Tab eventKey="bultos" title="Bultos">
                                <br></br>
                                <div className="container text-center">
                                    <div className="row">
                                        <div className="col col-md-5">
                                            <Form.Group  as={Col} className="col-md-12">
                                                    <div className="input-group">
                                                        {/*
                                                        <span className="input-group-text" id="lbCodigo">Codigo</span>
                                                        <MyFormControl type="text" name="CodigoEmbalaje" aria-describedby="basic-addon3"></MyFormControl>
                                                        <MyFormControl type="text" name="CodigoEmbalajeNombre" aria-describedby="basic-addon3"></MyFormControl>
                                                        */}

                                                        <MySelect isDisabled={true} name="CodigoEmbalaje" label="Codigo" options={() => apiSintia.getCodigosEmbarque().then(convertirDatosGenericosAOption)}
                                                            getOptionLabel={optionLabelConCodigo}></MySelect>

                                                    </div>
                                            </Form.Group>
                                            <Form.Group  as={Col} className="col-md-12">
                                                    <div className="input-group">
                                                        {/*<span className="input-group-text" id="lbTipo">Tipo&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                        <MyFormControl type="text" name="TipoEmbalaje" aria-describedby="basic-addon3"></MyFormControl>
                                                    <MyFormControl type="text" name="TipoEmbalajenombre" aria-describedby="basic-addon3"></MyFormControl>*/}
                                                        <MySelect isDisabled={true} name="TipoEmbalaje" label="Tipo" options={() => apiSintia.getTiposEmbarque().then(convertirDatosGenericosAOption)}
                                                            getOptionLabel={optionLabelConCodigo}></MySelect>

                                                    </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col col-md-5">
                                            <Form.Group  as={Col} className="col-md-12">
                                                    <div className="input-group">
                                                        <span className="input-group-text" id="lbpesoVolManif">Peso/Vol. Manif</span>
                                                        <MyFormControl type="text" name="PesoVolumenManif" aria-describedby="basic-addon3"></MyFormControl>
                                                    </div>
                                            </Form.Group>
                                            <Form.Group  as={Col} className="col-md-12">
                                                    <div className="input-group">
                                                        <span className="input-group-text" id="lbTipo">Cant. a Afectar</span>
                                                        <MyFormControl type="text" name="CantidadAAfectar" aria-describedby="basic-addon3"></MyFormControl>
                                                    </div>
                                            </Form.Group>
                                            <Form.Group  as={Col} className="col-md-12">
                                                    <div className="input-group">
                                                        <span className="input-group-text" id="lbcantSobFaltantes">Cant. Sob./Faltantes</span>
                                                        <MyFormControl type="text" name="CantSobFaltantes" aria-describedby="basic-addon3"></MyFormControl>
                                                    </div>
                                            </Form.Group>
                                        </div>
                                    </div>    

                                    <div className="row">
                                        <div className="col">
                                            <Form.Group  as={Col} className="col-md-10">
                                                    <div className="input-group">
                                                        <span className="input-group-text" id="lbcantidadTotal">Cantidad Total</span>
                                                        <MyFormControl type="text" name="CantidadTotal" aria-describedby="basic-addon3"></MyFormControl>
                                                    </div>
                                            </Form.Group>
                                            <Form.Group  as={Col} className="col-md-10">
                                                    <div className="input-group">
                                                        <span className="input-group-text" id="lbcantidadParcial">Cantidad Parcial</span>
                                                        <MyFormControl type="text" name="CantidadParcial" aria-describedby="basic-addon3"></MyFormControl>
                                                        &nbsp;&nbsp;
                                                        <span className="input-group-text" id="lbunidadmedida">Unidad de Medida</span>
                                                        <MyFormControl type="text" name="UnidadDeMedida" aria-describedby="basic-addon3"></MyFormControl>
                                                    </div>

                                            </Form.Group>
                                            <Form.Group  as={Col} className="col-md-10">
                                                    <div className="input-group">
                                                        <span className="input-group-text" id="lbnrobultos">Nro de Bultos</span>
                                                        <MyFormControl type="text" name="NroDeBulto" aria-describedby="basic-addon3"></MyFormControl>
                                                    </div>
                                            </Form.Group>
                                        </div>
                                    </div>                                        
                                </div>
                            </Tab>
                                        
                        </Tabs>
                    <br/>
                    <br/>
                    <br/>
                    </MyForm>
                </BlockUi>
            </Formik>


        </Container>

        <GetModalManifiestos ref={getModalManifiestosRef}/>
        <GetModalCodigosBultos ref={getModalCodigosBultosRef}/>
        <GetModalSetNotificacionBultos ref={getModalSetNotificacionBultosRef}/>

        
    </>
    
    )
}