import React from 'react'
import BlockUi from 'react-block-ui'
import { Button,  Modal } from 'react-bootstrap'
import Loader from 'react-loaders'

export interface ModalEditarObservacionProps {
    showModalEditarObservacion: boolean
    handleCloseModalEditarObservacion: () => void
    editarObservacionArchivo: (idArchivo: number) => void
    cargando: boolean
    progress: number | undefined
    formObservacion: () => void
    idArchivo: number | undefined
}

const ModalEditarObservacion = ({ cargando, editarObservacionArchivo, formObservacion, handleCloseModalEditarObservacion, progress, showModalEditarObservacion, idArchivo }: ModalEditarObservacionProps) => {
    return (
        <Modal animation={false} backdrop="static" show={showModalEditarObservacion} onHide={handleCloseModalEditarObservacion} dialogClassName="modal-upload"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <BlockUi blocking={cargando} loader={<Loader active={cargando} type='ball-beat' />} message="Editando.." >
                <Modal.Header closeButton={false}>
                    <Modal.Title>Editar Observación</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        {formObservacion()}
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }}>
                            <Button onClick={handleCloseModalEditarObservacion} variant='danger'>Cerrar</Button>
                            <Button onClick={() => editarObservacionArchivo(idArchivo!)} variant='success'>Editar</Button>
                        </div>
                    </>
                </Modal.Body>
            </BlockUi>
        </Modal>
    )
}

export default ModalEditarObservacion