import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useContext, useEffect, useReducer, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useHistory } from "react-router";
import { AppContext } from "App";
import { GrillaRef, GrillaSync, TipoCampo } from "../../Grilla";
import { useDecodedParams, useQuery, isNullOrWhiteSpace } from "../../Utilidades";
import { EstadoArticuloProcesoExcel, EstadoNcmArticuloProcesoExcel } from "Enums";
import { NotFoundComponent } from "../../App";
import BlockUi from "react-block-ui";
import FileSaver from "file-saver";
import { MyModal } from "MyModal";
import Loader from "react-loaders";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import { MyAsyncSelect, MyForm } from "FormikHooks";
import { IngresoEstablecerSufijosAutomatico, IngresoEstablecerSufijosAutomaticoRef } from "./IngresoEstablecerSufijosAutomatico";
import { EstablecerSufijosRef, EstablecerSufijos } from "./EstablecerSufijos";
import { useApi, TipoLock } from "ApiHooks";
import Select from 'react-select';

enum ArticulosAMostrar {
    Todos,
    OK,
    ConError
}


const camposOrder = [
    { value: 'LineaNro', label: 'Orden Carga' },
    { value: 'Codigo', label: 'Codigo Articulo' },
    { value: 'NCM', label: 'NCM' }
  ];


 function dynamicSort(property: string) {

    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a : any,b : any) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

export function ArticulosProcesoExcel() {
    let refGrilla = useRef<GrillaRef>(null);
    let ingresoSufijosRef = useRef<IngresoEstablecerSufijosAutomaticoRef>(null);
    let establecerSufijosRef = useRef<EstablecerSufijosRef>(null);
    let formikEstableciendoNcmRef = useRef<FormikProps<any>>(null);
    let history = useHistory();
    let { mostrarError } = useContext(AppContext);
    let api = useApi();
    let query = useQuery();
    let { interno } = useDecodedParams() as { interno: string };
    let [notFound, updateNotFound] = useState(false);
    let [articulosGrilla, updateArticulosGrilla] = useState<any>([]);
    
    const [selectedOption, setSelectedOption] = useState({ value: 'LineaNro', label: 'Orden Carga' });

    let [estado, updateEstado] = useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'cargarCaratula') {
            return { ...estado, cargando: false, caratula: accion.caratula, articulos: accion.articulos, articulosAMostrar: accion.articulosAMostrar};
        } else if (accion.tipo === 'setArticulosAMostrar') {
            return { ...estado, articulosAMostrar: accion.valor };
        } else if (accion.tipo === 'setMensajeSpinner') {
            return { ...estado, mensajeSpinner: accion.valor };
        } else if (accion.tipo === 'mostrarModalEstablecerNcm') {
            return { ...estado, estableciendoNcm: true, filasSeleccionadas: accion.filas };
        } else if (accion.tipo === 'esconderModalEstablecerNcm') {
            return { ...estado, estableciendoNcm: false, filasSeleccionadas: [] };
        } else if (accion.tipo === 'setCargando') {
            return { ...estado, cargando: accion.valor };
        }
        return { ...estado };
    }, {
        articulosAMostrar: ArticulosAMostrar.Todos, caratula: null, articulos: null, mensajeSpinner: null,
        estableciendoNcm: false, filasSeleccionadas: [], cargando: true
    });
    const cargar = useCallback(async (primeraVez: boolean) => {
        try {
            updateEstado({ tipo: 'setCargando', valor: true });
            let respuesta = await api.getCaratulaProcesoExcel(interno);
            if (!respuesta) {
                updateNotFound(true);
                return;
            }
            let articulosAMostrar = ArticulosAMostrar.Todos;
            if (primeraVez) {
                if (query.get('mostrar') === 'error') {
                    articulosAMostrar = ArticulosAMostrar.ConError;
                } else if (query.get('mostrar') === 'ok') {
                    articulosAMostrar = ArticulosAMostrar.OK;
                }
            } else {
                articulosAMostrar = estado.articulosAMostrar;
            }
            updateEstado({ tipo: 'cargarCaratula', caratula: respuesta.Caratula, articulos: respuesta.Articulos ?? [], articulosAMostrar: articulosAMostrar });
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al cargar caratula proceso excel', error);
                mostrarError('Hubo un error al cargar la caratula del Proceso Excel');
            }
        }
        //eslint-disable-next-line 
    }, []);
    useEffect(() => {
        cargar(true);
    }, [cargar]);
    useEffect(() => {
        let nuevosArticulosGrilla = Array.from(estado.articulos ?? []).sort(dynamicSort(selectedOption!.value));
        if (estado.articulosAMostrar === ArticulosAMostrar.OK) {
            nuevosArticulosGrilla = nuevosArticulosGrilla.filter((art: any) => art.EstadoArticulo === EstadoArticuloProcesoExcel.OK).sort(dynamicSort(selectedOption.value));
        } else if (estado.articulosAMostrar === ArticulosAMostrar.ConError) {
            nuevosArticulosGrilla = nuevosArticulosGrilla.filter((art: any) => art.EstadoArticulo !== EstadoArticuloProcesoExcel.OK).sort(dynamicSort(selectedOption.value));
        }
        updateArticulosGrilla(nuevosArticulosGrilla);
    }, [estado.caratula, estado.articulos, estado.articulosAMostrar, selectedOption]);

    let campos = [{ propiedad: 'LineaNro', clave: true, visible: false },
    { titulo: 'Nro. Articulo', propiedad: 'Codigo' }, { titulo: 'Descripción Catalogo', propiedad: 'Descripcion' },
    { titulo: 'Fob Total', propiedad: 'FobTotal', tipo: TipoCampo.Number },
    { titulo: 'NCM', propiedad: 'NCM' }, { titulo: 'Sufijos', propiedad: 'Sufijos' },
    {
        titulo: 'Estado', propiedad: 'EstadoArticulo', plantillaFormato: (valor: any) => {
            switch (valor) {
                case EstadoArticuloProcesoExcel.OK:
                    return 'OK';
                case EstadoArticuloProcesoExcel.ErrorNcm:
                    return 'NCM Invalido';
                case EstadoArticuloProcesoExcel.ErrorSufijos:
                    return 'Sufijo invalido';
                case EstadoArticuloProcesoExcel.ErrorFaltaCantEst:
                    return 'Falta cantidad estadistica';
                case EstadoArticuloProcesoExcel.ErrorCantEstNoCoincide:
                    return 'Cantidad declarada y cantidad estadistica no coinciden';
                case EstadoArticuloProcesoExcel.ErrorNoExisteEnCatalogo:
                    return 'No existe en catalogo';
                case EstadoArticuloProcesoExcel.ErrorFaltaUnidadDeclarada:
                    return 'Falta unidad declarada';
                case EstadoArticuloProcesoExcel.ErrorArticuloNoValidado:
                    return 'No se validó el articulo';
            }
            return '';
        }
    }];
    async function exportarAExcel() {
        try {
            updateEstado({ tipo: 'setMensajeSpinner', valor: 'Generando Excel...' });
            let { fileName, excel } = await api.reporteArticulosProcesoExcel(interno, estado.articulosAMostrar);
            updateEstado({ tipo: 'setMensajeSpinner', valor: null });
            FileSaver.saveAs(excel, fileName);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error(error);
                mostrarError('Error al exportar reporte articulos a Excel');
            }
            if (!api.isUnmounted()) {
                updateEstado({ tipo: 'setMensajeSpinner', accion: null });
            }
        }
    }
    async function establecerNcm(values: { Ncm: string }) {
        try {
            let puedeModificar = await api.obtenerLock(TipoLock.CaratulaProcesoExcel, interno);
            if (puedeModificar) {
                await api.establecerNcmArticulosProcesoExcel(interno, estado.filasSeleccionadas, values.Ncm);
                await api.eliminarLock(TipoLock.CaratulaProcesoExcel, interno);
                updateEstado({ tipo: 'esconderModalEstablecerNcm' });
                await cargar(false);
            } else {
                mostrarError('No se puede modificar la caratula porque otro usuario la está modificando');
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al establecer ncm', error);
                mostrarError('Error al establecer ncm');
            }
        }
    }
    async function establecerSufijos(nrosLinea: any[], ncm: any, sufijosArticulo: string[]) {
        try {
            updateEstado({ tipo: 'setMensajeSpinner', valor: 'Validando NCM...' });
            let ncmValidado = await api.validarNcmCompleto(ncm);
            updateEstado({ tipo: 'setMensajeSpinner', valor: null });
            if (ncmValidado) {
                ingresoSufijosRef.current!.mostrar(ncm, sufijosArticulo).then(async sufijosNuevos => {
                    try {
                        updateEstado({ tipo: 'setMensajeSpinner', valor: 'Estableciendo sufijos...' });
                        let puedeModificar = await api.obtenerLock(TipoLock.CaratulaProcesoExcel, interno);
                        if (puedeModificar) {
                            let { exito, error } = await api.establecerSufijosAutomaticoProcesoExcel(interno,
                                nrosLinea, sufijosNuevos);
                            if (exito) {
                                updateEstado({ tipo: 'setMensajeSpinner', valor: null });
                                await cargar(false);
                            } else {
                                mostrarError(error as string);
                            }
                        } else {
                            updateEstado({ tipo: 'setMensajeSpinner', valor: null });
                            mostrarError('No se puede modificar la caratula porque otro usuario la está modificando');
                        }
                    } catch (error) {
                        if (!api.isCancel(error)) {
                            console.error('Error al establecer sufijos', error);
                            mostrarError('Error al establecer sufijos');
                        }
                        if (!api.isUnmounted()) {
                            updateEstado({ tipo: 'setMensajeSpinner', valor: null });
                        }
                    }
                }).catch(() => { });
            } else {
                mostrarError('Los artículos tienen NCM invalido. Utilice la función Establecer Sufijos Manual');
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al validar NCM', error);
                mostrarError('Error al validar NCM');
            }
            if (!api.isUnmounted()) {
                updateEstado({ tipo: 'setMensajeSpinner', valor: null });
            }
        }
    }
    async function establecerSufijosManual(nrosLinea: any[], sufijos: { codigo: string, valor: string | null | undefined }[]) {
        try {
            updateEstado({ tipo: 'setMensajeSpinner', valor: 'Estableciendo sufijos...' });
            let puedeModificar = await api.obtenerLock(TipoLock.CaratulaProcesoExcel, interno);
            if (puedeModificar) {
                let dictSufijos: any = {};
                for (let item of sufijos) {
                    dictSufijos[item.codigo] = item.valor;
                }
                let { exito, error } = await api.establecerSufijosManualProcesoExcel(interno, nrosLinea, dictSufijos);
                if (exito) {
                    updateEstado({ tipo: 'setMensajeSpinner', valor: null });
                    await cargar(false);
                } else {
                    mostrarError(error as string);
                }
            } else {
                updateEstado({ tipo: 'setMensajeSpinner', valor: null });
                mostrarError('No se puede modificar la caratula porque otro usuario la está modificando');
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al establecer sufijos', error);
                mostrarError('Error al establecer sufijos');
            }
            if (!api.isUnmounted()) {
                updateEstado({ tipo: 'setMensajeSpinner', valor: null });
            }
        }
    }


    function handleChange(e : any) {
        console.log("Fruit Selected!!");
        setSelectedOption(e);
      }

return notFound ? <NotFoundComponent></NotFoundComponent> : <>
        <h2>Proceso Excel</h2>
        <h4>Caratula {interno}</h4>
        <BlockUi blocking={estado.cargando}>
            <Form>
                <div>
                    <Form.Check type="radio" id="radioTodos" name="articulosAMostrar" label="Todos" custom
                        checked={estado.articulosAMostrar === ArticulosAMostrar.Todos} inline
                        onChange={(e: any) => {
                            if (e.target.checked) {
                                updateEstado({ tipo: 'setArticulosAMostrar', valor: ArticulosAMostrar.Todos });
                            }
                        }} />

                    <Form.Check type="radio" id="radioOK" name="articulosAMostrar" label="Completos" custom
                        checked={estado.articulosAMostrar === ArticulosAMostrar.OK} inline
                        onChange={(e: any) => {
                            if (e.target.checked) {
                                history.push('/procesoExcel/caratulas/' + encodeURIComponent(interno) + '/articulos?mostrar=ok');
                                updateEstado({ tipo: 'setArticulosAMostrar', valor: ArticulosAMostrar.OK });
                            }
                        }} />


                    <Form.Check type="radio" id="radioConError" name="articulosAMostrar" label="Incompletos" custom
                        checked={estado.articulosAMostrar === ArticulosAMostrar.ConError} inline
                        onChange={(e: any) => {
                            if (e.target.checked) {
                                history.push('/procesoExcel/caratulas/' + encodeURIComponent(interno) + '/articulos?mostrar=error');
                                updateEstado({ tipo: 'setArticulosAMostrar', valor: ArticulosAMostrar.ConError });
                            }
                        }} />
                                    <div style={{width: '300px'}}>
                                        <br/>
                                        <label>Ordenar por:</label>
                                        <Select value={selectedOption} name="ordenadopor" options={camposOrder}  onChange={handleChange} label="Ordenar por:"/>
                                    </div>

                </div>
            </Form>
            <Button variant="danger" className="my-2 mr-2" onClick={() => history.push('/procesoExcel/caratulas/' + encodeURIComponent(interno))}>Volver</Button>
            <Button variant="success" className="my-2 mr-2" onClick={exportarAExcel}>
                <FontAwesomeIcon icon={faFileExcel} />
                <span>Exportar a Excel</span>
            </Button>
            <Button className="my-2 mr-2" onClick={() => {
                let filasSeleccionadas = refGrilla.current?.getClavesSeleccionadas();
                if (filasSeleccionadas && filasSeleccionadas.length > 0) {
                    updateEstado({ tipo: 'mostrarModalEstablecerNcm', filas: filasSeleccionadas });
                } else {
                    mostrarError('Debe seleccionar una fila para establecer el NCM');
                }
            }}>Establecer NCM</Button>
            <Button className="my-2 mr-2" onClick={() => {
                let filasSeleccionadas = refGrilla.current?.getClavesSeleccionadas();
                if (filasSeleccionadas && filasSeleccionadas.length > 0) {
                    let ncms = new Set();
                    let sufijosArticulo: string[] = [];
                    for (let art of estado.articulos) {
                        if (filasSeleccionadas.includes(art.LineaNro)) {
                            let ncm = art.EstadoNCM === EstadoNcmArticuloProcesoExcel.Catalogo ? art.NCMCatalogo : art.NCM;
                            if (!isNullOrWhiteSpace(ncm)) {
                                ncms.add(ncm);
                            }
                            sufijosArticulo.push(art.Sufijos);
                        }
                    }
                    if (ncms.size === 0) {
                        mostrarError('Los artículos no tienen NCM. Utilice la función Establecer Sufijos Manual');
                    } else if (ncms.size > 1) {
                        mostrarError('Los artículos tienen más de un NCM. Utilice la función Establecer Sufijos Manual');
                    } else {
                        //eslint-disable-next-line
                        let [ncm, ...otros] = ncms;
                        establecerSufijos(filasSeleccionadas, ncm, sufijosArticulo);
                    }
                } else
                    mostrarError('Debe seleccionar una fila para establecer los sufijos');
            }
            }>Establecer Sufijos</Button>
            <Button className="my-2 mr-2" onClick={() => {
                let filasSeleccionadas = refGrilla.current?.getClavesSeleccionadas();
                if (filasSeleccionadas && filasSeleccionadas.length > 0) {
                    establecerSufijosRef.current!.mostrar()
                        .then(sufijos => establecerSufijosManual(filasSeleccionadas as any[], sufijos)).catch(() => { });
                } else {
                    mostrarError('Debe seleccionar una fila para establecer los sufijos');
                }
            }}>Establecer Sufijos Manual</Button>

            <GrillaSync datos={articulosGrilla} campos={campos} ref={refGrilla} seleccionMultiple
                eventoDetalle={item => history.push('/procesoExcel/caratulas/' + encodeURIComponent(interno) + '/articulos/' + encodeURIComponent(item.LineaNro))} />
        </BlockUi>
        
        

        <MyModal show={!!estado.mensajeSpinner}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="lead">{estado.mensajeSpinner}</p>
                    <div className="loader-container">
                        <Loader type="ball-spin-fade-loader" active></Loader>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        api.cancelCurrentTokens();
                    }}>Cancelar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={estado.estableciendoNcm} onHide={() => updateEstado({ tipo: 'esconderModalEstablecerNcm' })}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    Establecer NCM
                </Modal.Header>
                <Formik innerRef={formikEstableciendoNcmRef} onSubmit={establecerNcm} validationSchema={Yup.object({
                    'Ncm': Yup.string().nullable().required('Debe ingresar el NCM'),
                })} initialValues={{ Ncm: '' }}>
                    {({ isSubmitting, submitForm }) => <>
                        <Modal.Body>
                            <MyForm>
                                <Form.Group>
                                    <MyAsyncSelect name="Ncm" label="NCM Nuevo"
                                        loadOptions={api.cargarNcm()}></MyAsyncSelect>
                                </Form.Group>
                            </MyForm>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={() => updateEstado({ tipo: 'esconderModalEstablecerNcm' })}>
                                Cancelar
                            </Button>
                            <Button disabled={isSubmitting} onClick={submitForm}>
                                Ingresar
                            </Button>
                        </Modal.Footer>
                    </>}
                </Formik>

            </Modal.Dialog>
        </MyModal>
        <IngresoEstablecerSufijosAutomatico ref={ingresoSufijosRef}></IngresoEstablecerSufijosAutomatico>
        <EstablecerSufijos ref={establecerSufijosRef}></EstablecerSufijos>
    </>;
}