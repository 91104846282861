import React, { useContext, useMemo } from "react";
import { createMemoryHistory } from "history";
import { isNullOrWhiteSpace } from "Utilidades";
import { Router } from "react-router-dom";
import { RedirectEventCallbackContext } from "SintiaHooks";

export default function InnerRouter(props: React.PropsWithChildren<{ initialRoute?: string }>) {
    let { runAfterUserConfirmation } = useContext(RedirectEventCallbackContext);
    let history = useMemo(() => createMemoryHistory({
        initialEntries: [isNullOrWhiteSpace(props.initialRoute) ? '/' : props.initialRoute!],
        initialIndex: 0,
        getUserConfirmation: (_, routerCallback) => runAfterUserConfirmation(routerCallback)
    }), []); //eslint-disable-line
    return <Router history={history}>
        {props.children}
    </Router>
}