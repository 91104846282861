import React, { useState } from 'react';
import { MyModal } from '../../../MyModal';
import { Button, Form, Modal } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { MyForm, MySelect, SelectOption } from '../../../FormikHooks';
import { useApi } from 'ApiHooks';
import { convertirDatosGenericosAOptionIdDescripcion } from "Utilidades";

enum EstadoModal {
    Cerrado,
    Cargando,
    Abierto
}

type Mapeos = Array<{ valor: string, codigo: string }>;

export type IngresoMapeosRef = {
    mostrar: (valoresPaisAIngresar: string[], valoresUnidadAIngresar: string[], valoresEstadoMercAIngresar: string[]) =>
        Promise<{
            mapeosPais: Mapeos, mapeosUnidad: Mapeos, mapeosEstadoMerc: Mapeos
        }>
}

export const IngresoMapeos = React.forwardRef((props: any, ref) => {
    let api = useApi();
    let formikRef = React.useRef<FormikProps<any>>(null);

    const [listaPaises, updateListaPaises] = useState<SelectOption[]>([]);
    const [listaUnidades, updatelistaUnidades] = useState<SelectOption[]>([]);
    const [listaEstados, updatelistaEstados] = useState<SelectOption[]>([]);


    React.useEffect(() => {

        async function cargar() {
            try {
            
                let paises = await api.getPaises().then(convertirDatosGenericosAOptionIdDescripcion);
                updateListaPaises(paises);

                let unidades = await api.getUnidades().then(convertirDatosGenericosAOptionIdDescripcion);
                updatelistaUnidades(unidades);

                let estados = await api.getEstadosMercaderia().then(convertirDatosGenericosAOptionIdDescripcion);
                updatelistaEstados(estados)
                //updateCargando(false);

             //   updateBusqueda({ tipo: 'fechaDesde', valor: hoy })
             //   updateBusqueda({ tipo: 'fechaHasta', valor: hoy })

            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar datos', error);
                }
            }
        }
        cargar();
        // eslint-disable-next-line
    }, [])
    


    let [estado, updateEstado] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'cerrar') {
            return { ...estado, estadoModal: EstadoModal.Cerrado };
        } else if (accion.tipo === 'iniciar') {
            return {
                ...estado, estadoModal: EstadoModal.Abierto, resolve: accion.resolve,
                reject: accion.reject, valoresPaisAIngresar: accion.valoresPaisAIngresar,
                valoresUnidadAIngresar: accion.valoresUnidadAIngresar,
                valoresEstadoMercAIngresar: accion.valoresEstadoMercAIngresar,
                valoresPaisIngresados: [], valoresUnidadIngresados: [],
                valoresEstadoMercIngresados: []
            }
        } else if (accion.tipo === 'ingresoMapeoPais') {
            let valores = estado.valoresPaisIngresados.slice();
            valores.push(accion.mapeo);
            return { ...estado, valoresPaisIngresados: valores };
        } else if (accion.tipo === 'ingresoMapeoUnidad') {
            let valores = estado.valoresUnidadIngresados.slice();
            valores.push(accion.mapeo);
            return { ...estado, valoresUnidadIngresados: valores };
        } else if (accion.tipo === 'ingresoMapeoEstadoMerc') {
            let valores = estado.valoresEstadoMercIngresados.slice();
            valores.push(accion.mapeo);
            return { ...estado, valoresEstadoMercIngresados: valores };
        } else {
            return { ...estado };
        }
    }, { estadoModal: EstadoModal.Cerrado });



    React.useEffect(() => {
        if (estado.estadoModal === EstadoModal.Abierto
            && estado.valoresPaisIngresados.length === estado.valoresPaisAIngresar.length
            && estado.valoresUnidadIngresados.length === estado.valoresUnidadAIngresar.length
            && estado.valoresEstadoMercIngresados.length === estado.valoresEstadoMercAIngresar.length) {
            estado.resolve({
                mapeosPais: estado.valoresPaisIngresados, mapeosUnidad: estado.valoresUnidadIngresados,
                mapeosEstadoMerc: estado.valoresEstadoMercIngresados
            });
            updateEstado({ tipo: 'cerrar' });
        }
      
        //eslint-disable-next-line 
    }, [estado.estadoModal, estado.valoresPaisIngresados, estado.valoresUnidadIngresados,
    estado.valoresEstadoMercIngresados, estado.valoresPaisAIngresar, estado.valoresUnidadAIngresar,
    estado.valoresEstadoMercAIngresar]);


    React.useEffect(() => {
        formikRef.current?.setFieldValue('IngresoPais', '');
        formikRef.current?.setFieldTouched('IngresoPais', false);

        formikRef.current?.setFieldValue('IngresoUnidad', '');
        formikRef.current?.setFieldTouched('IngresoUnidad', false);

        formikRef.current?.setFieldValue('IngresoEstado', '');
        formikRef.current?.setFieldTouched('IngresoEstado', false);


    }, [estado.estadoModal, estado.valoresPaisIngresados, estado.valoresUnidadIngresados,
    estado.valoresEstadoMercIngresados, estado.valoresPaisAIngresar, estado.valoresUnidadAIngresar,
    estado.valoresEstadoMercAIngresar]);



    React.useImperativeHandle(ref, () => ({
        mostrar: (valoresPaisAIngresar: string[], valoresUnidadAIngresar: string[], valoresEstadoMercAIngresar: string[]) => {
            return new Promise((resolve, reject) => {
                updateEstado({
                    tipo: 'iniciar', resolve: resolve, reject: reject,
                    valoresPaisAIngresar: valoresPaisAIngresar, valoresUnidadAIngresar: valoresUnidadAIngresar,
                    valoresEstadoMercAIngresar: valoresEstadoMercAIngresar
                });
            });
        }
    }));
    let cancelar = () => {
        if (estado.reject) {
            estado.reject();
        }
        updateEstado({ tipo: 'cerrar' });
    };
    let renderModalDialog = ()  =>  {
        if (estado.estadoModal !== EstadoModal.Abierto) {
            return (<Modal.Dialog></Modal.Dialog>)
        }
        if (estado.valoresPaisIngresados.length < estado.valoresPaisAIngresar.length) {
            let indice = estado.valoresPaisIngresados.length;
            let schema = Yup.object({
                IngresoPais: Yup.string().nullable().required('Debe seleccionar el pais')
            });
            return (<Modal.Dialog>
                <Modal.Header closeButton>
                    <Modal.Title>Ingresar Mapeo Pais</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik enableReinitialize  initialValues={{ IngresoPais: '' }} validationSchema={schema}
                        onSubmit={(values: any, formikBag: FormikHelpers<any>) => {
                            updateEstado({
                                tipo: 'ingresoMapeoPais', mapeo: {
                                    valor: estado.valoresPaisAIngresar[indice], codigo: values.IngresoPais
                                }
                                
                            });

                            if (estado.estadoModal !== EstadoModal.Cerrado) {
                                formikBag.setSubmitting(false);

                            }
                            
                        }} innerRef={formikRef}>
                        <MyForm>
                            <Form.Group controlId="valorExcel">
                                <Form.Label>Valor en Excel</Form.Label>
                                <Form.Control disabled value={estado.valoresPaisAIngresar[indice]}></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <MySelect id='IngresoPais' name="IngresoPais" label="Pais correspondiente" options={listaPaises} getOptionLabel={(option: SelectOption) => option.value + ' - ' + option.label} />
                            </Form.Group>
                        </MyForm>
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={cancelar}>
                        Cancelar
                    </Button>
                    <Button type="button" variant="primary" onClick={() => {
                        formikRef.current?.handleSubmit();
                    }}>Ingresar</Button>
                </Modal.Footer>
            </Modal.Dialog>);
        } else if (estado.valoresUnidadIngresados.length < estado.valoresUnidadAIngresar.length) {
            let indice = estado.valoresUnidadIngresados.length;
            let schema = Yup.object({
                IngresoUnidad: Yup.string().nullable().required('Debe seleccionar la unidad')
            });
            return (<Modal.Dialog>
                <Modal.Header closeButton>
                    <Modal.Title>Ingresar Mapeo Unidad</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik enableReinitialize  initialValues={{ IngresoUnidad: '' }} validationSchema={schema}
                        onSubmit={(values: any, formikBag: FormikHelpers<any>) => {
                            updateEstado({
                                tipo: 'ingresoMapeoUnidad', mapeo: {
                                    valor: estado.valoresUnidadAIngresar[indice], codigo: values.IngresoUnidad
                                }
                            });
                            if (estado.estadoModal !== EstadoModal.Cerrado) {
                                formikBag.setSubmitting(false);
                            }
                        }} innerRef={formikRef}>
                        <MyForm>
                            <Form.Group controlId="valorExcel">
                                <Form.Label>Valor en Excel</Form.Label>
                                <Form.Control disabled value={estado.valoresUnidadAIngresar[indice]}></Form.Control>
                            </Form.Group>
                            <Form.Group>

                                <MySelect id='IngresoUnidad' name="IngresoUnidad" label="Unidad correspondiente" options={listaUnidades} getOptionLabel={(option: SelectOption) => option.value + ' - ' + option.label} />                            

                            </Form.Group>
                        </MyForm>
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={cancelar}>
                        Cancelar
                    </Button>
                    <Button type="button" variant="primary" onClick={() => {
                        formikRef.current?.handleSubmit();
                    }}>Ingresar</Button>
                </Modal.Footer>
            </Modal.Dialog>);
        } else if (estado.valoresEstadoMercIngresados.length < estado.valoresEstadoMercAIngresar.length) {
            let indice = estado.valoresEstadoMercIngresados.length;
            let schema = Yup.object({
                IngresoEstado: Yup.string().nullable().required('Debe seleccionar el estado de mercadería')
            });
            return (<Modal.Dialog>
                <Modal.Header closeButton>
                    <Modal.Title>Ingresar Mapeo Estado Mercadería</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik enableReinitialize  initialValues={{ IngresoEstado: '' }} validationSchema={schema}
                        onSubmit={(values: any, formikBag: FormikHelpers<any>) => {
                            updateEstado({
                                tipo: 'ingresoMapeoEstadoMerc', mapeo: {
                                    valor: estado.valoresEstadoMercAIngresar[indice], codigo: values.IngresoEstado
                                }
                            });
                            if (estado.estadoModal !== EstadoModal.Cerrado) {
                                formikBag.setSubmitting(false);
                            }
                        }} innerRef={formikRef}>
                        <MyForm>
                            <Form.Group controlId="valorExcel">
                                <Form.Label>Valor en Excel</Form.Label>
                                <Form.Control disabled value={estado.valoresEstadoMercAIngresar[indice]}></Form.Control>
                            </Form.Group>
                            <Form.Group>

                                <MySelect id='IngresoEstado' name="IngresoEstado" label="Estado mercadería correspondiente" options={listaEstados} getOptionLabel={(option: SelectOption) => option.value + ' - ' + option.label} />                            

                            </Form.Group>
                        </MyForm>
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={cancelar}>
                        Cancelar
                    </Button>
                    <Button type="button" variant="primary" onClick={() => {
                        formikRef.current?.handleSubmit();
                    }}>Ingresar</Button>
                </Modal.Footer>
            </Modal.Dialog>);
        }
    };
    return (<MyModal show={estado.estadoModal !== EstadoModal.Cerrado}
        onHide={cancelar}>
        {renderModalDialog()}
    </MyModal>);
});