import { Formik } from 'formik';
import React, { useState } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { MyForm, MyFormControlWithIcon, LoginPasswordControl } from './FormikHooks';
import { loginClient, AppContext } from './App';
import { tokenStorage } from './TokenStorage'
import { faUser } from '@fortawesome/free-solid-svg-icons';
import loginPanelBg from './Imagenes/login_panel_transparente.png';
import BlockUi from 'react-block-ui';
import './App.css';


export function Login() {

    const [cargando, setCargando] = useState(false);
    
    let [mensajeError, setMensajeError] = useState('');
    let { iniciarSesion, cerrarSesion } = React.useContext(AppContext);
    let schema = Yup.object({
        userName: Yup.string().nullable().required('Debe ingresar el nombre de usuario'),
        password: Yup.string().nullable().required('Debe ingresar la contraseña')
    });
    function login(values: { userName: string; password: string; }) {
        setCargando(true);
        return loginClient.post('/Account/Login', {
            UserName: values.userName, Password: values.password,
            Proyecto: 2
        }).then(respuesta => {
            tokenStorage.setUserName(values.userName);
            tokenStorage.setPassword(values.password);
            tokenStorage.setAccessToken(respuesta.data.AccessToken);
            tokenStorage.setRefreshToken(respuesta.data.RefreshToken);
            iniciarSesion(respuesta.data.AccessToken);
            setCargando(false);
        }).catch(error => {

            setCargando(false);
            console.log(error);
            if (error.response && error.response.status === 400) {
                let mensajesError: any[] = [];
                Object.keys(error.response.data).forEach(key => {
                    error.response.data[key].forEach((mensaje: any) => mensajesError.push(mensaje));
                });
                setMensajeError(mensajesError.join('\r\n'));
                cerrarSesion();
            } else {
                console.error('Error al iniciar sesion', error);
                setMensajeError('Hubo un error al iniciar sesión');
                cerrarSesion();
            }
        });
    }
    function submit(values: { userName: string; password: string; }) {
        // mantenimientoClient.get('/Mantenimiento').then(respuesta => {
        //     if (respuesta.data.EnMantenimiento) {
        //         cerrarSesion();
        //         setMensajeError('El Sintia está en mantenimiento. Intente nuevamente más tarde');
        //     } else {
        //         login(values);
        //     }
        // });
        setCargando(false);
        return login(values);
    }
    let initialValues = { userName: tokenStorage.getUserName(), password: tokenStorage.getPassword() };
    return (<div className='mi-div'>
        <img src={loginPanelBg} style={{ width: '100%', height: 'auto', marginTop: '1rem' }} alt="Sintia NET" title="Sintia NET"></img>
        <h2 className="text-center">Iniciar sesión</h2>
        {mensajeError && (<Alert variant="danger">{mensajeError}</Alert>)}


        <BlockUi blocking={cargando}>
            <Formik initialValues={initialValues}
                validationSchema={schema}
                onSubmit={submit}>
                <MyForm className="loginForm">
                    <Form.Group>
                        <MyFormControlWithIcon type="text" icon={faUser} placeholder="Nombre de usuario"
                            label="Nombre de usuario" name="userName"></MyFormControlWithIcon>
                    </Form.Group>
                    <Form.Group>
                        <LoginPasswordControl placeholder="Contraseña" label="Contraseña" name="password"/>
                    </Form.Group>
                    <div className="d-flex flex-wrap justify-content-center">
                        <Button type="submit">Iniciar sesión</Button>
                        <div style={{ width: '100%', margin: '5px' }}></div>
                        <a href="http://www.alpha2000.com.ar/sistemas/clientes/Sintia4/SetupSintia4.exe">Descargue la versión de escritorio</a>
                    </div>
                </MyForm>
            </Formik >
        </BlockUi>



    </div >);
}