import React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Alert, Button, Form, Container } from "react-bootstrap";
import { convertirDatosGenericosAOption, isInRole, isNullOrWhiteSpace, optionLabelConCodigo } from "../../../Utilidades";
import { AppContext, NotFoundComponent } from "../../../App";
import { MyAsyncSelect, MyForm, MyFormControl, MySelect } from "../../../FormikHooks";
import { DateTime } from "luxon";
import { useDecodedParams } from "Utilidades";
import { useApi } from "ApiHooks";

export function CrearEditarAviso() {
    let { mostrarError, userInfo } = React.useContext(AppContext);
    let history = useHistory();
    let formikRef = React.useRef<FormikProps<any>>(null);
    let { id } = useDecodedParams();
    let [cargando, updateCargando] = React.useState(true);
    let [aviso, updateAviso] = React.useState<any>({});
    let [mensajeErrorAlert, updateMensajeErrorAlert] = React.useState('');
    let api = useApi();
    let [notFound, updateNotFound] = React.useState(false);
    let schema = Yup.object({
        'CatalogoId': Yup.string().nullable(),
        'CodigoArticulo': Yup.string().nullable(),
        'CodigoSubregimen': Yup.string().nullable(),
        'CodigoPaisOrigen': Yup.string().nullable(),
        'Ncm': Yup.string().nullable(),
        'TipoAvisoId': Yup.string().nullable(),
        'TextoAvisoId': Yup.string().nullable().required('Debe seleccionar el texto del aviso')
    });
    async function cargarCatalogos() {
        try {
            let respuesta = await api.getCatalogos();
            return respuesta.map((catalogo: any) => ({ value: catalogo.Codigo, label: catalogo.Descripcion }));
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al cargar catalogos', error);
                mostrarError('Error al cargar los catalogos');
            }
            return [];
        }
    }
    async function cargarTextosAviso() {
        try {
            let respuesta = await api.getTextosAviso();
            return respuesta.map((item: any) => ({ value: item.Id, label: item.Texto }));
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al cargar textos aviso', error);
                mostrarError('Error al cargar los textos aviso');
            }
            return [];
        }
    }
    async function cargarTiposAviso() {
        try {
            let respuesta = await api.getTiposAviso();
            return respuesta.map((item: any) => ({ value: item.Id, label: item.Nombre }));
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al cargar tipos aviso', error);
                mostrarError('Error al cargar los tipos aviso');
            }
            return [];
        }
    }
    React.useEffect(() => {
        async function cargar() {
            try {
                let respuesta = await api.getAvisos(id);
                if (respuesta.length === 0) {
                    updateNotFound(true);
                    return;
                }
                updateAviso(respuesta[0]);
                formikRef.current?.resetForm({
                    values: {
                        'CatalogoId': respuesta[0].CatalogoId,
                        'CodigoArticulo': respuesta[0].CodigoArticulo,
                        'Ncm': respuesta[0].Ncm,
                        'CodigoSubregimen': respuesta[0].CodigoSubregimen,
                        'CodigoPaisOrigen': respuesta[0].CodigoPaisOrigen,
                        'TipoAvisoId': respuesta[0].TipoAvisoId,
                        'TextoAvisoId': respuesta[0].TextoAvisoId,
                    }
                });
                updateCargando(false);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar aviso', error);
                    mostrarError('Error al cargar aviso');
                }
            }
        }
        if (id) {
            cargar();
        } else {
            updateCargando(false);
        }
        //eslint-disable-next-line 
    }, []);
    async function submit(values: any, actions: FormikHelpers<any>) {
        if (isNullOrWhiteSpace(values.CatalogoId) && isNullOrWhiteSpace(values.CodigoArticulo) &&
            isNullOrWhiteSpace(values.Ncm) && isNullOrWhiteSpace(values.CodigoPaisOrigen) &&
            isNullOrWhiteSpace(values.CodigoSubregimen)) {
            updateMensajeErrorAlert('Debe ingresar alguno de los siguientes valores: Catalogo, Codigo Articulo, Posición, Pais Origen, Subregimen');
            actions.setSubmitting(false);
        } else {
            let nuevoAviso;
            if (id) {
                nuevoAviso = {
                    ...values,
                    Id: aviso.Id,
                    NroClienteAlpha: userInfo.nroClienteAlpha,
                    EmpresaId: userInfo.empresaActual,
                    CreadoPor: aviso.CreadoPor,
                    CreadoEl: aviso.CreadoEl,
                    ModificadoPor: userInfo.claims.nameid,
                    ModificadoEl: DateTime.local().toISO()
                };
            } else {
                nuevoAviso = {
                    ...values,
                    NroClienteAlpha: userInfo.nroClienteAlpha,
                    EmpresaId: userInfo.empresaActual,
                    CreadoPor: userInfo.claims.nameid,
                    CreadoEl: DateTime.local().toISO(),
                    ModificadoPor: userInfo.claims.nameid,
                    ModificadoEl: DateTime.local().toISO()
                };
            }
            try {
                let { exito, error } = await api.insertAviso(nuevoAviso);
                if (exito) {
                    history.replace('/avisos');
                } else {
                    updateMensajeErrorAlert(error);
                }
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al insertar aviso', error);
                    updateMensajeErrorAlert('Error al guardar aviso');
                }
            }
        }
    }
    let esOperador = isInRole(userInfo, 'Operador');
    return notFound ? (<NotFoundComponent></NotFoundComponent>) :
        (<>
            <Container>
                <h2>{id ? 'Modificar Aviso' : 'Crear Aviso'}</h2>
                {mensajeErrorAlert && (<Alert variant="danger">{mensajeErrorAlert}</Alert>)}
                <Formik initialValues={{}} innerRef={formikRef} validationSchema={schema} onSubmit={submit}>
                    <MyForm blocking={cargando}>
                        <Form.Group>
                            <MySelect autoFocus name="CatalogoId" label="Catalogo" isDisabled={esOperador}
                                options={cargarCatalogos} getOptionLabel={optionLabelConCodigo}></MySelect>
                        </Form.Group>
                        <Form.Group>
                            <MyFormControl disabled={esOperador} name="CodigoArticulo" label="Codigo Articulo" type="text"></MyFormControl>
                        </Form.Group>
                        <Form.Group>
                            <MySelect name="CodigoSubregimen" isDisabled={esOperador} label="Subregimen" getOptionLabel={optionLabelConCodigo}
                                options={() => api.getSubregimenes().then(convertirDatosGenericosAOption)}></MySelect>
                        </Form.Group>
                        <Form.Group>
                            <MyAsyncSelect name="Ncm" label="Posición" isDisabled={esOperador}
                                loadOptions={api.cargarNcm()}></MyAsyncSelect>
                        </Form.Group>
                        <Form.Group>
                            <MySelect name="CodigoPaisOrigen" label="Pais Origen" isDisabled={esOperador}
                                options={() => api.getPaises().then(convertirDatosGenericosAOption)}></MySelect>
                        </Form.Group>
                        <Form.Group>
                            <MySelect name="TipoAvisoId" label="Tipo" isDisabled={esOperador} options={cargarTiposAviso}></MySelect>
                        </Form.Group>
                        <Form.Group>
                            <MySelect name="TextoAvisoId" label="Texto" isDisabled={esOperador} options={cargarTextosAviso}></MySelect>
                        </Form.Group>
                        <Button variant="danger" className="mb-2" onClick={() => history.goBack()}>Cancelar</Button>
                        <Button type="submit" className="ml-2 mb-2" disabled={esOperador}>Guardar</Button>
                    </MyForm>
                </Formik>
            </Container>
        </>);
}