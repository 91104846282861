import React, { useEffect, useRef,  useState } from "react";
import { Button, Container, Form, Col, Table, Alert } from "react-bootstrap";
//import { BaseSelect } from "BaseSelect";
//import styled from "styled-components";
import { MyForm, MyFormControl, MySelect, SelectOption } from "../../FormikHooks";
import * as Yup from "yup";
//import { useApi } from "ApiHooks";
import { useAdvAPI } from './AdvancedApi';
import { useApi } from "ApiHooks";
import { convertirDatosGenericosAOptionIdDescripcion } from "Utilidades";
import { useHistory } from "react-router";
import { LoginAFIP, LoginAFIPRef } from './sesionafip';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, FormikProps } from "formik";

/*
const CustomSelect = styled(BaseSelect)`
    width:100%;
`;
*/
export function BuscarCaratula() {

    let api = useAdvAPI();
    //let refGrilla = useRef<GrillaRef>(null);
    let history = useHistory();
    let apiSintia = useApi();
    let [optionsDespachantes, updateOptionsDespachantes] = useState<SelectOption[]>([]);
    let [optionsImportadores, updateOptionsImportadores] = useState<SelectOption[]>([]);
    let [optionsAduana, updateoptionsAduana] = useState<SelectOption[]>([]);
    //let [cargando, updateCargando] = useState(true);
    let [despachosNoResult, updateDespachosNoResult] = useState(false);
    let [resulado, updateREsulado] = useState([]);
    let loginAFIPRef = useRef<LoginAFIPRef>(null);






    let formikRef = React.useRef<FormikProps<any>>(null);
    let huboCambios = React.useRef<{ valor: boolean }>({ valor: false });
    const eventoHuboCambios = () => {
        huboCambios.current.valor = true;
    }

    let schema = Yup.object({
            'cuitDespachante': Yup.string().nullable().required('Debe seleccionar un despachante'),
            'cuitImpoExpo': Yup.string().nullable().optional(),
            'aduana': Yup.string().nullable().required('Debe seleccionar una aduana'),
            'fechaDesde': Yup.date().required('Debe ingresar Fecha Desde'),
            'fechaHasta': Yup.date().required('Debe ingresar Fecha Hasta')
        });
    



    var d = new Date();
    let hoy = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');


    useEffect(() => {
        async function cargar() {
            try {
                let despachantes = await apiSintia.getDespachantes();
                updateOptionsDespachantes(despachantes.map((item: any) => ({ value: item.CUIT, label: item.Nombre })));
                let importadores = await apiSintia.getImportadores();
                updateOptionsImportadores(importadores.map((item: any) => ({ value: item.CUIT, label: item.Nombre })));

                let aduana = await apiSintia.getAduanas().then(convertirDatosGenericosAOptionIdDescripcion);
                updateoptionsAduana(aduana)


                //updateCargando(false);

             //   updateBusqueda({ tipo: 'fechaDesde', valor: hoy })
             //   updateBusqueda({ tipo: 'fechaHasta', valor: hoy })

            } catch (error) {
                if (!apiSintia.isCancel(error)) {
                    console.error('Error al cargar caratulas', error);
                }
            }
        }
        cargar();
        //eslint-disable-next-line
    }, []);
    

    async function cargarDatos(pbusqueda: any) {
        
        if (pbusqueda.cuitDespachante) {

            /*let logueo = false;
            loginAFIPRef.current!.mostrar().finally(() => { logueo = true; })
            console.log(logueo);*/

            updateDespachosNoResult(false);
            let respuesta = await api.getDeclaracionesOficializadas(
                pbusqueda.fechaDesde,
                pbusqueda.fechaHasta,
                pbusqueda.cuitDespachante, 
                pbusqueda.cuitImpoExpo, 
                pbusqueda.aduana);


            // cuando FechaAVC es = "NO"
            // Iden
            if (respuesta.ListDespachosOficializados.length >0) {
                    let resultadoF = respuesta.ListDespachosOficializados.map((respuestan : any) => { if (respuestan.FechaAVC === "NO") respuestan.IdentificadorAVC = "";
                    return respuestan; });
                    updateREsulado(resultadoF);
                }
            else {
                updateREsulado([]);
                updateDespachosNoResult(true);
            }

        }
    }

        function GrillaDespachos(props: {
            despachos: any[],
            eventoRatificar: (item: any) => void,
            eventoAVC: (item: any) => void
        }) {
            return <>

                    <Table responsive className="text-nowrap middle-vertical-align table table-sm table-striped table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Despacho</th>
                                <th scope="col">Fecha Of</th>
                                <th scope="col">Impo Expo</th>
                                <th scope="col">Estado</th>
                                <th scope="col">Canal</th>
                                <th scope="col" className="text-right">A Pagar</th>
                                <th scope="col" className="text-right">A Garantizar</th>
                                <th scope="col">FechaAVC</th>
                                <th scope="col">Identif. AVC</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>{props.despachos.map((t: any, indice: number) =>
                        (
                            /*<tr className="cursor-pointer " key={t.Despacho} onClick={e => {
                                //let element = e.target as HTMLElement;
                                //ejecutar solamente cuando el target sea una celda de la tabla y no un botón o checkbox
                                /*if (element.nodeName === 'TD' || element.nodeName === 'TR') {
                                    props.eventoAbrir(t);
                                }*/
    //                        }}>*/}

                            <tr>
                                <th scope="row">{t.Despacho}</th>
                                <td>{t.FechaOficializacion}</td>
                                <td>{t.NombreImportador}</td>
                                <td>{t.Estado}</td>
                                <td>{t.Canal}</td>
                                <td className="text-right">{t.TotalAPagar}</td>
                                <td className="text-right">{t.TotalAGarantizar}</td>
                                <td>{t.FechaAVC}</td>
                                <td>{t.IdentificadorAVC}</td>
                                <td>
                                   {/*
                                    <Button className="btn btn-sm btn-success mr-1"  onClick={e => {
                                        props.eventoRatificar(t);
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}>Ratificar</Button>
                                    */}


                                    <Button className="btn btn-sm btn-info" onClick={e => {
                                        props.eventoAVC(t);
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}>Aviso de Carga</Button>
                                </td>

                            </tr>
                        ))}
                        </tbody>
                    </Table>

            </>;
        }

        let initialvalues = {
            cuitDespachante: '',
            cuitImpoExpo: '',
            aduana: '',
            fechaDesde: hoy,
            fechaHasta: hoy
        };

        function avidoDeCarga(item: any){
            history.push('/advanced/avisodecargapost/' + encodeURIComponent(item.Despacho) + '|' + formikRef.current?.values?.cuitDespachante);
        }


    return (
    <>
        <Container>
            <br/><p className="h3">Consulta de Declaraciones Oficializadas</p><br/>
            <Formik initialValues={initialvalues} validationSchema={schema} innerRef={formikRef} onSubmit={cargarDatos}>
                    <MyForm className="col-md-12">
                        <Form.Row className="col-md-12">
                            <Form.Group as={Col}>
                                <MySelect 
                                    name="cuitDespachante" label="Despachante"
                                    options={optionsDespachantes} onValueChange={eventoHuboCambios} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="col-md-12">
                            <Form.Group as={Col} className="col-md-3" >
                                <MyFormControl name="fechaDesde" type="date" label="Fecha Desde" ></MyFormControl>
                            </Form.Group>    
                            <Form.Group as={Col} className="col-md-3" >
                                <MyFormControl name="fechaHasta" type="date" label="Fecha Hasta" ></MyFormControl>
                            </Form.Group>
                            <Form.Group as={Col} className="col-md-6">
                                <MySelect 
                                    name="aduana" label="Aduana"
                                    options={optionsAduana} onValueChange={eventoHuboCambios} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Group className="col-md-12">
                                <MySelect 
                                    name="cuitImpoExpo" label="Importador"
                                    options={optionsImportadores} onValueChange={eventoHuboCambios} />
                                <br/>
                        </Form.Group>
                        <Form.Group className="col-md-12">
                            <Button type="submit" className="mb-2">Buscar</Button>
                        </Form.Group>
                        <Form.Group className="col-md-12">
                            {despachosNoResult && 
                                <Alert variant="warning">
                                    <FontAwesomeIcon icon={faExclamationTriangle}></FontAwesomeIcon>
                                        <span className="ml-3">No hay Declaraciones para los paremetros seleccionados</span>
                                </Alert>
                            }
                        </Form.Group>
                    </MyForm>
            </Formik>

            <div className="mt-2">
                        <GrillaDespachos despachos={resulado}
                            eventoRatificar={(item: any) => ({})}
                            eventoAVC={(item: any) => avidoDeCarga(item)}></GrillaDespachos>
                    </div>

        </Container>

        <LoginAFIP ref={loginAFIPRef} />    
    </>
    
    )
}