import React  from "react";
import { useHistory } from "react-router";
import bannerAdv from './Imagenes/banneradv.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import './style.advanced.css';
import styles from "./advanced.module.css";
import { faBox, faBoxes, faCheckCircle, faHistory, faMoneyCheck, faUserTag, faUserTie } from "@fortawesome/free-solid-svg-icons";
//import { Container } from "react-bootstrap";



export function Advanced() {
    let history = useHistory();

    return (
                <div className={styles.containers}>
                        <div>
                                <img  src={bannerAdv} style={{left:0,  width: '100%', height: 'auto', margin:0, marginTop: '0' }} alt="Sintia Advanced" title="Sintia Advanced"></img>
                        </div>
                        <div>
                                <ul className={styles.nav}>
                                        <li className={styles.lis}>
                                        <span onClick={() => history.push('/despachantes')}>
                                                        <FontAwesomeIcon size="3x"  icon={faUserTie}></FontAwesomeIcon>                                        
                                                        <div className={styles.circle}></div>
                                                        <div className={styles.title}>Despachantes</div>
                                                </span>
                                        </li>

                                        <li className={styles.lis}>
                                                <span onClick={() => history.push('/importadores')}>
                                                        {/*<i className="fa-solid fa-people-group fa-3x">*/}
                                                        <FontAwesomeIcon size="3x"  icon={faUserTag}></FontAwesomeIcon>                                        
                                                        <div className={styles.circle}></div>
                                                        <div className={styles.title}>Importadores</div>
                                                </span>
                                        </li>


                                        <li className={styles.lis}>
                                                <span onClick={() => history.push('/advanced/declaracionesoficializadas')}>
                                                        {/*<i className="fa-solid fa-clock-rotate-left fa-3x">*/}
                                                        <FontAwesomeIcon size="3x"  icon={faHistory}></FontAwesomeIcon>
                                                        <div className={styles.circle}></div>
                                                        <div className={styles.title}>Oficializaciones</div>
                                                </span>
                                        </li>



                                        <li className={styles.lis}>
                                                <span onClick={() => history.push('/advanced/avisodecarga')}>
                                                        {/*<i className="fa-solid  fa-person-military-pointing fa-3x">*/}
                                                        <FontAwesomeIcon size="3x"  icon={faBoxes}></FontAwesomeIcon>
                                                        <div className={styles.circle}></div>
                                                        <div className={styles.title}>Aviso de Carga</div>
                                                </span>
                                        </li>
                                

                                        <li className={styles.lis}>
                                                <span onClick={() => history.push('/advanced/consultasubcuenta')}>
                                                        {/*<i className="fa-solid fa-money-check-dollar fa-3x">*/}
                                                        <FontAwesomeIcon size="3x"  icon={faMoneyCheck}></FontAwesomeIcon>
                                                        <div className={styles.circle}></div>
                                                        <div className={styles.title}>Subcuenta Maria</div>
                                                </span>
                                        </li>
                                        
                                        <li className={styles.lis}>
                                                <span onClick={() => history.push('/advanced/ratificacionautoria')}>
                                                        {/*<i className="fa-regular fa-circle-check fa-3x">*/}
                                                        <FontAwesomeIcon size="3x"  icon={faCheckCircle}></FontAwesomeIcon>
                                                        <div className={styles.circle}></div>
                                                        <div className={styles.title}>Ratificaciones</div>
                                                </span>
                                        </li>

                                        <li className={styles.lis}>
                                                <span onClick={() => history.push('/advanced/consultabultos/')}>
                                                        {/*<i className="fa-solid fa-boxes-packing fa-3x ">*/}
                                                        <FontAwesomeIcon size="3x"  icon={faBox}></FontAwesomeIcon>
                                                        <div className={styles.circle}></div>
                                                        <div className={styles.title}>Bultos</div>
                                                </span>
                                        </li>
                                        <div className={styles.background}></div>
                                </ul>
                        </div>
                </div>

                
    )


}