import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useRef, useState } from "react";
import { Button, Form, Container, Modal } from "react-bootstrap";
import { Route, useHistory, useRouteMatch } from "react-router";
import { Switch } from "react-router-dom";
import { AppContext } from "App";
import Grilla, { GrillaRef } from "../../Grilla";
import { DialogoConfirmar, DialogoConfirmarRef } from "../../DialogoConfirmar";
import { isInRole } from 'Utilidades';
import { CrearEditarImportador } from "Paginas/Importadores/CrearEditarImportador";
import ProtectedRoute from 'ProtectedRoute';
import InnerRouter from "InnerRouter";
import { MyModal } from "MyModal";
import { RedirectEventCallbackContext, CancelToken, useRedirectEventCallbackContextState } from "SintiaHooks";
import { useApi, TipoLock } from "ApiHooks";

export function Importadores(props: { eventoSeleccionarImportador?: (item: any) => void }) {
    let refGrilla = useRef<GrillaRef>(null);
    let refDialogo = useRef<DialogoConfirmarRef>(null);
    let history = useHistory();
    let { mostrarError, userInfo } = useContext(AppContext);
    let campos = [{ titulo: '', propiedad: 'Id', visible: false, clave: true },
    { titulo: 'CUIT', propiedad: 'CUIT' },
    { titulo: "Nombre", propiedad: 'Nombre' }];
    let api = useApi();
    let [busquedaActual, updateBusquedaActual] = useState('');
    let [busqueda, updateBusqueda] = useState('');
    let [mensajeEliminarImportador, updateMensajeEliminarImportador] = useState('');
    async function cargarDatos(desde: number, hasta: number, cancelToken: CancelToken) {
        let respuesta = await api.getImportadoresPaginado(busquedaActual, desde, hasta, cancelToken);
        return { cantidadItems: respuesta.CantidadTotal, items: respuesta.Items };
    }

    function eventoEliminar(item: any) {
        updateMensajeEliminarImportador(`¿Está seguro que desea eliminar el cliente ${item.Nombre}?`);
        return refDialogo.current!.mostrar().then(async () => {
            refGrilla.current?.setBloqueado(true);
            try {
                let puedeEliminar = await api.obtenerLock(TipoLock.Importador, item.Id);
                if (puedeEliminar) {
                    let { exito, error } = await api.deleteImportador(item.Id);
                    if (exito) {
                        refGrilla.current?.recargar();
                    } else {
                        mostrarError(error as string);
                    }
                } else {
                    mostrarError(`No se puede eliminar el cliente ${item.CUIT} porque otra persona lo está utilizando`);
                }
                refGrilla.current?.setBloqueado(false);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al eliminar importador', error);
                    mostrarError('Hubo un error al eliminar el cliente');
                    refGrilla.current?.setBloqueado(false);
                }
            }
        }).catch(() => { });
    }
    let esOperador = isInRole(userInfo, 'Operador');
    const eventoModificarImportador = (item: any) => history.push('/importadores/edit/' + encodeURIComponent(item.Id));
    const eventoSeleccionarImportador = props.eventoSeleccionarImportador ?? eventoModificarImportador;
    return (<>
        <Container>
            <h2>Clientes</h2>
            <Form inline onSubmit={e => {
                updateBusquedaActual(busqueda);
                refGrilla.current?.recargar();
                e.preventDefault();
            }}>
                <Form.Control type="text" id="txtCodigoImportador" value={busqueda} className="mr-2 mb-2"
                    onChange={e => updateBusqueda(e.target.value)}></Form.Control>
                <Button type="submit" className="mb-2">Buscar</Button>
            </Form>
            <Button disabled={esOperador} onClick={() => history.push('/importadores/add')} className="mb-2 mr-2">
                <FontAwesomeIcon icon={faPlus} />
                <span>Agregar</span>
            </Button>
            <Grilla campos={campos} cargarDatos={cargarDatos}
                eventoDetalle={eventoSeleccionarImportador}
                eventoModificar={props.eventoSeleccionarImportador ? eventoModificarImportador : undefined}
                eventoEliminar={eventoEliminar} deshabilitarBotonEliminar={esOperador} ref={refGrilla} />
        </Container>
        <DialogoConfirmar ref={refDialogo} mensaje={mensajeEliminarImportador} textoBotonConfirmar="Sí" textoBotonCancelar="No" />    </>)
}

export function RutasImportador(props: { eventoSeleccionarImportador?: (item: any) => void }) {
    let { path } = useRouteMatch();
    return <Switch>
        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb', 'Operador']} path={`${path}/add`} component={CrearEditarImportador}></ProtectedRoute>
        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path={`${path}/edit/:id`} component={CrearEditarImportador}></ProtectedRoute>
        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} exact path={path}>
            <Importadores {...props}></Importadores>
        </ProtectedRoute>
    </Switch>
}

export function ModalImportadores(props: {
    mostrar: boolean,
    eventoCerrarModal: () => void,
    eventoSeleccionarImportador: (item: any) => void
}) {
    let redirectEventCallbackContextState = useRedirectEventCallbackContextState();
    const eventoCerrar = () => {
        redirectEventCallbackContextState.runAfterUserConfirmation(result => {
            if (result) {
                props.eventoCerrarModal();
            }
        });
    }
    return <MyModal show={props.mostrar} onHide={eventoCerrar}>
        <Modal.Dialog size="xl">
            <Modal.Body>
                <RedirectEventCallbackContext.Provider value={redirectEventCallbackContextState}>
                    <InnerRouter initialRoute="/importadores">
                        <Switch>
                            <Route path="/importadores">
                                <RutasImportador eventoSeleccionarImportador={props.eventoSeleccionarImportador}></RutasImportador>
                            </Route>
                        </Switch>
                    </InnerRouter>
                </RedirectEventCallbackContext.Provider>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={eventoCerrar}>Cerrar</Button>
            </Modal.Footer>
        </Modal.Dialog>
    </MyModal>
}