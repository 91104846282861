import { useHistory } from "react-router";
import { useApi } from "ApiHooks";
import React, { useContext, useEffect } from "react";
import { AppContext } from "App";
import { MySpinnerModal } from "../MyModal";

const urlWebLna = 'https://lna.sintia.com.ar/Account/LoginToken';
//const urlWebLna = 'https://localhost:44305/Account/LoginToken';

function abrirVentana(token: string) {
    const windowHtml = `
                    <!DOCTYPE html>
                    <html>
                        <head>
                            <title>Iniciando sesi&oacute;n en Web LNA</title>
                            <script type="text/javascript">
                                window.onload = function(){
                                    this.opener.postMessage({'loaded': true}, "*");
                                    document.getElementById('form').submit();
                                };
                            </script>
                        </head>
                        <body>
                            <p>Iniciando sesi&oacute;n en Web LNA</p>
                            <form action="${urlWebLna}" method="post" id="form">
                                <input type="hidden" name="Token" value="${token}"></input>
                            </form>
                        </body>
                    </html>
                `;
    return new Promise<void>((resolve, reject) => {
        const windowUrl = URL.createObjectURL(new Blob([windowHtml], { type: "text/html" }));
        const ventana = window.open(windowUrl);
        if (ventana) {
            const listener = (event: MessageEvent) => {
                if (event.data.loaded) {
                    resolve();
                } else {
                    reject('No se abrió la ventana');
                }
            }
            window.addEventListener('message', event => {
                listener(event);
                window.removeEventListener('message', listener);
            });
            setTimeout(() => {
                reject('No se abrió la ventana');
                window.removeEventListener('message', listener);
            }, 1000);
        } else {
            reject('Ventana es undefined');
        }
    });
}

export function LoginLNA() {
    let api = useApi();
    let history = useHistory();
    let { mostrarError } = useContext(AppContext);
    useEffect(() => {
        async function iniciarSesionEnWebLNA() {
            try {
                let token = await api.generarTokenLoginLNA();
                try {
                    await abrirVentana(token);
                } catch {
                    mostrarError('Tiene habilitado el bloqueador de pop-ups. Por favor deshabilitelo y vuelva a intentarlo');
                }
                history.replace('/');
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al iniciar sesión en web LNA', error);
                    mostrarError('Hubo un error al iniciar sesión en LNA Web')
                    history.replace('/');
                }
            }
        }
        iniciarSesionEnWebLNA();
        //eslint-disable-next-line
    }, []);
    return <>
        <MySpinnerModal mostrar={true} mensaje="Iniciando sesión en web LNA" />
    </>
}