
import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { AppContext } from "../../App";
import Grilla, { GrillaRef, TipoCampo } from "../../Grilla";
import { MyModal } from "../../MyModal";
import Loader from "react-loaders";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { arabToRoman } from "roman-numbers";
import FileSaver from "file-saver";
import { useDecodedParams } from 'Utilidades';
import { useApi } from "ApiHooks";
export function ReporteDetalladoCatalogo() {
    let { codigoCatalogo } = useDecodedParams();
    let { mostrarError } = React.useContext(AppContext);
    let api = useApi();
    let reporte = React.useRef([]);
    let refGrilla = React.useRef<GrillaRef>(null);
    let [generandoExcel, updateGenerandoExcel] = React.useState(false);
    function busquedaReducer(estado: any, accion: any) {
        if (accion.tipo === 'conLNA') {
            return { ...estado, conLNA: accion.valor };
        } else if (accion.tipo === 'buscarPorFecha') {
            return { ...estado, buscarPorFecha: accion.valor };
        } else if (accion.tipo === 'fechaDesde') {
            return { ...estado, fechaDesde: accion.valor };
        } else if (accion.tipo === 'fechaHasta') {
            return { ...estado, fechaHasta: accion.valor };
        }
        return estado;
    }
    let busquedaInicial = { conLNA: false, buscarPorFecha: false, fechaDesde: '', fechaHasta: '' };
    let [busquedaActual, updateBusquedaActual] = React.useState(busquedaInicial);
    let [busqueda, updateBusqueda] = React.useReducer(busquedaReducer, { ...busquedaInicial });
    const cargarReporte = React.useCallback(async () => {
        try {
            refGrilla.current?.setBloqueado(true);
            let respuesta = await api.busquedaReporteArticulos({
                codigoCatalogo: codigoCatalogo,
                buscarPorFecha: busquedaActual.buscarPorFecha,
                fechaDesde: busquedaActual.fechaDesde,
                fechaHasta: busquedaActual.fechaHasta,
                soloConLna: busquedaActual.conLNA
            });
            reporte.current = respuesta;
            refGrilla.current?.setBloqueado(false);
            refGrilla.current?.cambiarPagina(1);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error(error);
                mostrarError('Error al cargar reporte detallado');
            }
        }
    }, [mostrarError, busquedaActual, codigoCatalogo, api]);
    React.useEffect(() => {
        cargarReporte();
        //eslint-disable-next-line
    }, [busquedaActual]);
    async function exportarAExcel() {
        try {
            updateGenerandoExcel(true);
            let { excel, fileName } = await api.excelBusquedaReporteArticulos({
                codigoCatalogo: codigoCatalogo,
                buscarPorFecha: busquedaActual.buscarPorFecha,
                fechaDesde: busquedaActual.fechaDesde,
                fechaHasta: busquedaActual.fechaHasta,
                soloConLna: busquedaActual.conLNA
            });
            updateGenerandoExcel(false);
            FileSaver.saveAs(excel, fileName);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error(error);
                mostrarError('Error al exportar reporte detallado a Excel');
            }
            if (!api.isUnmounted()) {
                updateGenerandoExcel(false);
            }
        }
    }
    let funcionFormatoBool = (valor: any) => {
        if (valor === null || valor === undefined) {
            return '';
        } else {
            return valor ? 'Sí' : 'No';
        }
    }
    let camposGrilla = [{ propiedad: 'CodigoCatalogo', titulo: 'Catalogo' },
    { propiedad: 'CodigoArticulo', titulo: 'Articulo', clave: true }, { propiedad: 'Descripcion', titulo: 'Descripción Catalogo' },
    { propiedad: 'DescripcionMaria', titulo: 'Descripción María' }, { propiedad: 'Otros', titulo: 'Otros' },
    { propiedad: 'NCM', titulo: 'NCM' }, { propiedad: 'Naladisa', titulo: 'Naladisa' },
    { propiedad: 'PrecioUnitario', titulo: 'Precio Unitario' }, { propiedad: 'CodigoPaisOrigen', titulo: 'País' },
    { propiedad: 'Sufijos', titulo: 'Sufijos' }, { propiedad: 'KgNeto', titulo: 'Kg Neto', tipo: TipoCampo.Number },
    { propiedad: 'CodigoEstadoMercaderia', titulo: 'Estado Mercadería' },
    { propiedad: 'NombreModificadoPor', titulo: 'Usuario Modificación' },
    { propiedad: 'ModificadoEl', titulo: 'Fecha Modificación', tipo: TipoCampo.DateTime },
    { propiedad: 'TieneLNA', titulo: 'Tiene LNA', plantillaFormato: funcionFormatoBool },
    { propiedad: 'AnexoLNA', titulo: 'Anexo LNA', plantillaFormato: arabToRoman },
    { propiedad: 'Dumping', titulo: 'Dumping', plantillaFormato: funcionFormatoBool },
    { propiedad: 'KgNetoUnitario', titulo: 'Kg Neto Unitario', tipo: TipoCampo.Number, decimales: 5 },
    { propiedad: 'Observaciones', titulo: 'Observaciones', plantillaFormato: (valor: any) => valor?.join(' ') }];
    return (<>
        <h2>Reporte detallado de catalogo {codigoCatalogo}</h2>
        <Form inline onSubmit={e => {
            updateBusquedaActual(busqueda);
            e.preventDefault();
        }}>
            <Form.Check className="mr-2" custom label="Solo con LNA" id="checkSoloLNA" checked={busqueda.conLNA}
                onChange={(e: any) => updateBusqueda({ tipo: 'conLNA', valor: e.target.checked })} />
            <Form.Group>
                <Form.Check custom label="Buscar por fecha modificación" id="checkBuscarPorFecha" checked={busqueda.buscarPorFecha}
                    onChange={(e: any) => updateBusqueda({ tipo: 'buscarPorFecha', valor: e.target.checked })}></Form.Check>
                <Form.Label htmlFor="txtFechaDesde" className="mx-2">Desde</Form.Label>
                <Form.Control type="date" id="txtFechaDesde" disabled={!busqueda.buscarPorFecha} value={busqueda.fechaDesde} className="mb-2 mr-2"
                    onChange={e => updateBusqueda({ tipo: 'fechaDesde', valor: e.target.value })}></Form.Control>
                <Form.Label htmlFor="txtFechaHasta" className="mr-2">Hasta</Form.Label>
                <Form.Control type="date" id="txtFechaHasta" disabled={!busqueda.buscarPorFecha} value={busqueda.fechaHasta} className="mb-2 mr-2"
                    onChange={e => updateBusqueda({ tipo: 'fechaHasta', valor: e.target.value })}></Form.Control>
            </Form.Group>
            <Button type="submit" className="mb-2">Buscar</Button>
        </Form>
        <Button variant="success" className="mb-2" onClick={exportarAExcel}>
            <FontAwesomeIcon icon={faFileExcel} />
            <span>Exportar a Excel</span>
        </Button>
        <Grilla responsiva cargarDatos={(desde: number, hasta: number) => {
            return Promise.resolve({ cantidadItems: reporte.current.length, items: reporte.current.slice(desde - 1, hasta) });
        }} campos={camposGrilla} ref={refGrilla}></Grilla>
        <MyModal show={generandoExcel}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="lead">Generando Excel...</p>
                    <div className="loader-container">
                        <Loader type="ball-spin-fade-loader" active></Loader>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        api.cancelCurrentTokens();
                    }}>Cancelar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
    </>);
}