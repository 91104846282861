import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Modal, Col, Badge, Alert } from "react-bootstrap";
import { MyModal } from "MyModal";
import * as Yup from "yup";
import { MyForm, MyFormControl, MySelect, SelectOption } from "FormikHooks";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useApi } from "ApiHooks";
import { useAdvAPI } from './AdvancedApi';
import { AppContext } from "App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell} from "@fortawesome/free-solid-svg-icons";


export type GetModalSetNotificacionBultosRef = {
    mostrar: (puerto: string, documentoTransporte: string, aduana: string, manifiesto: string) => Promise<{resultado: boolean}>
};

export const GetModalSetNotificacionBultos = React.forwardRef((props: any, ref: any) => {

    let { userInfo } = useContext(AppContext);
    let [optionsDespachantes, updateOptionsDespachantes] = useState<SelectOption[]>([]);
    
    const [errorActivar, setErrorActivar] = useState(false);
    const [errorActivarTexto, setErrorActivarTexto] = useState('');

    const [tipoAlerta, setTipoAlerta] = useState('danger');


    const [aduana, setaduana] = useState('');
    const [puerto, setpuerto] = useState('');
    const [documentoTransporte, setdocumentoTransporte] = useState('');
    const [manifiesto, setmanifiesto] = useState('');

    let formikRef = React.useRef<FormikProps<any>>(null);
    let funcionesPromesa = React.useRef<any>({ resolve: null, reject: null });
    let apiSintia = useApi();
    let api = useAdvAPI();
    let [estadoModal, updateEstadoModal] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrar') {
            return { abierto: true, opciones: accion.opciones, error: '', resolve: accion.resolve };
        } else if (accion.tipo === 'cerrar') {
            return { abierto: false, opciones: null, error: '' };
        } else if (accion.tipo === 'setError') {
            return { ...estado, error: accion.error };
        }
    }, { abierto: false, opciones: null, error: '' });

    let initialvalues = {
        cuitDespachante:'', 
        email: userInfo.nombreUsuario,
        aduana: '',
        puerto: '',
        documentoTransporte: '',
        manifiesto: ''
    };



    React.useImperativeHandle(ref, () => ({
        mostrar: (puerto: string, documentoTransporte: string, aduana: string, manifiesto: string) => new Promise<void>((resolve, reject) => {
            funcionesPromesa.current = { resolve: resolve, reject: reject };
            setpuerto(puerto);
            setdocumentoTransporte(documentoTransporte);
            setaduana(aduana);
            setmanifiesto(manifiesto);

            updateEstadoModal({ tipo: 'mostrar', resolve: resolve });
        })
    }));


    //load de la pagina
    useEffect(() => {
        async function cargar() {
            try {
                let despachantes = await apiSintia.getDespachantes();
                updateOptionsDespachantes(despachantes.map((item: any) => ({ value: item.CUIT, label: item.Nombre })));

            } catch (error) {
                console.log('error ' + error);
            }
        }
        cargar();
        //eslint-disable-next-line
    }, []);

    const cerrar = () => {

        if (estadoModal.resolve) {
            estadoModal.resolve();
        }

        setErrorActivar(false);
        setErrorActivarTexto('');
        updateEstadoModal({ tipo: 'cerrar' });
    }


    let schema = Yup.object({
        'cuitDespachante': Yup.string().nullable().required('Debe seleccionar un despachante'),
        'email': Yup.string().email().required('Debe ingresar correo electronico'),
    });



    async function activarNotificacion(param : any, actions: FormikHelpers<any>) {
        
        try {
            
            let resultado = await api.setNotificacionCierreIngresoDeposito(param.cuitDespachante, puerto, documentoTransporte, aduana, manifiesto, param.email);
            setErrorActivarTexto(resultado);
            setTipoAlerta('success');
            setErrorActivar(true);
            return true;

        } catch (error) {

            if (error.response?.status !== null)
            {
                if (error.response?.status!==200)
                {
                    setErrorActivar(true);

                    if (error.response.data.toLowerCase().includes('el registro ya existe'))
                    {
                        setErrorActivarTexto('Alerta ya activada para este manifiesto');
                        setTipoAlerta('info');
                    } else {
                        setErrorActivarTexto(error.response.data);
                        setTipoAlerta('danger');
                    }

                }
            }

        }

        return false;

    };

    return (<>
        {/*onHide={cerrar}*/}
        <MyModal show={estadoModal.abierto} onHide={cerrar} >
            <Modal.Dialog size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Header>
                    <Modal.Title><FontAwesomeIcon icon={faBell} /><strong className="ml-3">Activar Notificacion - Cierre de Ingreso a Deposito</strong></Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                            <Formik initialValues={initialvalues} validationSchema={schema} innerRef={formikRef} onSubmit={activarNotificacion}>
                                <MyForm className="col-md-12 mt-0">

                                    <Form.Row>
                                        {errorActivar && 
                                            <Alert className="p-auto" variant={tipoAlerta} onClose={() => setErrorActivar(false)} dismissible>
                                                <b>{errorActivarTexto}</b>
                                            </Alert>
                                        }
                                    </Form.Row>

                                    <Form.Row className="align-items-center justify-content-center">
                                        <Form.Group as={Col}  className="col-auto">
                                            <h4>Aduana <Badge variant="info">{aduana}</Badge></h4>
                                        </Form.Group>
                                        <Form.Group as={Col}  className="col-auto">
                                            <h4>Puerto <Badge variant="info">{puerto}</Badge></h4>
                                        </Form.Group>
                                        <Form.Group as={Col}  className="col-auto">
                                            <h4>Doc Trans <Badge variant="info">{documentoTransporte}</Badge></h4>
                                        </Form.Group>
                                        <Form.Group as={Col}   className="col-auto">
                                            <h4>Manifiesto <Badge variant="info">{manifiesto}</Badge></h4>
                                        </Form.Group>
                                    </Form.Row>


                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <MySelect name="cuitDespachante" label="Despachante"
                                                options={optionsDespachantes}/>
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.Group  as={Col}>
                                            <Form.Label> Correo Electronico</Form.Label>
                                            <MyFormControl type="email" aria-label="email" name="email"  ></MyFormControl>
                                        </Form.Group>
                                    </Form.Row>
                                </MyForm>
                            </Formik>
                    </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={cerrar}>Cerrar</Button>
                    <Button variant="primary" onClick={()=> formikRef.current!.submitForm()}>Activar</Button>

                </Modal.Footer>

            </Modal.Dialog>
        </MyModal>
        </>)
/*

updateEstadoModal({ tipo: 'cerrar' });
funcionesPromesa.current?.resolve({ Codigo: item.Codigo });


*/


})
