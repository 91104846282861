//import { Formik, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Container, Form, Modal, Col } from "react-bootstrap";
import { MyModal } from "MyModal";
import * as Yup from "yup";
import Grilla, {GrillaRef} from "../../Grilla";
import { MyForm, MyFormControl, MySelect } from "FormikHooks";
import { Formik, FormikProps } from "formik";
import { useApi } from "ApiHooks";


export type GetModalCodigosBultosRef = {
    mostrar: (aduana: string) => Promise<{Codigo: string}>
};

export const GetModalCodigosBultos = React.forwardRef((props: any, ref: any) => {

    const [aduana, setaduana] = useState('');
    const [resultado, setResultado] = useState([]);
    
    //const [showModal, setshowModal] = React.useState(true);
    let formikRef = React.useRef<FormikProps<any>>(null);
    let refGrilla = React.useRef<GrillaRef>(null);
    let funcionesPromesa = React.useRef<any>({ resolve: null, reject: null });
    let apiSintia = useApi();


    let [estadoModal, updateEstadoModal] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrar') {
            return { abierto: true, opciones: accion.opciones, error: '', resolve: accion.resolve };
        } else if (accion.tipo === 'cerrar') {
            return { abierto: false, opciones: null, error: '' };
        } else if (accion.tipo === 'setError') {
            return { ...estado, error: accion.error };
        }
    }, { abierto: false, opciones: null, error: '' });

    //cada vez que se modifica resultado , recargo la grilla    
    useEffect(() => {
        try
        {   
            refGrilla.current!.recargar();
            
        } catch(error) {
            console.log(error);
        }

    }, [resultado])
    

    React.useImperativeHandle(ref, () => ({
        mostrar: (aduana : string) => new Promise<void>((resolve, reject) => {
            funcionesPromesa.current = { resolve: resolve, reject: reject };
            setaduana(aduana);
            updateEstadoModal({ tipo: 'mostrar', resolve: resolve });
        })
    }));

    const cerrar = () => {
        if (estadoModal.resolve) {
            estadoModal.resolve();
        }
        updateEstadoModal({ tipo: 'cerrar' });
    }


    let campos = [
        { propiedad: 'Id', visible: false, clave: true },
        { titulo: 'Descripcion', propiedad: 'Descripcion' },
        { titulo: "Codigo", propiedad: 'Codigo' },
    ]

    const opcionesBusqueda = [
                        { value: 'aereo', label: 'Compañias Areas' },
                        { value: 'puertos', label: 'Maritimo' }
    ];
    let initialvalues = {
        valorBusqueda:'', 
        opcionesBusqueda: ''
    };

    let schema = Yup.object({
        //'Manifiesto': Yup.string().nullable().required('Debe seleccionar una aduana de conexion'), 
        'valorBusqueda':  Yup.string().nullable().required('Debe ingresar un valor de busqueda').min(3, 'Debe tener al menos 3 digitos'), 
        'opcionesBusqueda': Yup.string().nullable().required('Debe seleccionar una opcion')
    });


    async function buscar(pBusqueda: any) {
        let opcion = pBusqueda.opcionesBusqueda;
        let valor = pBusqueda.valorBusqueda;
        var _resultado : any;


        if (aduana === '')
        {//iba a usar aduana para buscar en aereo o maritimo, pero no vale la pena, se complica sin necesidad, este if, esta para sacar un warning
        }

        if (opcion === 'aereo')
        {
            _resultado = await apiSintia.getCompaniasAereas(valor);
        } else {
            //sino es maritimo
            _resultado = await apiSintia.getPuertosBusqueda(valor);
        }

        setResultado(_resultado);

    }

    async function cargarDatos(desde: number, hasta: number, cancelToken: any) {
        return { cantidadItems: resultado.length, items: resultado };
    }


    return (<>
        {/*onHide={cerrar}*/}
        <MyModal show={estadoModal.abierto} onHide={cerrar} >
            <Modal.Dialog size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Header>

                <Formik initialValues={initialvalues} validationSchema={schema} innerRef={formikRef} onSubmit={buscar}>
                    <MyForm className="col-md-12">

                        <Form.Row>
                            <Form.Group  as={Col} className="col-md-4">
                                <MyFormControl type="text" name="valorBusqueda"></MyFormControl>
                                </Form.Group>
                            <Form.Group as={Col} className="col-md-4">
                                <MySelect name="opcionesBusqueda" label=""
                                        options={opcionesBusqueda}/>
                            </Form.Group>
                            <Form.Group as={Col} className="d-flex">
                                <Button type="submit" className="align-self-end my-4" >Buscar</Button>
                            </Form.Group>
                        </Form.Row>
                    </MyForm>
                </Formik>

                </Modal.Header>
                    <Container >
                        <Form onSubmit={e => {
                            e.preventDefault();
                            }} >
                                <Grilla responsiva={true} campos={campos} cargarDatos={cargarDatos}
                                mostrarFormCambiarPagina={false} 
                                eventoDetalle={false ? undefined : item => {
                                    updateEstadoModal({ tipo: 'cerrar' });
                                    funcionesPromesa.current?.resolve({ Codigo: item.Codigo });
                                }} ref={refGrilla} seleccionMultiple={false} />

                            <div className="d-flex flex-wrap justify-content-end">
                                <Button className="btn-md mr-1" variant="danger" onClick={cerrar}>Cancelar</Button>
                            </div>

                            <br/>
                            <br/>
                        </Form>
                    </Container>
            </Modal.Dialog>
        </MyModal>
        </>)

})
