import React, { useContext, useRef, useState, useReducer } from "react";
import { CancelToken } from "SintiaHooks";
import { useApi } from "ApiHooks";
import { AppContext } from "App";
import Grilla, { GrillaRef, TipoCampo } from "Grilla";
import { MySelectNoFormik } from "BaseSelect";
import { Button, Form } from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { isNullOrWhiteSpace } from "Utilidades";

const Select = styled(MySelectNoFormik)`
    width:20rem;
`;
const optionsEstados = ['ANUC', 'ANUL', 'AUTO', 'BLOQ', 'CANC', 'DENU', 'OBSR', 'OFIC', 'PRES', 'RECH', 'SALI', 'VENC']
    .map(estado => ({ value: estado, label: estado }));
export function InformeSIMI() {
    let { mostrarError } = useContext(AppContext);
    let refGrilla = useRef<GrillaRef>(null);
    let api = useApi();
    // let [generandoExcel, updateGenerandoExcel] = useState(false);
    function busquedaReducer(estado: any, accion: any) {
        return { ...estado, [accion.tipo]: accion.valor };
    }
    let busquedaInicial = {
        buscarPorFecha: false, fechaDesde: '', fechaHasta: '', nroCarpeta: '',
        cuitCliente: '', codigoVendedor: '', vuestraReferencia: '', estadoSimi: '',
    };
    let [busquedaActual, updateBusquedaActual] = useState(busquedaInicial);
    let [busqueda, updateBusqueda] = useReducer(busquedaReducer, { ...busquedaInicial });
    let campos = [
        {
            titulo: 'Nro. Carpeta', propiedad: 'NroCarpeta', plantillaFormato: (interno: any) => {
                if (!isNullOrWhiteSpace(interno)) {
                    return <Link to={'/caratulas/' + encodeURIComponent(interno)}>{interno}</Link>;
                }
                return '';
            }
        }, { titulo: 'Referencia', propiedad: 'Referencia' },
        { titulo: 'Nro. Simi', propiedad: 'NroSimi', clave: true },
        { titulo: 'Cliente', propiedad: 'NombreCliente' },
        { titulo: 'Vendedor', propiedad: 'NombreVendedor' },
        { titulo: "Fecha Apertura Carpeta", propiedad: 'FechaAperturaCarpeta', tipo: TipoCampo.DateTime },
        { titulo: 'Fecha Ofic.', propiedad: 'FechaOficSimi', tipo: TipoCampo.Date },
        { titulo: 'Fecha Arribo', propiedad: 'FechaArribo', tipo: TipoCampo.Date },
        { titulo: 'Fecha Vencimiento', propiedad: 'FechaVencimientoSimi', tipo: TipoCampo.Date },
        { titulo: 'Estado', propiedad: 'EstadoSimi' },
        {
            titulo: 'Tiene LNA', propiedad: 'TieneLNA', plantillaFormato: (valor: any) => {
                if (valor === true) {
                    return 'Sí';
                } else if (valor === false) {
                    return 'No';
                }
                return '';
            }
        }, { titulo: 'LNA Tramite', propiedad: 'TramiteLNA' }, { titulo: 'Mensaje LNA', propiedad: 'MensajeLNA' },
        { titulo: 'Prorroga', propiedad: 'Prorroga' }, { titulo: 'Estado Prorroga', propiedad: 'EstadoProrroga' },
        { titulo: 'Observaciones Prorroga', propiedad: 'ObservacionesProrroga' }];
    React.useEffect(() => {
        refGrilla.current?.recargar();
    }, [busquedaActual]);
    async function cargarDatos(desde: number, hasta: number, cancelToken: CancelToken) {
        let respuesta = await api.busquedaInformeSimiPaginado(desde, hasta, cancelToken, busquedaActual.buscarPorFecha, busquedaActual.fechaDesde,
            busquedaActual.fechaHasta, busquedaActual.cuitCliente, busquedaActual.codigoVendedor,
            busquedaActual.nroCarpeta, busquedaActual.vuestraReferencia, busquedaActual.estadoSimi);
        return { cantidadItems: respuesta.CantidadTotal, items: respuesta.Items };
    }
    // async function exportarAExcel() {
    //     try {
    //         updateGenerandoExcel(true);
    //         let { fileName, excel } = await api.exportarBusquedaArticulosAExcel([codigoCatalogo],
    //             busquedaActual.codigoArticulo, busquedaActual.descripcion, busquedaActual.ncm,
    //             busquedaActual.buscarPorFecha, busquedaActual.fechaDesde, busquedaActual.fechaHasta, true);
    //         updateGenerandoExcel(false);
    //         FileSaver.saveAs(excel, fileName);
    //     } catch (error) {
    //         if (!api.isCancel(error)) {
    //             console.error(error);
    //             mostrarError('Error al exportar listado de articulos a Excel');
    //         }
    //         if (!api.isUnmounted()) {
    //             updateGenerandoExcel(false);
    //         }
    //     }
    // }
    async function cargarImportadores() {
        try {
            let respuesta = await api.getImportadores();
            return respuesta.map((importador: any) => ({ value: importador.CUIT, label: importador.Nombre }));
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al cargar clientes', error);
                mostrarError('Error al cargar los clientes');
            }
            return [];
        }
    }
    async function cargarVendedores() {
        try {
            let respuesta = await api.getVendedores();
            return respuesta.map((item: any) => ({ value: item.Codigo, label: item.Nombre }));
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al cargar vendedores', error);
                mostrarError('Error al cargar los vendedores');
            }
            return [];
        }
    }
    return (<>
        <h2>Informe SIMI</h2>
        <Form inline onSubmit={e => {
            updateBusquedaActual(busqueda);
            e.preventDefault();
        }}>
            <Form.Group className="my-2">
                <Form.Check custom label="Buscar por fecha apertura carpeta" id="checkBuscarPorFecha" checked={busqueda.buscarPorFecha}
                    onChange={(e: any) => updateBusqueda({ tipo: 'buscarPorFecha', valor: e.target.checked })}></Form.Check>
                <Form.Label htmlFor="txtFechaDesde" className="mx-2">Desde</Form.Label>
                <Form.Control type="date" id="txtFechaDesde" disabled={!busqueda.buscarPorFecha} value={busqueda.fechaDesde} className="mr-2"
                    onChange={e => updateBusqueda({ tipo: 'fechaDesde', valor: e.target.value })}></Form.Control>
                <Form.Label htmlFor="txtFechaHasta" className="mr-2">Hasta</Form.Label>
                <Form.Control type="date" id="txtFechaHasta" disabled={!busqueda.buscarPorFecha} value={busqueda.fechaHasta} className="mr-2"
                    onChange={e => updateBusqueda({ tipo: 'fechaHasta', valor: e.target.value })}></Form.Control>
            </Form.Group>
            <Form.Group className="my-2 mr-2">
                <Form.Label htmlFor="cboImportador" className="mr-2">Cliente</Form.Label>
                <Select options={cargarImportadores} id="cboImportador" value={busqueda.cuitCliente}
                    onValueChange={(valor: any) => {
                        updateBusqueda({ tipo: 'cuitCliente', valor: valor });
                    }}></Select>
            </Form.Group>
            <Form.Group className="my-2 mr-2">
                <Form.Label htmlFor="cboVendedor" className="mr-2">Vendedor</Form.Label>
                <Select options={cargarVendedores} id="cboVendedor" value={busqueda.codigoVendedor}
                    onValueChange={(valor: any) => {
                        updateBusqueda({ tipo: 'codigoVendedor', valor: valor });
                    }}></Select>
            </Form.Group>
            <Form.Group className="my-2">
                <Form.Label htmlFor="txtNroCarpeta" className="mr-2">Nro. Carpeta</Form.Label>
                <Form.Control type="text" id="txtNroCarpeta" value={busqueda.nroCarpeta} className="mb-2 mr-2"
                    onChange={e => updateBusqueda({ tipo: 'nroCarpeta', valor: e.target.value })}></Form.Control>
            </Form.Group>
            <Form.Group className="my-2">
                <Form.Label htmlFor="txtVuestraReferencia" className="mr-2">Referencia</Form.Label>
                <Form.Control type="text" id="txtVuestraReferencia" value={busqueda.vuestraReferencia} className="mb-2 mr-2"
                    onChange={e => updateBusqueda({ tipo: 'vuestraReferencia', valor: e.target.value })}></Form.Control>
            </Form.Group>
            <Form.Group className="my-2 mr-2">
                <Form.Label htmlFor="cboEstado" className="mr-2">Estado</Form.Label>
                <Select options={optionsEstados} id="cboEstado" value={busqueda.estadoSimi}
                    onValueChange={(valor: any) => {
                        updateBusqueda({ tipo: 'estadoSimi', valor: valor });
                    }}></Select>
            </Form.Group>
            <Button type="submit" className="my-2">Buscar</Button>
        </Form>
        {/* <Button variant="success" className="mr-2 mb-2" onClick={exportarAExcel}>
            <FontAwesomeIcon icon={faFileExcel} />
            <span>Exportar a Excel</span>
        </Button> */}
        {/*         
        <MyModal show={generandoExcel}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="lead">Generando Excel...</p>
                    <div className="loader-container">
                        <Loader type="ball-spin-fade-loader" active></Loader>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        api.cancelCurrentTokens();
                    }}>Cancelar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal> */}
        <Grilla ref={refGrilla} campos={campos} cargarDatos={cargarDatos}></Grilla>
    </>)
}