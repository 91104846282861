import React, { forwardRef, useContext, useEffect, useState } from "react";
import { AppContext } from "App";
import { isNullOrWhiteSpace, abrirPdf, toFixedDecimal } from "Utilidades";
import { Button, Col, Form } from "react-bootstrap";
import { MySpinnerModal } from "../../MyModal";
import { GrillaSync, TipoCampo } from "../../Grilla";
import { useApi } from "ApiHooks";
// import styled from "styled-components";
import BlockUi from "react-block-ui";

enum EstadoCargando {
    CargaInicial,
    Cargando,
    Listo
}

// const GrillaSyncReferencias = styled(GrillaSync)`
//     & {
//         th:nth-child(2),td:nth-child(2){
//             min-width:10rem;
//         }
//     }
// `;

export const ResumenCaratula = forwardRef((props: {
    interno: string
}, ref: any) => {
    //eslint-disable-next-line
    let { mostrarError, userInfo } = useContext(AppContext);
    let api = useApi();
    let [cargando, updateCargando] = useState(EstadoCargando.CargaInicial);
    let [caratula, updateCaratula] = useState<any>(null);
    let [tareas, updateTareas] = useState<any[]>([]);
    let [generandoPdf, updateGenerandoPdf] = useState(false);
    let [ultimoBlobUrl, updateUltimoBlobUrl] = React.useState('');
    useEffect(() => {
        async function cargar() {
            try {
                let caratula = await api.getCaratula(props.interno);
                if (!caratula) {
                    throw new Error(`Caratula con interno ${props.interno} no existe`);
                }
                let tareas = await api.getTareasCaratula(props.interno);
                updateCaratula(caratula);
                updateTareas(tareas);
                updateCargando(EstadoCargando.Listo);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar resumen de carpeta', error);
                    mostrarError('Error al cargar resumen de carpeta');
                }
            }
        }
        cargar();
        //eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (!isNullOrWhiteSpace(ultimoBlobUrl)) {
            return () => {
                URL.revokeObjectURL(ultimoBlobUrl);
            }
        }
    }, [ultimoBlobUrl]);
    async function imprimir() {
        try {
            updateGenerandoPdf(true);
            let respuesta = await api.exportarResumenCaratulaPdf(props.interno);
            updateGenerandoPdf(false);
            let blobUrl = URL.createObjectURL(respuesta);
            updateUltimoBlobUrl(blobUrl);
            abrirPdf(blobUrl).catch(() => {
                mostrarError('Tiene habilitado el bloqueador de pop-ups. Por favor deshabilitelo y vuelva a intentarlo');
            });
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al imprimir resumen caratula', error);
                mostrarError('Error al imprimir resumen caratula');
            }
            if (!api.isUnmounted()) {
                updateGenerandoPdf(false);
            }
        }
    }
    let tipoOperacionYSubregimen = '';
    if (caratula) {
        tipoOperacionYSubregimen = caratula.TipoOperacion + ' / ' + caratula.CodigoSubregimen;
    }
    let facturas = '';
    if (caratula && caratula.Facturas) {
        facturas = caratula.Facturas.join(',');
    }
    const camposReferencias = [{ titulo: 'Nombre', propiedad: 'Nombre', clave: true },
    { titulo: 'Valor', propiedad: 'Valor' }, { titulo: 'Usuario', propiedad: 'NombreModificadoPor' },
    { titulo: 'Fecha', propiedad: 'ModificadoEl', tipo: TipoCampo.DateTime }];
    const camposTareas = [{ titulo: 'Tarea', propiedad: 'Nombre', clave: true },
    { titulo: 'Descripción', propiedad: 'Descripcion' },
    {
        titulo: 'Estado', propiedad: 'Estado', plantillaFormato: (valor: any) => {
            if (valor === 1) {
                return 'Realizada';
            } else if (valor === 0) {
                return 'No Realizada';
            } else {
                return '';
            }
        }
    }, { titulo: 'Usuario', propiedad: 'NombreModificadoPor' },
    { titulo: 'Fecha', propiedad: 'ModificadoEl', tipo: TipoCampo.DateTime }];
    return <>
        <BlockUi blocking={cargando !== EstadoCargando.Listo}>
            <Form>
                <Form.Row>
                    <Form.Group as={Col} controlId="txtInterno">
                        <Form.Label>Nro. Interno</Form.Label>
                        <Form.Control type="text" readOnly value={props.interno}></Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} controlId="txtRefCliente">
                        <Form.Label>Referencia Cliente</Form.Label>
                        <Form.Control type="text" readOnly value={caratula?.IdentificadorExterno}></Form.Control>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="txtTipoOperacion">
                        <Form.Label>Tipo Operación/Subregimen</Form.Label>
                        <Form.Control type="text" readOnly value={tipoOperacionYSubregimen}></Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} controlId="txtFechaCreacion">
                        <Form.Label>Fecha Creación</Form.Label>
                        <Form.Control type="date" readOnly value={caratula?.CreadoEl}></Form.Control>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="txtCliente">
                        <Form.Label>Cliente</Form.Label>
                        <Form.Control type="text" readOnly value={caratula?.DescripcionImportador}></Form.Control>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="txtVendedor">
                        <Form.Label>Comprador/Vendedor</Form.Label>
                        <Form.Control type="text" readOnly value={caratula?.DescripcionVendedor}></Form.Control>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="txtMontoTotal">
                        <Form.Label>Monto Total</Form.Label>
                        <Form.Control type="text" readOnly value={toFixedDecimal(caratula?.TotalFob)}></Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} controlId="txtNroFactura">
                        <Form.Label>Monto Total</Form.Label>
                        <Form.Control type="text" readOnly value={facturas}></Form.Control>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="txtDescripcionMercaderia">
                        <Form.Label>Descripción Mercadería de Carpetas</Form.Label>
                        <Form.Control as="textarea" readOnly value={caratula?.DescripcionMercaderiaCaratula}></Form.Control>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <h4>Referencias</h4>
                        <GrillaSync datos={caratula?.Referencias ?? []} campos={camposReferencias}
                            mostrarFormCambiarPagina={false}></GrillaSync>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <h4>Tareas</h4>
                        <GrillaSync datos={tareas} campos={camposTareas}
                            mostrarFormCambiarPagina={false}></GrillaSync>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="txtFechaGeneracionTxt">
                        <Form.Label>Fecha Generación Txt</Form.Label>
                        <Form.Control type="date" readOnly value={caratula?.FechaGeneracionTxtMaria}></Form.Control>
                    </Form.Group>
                    <Col className="d-flex">
                        <Button className="align-self-end my-4" onClick={imprimir}>Imprimir</Button>
                    </Col>
                </Form.Row>
            </Form>
        </BlockUi>
        <MySpinnerModal mostrar={generandoPdf} mensaje="Generando Pdf..." onCancel={() => api.cancelCurrentTokens()}></MySpinnerModal>
    </>;
});

