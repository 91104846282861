//import React from 'react';
import { SesionPerdidaError } from 'App';
import Axios, { AxiosRequestConfig } from 'axios';
import RateLimit from 'RateLimit';
//import { useState } from 'react';
import { tokenStorageADV } from '../../TokenStorage';
import { DateTime } from 'luxon';
import { storageFactory } from 'storage-factory';
import "../../modelos/Advanced";
import { bodyListarArchivos, InsertCPLBody, InsertDVDBody, ObtenerArticulosVistaResponse, ObtenerArticuosLiqVistaResponse, ObtenerDespachoCaratulaResponse, ObtenerDespachoFiltrosRequest, ObtenerDespachoFiltrosResponse, ObtenerEstadoArchivos, ObtenerItemVistaResponse, ResponseListarArchivos, SubirArchivos, UpdateDespachoCaratulaBody } from '../../modelos/Advanced';
//import { ArchivoASubir, ArchivoBinario, bodyListarArchivos, ListArchivos, ObtenerArticulosVistaResponse, ObtenerArticuosLiqVistaResponse,ObtenerDespachoCaratulaResponse, ObtenerDespachoFiltrosRequest, ObtenerDespachoFiltrosResponse, ObtenerEstadoArchivos, ObtenerItemVistaResponse, ResponseListarArchivos, SubirArchivos, UpdateDespachoCaratulaBody } from '../../modelos/Advanced';

//import { randomFillSync } from 'crypto';

function getUrlApiAdv() {
  if (process.env.NODE_ENV === 'development') {
    return 'http://200.85.188.24:8084/'
    //return 'http://192.168.2.91:8084/';

    //return 'https://apiadv.sintia.com.ar/'

  } else if (process.env.REACT_APP_BUILD === 'dev') {
    return 'https://apiadv.sintia.com.ar/';
  } else {
    return 'https://apiadv.sintia.com.ar/';
  }
}

//const urlAdvanced = 'https://apiadv.sintia.com.ar/';
const urlAdvanced = getUrlApiAdv();

const rateLimit = new RateLimit(7);

const advClient = Axios.create({
  baseURL: urlAdvanced
});

const storage = storageFactory(() => localStorage);

advClient.interceptors.request.use(config => {
  config.headers = {
    ...config.headers,
    'Authorization': 'Bearer ' + tokenStorageADV.getAccessToken()
  };
  return rateLimit.delayConfig(config) as Promise<any>;
}, error => error);

//Se crea función para setear token en cada petición para api.postmaria
const urlAdvancedApiPostMaria = 'https://api.postmaria.alpha2000.com.ar/api/'

const advClientApiPostmaria = Axios.create({
  baseURL: urlAdvancedApiPostMaria
});

advClientApiPostmaria.interceptors.request.use(config => {
  config.headers = {
    ...config.headers,
    'Authorization': 'Bearer ' + storage.getItem('accessToken')
  };
  return rateLimit.delayConfig(config) as Promise<any>;
}, error => error);

async function refrescarToken() {
  try {
    let respuesta = await advClient.post('/Account/ValidRefreshToken', {
      UserName: storage.getItem('userNameADV'),
      RefreshToken: tokenStorageADV.getRefreshToken()
    });

    tokenStorageADV.setAccessToken(respuesta.data.AccessToken);
    tokenStorageADV.setRefreshToken(respuesta.data.RefreshToken);
    console.log('se refresco el token');
  } catch (error2) {
    console.log('Error en funcion refrescarToken()', JSON.stringify(error2));
  }
}

let promesaRefrescarToken: Promise<void> | null = null;

advClient.interceptors.response.use(success => success, async error => {
  try {

    if (error.response?.status === 429) {
      debugger;
    }
    if (!error.response || error.response.status !== 401) {

      console.log('config _retry ' + error.config._retry);

      if (!error.config._retry) { error.config._retry = true; }

      return Promise.reject(error);
    }

    if (!promesaRefrescarToken) {
      console.log('ADVAPI se mando refreshtoken....');
      promesaRefrescarToken = refrescarToken();
    }

    await promesaRefrescarToken;
    promesaRefrescarToken = null;

    return advClient.request(error.config);

  } catch (error2) {

    //todo: ver como avisar al usuario de un error
    console.log('Error al refrescar la sesion en client.interceptors.response.use', JSON.stringify(error2));
    //appContextState.cerrarSesion();
    //browserHistory?.replace('/login');
    //mostrarErrorSesionPerdida();

    return Promise.reject(new SesionPerdidaError('Error al refrescar la sesion'));
  }

});


export function useAdvAPI() {

  //let [ mensajeError, setMensajeError] = useState('');
  //let { iniciarSesion, cerrarSesion } = React.useContext(AppContext);

  // por ahora no es necesario por que el api de advanced validar y leer el accesstoken de sintia4
  /*
      function loginAdv(values: { userName: string; password: string; }) {
          
          return advClient.post('/Account/ValidLogin', {
              UserName: values.userName, 
              Password: values.password
          }).then(respuesta => {
                      tokenStorageADV.setUserName(values.userName);
                      tokenStorageADV.setPassword(values.password);
                      tokenStorageADV.setAccessToken(respuesta.data.AccessToken);
                      tokenStorageADV.setRefreshToken(respuesta.data.RefreshToken);
                      ///iniciarSesion(respuesta.data.AccessToken);
                  }).catch(error => {
                          if (error.response && error.response.status === 400) {
                              let mensajesError: any[] = [];
                              Object.keys(error.response.data).forEach(key => {
                                  error.response.data[key].forEach((mensaje: any) => mensajesError.push(mensaje));
                              });
                              //setMensajeError(mensajesError.join('\r\n'));
                              //cerrarSesion();
                          } else {
                              console.error('Error al iniciar sesion', error);
                              //setMensajeError('Hubo un error al iniciar sesión');
                              //cerrarSesion();
                          }
                      });
      }
  */


  async function getDeclaracionesOficializadas(fechaDesde: any, fechaHasta: any, cuitDespachante: any, cuitImpoExpo: any, aduana: any) {

    let fechaDesdeFinal = DateTime.fromISO(fechaDesde).toFormat('dd/MM/yyyy');
    let fechaHastaFinal = DateTime.fromISO(fechaHasta).toFormat('dd/MM/yyyy'); //DateTime.fromISO(fechaHasta).plus({ days: 1 }).plus({ seconds: -1 }).toISODate();

    //loginAdv({ userName: 'javierg@alpha2000.com.ar', password: '20221486312' });
    //   loginAdv({ userName: 'testadvanced', password: 'Alph@.2oo2' });

    let respuesta = await advClient.post('/KitMaria/getDeclaracionesOficializadas',
      {
        FechaDesde: fechaDesdeFinal,
        FechaHasta: fechaHastaFinal,
        cuitDespachante: cuitDespachante,
        cuitImpoExpo: cuitImpoExpo,
        aduana: aduana
      });
    return respuesta.data;
  }


  async function getRatificacionesDespachosHistoricosPorFecha(cuitDespachante: any, fechaDesde: any, fechaHasta: any) {

    let fechaDesdeFinal = fechaDesde //DateTime.fromISO(fechaDesde).toFormat('dd/MM/yyyy');
    let fechaHastaFinal = fechaHasta // DateTime.fromISO(fechaHasta).toFormat('dd/MM/yyyy'); //DateTime.fromISO(fechaHasta).plus({ days: 1 }).plus({ seconds: -1 }).toISODate();

    //loginAdv({ userName: 'javierg@alpha2000.com.ar', password: '20221486312' });
    //   loginAdv({ userName: 'testadvanced', password: 'Alph@.2oo2' });

    let respuesta = await advClient.post('/Ratificacion/getRatificacionesDespachosHistoricosPorFecha',
      {
        cuitDespachante: cuitDespachante,
        FechaDesde: fechaDesdeFinal,
        FechaHasta: fechaHastaFinal
      });
    return respuesta.data;
  }

  async function getSubCuentaMaria(
    AduanaConexion: any,
    AduanaRegistro: any,
    CuitDespachante: any,
    CuitImportador: any,
    DepConSaldoPendiente: any,
    fechaDesde: any,
    fechaHasta: any,
    TipoSubCuenta: any) {

    let fechaDesdeFinal = DateTime.fromISO(fechaDesde).toFormat('dd/MM/yyyy');
    let fechaHastaFinal = DateTime.fromISO(fechaHasta).toFormat('dd/MM/yyyy');

    //   ({ userName: 'testadvanced', password: 'Alph@.2oo2' });

    let respuesta = await advClient.post('/KitMaria/getSubCuentasMaria',
      {
        AduanaConexion: AduanaConexion,
        AduanaRegistro: AduanaRegistro,
        CuitDespachante: CuitDespachante,
        CuitImportador: CuitImportador,
        DepConSaldoPendiente: DepConSaldoPendiente,
        fechaDesde: fechaDesdeFinal,
        fechaHasta: fechaHastaFinal,
        TipoSubCuenta: TipoSubCuenta
      });

    return respuesta.data;

  }



  async function getAvisoDeCargaConsulta(despacho: string, cuitDespachante: string, aduana: string) {

    let respuesta = await advClient.post('/KitMaria/avisoDeCargaConsulta',
      {
        despacho: despacho,
        cuitDespachante: cuitDespachante,
        aduana: aduana
      });

    return respuesta.data;

  }


  async function avisoDeCargaEnviar(aduana: string, despacho: string, cuitdespachante: string, fechaaviso: string, horaaviso: string, calle: string, nropuerta: string, localidad: string, observacion: string, telefono: string) {

    let respuesta = await advClient.post('/KitMaria/avisoDeCargaEnviar',
      {
        Aduana: aduana,
        despacho: despacho,
        CuitDespachante: cuitdespachante,
        FechaAviso: fechaaviso,
        HoraAviso: horaaviso,
        Calle: calle,
        NroPuerta: nropuerta,
        Localidad: localidad,
        Observacion: observacion,
        Telefono: telefono
      });

    return respuesta.data;

  };



  async function getConsultaBultos(
    manifiesto: string,
    puerto: string,
    documentoTransporte: string,
    aduana: string) {
    let respuesta = await advClient.post('/KitMaria/getConsultaDeBultos',
      {
        manifiesto: manifiesto,
        puerto: puerto,
        documentoTransporte: documentoTransporte,
        aduana: aduana
      });

    return respuesta.data;
  }

  async function getManifiestos(
    puerto: string,
    documentoTransporte: string,
    aduana: string) {
    let respuesta = await advClient.post('/KitMaria/getConsultaManifiesto',
      {
        puerto: puerto,
        documentoTransporte: documentoTransporte,
        aduana: aduana
      });

    return respuesta.data;
  }



  async function setNotificacionCierreIngresoDeposito(
    cuitDespachante: string, puerto: string, documentoTransporte: string,
    aduana: string, manifiesto: string, email: string) {

    let respuesta = await advClient.post('/KitMaria/setNotificacionCierreIngresoDeposito',
      {
        cuitDespachante: cuitDespachante,
        puerto: puerto,
        documentoTransporte: documentoTransporte,
        aduana: aduana,
        manifiesto: manifiesto,
        email: email
      }).catch(error => { throw error });

    return respuesta.data;

  }


  async function getSesionInfo(cuitDespachante: string) {
    //trae info si esta iniciado sesion en sesion manager
    let respuesta = await advClient.post('/Ratificacion/GetSessionInfo',
      { Cuit: cuitDespachante }
    );

    return respuesta.data;

  }


  async function iniciarSesionAFIP(cuit: string, clave: string, token: string, captcha: string) {
    //trae info si esta iniciado sesion en sesion manager
    let respuesta = await advClient.post('/Ratificacion/Login',
      {
        Cuit: cuit,
        Password: (clave === '' || undefined) ? token : clave,
        Token: (clave === '' || undefined) ? '' : token,
        Captcha: (captcha === undefined || null) ? '' : captcha
      }
    );

    return respuesta.data;
  }


  async function getConstanciaAFIP(cuit: string) {
    //trae info si esta iniciado sesion en sesion manager
    let respuesta = await advClient.post('/ConsultaConstacia/getDatos',
      { idPersona: cuit }
    );



    /* RESPUESTA
    {
        "Caracterizacion": "caracterizacion: ...",
        "CodPostal": "codPostal: \\\"3283\\\"",
        "DatoAdicional": "datoAdicional: \\\"RUTA PROV N 26\\\"",
        "Direccion": "direccion: \\\"KILOMETRO 5 RUTA PRO 0\\\"",
        "IdProvincia": "idProvincia: 5",
        "Localidad": "localidad: \\\"SAN JOSE\\\"",
        "DescripcionProvincia": "descripcionProvincia: \\\"ENTRE RIOS\\\"",
        "TipoDomicilio": "tipoDomicilio: \\\"FISCAL\\\"",
        "EstadoClave": "estadoClave: \\\"ACTIVO\\\"",
        "IdPersonaRta": "idPersona: 30514392001",
        "RazonSocial": "razonSocial: \\\"LAS CAMELIAS S A\\\"",
        "Apellido": "apellido: null",
        "Nombres": "nombre: null",
        "TipoClave": "tipoClave: \\\"CUIT\\\"",
        "TipoPersona": null,
        "ImpuestoIVA": "idImpuesto: 30",
        "ImpuestoIVAEXENTO": null,
        "ImpuestoMONOTRIBUTO": null
    }
        "Apellido": "apellido: \\\"GONZALEZ\\\"",
        "Nombres": "nombre: \\\"FLORENCIO JAVIER\\\"",

    */




    return respuesta.data;
  }

  const getObtenerDespachosFiltros = async (param: ObtenerDespachoFiltrosRequest): Promise<ObtenerDespachoFiltrosResponse[]> => {
    try {
      let respuesta = await advClient.post<ObtenerDespachoFiltrosRequest, any>('/Advanced/ObtenerDespachosFiltros', param);
      return respuesta.data;
    } catch (err) {
      throw new Error(`Error getObtenrDespachosFiltros: ${err}`);
    }
  };


  const getObtenerItemVista = async (id: string): Promise<ObtenerItemVistaResponse[]> => {
    try {
      let respuesta = await advClient.get(`/Advanced/ObtenerItemsVista?DespachoID=${id}`);
      return respuesta.data.Items;
    } catch (err) {
      console.error(err);
      throw new Error(`Error getObtenerItemVista: ${err}`);
    }
  };

  const getObtenerArticulosVista = async (id: string): Promise<ObtenerArticulosVistaResponse[]> => {
    try {
      let respuesta = await advClient.get(`/Advanced/ObtenerArticulosVista?DespachoID=${id}`);
      return respuesta.data.Articulos;
    } catch (err) {
      console.error(err);
      throw new Error(`Error getObtenerArticulosVista: ${err}`);
    }
  };

  const getObtenerArticulosLiq = async (id: string): Promise<ObtenerArticuosLiqVistaResponse[]> => {
    try {
      let respuesta = await advClient.get(`/Advanced/ObtenerArticulosLiquidacionVista?DespachoID=${id}`);
      return respuesta.data.Liquidaciones;
    } catch (err) {
      console.error(err);
      throw new Error(`Error getObtenerArticulosLiquidaciónVista: ${err}`);
    }
  };

  const getObtenerDespachoCaratula = async (id: string): Promise<ObtenerDespachoCaratulaResponse> => {
    try {
      let respuesta = await advClient.get(`/Advanced/ObtenerDespachoCaratula?DespachoID=${id}`);
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error getObtenerArticulosLiquidaciónVista: ${err}`);
    }
  }

  const updateDespachoCaratula = async (datosUpdate: UpdateDespachoCaratulaBody) => {
    try {
      let respuesta = await advClient.post(`/Advanced/UpdateDespachoCaratula`, { ...datosUpdate });
      return respuesta?.data
    } catch (error) {
      console.error(error);
      throw new Error(`Error updateDespachoCaratula: ${error}`);
    }
  }

  const insertDVD = async (dataDVD: InsertDVDBody) => {
    try {
      let respuesta = await advClient.post(`/Advanced/InsertDVD`, { ...dataDVD });
      return respuesta?.data
    } catch (error) {
      throw new Error(`Error: ${error}`);
    }
  }

  const deleteDVD = async (idDVD: number) => {
    try {
      let respuesta = await advClient.get(`/Advanced/DeleteDVD?ID=${idDVD}`);
      return respuesta?.data
    } catch (error) {
      throw new Error(`Error: ${error}`);
    }
  }

  const insertCPL = async (dataCPL: InsertCPLBody) => {
    try {
      let respuesta = await advClient.post('/Advanced/InsertCPL', { ...dataCPL });
      return respuesta?.data
    } catch (error) {
      throw new Error(`Error: ${error}`);
    }
  }

  const deleteCPL = async (idCPL: number) => {
    try {
      let respuesta = await advClient.get(`/Advanced/DeleteCPL?ID=${idCPL}`);
      return respuesta?.data
    } catch (error) {
      throw new Error(`Error: ${error}`);
    }
  }



  const subirTXT = async (archivoTXT: FormData): Promise<SubirArchivos[]> => {
    try {
      let respuesta = await advClient.post(`Advanced/SubirArchivos`, archivoTXT);
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error al subir archivo: ${err}`);
    }
  }

  const obtenerEstadoArchivos = async (): Promise<ObtenerEstadoArchivos[]> => {
    try {
      let respuesta = await advClient.get(`Advanced/ObtenerEstadoArchivos`);
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const eliminarArchivos = async (ids: number[]) => {
    try {
      let respuesta = await advClient.post(`Advanced/EliminarArchivos`, ids);
      return respuesta?.data;
    } catch (err) {
      console.error(err);
      throw new Error(`Error: ${err}`);
    }
  }

  const getObtenerItemDetalleVista = async (despachoID: string, NroItem: string) => {
    try {
      let respuesta = await advClient.get(`Advanced/ObtenerItemDetalleVista?DespachoID=${despachoID}&NroItem=${NroItem}`);
      return respuesta?.data;
    } catch (error) {
      throw new Error(`Error: ${error}`);

    }
  }

  const obtenerListaArchivos = async (bodyListarArchivos: bodyListarArchivos): Promise<ResponseListarArchivos> => {
    try {
      let respuesta = await advClientApiPostmaria.post(`Archivos/ListarArchivos`, { ...bodyListarArchivos });
      return respuesta?.data
    } catch (error) {
      throw new Error(`Error: ${error}`);
    }
  }

  const subirArchivosVarios = async (archivoASubir: any, options: AxiosRequestConfig) => {
    try {
      let respuesta = await advClientApiPostmaria.post(`/Archivos/GuardarArchivo`, { ...archivoASubir }, options,);
      return respuesta?.data
    } catch (error) {
      throw new Error(`Error: ${error}`);
    }
  }

  const eliminarArchivo = async (idArchivo: number) => {
    try {
      let respuesta = await advClientApiPostmaria.post(`/Archivos/EliminarArchivo`, { 'ID': idArchivo });
      return respuesta?.data
    } catch (error) {
      throw new Error(`Error: ${error}`);
    }
  }

  const descargarArchivo = async (idArchivo: number, options: AxiosRequestConfig,) => {
    try {
      let respuesta = await advClientApiPostmaria.get(`/Archivos/DescargarArchivo?ID=${idArchivo}`, options);
      return respuesta?.data
    } catch (error) {
      throw new Error(`Error: ${error}`);
    }
  }

  const editarObservacionArchivo = async (id: number, Observacion: string,) => {
    try {
      let respuesta = await advClientApiPostmaria.post(`/Archivos/ModificarObservacionArchivo`, { ID: id, Observacion: Observacion });
      return respuesta?.data
    } catch (error) {
      throw new Error(`Error: ${error}`);
    }
  }

  const descargarInternoCompleto = async ( bodyDatosCliente:any) => {
    try {
      let respuesta = await advClientApiPostmaria.post(`/Archivos/DescargarInternoCompleto`, bodyDatosCliente);
      return respuesta?.data
    } catch (error) {
      throw new Error(`Error: ${error}`);
    }
  }
  return {
    getDeclaracionesOficializadas,
    getAvisoDeCargaConsulta,
    avisoDeCargaEnviar,
    getSubCuentaMaria,
    getConsultaBultos,
    getManifiestos,
    setNotificacionCierreIngresoDeposito,
    getRatificacionesDespachosHistoricosPorFecha,
    getSesionInfo,
    iniciarSesionAFIP,
    getConstanciaAFIP,
    getObtenerDespachosFiltros,
    getObtenerItemVista,
    getObtenerArticulosVista,
    getObtenerArticulosLiq,
    getObtenerDespachoCaratula,
    subirTXT,
    obtenerEstadoArchivos,
    eliminarArchivos,
    getObtenerItemDetalleVista,
    obtenerListaArchivos,
    subirArchivosVarios,
    eliminarArchivo,
    descargarArchivo,
    updateDespachoCaratula,
    insertDVD,
    deleteDVD,
    insertCPL,
    deleteCPL,
    editarObservacionArchivo,
    descargarInternoCompleto
  }

}