
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { AppContext } from "App";
import { DialogoConfirmar, DialogoConfirmarRef } from "../../DialogoConfirmar";
import BlockUi from "react-block-ui";
import { MyModal } from "../../MyModal";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import { MyForm, MyFormControl } from "../../FormikHooks";
import { isNullOrWhiteSpace } from "Utilidades";
import { strCmp } from "../../Utilidades";
import { useApi } from "ApiHooks";

export function ReferenciasPresupuesto(props: {
    referenciasExistentes: string[],
    referenciaSeleccionada: (referencia: string) => void
    setSubmitRef: (args: boolean) => void
    setValoresNuevaReferencia: any
}) {
    let [referencias, updateReferencias] = useState<any[]>([]);
    let [cargando, updateCargando] = useState(true);
    let dialogoRef = useRef<DialogoConfirmarRef>(null);
    let { mostrarError, userInfo } = useContext(AppContext);
    let api = useApi();
    let [mensajeDialogoConfirmar, updateMensajeDialogoConfirmar] = useState('');
    let formikRef = useRef<FormikProps<any>>(null);
    let [mostrarModalCreando, updateMostrarModalCreando] = useState(false);
    const [valueReferencia, setValueReferencia] = useState('')
    const [valorReferencia, setValorReferencia] = useState('')
    let selectRef = useRef<any>(null);
    useEffect(() => {
        async function cargar() {
            try {
                let respuesta = await api.getTiposReferenciaPresupuesto();
                updateReferencias(respuesta?.sort((a: any, b: any) => strCmp(a.Nombre, b.Nombre)) ?? []);
                updateCargando(false);
            } catch (error) {
                if (!api.isCancel(true)) {
                    console.error('Error al cargar tipos referencia presupuesto', error);
                    mostrarError('Error al cargar tipos referencia presupuesto');
                }
            }
        }
        cargar();
        //eslint-disable-next-line
    }, []);
    async function eventoEliminar() {
        let nombreReferencia = selectRef.current?.value;
        if (isNullOrWhiteSpace(nombreReferencia)) {
            mostrarError('Debe seleccionar una referencia para eliminarla');
            return;
        }
        updateMensajeDialogoConfirmar(`¿Está seguro que desea eliminar la referencia ${nombreReferencia}?`);
        dialogoRef.current!.mostrar().then(async () => {
            try {
                updateCargando(true);
                await api.deleteTipoReferenciaPresupuesto(nombreReferencia);
                updateReferencias(referencias.filter((c: any) => c.Nombre !== nombreReferencia).sort((a: any, b: any) => strCmp(a.Nombre, b.Nombre)) ?? []);
                updateCargando(false);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al eliminar tipo referencia', error);
                    mostrarError('Error al eliminar tipo referencia');
                }
                if (!api.isUnmounted()) {
                    updateCargando(false);
                }
            }
        }).catch(() => { });
    }
    return <>
        {/* <h2>Referencias presupuesto</h2> */}
        <BlockUi blocking={cargando}>
            <Button className="mb-2 mr-2" onClick={() => updateMostrarModalCreando(true)}>
                <FontAwesomeIcon icon={faPlus} />
                <span>Agregar</span>
            </Button>
            <Button className="mb-2" variant="danger" onClick={eventoEliminar}>
                <FontAwesomeIcon icon={faTrash} />
                <span>Eliminar</span>
            </Button>
            <p>Haga doble click para seleccionar una referencia</p>
            <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Control as="select" ref={selectRef} value={valueReferencia} onChange={(e) => {
                    setValueReferencia(e?.target?.value)
                }}>
                    <option key={''} value={''}>Seleccione</option>
                    {referencias?.map((referencia) => (
                        <option key={referencia?.Nombre} value={referencia?.Nombre}>{referencia?.Nombre}</option>
                    ))}
                </Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Control type="text" value={valorReferencia} onChange={(e) => {
                    setValorReferencia(e?.target?.value)
                }} placeholder="Valor" />
            </Form.Group>

            <Button onClick={() => {
                if (!isNullOrWhiteSpace(valueReferencia)) {
                    if (props.referenciasExistentes.includes(valueReferencia)) {
                        mostrarError('Esta referencia ya fue ingresada');
                        setValueReferencia('')
                    } else {
                        props.setValoresNuevaReferencia({
                            "Nombre": valueReferencia,
                            "Valor": valorReferencia
                        })
                        props.referenciaSeleccionada(valueReferencia)
                        props?.setSubmitRef(true)
                    }
                }
            }}>
                Ingresar
            </Button>
            {/* <select size={10} className="custom-select" ref={selectRef} onDoubleClick={event => {
                if (!isNullOrWhiteSpace(selectRef.current?.value)) {
                    if (props.referenciasExistentes.includes(selectRef.current!.value)) {
                        mostrarError('Esta referencia ya fue ingresada');
                    } else {
                        props.referenciaSeleccionada(selectRef.current!.value);
                    }
                }
            }}>
                {referencias.map((r: any) => <option key={r.Nombre} value={r.Nombre}>{r.Nombre}</option>)}
            </select> */}

        </BlockUi>
        <DialogoConfirmar ref={dialogoRef} mensaje={mensajeDialogoConfirmar} textoBotonCancelar="No" textoBotonConfirmar="Sí"></DialogoConfirmar>
        <MyModal show={mostrarModalCreando}
            onHide={() => updateMostrarModalCreando(false)}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    <h2>Crear Referencia</h2>
                </Modal.Header>
                <Formik innerRef={formikRef} validationSchema={Yup.object({
                    'Nombre': Yup.string().nullable().required('Debe ingresar el nombre'),
                })} initialValues={{
                    'Nombre': '',
                }} onSubmit={async (nuevaReferencia: any, actions) => {
                    let referenciasExistentes = referencias.map((c: any) => c.Nombre);
                    if (referenciasExistentes.includes(nuevaReferencia.Nombre)) {
                        actions.setFieldError('Nombre', `La referencia con nombre ${nuevaReferencia.Nombre} ya fue ingresada`);
                    } else {
                        try {
                            nuevaReferencia = {
                                NroClienteAlpha: userInfo.nroClienteAlpha,
                                Nombre: nuevaReferencia.Nombre
                            };
                            await api.insertTipoReferenciaPresupuesto(nuevaReferencia);
                            let nuevasReferencias = Array.from(referencias);
                            nuevasReferencias.push(nuevaReferencia);
                            updateReferencias(nuevasReferencias.sort((a: any, b: any) => strCmp(a.Nombre, b.Nombre)));
                            updateMostrarModalCreando(false);
                        } catch (error) {
                            if (!api.isCancel(error)) {
                                console.error('Error al guardar referencia', error);
                                mostrarError('Error al guardar referencia');
                            }
                        }
                    }
                }}>{({ submitForm, isSubmitting }) => <>
                    <Modal.Body>
                        <MyForm>
                            <Form.Group>
                                <MyFormControl type="text" name="Nombre" label="Nombre"></MyFormControl>
                            </Form.Group>
                        </MyForm>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => updateMostrarModalCreando(false)}>
                            Cancelar
                        </Button>
                        <Button disabled={isSubmitting} onClick={submitForm}>
                            Ingresar
                        </Button>
                    </Modal.Footer>
                </>}
                </Formik>
            </Modal.Dialog>
        </MyModal>
    </>;
}