import React from "react";
import { useHistory } from "react-router";
import { AppContext } from "../../App";
import { Button, Form } from "react-bootstrap";
import Grilla, { GrillaRef, TipoCampo } from "../../Grilla";
import { BaseCreatableSelect as Select } from "BaseSelect";
import { isNullOrWhiteSpace } from "../../Utilidades";
import { DateTime } from "luxon";
import { CancelToken } from "SintiaHooks";
import { useApi } from "ApiHooks";
import styled from "styled-components";

type SelectOption = { label: string, value: string };


const SelectCustom = styled(Select)`
    width:400px;
`;

export function BitacorasArticulosCatalogo() {
    let { mostrarError } = React.useContext(AppContext);
    let history = useHistory();
    let api = useApi();
    let refGrilla = React.useRef<GrillaRef>(null);
    let [optionsCatalogos, updateOptionsCatalogos] = React.useState<SelectOption[]>([]);
    function busquedaReducer(estado: any, accion: any) {
        if (accion.tipo === 'catalogo') {
            return { ...estado, catalogo: accion.valor };
        } else if (accion.tipo === 'codigoArticulo') {
            return { ...estado, codigoArticulo: accion.valor };
        } else if (accion.tipo === 'fechaDesde') {
            return { ...estado, fechaDesde: accion.valor };
        } else if (accion.tipo === 'fechaHasta') {
            return { ...estado, fechaHasta: accion.valor };
        } else if (accion.tipo === 'usuario') {
            return { ...estado, usuario: accion.valor };
        }
        return estado;
    }
    let busquedaInicial = {
        codigoArticulo: '', catalogo: '',
        fechaDesde: DateTime.local().plus({ day: -7 }).toISODate(),
        fechaHasta: DateTime.local().plus({ day: 1 }).toISODate(), usuario: ''
    };
    let [busquedaActual, updateBusquedaActual] = React.useState(busquedaInicial);
    let [busqueda, updateBusqueda] = React.useReducer(busquedaReducer, { ...busquedaInicial });
    let campos = [{ titulo: '', propiedad: 'Id', clave: true, visible: false },
    { titulo: 'Usuario', propiedad: 'NombreUsuario' },
    {
        titulo: 'Accion', propiedad: 'Tipo', plantillaFormato: (valor: any) => {
            if (valor === 0) {
                return 'Alta';
            } else if (valor === 1) {
                return 'Baja';
            } else if (valor === 2) {
                return 'Modificación';
            }
            return '';
        }
    }, { titulo: 'Fecha', propiedad: 'Fecha', tipo: TipoCampo.DateTime },
    { titulo: 'Catalogo', propiedad: 'CodigoCatalogo' },
    { titulo: "Nro. Articulo", propiedad: 'CodigoArticulo' },
    { titulo: 'Ncm', propiedad: 'Ncm' }, { titulo: 'Descripcion Catalogo', propiedad: 'Descripcion' },
    { titulo: 'Sufijos', propiedad: 'Sufijos' }, { titulo: 'Pais Origen', propiedad: 'NombrePaisOrigen' }];
    React.useEffect(() => {
        async function cargarCatalogos() {
            try {
                let respuesta = await api.getCatalogos();
                let options = respuesta.map((catalogo: any) => ({ value: catalogo.Codigo, label: catalogo.Descripcion }));
                updateOptionsCatalogos(options);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar catalogos', error);
                    mostrarError('Error al cargar los catalogos');
                }
            }
        }
        cargarCatalogos();
        //eslint-disable-next-line 
    }, []);
    React.useEffect(() => {
        refGrilla.current?.recargar();
    }, [busquedaActual]);
    async function cargarDatos(desde: number, hasta: number, cancelToken: CancelToken) {
        let respuesta = await api.busquedaBitacoraArticulosCatalogoPaginado(busquedaActual.catalogo,
            busquedaActual.codigoArticulo, busquedaActual.fechaDesde, busquedaActual.fechaHasta,
            busquedaActual.usuario, desde, hasta, cancelToken);
        let paises = await api.getPaises();
        let items = respuesta.Items.map((item: any) => ({
            ...item,
            NombrePaisOrigen: paises.find((pais: any) => pais.Codigo === item.CodigoPaisOrigen)?.Descripcion
        }));
        return { cantidadItems: respuesta.CantidadTotal, items: items };
    }
    function getValueComboCatalogos() {
        if (isNullOrWhiteSpace(busqueda.catalogo)) {
            return null;
        } else {
            let option = optionsCatalogos.find(o => o.value === busqueda.catalogo);
            return { value: busqueda.catalogo, label: option ? option.label : busqueda.catalogo };
        }
    }
    return (<>
        <h2>Bitacoras de articulos de catalogo</h2>
        <Form inline onSubmit={e => {
            updateBusquedaActual(busqueda);
            e.preventDefault();
        }}>
            <Form.Label htmlFor="cboCatalogo" className="mr-2">Catalogo</Form.Label>
            <SelectCustom id="cboCatalogo" className="mr-2" isClearable options={optionsCatalogos} placeholder="Seleccionar..."
                value={getValueComboCatalogos()} createOptionPosition="first"
                formatCreateLabel={(valor: any) => valor} onChange={(option: any) => {
                    updateBusqueda({ tipo: 'catalogo', valor: option?.value ?? '' });
                }} getOptionLabel={(option: SelectOption) => option.value + ' - ' + option.label}></SelectCustom>
            <Form.Label htmlFor="txtCodigoArticulo" className="mr-2">Codigo Articulo</Form.Label>
            <Form.Control type="text" id="txtCodigoArticulo" value={busqueda.codigoArticulo} className="mb-2 mr-2"
                onChange={e => updateBusqueda({ tipo: 'codigoArticulo', valor: e.target.value })}></Form.Control>
            <Form.Label htmlFor="txtUsuario" className="mr-2">Usuario</Form.Label>
            <Form.Control type="text" id="txtUsuario" value={busqueda.usuario} className="mb-2 mr-2"
                onChange={e => updateBusqueda({ tipo: 'usuario', valor: e.target.value })}></Form.Control>
            <Form.Group>
                <Form.Label htmlFor="txtFechaDesde" className="mx-2">Desde</Form.Label>
                <Form.Control type="date" id="txtFechaDesde" value={busqueda.fechaDesde} className="mb-2 mr-2"
                    onChange={e => updateBusqueda({ tipo: 'fechaDesde', valor: e.target.value })}></Form.Control>
                <Form.Label htmlFor="txtFechaHasta" className="mr-2">Hasta</Form.Label>
                <Form.Control type="date" id="txtFechaHasta" value={busqueda.fechaHasta} className="mb-2 mr-2"
                    onChange={e => updateBusqueda({ tipo: 'fechaHasta', valor: e.target.value })}></Form.Control>
            </Form.Group>
            <Button type="submit" className="mb-2">Buscar</Button>
        </Form>
        <Grilla campos={campos} cargarDatos={cargarDatos}
            eventoDetalle={item => history.push(`/bitacoras/articulos/${encodeURIComponent(item.Id)}`)}
            ref={refGrilla} />
    </>);
}