import { createContext, useCallback, useContext, useLayoutEffect, useRef, useEffect } from "react";
import { useHistory } from "react-router";
import axios from "axios";
export type { CancelToken } from "axios";

export class UnmountedError extends Error {
    constructor(message?: string) {
        // Pasa los argumentos restantes (incluidos los específicos del proveedor) al constructor padre
        super(message);

        // Mantiene un seguimiento adecuado de la pila para el lugar donde se lanzó nuestro error (solo disponible en V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, UnmountedError)
        }

        this.name = 'UnmountedError'
    }
}

export function useCancel() {
    const cancelTokenSource = useRef(axios.CancelToken.source());
    const desmontado = useRef({ valor: false });
    useEffect(() => {
        return () => {
            cancelTokenSource.current.cancel();
            //eslint-disable-next-line
            desmontado.current.valor = true;
        }
    }, []);
    return {
        getCancelToken: () => cancelTokenSource.current.token,
        cancelCurrentTokens: () => {
            cancelTokenSource.current.cancel();
            cancelTokenSource.current = axios.CancelToken.source();
        },
        isUnmounted: () => desmontado.current.valor,
        isCancel: (value: any) => axios.isCancel(value) || value instanceof UnmountedError
    }
}

export function useRedirectEventCallback(callback: () => Promise<boolean>) {
    const history = useHistory();
    const { setCurrentRedirectCallback } = useContext(RedirectEventCallbackContext);
    //usar useLayoutEffect en vez de useEffect para garantizar que se limpie el callback antes que otro
    //componente se monte
    useLayoutEffect(() => {
        setCurrentRedirectCallback(callback);
        //eslint-disable-next-line
    }, [callback]);

    useLayoutEffect(() => {
        const unblock = history.block('');
        return () => {
            setCurrentRedirectCallback(null);
            unblock();
        }
        //eslint-disable-next-line
    }, []);
    return { disableRedirectEventCallback: () => setCurrentRedirectCallback(null) };
}

interface RedirectEventCallbackContextState {
    runAfterUserConfirmation: (callback: (result: boolean) => void) => Promise<void>,
    getCurrentRedirectCallback: () => (() => Promise<boolean>) | null,
    setCurrentRedirectCallback: (redirectCallback: (() => Promise<boolean>) | null) => void
}

export const RedirectEventCallbackContext = createContext<RedirectEventCallbackContextState>({
    runAfterUserConfirmation: () => Promise.resolve(),
    getCurrentRedirectCallback: () => null,
    setCurrentRedirectCallback: () => { }
});

export function useRedirectEventCallbackContextState() {
    let redirectCallback = useRef<(() => Promise<boolean>) | null>(null);
    const runAfterUserConfirmation = useCallback(async (routerCallback: (result: boolean) => void) => {
        if (redirectCallback.current) {
            const continuar = await redirectCallback.current();
            routerCallback(continuar);
        } else {
            routerCallback(true);
        }
    }, []);
    return {
        runAfterUserConfirmation: runAfterUserConfirmation,
        getCurrentRedirectCallback: () => redirectCallback.current,
        setCurrentRedirectCallback: (callback: (() => Promise<boolean>) | null) => {
            redirectCallback.current = callback;
        }
    };
}
