import bsCustomFileInput from "bs-custom-file-input";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import BlockUi from "react-block-ui";
import { Button, Form, Modal } from "react-bootstrap";
import { useHistory } from "react-router";
import { AppContext } from "../../App";
import { GrillaRef, GrillaSync } from "../../Grilla";
import { DialogoConfirmar, DialogoConfirmarRef } from "../../DialogoConfirmar";
import { MyModal } from "../../MyModal";
import Loader from "react-loaders";
import { useApi } from "ApiHooks";

function CargarArchivoJson(archivo: File) {
    return new Promise<any>((resolve, reject) => {
        let fileReader = new FileReader();
        fileReader.onload = () => {
            try {
                let contenido = JSON.parse(fileReader.result as string);
                resolve(contenido);
            } catch (error) {
                reject(error);
            }
        };
        fileReader.onerror = () => reject(fileReader.error);
        fileReader.readAsText(archivo);
    });
}

export function ImportarCaratulas() {
    let history = useHistory();
    let { mostrarError, userInfo } = useContext(AppContext);
    let api = useApi();
    let [mensajeSpinner, updateMensajeSpinner] = useState('');
    let [mensajeExito, updateMensajeExito] = useState('');
    let [mensajeDialogo, updateMensajeDialogo] = useState('');
    let [mensajeErrorArchivo, updateMensajeErrorArchivo] = useState('');
    let [cargando, updateCargando] = useState(false);
    let grillaRef = useRef<GrillaRef>(null);
    let dialogoRef = useRef<DialogoConfirmarRef>(null);
    let [caratulasBloqueadas, updateCaratulasBloqueadas] = useState<string[]>([]);
    let [estado, updateEstado] = useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'cargarJson') {
            return {
                json: accion.valor, filasSeleccion: accion.valor.Entradas.map((entrada: any) => ({
                    Interno: entrada.Caratula.Carpeta,
                    Aduana: entrada.Caratula.CodigoAduana,
                    Subregimen: entrada.Caratula.CodigoSubregimen,
                    Cliente: entrada.NombreImportador,
                    Despachante: entrada.NombreDespachante,
                    Vendedor: entrada.NombreVendedor,
                    Fecha: entrada.Caratula.CreadoEl
                })),
            };
        }
        return estado;
    }, { json: null, filasSeleccion: [] });
    useEffect(() => {
        bsCustomFileInput.init();
    }, []);
    let campos = [{ titulo: 'Interno', propiedad: 'Interno', clave: true },
    { titulo: 'Subregimen', propiedad: 'Subregimen' }, { titulo: 'Despachante', propiedad: 'Despachante' },
    { titulo: 'Cliente', propiedad: 'Cliente' }];
    async function importarCarpetas() {
        let internosSeleccionados = grillaRef.current?.getClavesSeleccionadas();
        if (!internosSeleccionados || internosSeleccionados.length === 0) {
            mostrarError('Debe seleccionar al menos una carpeta para importar');
            return;
        }
        try {
            updateMensajeSpinner('Importando caratulas');
            let caratulasQueExisten: string[] = [];
            let bloqueadas: string[] = [];
            for (let interno of internosSeleccionados) {
                let respuesta = await api.getCaratula(interno);
                if (respuesta) {
                    caratulasQueExisten.push(interno);
                    if (respuesta.Bloqueada) {
                        bloqueadas.push(interno);
                    }
                }
            }
            if (bloqueadas.length > 0) {
                updateCaratulasBloqueadas(bloqueadas);
                updateMensajeSpinner('');
                return;
            }
            if (caratulasQueExisten.length > 0) {
                updateMensajeDialogo(`Los siguientes internos existen: ${caratulasQueExisten.join(',')}\r\nSi importa los internos se sobreescribirán los internos que ya existen y no podrá recuperarlos. ¿Desea continuar?`);
                if (!await dialogoRef.current!.mostrar()) {
                    updateMensajeSpinner('');
                    return;
                }
            }
            let caratulas: any[] = [];
            let subitems: any[] = [];
            let cancelaciones: any[] = [];
            for (let entrada of estado.json.Entradas.filter((e: any) => internosSeleccionados!.includes(e.Caratula.Carpeta))) {
                caratulas.push({
                    ...entrada.Caratula,
                    NroClienteAlpha: userInfo.nroClienteAlpha,
                    EmpresaId: userInfo.empresaActual,
                    Importado: true,
                    CreadoPor: userInfo.claims.nameid,
                    CreadoEl: DateTime.local().toISO(),
                    ModificadoPor: userInfo.claims.nameid,
                    ModificadoEl: DateTime.local().toISO(),
                });
                if (entrada.Subitems) {
                    subitems = subitems.concat(entrada.Subitems.map((s: any) => ({
                        ...s,
                        NroClienteAlpha: userInfo.nroClienteAlpha,
                        EmpresaId: userInfo.empresaActual,
                    })));
                }
                if (entrada.Cancelaciones) {
                    cancelaciones = cancelaciones.concat(entrada.Cancelaciones.map((c: any) => ({
                        ...c,
                        NroClienteAlpha: userInfo.nroClienteAlpha,
                        EmpresaId: userInfo.empresaActual,
                    })));
                }
            }
            await api.insertVariasCaratulas(caratulas, subitems, cancelaciones);
            history.replace('/caratulas');
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al importar caratulas', error);
                mostrarError('Error al importar caratulas');
            }
            if (!api.isUnmounted()) {
                updateMensajeSpinner('');
            }
        }
    }
    return <>
        <h2>Importar Carpetas</h2>
        <BlockUi blocking={cargando}>
            <Form>
                <Form.File custom>
                    <Form.File.Input id="inputArchivoCarpetas" accept=".sint2,.sint4" onChange={async (e: any) => {
                        if (!e.target.files || e.target.files.length === 0) {
                            updateMensajeErrorArchivo('Debe seleccionar un archivo');
                            return;
                        }
                        updateCargando(true);
                        try {
                            if (e.target.files[0].name.endsWith('.sint2')) {
                                let respuesta = await api.parsearArchivoSintia3(e.target.files[0]);
                                updateEstado({ tipo: 'cargarJson', valor: respuesta });
                            } else {
                                let datos = await CargarArchivoJson(e.target.files[0]);
                                updateEstado({ tipo: 'cargarJson', valor: datos });
                            }
                            updateCargando(false);
                        } catch (error) {
                            if (!api.isCancel(error)) {
                                console.error('Error al cargar archivo', error);
                                updateMensajeErrorArchivo('Error al cargar archivo.');
                                updateCargando(false);
                            }
                        }
                    }}></Form.File.Input>
                    <Form.File.Label data-browse="Seleccionar">Archivo Sintia</Form.File.Label>
                    <Form.Control.Feedback type="invalid">{mensajeErrorArchivo}</Form.Control.Feedback>
                </Form.File>
            </Form>
            <Button variant="danger" className="my-2" onClick={() => history.push('/caratulas')}>Volver a Caratulas</Button>
            {estado.json && (<>
                <Button className="m-2" onClick={importarCarpetas}>Importar Internos</Button>
                <GrillaSync ref={grillaRef} datos={estado.filasSeleccion} campos={campos} seleccionMultiple /></>)}
        </BlockUi>
        <MyModal show={caratulasBloqueadas.length > 0} onHide={() => updateCaratulasBloqueadas([])}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="text-danger lead">Las siguientes caratulas están bloqueadas. Para sobreescribirlas desbloquearlas primero</p>
                    <Form>
                        <Form.Control as="textarea" readOnly rows={5} value={caratulasBloqueadas.join('\r\n')}></Form.Control>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => updateCaratulasBloqueadas([])}>Cerrar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={!!mensajeSpinner}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="lead">{mensajeSpinner}</p>
                    <div className="loader-container">
                        <Loader type="ball-spin-fade-loader" active></Loader>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        api.cancelCurrentTokens();
                    }}>Cancelar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={!!mensajeExito} onHide={() => updateMensajeExito('')}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="text-success lead">{mensajeExito}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => updateMensajeExito('')}>Cerrar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <DialogoConfirmar resolverPromesaAlCancelar ref={dialogoRef} mensaje={mensajeDialogo} textoBotonConfirmar="Sí" textoBotonCancelar="No" />
    </>;
}