import React, { useEffect, useRef, useState} from "react";
import { Button, Form, Col, Container, Alert, Badge, Tooltip } from "react-bootstrap";
import { MyForm, MyFormControl, MySelect } from "../../FormikHooks";
import { Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";
import { useDecodedParams,  convertirDatosGenericosAOption} from "../../Utilidades";
import { useApi } from "ApiHooks";
import { DateTime } from "luxon";
import { useAdvAPI } from './AdvancedApi';
import { useHistory } from "react-router";
import { DialogoConfirmar, DialogoConfirmarRef } from "DialogoConfirmar";
//import { Console } from "console";
//import { mostrarError } from "App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
//import Select from "react-select/src/Select";
//"fa-solid", "fa-circle-info"


let schema = Yup.object({

    'CuitDespachante': Yup.string().nullable().required('Debe seleccionar un despachante'),
    'Declaracion': Yup.string().required('Debe ingresar una declaracion').test(
        'Ancho', 'Debe tener 16 caracteres',
        (val) => val !== null && val !== undefined && (val.length >0  && val?.length <= 16 )),
    'Calle': Yup.string().nullable().required('Debe ingresar Calle').test(
        'Ancho',
        'Calle debe ser menor o igual a 40 caracteres',
        (val) => val !== null && val !== undefined && (val.length >0  && val.length <= 40 )),
    'Numero': Yup.string().nullable().required('Debe ingresar numero valido').test(
        'Ancho',
        'El numero de calle debe ser menor o igual a 5 digitos',
        (val) => val !== null && val !== undefined && (val.toString().length >0  && val.toString().length <= 5 )),
    'Localidad': Yup.string().nullable().required('Debe ingresar Localidad').test(
        'Ancho',
        'Localidad debe ser menor o igual a 40 caracteres',
        (val) => val !== null && val !== undefined && (val.length >0  && val.length <= 30 )),
    'Telefono': Yup.string().nullable().required('Debe ingresar Telefono').test(
        'Ancho',
        'Telefono debe ser menor o igual a 40 caracteres',
        (val) => val !== null && val !== undefined && (val.length >0  && val.length <= 30 )),
    'Fecha': Yup.string().nullable().required('Debe ingresar Fecha'),
    'Hora': Yup.string().nullable().required('Debe ingresar Hora'),
    'Observaciones': Yup.string().optional().max(250, 'Observaciones debe ser menor o igual a 250 caracteres')
    });
/*        (val) => val !== null && val !== undefined && (val.length >=0  && val.length <= 250 ))*/

/*
    Calle maxima logintud: 40
    Locadidad max log: 30
    observaciones: 250
    Nro. Pta.  : 5
    Telefono: 30    
*/
/*
    async function submit(values: any) {
    try {

        let nuevoAviso: any = {
            'CuitDespachante': values.current?.CuitDespachante,
            'Declaracion': values.current.Declaracion,
            'Calle': values.current.Calle,
            'Numero': values.current.Numero,
            'Localidad': values.current.Localidad,
            'Telefono': values.current.Telefono,
            'Fecha': values.current.Fecha,
            'Hora': values.current.Hora,
            'Observaciones': values.current.Observaciones
        }       

/*        let { exito, error } = await api.insertImportador(nuevoImportador, ventajas, plantillas);
        if (exito) {
            if (importador.current) {
                await api.eliminarLock(TipoLock.Importador, importador.current.Id);
            }
            huboCambios.current.valor = false;
            return true;
        } else {
            mostrarError(error as string);
            return false;
        }*/

//        return true;
    //} catch (error) {
        //if (!api.isCancel(error)) {
      //      console.error('Error al insertar importador');
          //  mostrarError('Error al insertar importador');
//        }
//        return false;
//    }

export function Avisodecarga() {
    let api = useApi();
    let formikRef = React.useRef<FormikProps<any>>(null);
    let huboCambios = React.useRef<{ valor: boolean }>({ valor: false });
    let { nroDespacho } = useDecodedParams() as { nroDespacho: string };
    let apiADV = useAdvAPI();
    let history = useHistory();
    let refDialogo = useRef<DialogoConfirmarRef>(null);
    let [mensajeDialogo, updateMensajeDialogo] = useState('');

    const [avcEnviado, updateavcEnviado] = useState(false);
    const [avcEnvioError, updateavcEnvioError] = useState(false);
    const [avcEnvioErrorMsg, updateavcEnvioErrorMsg] = useState('');
    const [IdentificadorAVC, updateIdentificadorAVC] = useState('');
    const [errorGetDespacho, setErrorGetDespacho] = useState('');

    const eventoHuboCambios = () => {
        huboCambios.current.valor = true;
    }
    
/*    formikRef.current?.resetForm({
        values: {
            'CuitDespachante': aviso.current?.CuitDespachante,
            'Declaracion': aviso.current?.Declaracion,
            'Calle': aviso.current?.Calle,
            'Numero': aviso.current?.Numero,
            'Localidad': aviso.current?.Localidad,
            'Telefono': aviso.current?.Telefono,
            'Fecha': aviso.current?.Fecha,
            'Hora': aviso.current?.Hora,
            'Observaciones': aviso.current?.Observaciones
        }})*/


        async function cargarDespacho(despacho:string , cuitDespachante:string) {

            let aduana : string;
            aduana = despacho.substring(2,5);
            let respuesta = await apiADV.getAvisoDeCargaConsulta(
                    despacho,
                    cuitDespachante,
                    aduana
                );

            if (respuesta.Exito) {

                formikRef.current?.setFieldValue('CuitDespachante',cuitDespachante);
                formikRef.current?.setFieldValue('Declaracion',despacho);
                formikRef.current?.setFieldValue('FechaOficializacion',respuesta.ConsultaAvisoDatos.FechaOficializacion);
                formikRef.current?.setFieldValue('Importador',respuesta.ConsultaAvisoDatos.CuitImportador);
                formikRef.current?.setFieldValue('NombreImportador',respuesta.ConsultaAvisoDatos.DescripcionImportador);
                formikRef.current?.setFieldValue('Deposito',respuesta.ConsultaAvisoDatos.CodigoDeposito);
                formikRef.current?.setFieldValue('NombreDeposito',respuesta.ConsultaAvisoDatos.DescripcionDeposito);
                setErrorGetDespacho('');

            } else {

                //formikRef.current?.setFieldValue('CuitDespachante','');
                //formikRef.current?.setFieldValue('Declaracion','');
                formikRef.current?.setFieldValue('FechaOficializacion','');
                formikRef.current?.setFieldValue('Importador','');
                formikRef.current?.setFieldValue('NombreImportador','');
                formikRef.current?.setFieldValue('Deposito','');
                formikRef.current?.setFieldValue('NombreDeposito','');
                setErrorGetDespacho(respuesta.Msg);

            }

        }

        //buscar despacho si presiono enter
        const handleKeyDown = (event :any) => {
            if (event.keyCode === 13) {
                let despachante = formikRef.current?.values.CuitDespachante;
                let despacho = event.target.value;

                if (despacho?.length === 16 && despachante?.length === 11)
                {
                    cargarDespacho(despacho, despachante);
                }
                
            }
        }

        async function tirarAvisoDeCarga(values: any, actions: FormikHelpers<any>) {

                updateavcEnviado(false);
                updateMensajeDialogo(`Confirma dar Aviso de Carga \n Despacho: ${values?.Declaracion} \n Calle ${values?.Calle} Nro ${values?.Numero} \n Localidad: ${values?.Localidad} \n Dia: ${DateTime.fromISO(values?.Fecha).toFormat('dd/MM/yyyy')} Hora ${values?.Hora}?`);

                let promesa = refDialogo.current?.mostrar().then(() => true).catch(() => false);

                if (await promesa) {

                    if (promesa) {
                        try {
                            
                            let respuesta = await apiADV.avisoDeCargaEnviar(
                                values.Declaracion.substring(2,5),
                                values.Declaracion,
                                values.CuitDespachante,
                                DateTime.fromISO(values.Fecha).toFormat('dd/MM/yyyy'),
                                values.Hora,
                                values.Calle,
                                values.Numero,
                                values.Localidad,
                                values.Observaciones,
                                values.Telefono)

                                updateIdentificadorAVC(respuesta);
                                updateavcEnviado(true);
                                updateavcEnvioError(false);
                                formikRef.current?.resetForm();
                                //console.log(respuesta);
                                return true;

                        } catch (error) {
                            if (error.response?.status === 500)
                            {
                                updateavcEnvioErrorMsg(error.response.data);
                            } else {
                                console.log('error.resp ' + error.response);
                                console.log('error.req ' + error.request);
                                updateavcEnvioErrorMsg('Kit Malvina no responde, por favor reintente nuevamente.' + error.response?.status);
                            }
                            updateavcEnvioError(true);
                            //mostrarError('error data ' + error.response.data);
                        }
    
                    }
                    else {
                        return false;
                    }
                }
        }

        useEffect(() => {
            if (nroDespacho) {
                let despacho = nroDespacho.split("|")[0];
                let despachante = nroDespacho.split("|")[1];
                cargarDespacho(despacho, despachante);
            }

        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

    return (

        <Container>

                <br/><p className="h3">Aviso de Carga</p>

                <Formik initialValues={{}} validationSchema={schema} validateOnBlur={true} innerRef={formikRef} onSubmit={tirarAvisoDeCarga}>

                        <MyForm className="col-md-9">
                        {/*<MyForm blocking={cargando} submitEnUltimoElemento>*/}

                            <Form.Row>
                                <Form.Group as={Col}>
                                    <MySelect 
                                        name="CuitDespachante" 
                                        label="Despachante"
                                        options={() => api.getDespachantes().then(convertirDatosGenericosAOption)} onValueChange={eventoHuboCambios}
                                    />

                                </Form.Group>
                            </Form.Row>

                            <span className="font-weight-bold">Declaraciones</span> <br/><br/>

                            <Form.Row>
                                <Form.Group as={Col} className="col-md-3 align-items-center justify-content-center">
                                    
                                    <MyFormControl name="Declaracion"  type="text" label="Declaracion" onKeyDown={handleKeyDown}></MyFormControl>
                                     {errorGetDespacho && <Alert variant="warning">{errorGetDespacho}</Alert>}
                                     <Tooltip id="error" >test error de despacho</Tooltip>

                                </Form.Group>
                                <Form.Group as={Col} className="col-auto align-middle">
                                    <Button className="align-self-end my-4" onClick={() => {(history.push('/advanced/declaracionesoficializadas'));} } >...</Button>
                                </Form.Group>
                                <Form.Group as={Col} className="col-md-8">
                                    <MyFormControl name="FechaOficializacion" readOnly type="text" label="Fecha Oficializacion" ></MyFormControl>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} className="col-md-3">
                                    <MyFormControl name="Importador" readOnly type="text" label="Importador" ></MyFormControl>
                                </Form.Group>
                                <Form.Group as={Col} className="col-md-9">
                                    <MyFormControl name="NombreImportador" readOnly type="text" label="Nombre" ></MyFormControl>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} className="col-md-3">
                                    <MyFormControl name="Deposito" readOnly type="text" label="Deposito"></MyFormControl>
                                </Form.Group>
                                <Form.Group as={Col}  className="col-md-9">
                                    <MyFormControl name="NombreDeposito" readOnly type="text" label="Nombre" ></MyFormControl>
                                </Form.Group>
                            </Form.Row>


                            <span className="font-weight-bold">Aviso</span> <br/><br/>
                            <Form.Row>
                                <Form.Group as={Col} className="col-md-9">
                                    <MyFormControl name="Calle" type="text" label="Calle" ></MyFormControl>
                                </Form.Group>
                                <Form.Group as={Col} className="col-md-3">
                                    <MyFormControl name="Numero" type="text" label="Numero" ></MyFormControl>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} className="col-md-8">
                                    <MyFormControl name="Localidad" type="text" label="Localidad" ></MyFormControl>
                                </Form.Group>
                                <Form.Group as={Col} className="col-md-4">
                                    <MyFormControl name="Telefono" type="text" label="Telefono" ></MyFormControl>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} className="col-md-3">
                                    <MyFormControl name="Fecha" type="date" label="Fecha" ></MyFormControl>
                                </Form.Group>
                                <Form.Group as={Col} className="col-md-2">
                                    <MyFormControl name="Hora" type="time" label="Hora" ></MyFormControl>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} className="col-md-12">
                                    <MyFormControl name="Observaciones" type="text" label="Observaciones" ></MyFormControl>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} className="col-md-12">
                                    <Button onClick={() => formikRef.current?.submitForm()}>
                                        Tirar Aviso de Carga
                                    </Button>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} className="col-md-12">
                                    {avcEnviado && 
                                        <Alert variant="success">
                                            <Alert.Heading> <FontAwesomeIcon icon={faInfoCircle} className="mr-1" /> Enviado correctamente!</Alert.Heading>
                                                <p>El Aviso de Carga fue enviado exitosamente,   <Badge className="text-align-center" variant="secondary">Identificador Nro: <b>{IdentificadorAVC}</b></Badge></p>
                                                
                                                <hr />
                                                <p className="mb-0">
                                                    para buscar otras operaciones <Alert.Link onClick={() => {(history.push('/advanced/declaracionesoficializadas'));}}>Click Aqui</Alert.Link>
                                                </p>
                                        </Alert>
                                    }
                                </Form.Group>

                                <Form.Group as={Col} className="col-md-12">
                                    {avcEnvioError && 
                                        <Alert variant="danger" onClose={() => updateavcEnvioError(false)} dismissible>
                                        <Alert.Heading>Error al Enviar un Aviso de Carga!</Alert.Heading>
                                            <p><h1><b>{avcEnvioErrorMsg}</b></h1></p>
                                        </Alert>
                                    }
                                </Form.Group>
                            </Form.Row>


                        </MyForm>
                </Formik>

                <DialogoConfirmar ref={refDialogo} mensaje={mensajeDialogo} textoBotonConfirmar="Sí" textoBotonCancelar="No" />

        </Container>

    )
}
