import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { AppContext } from "../../App";
import Grilla, { GrillaRef, TipoCampo } from "../../Grilla";
import { MyModal } from "../../MyModal";
import { isNullOrWhiteSpace, toFixedDecimal } from "../../Utilidades";
import Loader from "react-loaders";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { arabToRoman } from "roman-numbers";
import FileSaver from "file-saver";
import { BaseSelect } from "BaseSelect";
import BlockUi from "react-block-ui";
import { useApi } from "ApiHooks";
import styled from "styled-components";

type SelectOption = { value: string, label: string };

const SelectCustom = styled(BaseSelect)`
    width:400px;
`;

export function ReporteNCMPorCatalogo() {
    let [cargando, updateCargando] = React.useState(true);
    let [optionsCatalogos, updateOptionsCatalogos] = React.useState<SelectOption[]>([]);
    let { mostrarError } = React.useContext(AppContext);
    let api = useApi();
    let reporte = React.useRef([]);
    let refGrilla = React.useRef<GrillaRef>(null);
    let [generandoExcel, updateGenerandoExcel] = React.useState(false);
    function busquedaReducer(estado: any, accion: any) {
        if (accion.tipo === 'catalogo') {
            return { ...estado, catalogo: accion.valor };
        }
        return estado;
    }
    let busquedaInicial = { catalogo: '' };
    let [busquedaActual, updateBusquedaActual] = React.useState(busquedaInicial);
    let [busqueda, updateBusqueda] = React.useReducer(busquedaReducer, { ...busquedaInicial });
    const cargarReporte = React.useCallback(async () => {
        try {
            if (isNullOrWhiteSpace(busquedaActual.catalogo)) {
                return;
            }
            refGrilla.current?.setBloqueado(true);
            let respuesta = await api.getReporteNCMPorCatalogo(busquedaActual.catalogo as string);
            reporte.current = respuesta.map((item: any, indice: number) => ({ ...item, Indice: indice }));
            refGrilla.current?.setBloqueado(false);
            refGrilla.current?.cambiarPagina(1);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error(error);
                mostrarError('Error al cargar reporte NCM');
            }
        }
    }, [mostrarError, busquedaActual.catalogo, api]);
    React.useEffect(() => {
        async function cargaInicial() {
            try {
                let respuesta = await api.getCatalogos();
                updateOptionsCatalogos(respuesta.map((catalogo: any) => ({ value: catalogo.Codigo, label: catalogo.Descripcion })));
                updateCargando(false);
                await cargarReporte();
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error(error);
                    mostrarError('Error al cargar catalogos');
                }
            }
        }
        cargaInicial();
        //eslint-disable-next-line 
    }, []);
    React.useEffect(() => {
        cargarReporte();
        //eslint-disable-next-line
    }, [busquedaActual]);
    async function exportarAExcel() {
        try {
            if (isNullOrWhiteSpace(busquedaActual.catalogo)) {
                mostrarError('Debe seleccionar un catalogo');
                return;
            }
            updateGenerandoExcel(true);
            let { excel, fileName } = await api.excelReporteNCMPorCatalogo(busquedaActual.catalogo as string);
            updateGenerandoExcel(false);
            FileSaver.saveAs(excel, fileName);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error(error);
                mostrarError('Error al exportar reporte NCM a Excel');
            }
            if (!api.isUnmounted()) {
                updateGenerandoExcel(false);
            }
        }
    }
    let funcionFormatoBool = (valor: any) => {
        if (valor === null || valor === undefined) {
            return '';
        } else {
            return valor ? 'Sí' : 'No';
        }
    }
    let funcionFormatoIVA = (valor: any, item: any) => item.IvaVariable ? 'Varía de acuerdo al destino' : toFixedDecimal(valor, 2);
    let camposGrilla = [{ propiedad: 'Indice', titulo: '', visible: false, clave: true },
    { propiedad: 'CodigoCatalogo', titulo: 'Catalogo' },
    { propiedad: 'CodigoArticulo', titulo: 'Articulo' },
    { propiedad: 'Ncm', titulo: 'Nomenclador' },
    { propiedad: 'PorcentajeDerImpoIntrazona', titulo: '% Der. Impo Intrazona', tipo: TipoCampo.Number },
    { propiedad: 'PorcentajeDerImpoExtrazona', titulo: '% Der. Impo Extrazona', tipo: TipoCampo.Number },
    { propiedad: 'PorcentajeDerExpo', titulo: '% Der. Expo', tipo: TipoCampo.Number },
    { propiedad: 'PorcentajeEstadistica', titulo: '% Estadistica', tipo: TipoCampo.Number },
    { propiedad: 'PorcentajeIva', titulo: '% Iva', plantillaFormato: funcionFormatoIVA, alinearDerecha: true },
    { propiedad: 'PorcentajeIvaAdicional', titulo: '%Iva Adic. (Estimado)', plantillaFormato: funcionFormatoIVA, alinearDerecha: true },
    { propiedad: 'PorcentajeReintegroExtrazona', titulo: '% Reintegro Extrazona', tipo: TipoCampo.Number },
    { propiedad: 'PorcentajeReintegroBrasil', titulo: '% Reintegro Intrazona', tipo: TipoCampo.Number },
    { propiedad: 'TieneLNA', titulo: 'Tiene LNA', plantillaFormato: funcionFormatoBool },
    { propiedad: 'AnexoLNA', titulo: 'Anexo LNA', plantillaFormato: arabToRoman },
    { propiedad: 'Otros', titulo: 'Otros' },
    { propiedad: 'Observaciones', titulo: 'Observaciones', plantillaFormato: (valor: any) => valor?.join(' ') },
    { propiedad: 'TieneDJCP', titulo: 'Tiene DJCP', plantillaFormato: funcionFormatoBool },
    { propiedad: 'TieneSeguridadElectrica', titulo: 'Seg. Electrica', plantillaFormato: funcionFormatoBool },];
    return (<>
        <h2>Reporte NCM por Catalogo</h2>
        <BlockUi blocking={cargando} message="Cargando catalogos">
            <Form inline onSubmit={e => {
                updateBusquedaActual(busqueda);
                e.preventDefault();
            }}>
                <Form.Group>
                    <Form.Label htmlFor="cboCatalogo" className="mx-2">Catalogo</Form.Label>
                    <SelectCustom id="cboCatalogo" options={optionsCatalogos} placeholder="Seleccionar..."
                        onChange={(option: SelectOption | null | undefined) =>
                            updateBusqueda({ tipo: 'catalogo', valor: option?.value ?? '' })}
                        getOptionLabel={(option: SelectOption) => option.value + ' - ' + option.label}></SelectCustom>
                </Form.Group>
                <Button type="submit" className="ml-2 mb-2">Buscar</Button>
            </Form>
            <Button variant="success" className="mb-2" onClick={exportarAExcel}>
                <FontAwesomeIcon icon={faFileExcel} />
                <span>Exportar a Excel</span>
            </Button>
            <Grilla responsiva cargarDatos={(desde: number, hasta: number) => {
                return Promise.resolve({ cantidadItems: reporte.current.length, items: reporte.current.slice(desde - 1, hasta) });
            }} campos={camposGrilla} ref={refGrilla}></Grilla>
        </BlockUi>
        <MyModal show={generandoExcel}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="lead">Generando Excel...</p>
                    <div className="loader-container">
                        <Loader type="ball-spin-fade-loader" active></Loader>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        api.cancelCurrentTokens();
                    }}>Cancelar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
    </>);
}