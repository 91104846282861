import React from "react";
import { Redirect } from "react-router";
import { isInRole } from "Utilidades";
import { AppContext } from "../App";

export function Home() {
    let { userInfo } = React.useContext(AppContext);
    let redirect: any;
    // return <Redirect to="/test"></Redirect>;
    if (isInRole(userInfo, 'Catalogo')) {
        redirect = <Redirect to="/catalogos"></Redirect>
    } else if (isInRole(userInfo, 'ContableWeb')) {
        redirect = <Redirect to="/reportesContable/cuentaCorrienteCliente"></Redirect>
    } else if (isInRole(userInfo, 'Advanced')) {
        redirect = <Redirect to="/advanced"></Redirect>
    } else {
        redirect = <Redirect to="/caratulas"></Redirect>
    }
    return redirect;
}
