import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useRef, useState } from "react";
import { Button, Form, Container } from "react-bootstrap";
import { AppContext } from "App";
import Grilla, { GrillaRef } from "../../Grilla";
import { DialogoConfirmar, DialogoConfirmarRef } from "../../DialogoConfirmar";
import { DateTime } from "luxon";
import { SeleccionarDespachanteAutorizado, SeleccionarDespachanteAutorizadoRef } from "./SeleccionarDespachanteAutorizado";
import { DialogoModificarDespachante, DialogoModificarDespachanteRef } from "./DialogoModificarDespachante";
import { isInRole } from 'Utilidades';
import { useRouteMatch } from "react-router";
import { Switch } from "react-router-dom";
import ProtectedRoute from 'ProtectedRoute';
import { CancelToken } from "SintiaHooks";
import { useApi, TipoLock } from "ApiHooks";

export function Despachantes(props: { eventoSeleccionarDespachante?: (item: any) => void }) {
    let refGrilla = useRef<GrillaRef>(null);
    let refDialogo = useRef<DialogoConfirmarRef>(null);
    let { mostrarError, userInfo } = useContext(AppContext);
    let campos = [{ titulo: 'CUIT', propiedad: 'CUIT', clave: true },
    { titulo: "Nombre", propiedad: 'Nombre' }];
    let api = useApi();
    let [busquedaActual, updateBusquedaActual] = useState('');
    let [busqueda, updateBusqueda] = useState('');
    let [mensajeEliminarDespachante, updateMensajeEliminarDespachante] = useState('');
    let seleccionarDespachanteAutorizadoRef = useRef<SeleccionarDespachanteAutorizadoRef>(null);
    let dialogoModificarDespachanteRef = useRef<DialogoModificarDespachanteRef>(null);
    async function cargarDatos(desde: number, hasta: number, cancelToken: CancelToken) {
        let respuesta = await api.getDespachantesPaginado(busquedaActual, desde, hasta, cancelToken);
        return { cantidadItems: respuesta.CantidadTotal, items: respuesta.Items };
    }
    async function insertarDespachanteAutorizado(desp: { CUIT: string, Nombre: string }) {
        try {
            refGrilla.current?.setBloqueado(true);
            let puedeCrear = await api.obtenerLock(TipoLock.Despachante, desp.CUIT);
            if (puedeCrear) {
                let { exito, error } = await api.insertDespachante({
                    CUIT: desp.CUIT,
                    Nombre: desp.Nombre,
                    CreadoPor: userInfo.claims.nameid,
                    CreadoEl: DateTime.local().toISO(),
                    ModificadoPor: userInfo.claims.nameid,
                    ModificadoEl: DateTime.local().toISO(),
                });
                if (exito) {
                    refGrilla.current?.recargar();
                } else {
                    mostrarError(error);
                }
                await api.eliminarLock(TipoLock.Despachante, desp.CUIT);
            } else {
                console.error('No se pudo obtener lock para crear despachante');
                mostrarError('No se pudo crear el despachante');
            }
            refGrilla.current?.setBloqueado(false);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al crear despachante', error);
                mostrarError('Error al crear despachante');
                refGrilla.current?.setBloqueado(false);
            }
        }
    }
    async function modificarDespachante(desp: any) {
        try {
            refGrilla.current?.setBloqueado(true);
            let puedeCrear = await api.obtenerLock(TipoLock.Despachante, desp.CUIT);
            if (puedeCrear) {
                let { exito, error } = await api.insertDespachante(desp);
                if (exito) {
                    refGrilla.current?.recargar();
                } else {
                    mostrarError(error);
                }
                await api.eliminarLock(TipoLock.Despachante, desp.CUIT);
            } else {
                mostrarError('No se pudo modificar el despachante porque otra persona lo está modificando');
            }
            refGrilla.current?.setBloqueado(false);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al modificar despachante', error);
                mostrarError('Error al modificar despachante');
                refGrilla.current?.setBloqueado(false);
            }
        }
    }
    function eventoEliminar(item: any) {
        updateMensajeEliminarDespachante(`¿Está seguro que desea eliminar el despachante ${item.Nombre}?`);
        return refDialogo.current!.mostrar().then(async () => {
            refGrilla.current?.setBloqueado(true);
            try {
                let puedeEliminar = await api.obtenerLock(TipoLock.Despachante, item.CUIT);
                if (puedeEliminar) {
                    let { exito, error } = await api.deleteDespachante(item.CUIT);
                    if (exito) {
                        refGrilla.current?.recargar();
                    } else {
                        mostrarError(error as string);
                    }
                } else {
                    mostrarError(`No se puede eliminar el despachante ${item.CUIT} porque otra persona lo está utilizando`);
                }
                refGrilla.current?.setBloqueado(false);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al eliminar despachante', error);
                    mostrarError('Hubo un error al eliminar el despachante');
                    refGrilla.current?.setBloqueado(false);
                }
            }
        }).catch(() => { });
    }
    let esOperador = isInRole(userInfo, 'Operador');
    const eventoModificarDespachante = (item: any) => {
        dialogoModificarDespachanteRef.current?.mostrar(item).then(nuevoNombre => modificarDespachante({
            ...item, Nombre: nuevoNombre, ModificadoPor: userInfo.claims.nameid, ModificadoEl: DateTime.local().toISO()
        })).catch(() => { });
    };
    const eventoDetalleDespachante = props.eventoSeleccionarDespachante ?? eventoModificarDespachante;
    return (<>
        <Container>
            <h2>Despachantes</h2>
            <Form inline onSubmit={e => {
                updateBusquedaActual(busqueda);
                refGrilla.current?.recargar();
                e.preventDefault();
            }}>
                <Form.Control type="text" id="txtCodigoDespachante" value={busqueda} className="mr-2 mb-2"
                    onChange={e => updateBusqueda(e.target.value)}></Form.Control>
                <Button type="submit" className="mb-2">Buscar</Button>
            </Form>
            <Button disabled={esOperador} onClick={() => {
                seleccionarDespachanteAutorizadoRef.current!.mostrar().then(insertarDespachanteAutorizado).catch(() => { });
            }} className="mb-2 mr-2">
                <FontAwesomeIcon icon={faPlus} />
                <span>Agregar</span>
            </Button>
            <Grilla campos={campos} cargarDatos={cargarDatos}
                eventoDetalle={eventoDetalleDespachante}
                deshabilitarEventoDetalle={esOperador && !props.eventoSeleccionarDespachante}
                eventoModificar={props.eventoSeleccionarDespachante ? eventoDetalleDespachante : undefined}
                deshabilitarBotonModificar={esOperador}
                eventoEliminar={eventoEliminar}
                deshabilitarBotonEliminar={esOperador} ref={refGrilla} />
        </Container>
        <DialogoModificarDespachante ref={dialogoModificarDespachanteRef} />
        <SeleccionarDespachanteAutorizado ref={seleccionarDespachanteAutorizadoRef} />
        <DialogoConfirmar ref={refDialogo} mensaje={mensajeEliminarDespachante} textoBotonConfirmar="Sí" textoBotonCancelar="No" />    </>)
}

export function RutasDespachante(props: { eventoSeleccionarDespachante?: (item: any) => void }) {
    let { path } = useRouteMatch();
    return <Switch>
        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path={path}>
            <Despachantes {...props}></Despachantes>
        </ProtectedRoute>
    </Switch>
}