import React from "react";
import BlockUi from "react-block-ui";
import { Button, Form, Modal } from "react-bootstrap";
import { AppContext } from "../../App";
import { DialogoConfirmar, DialogoConfirmarRef } from "../../DialogoConfirmar";
import Grilla, { GrillaRef } from "../../Grilla";
import { MyModal } from "../../MyModal";
import { isInRole } from "../../Utilidades";
import * as Yup from "yup";
import { Formik } from "formik";
import { MyForm, MyFormControl } from "../../FormikHooks";
import { CancelToken } from "SintiaHooks";
import { useApi } from "ApiHooks";

export type EstadosArticuloCatalogoRef = {
    mostrar: () => void
}

export const EstadosArticuloCatalogo = React.forwardRef((props: any, ref: any) => {
    let { userInfo, mostrarError } = React.useContext(AppContext);
    let api = useApi();
    let [mostrar, updateMostrar] = React.useState(false);
    let [mostrarModalCrear, updateMostrarModalCrear] = React.useState(false);
    let [mensajeDialogo, updateMensajeDialogo] = React.useState('');
    let [cargando, updateCargando] = React.useState(false);
    let grillaRef = React.useRef<GrillaRef>(null);
    let dialogoRef = React.useRef<DialogoConfirmarRef>(null);

    React.useEffect(() => {
        if (!mostrar) {
            api.cancelCurrentTokens();
        }
        //eslint-disable-next-line
    }, [mostrar]);
    React.useEffect(() => {
        if (!mostrarModalCrear) {
            api.cancelCurrentTokens();
        }
        //eslint-disable-next-line
    }, [mostrarModalCrear]);
    React.useImperativeHandle(ref, () => ({
        mostrar: () => updateMostrar(true)
    }));
    async function cargarDatos(desde: number, hasta: number, cancelToken: CancelToken) {
        let respuesta = await api.getEstadosArticuloPaginado(desde, hasta, cancelToken);
        return { items: respuesta.Items, cantidadItems: respuesta.CantidadTotal };
    }
    function eventoEliminar(item: any) {
        updateMensajeDialogo(`¿Está seguro que desea eliminar el estado ${item.Nombre}?`);
        dialogoRef.current!.mostrar().then(async () => {
            try {
                updateCargando(true);
                await api.deleteEstadoArticulo(item.Nombre);
                grillaRef.current?.recargar();
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al eliminar estado articulo', error);
                    mostrarError('Error al eliminar estado articulo');
                }
            } finally {
                if (!api.isUnmounted()) {
                    updateCargando(false);
                }
            }
        }).catch(() => { });
    }
    async function crearEstado(values: any) {
        try {
            await api.insertEstadoArticulo(values);
            updateMostrarModalCrear(false);
            grillaRef.current?.recargar();
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al crear estado articulo', error);
                mostrarError('Error al crear estado articulo');
            }
        }
    }
    const esOperador = isInRole(userInfo, 'Operador');
    return <>
        <MyModal show={mostrar} onHide={() => updateMostrar(false)}>
            <Modal.Dialog size="xl">
                <Modal.Header closeButton>
                    Estados Articulo
                </Modal.Header>
                <Modal.Body>
                    <BlockUi blocking={cargando}>
                        <Grilla campos={[{ titulo: 'Estado', propiedad: 'Nombre', clave: true }]}
                            cargarDatos={cargarDatos} ref={grillaRef}
                            eventoAgregar={() => updateMostrarModalCrear(true)}
                            deshabilitarBotonAgregar={esOperador}
                            eventoEliminar={eventoEliminar}
                            deshabilitarBotonEliminar={esOperador} />
                    </BlockUi>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateMostrar(false)}>Cerrar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={mostrarModalCrear} onHide={() => updateMostrarModalCrear(false)}>
            <Modal.Dialog size="lg">
                <Modal.Header closeButton>
                    Crear Estado Articulo
                </Modal.Header>
                <Formik validationSchema={Yup.object({ 'Nombre': Yup.string().nullable().required('Debe ingresar el nombre del estado') })}
                    initialValues={{ 'Nombre': '' }} onSubmit={crearEstado}>
                    {({ isSubmitting, submitForm }) => <>
                        <Modal.Body>
                            <MyForm>
                                <Form.Group>
                                    <MyFormControl type="text" name="Nombre" label="Nombre"></MyFormControl>
                                </Form.Group>
                            </MyForm>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={() => updateMostrarModalCrear(false)}>Cerrar</Button>
                            <Button disabled={isSubmitting} onClick={submitForm}>Crear</Button>
                        </Modal.Footer>
                    </>}
                </Formik>
            </Modal.Dialog>
        </MyModal>
        <DialogoConfirmar ref={dialogoRef} mensaje={mensajeDialogo} textoBotonConfirmar="Sí" textoBotonCancelar="No"></DialogoConfirmar>
    </>;
});