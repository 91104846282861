import React, { useRef, useState, useReducer } from "react";
import { CancelToken } from "SintiaHooks";
import { useApi } from "ApiHooks";
// import { AppContext } from "App";
import Grilla, { GrillaRef, TipoCampo } from "Grilla";
import { Button, Form } from "react-bootstrap";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { isNullOrWhiteSpace } from "Utilidades";

export function InformeIndicadores() {
    // let { mostrarError } = useContext(AppContext);
    let refGrilla = useRef<GrillaRef>(null);
    let api = useApi();
    // let [generandoExcel, updateGenerandoExcel] = useState(false);
    function busquedaReducer(estado: any, accion: any) {
        return { ...estado, [accion.tipo]: accion.valor };
    }
    let busquedaInicial = {
        buscarPorFechaApertura: false, fechaAperturaDesde: '', fechaAperturaHasta: '',
        buscarPorFechaArribo: false, fechaArriboDesde: '', fechaArriboHasta: '',
        buscarPorFechaOfic: false, fechaOficDesde: '', fechaOficHasta: ''
    };
    let [busquedaActual, updateBusquedaActual] = useState(busquedaInicial);
    let [busqueda, updateBusqueda] = useReducer(busquedaReducer, { ...busquedaInicial });
    let campos = [
        {
            titulo: 'Nro. Carpeta', propiedad: 'NroCarpeta', plantillaFormato: (interno: any) => {
                if (!isNullOrWhiteSpace(interno)) {
                    return <Link to={'/caratulas/' + encodeURIComponent(interno)}>{interno}</Link>;
                }
                return '';
            }
        },
        { titulo: 'Referencia', propiedad: 'Referencia' },
        { titulo: 'Nro. Despacho', propiedad: 'NroDespacho', clave: true },
        { titulo: 'FOB', propiedad: 'TotalFob', tipo: TipoCampo.Number },
        { titulo: 'Cliente', propiedad: 'NombreCliente' },
        { titulo: 'Vendedor', propiedad: 'NombreVendedor' },
        { titulo: "F. Apertura Carpeta", propiedad: 'FechaAperturaCarpeta', tipo: TipoCampo.DateTime },
        { titulo: 'F. Ofic. Simi', propiedad: 'FechaOficSimi', tipo: TipoCampo.Date },
        { titulo: 'F. Arribo', propiedad: 'FechaArribo', tipo: TipoCampo.Date },
        { titulo: 'F. Ofic. Impo', propiedad: 'FechaOfic', tipo: TipoCampo.Date },
        { titulo: 'F. Presentado', propiedad: 'FechaPresentado', tipo: TipoCampo.Date },
        { titulo: 'F. Autorizado', propiedad: 'FechaAutorizado', tipo: TipoCampo.Date },
        { titulo: 'F. Cancelado', propiedad: 'FechaCancelado', tipo: TipoCampo.Date },
        {
            titulo: 'Dias', plantillaFormato: (_: null, item: any) => {
                if (item.FechaArribo && item.FechaCancelado) {
                    try {
                        let cancelado = DateTime.fromISO(item.FechaCancelado).set({ hour: 0, minute: 0, second: 0 });
                        let arribo = DateTime.fromISO(item.FechaArribo).set({ hour: 0, minute: 0, second: 0 });
                        return cancelado.diff(arribo, 'days').days;
                    } catch {
                        return '';
                    }
                }
                return '';
            }
        }, {
            titulo: "Facturado", propiedad: 'Facturado', plantillaFormato: (valor: any) => {
                if (valor === true) {
                    return 'Sí';
                } else if (valor === false) {
                    return 'No';
                }
                return '';
            }
        }
    ];
    React.useEffect(() => {
        refGrilla.current?.recargar();
    }, [busquedaActual]);
    async function cargarDatos(desde: number, hasta: number, cancelToken: CancelToken) {
        let respuesta = await api.busquedaInformeIndicadoresPaginado(desde, hasta, cancelToken,
            busquedaActual.buscarPorFechaApertura, busquedaActual.fechaAperturaDesde, busquedaActual.fechaAperturaHasta,
            busquedaActual.buscarPorFechaArribo, busquedaActual.fechaArriboDesde, busquedaActual.fechaArriboHasta,
            busquedaActual.buscarPorFechaOfic, busquedaActual.fechaOficDesde, busquedaActual.fechaOficHasta);
        return { cantidadItems: respuesta.CantidadTotal, items: respuesta.Items };
    }
    // async function exportarAExcel() {
    //     try {
    //         updateGenerandoExcel(true);
    //         let { fileName, excel } = await api.exportarBusquedaArticulosAExcel([codigoCatalogo],
    //             busquedaActual.codigoArticulo, busquedaActual.descripcion, busquedaActual.ncm,
    //             busquedaActual.buscarPorFecha, busquedaActual.fechaDesde, busquedaActual.fechaHasta, true);
    //         updateGenerandoExcel(false);
    //         FileSaver.saveAs(excel, fileName);
    //     } catch (error) {
    //         if (!api.isCancel(error)) {
    //             console.error(error);
    //             mostrarError('Error al exportar listado de articulos a Excel');
    //         }
    //         if (!api.isUnmounted()) {
    //             updateGenerandoExcel(false);
    //         }
    //     }
    // }
    // async function cargarImportadores() {
    //     try {
    //         let respuesta = await api.getImportadores();
    //         return respuesta.map((importador: any) => ({ value: importador.CUIT, label: importador.Nombre }));
    //     } catch (error) {
    //         if (!api.isCancel(error)) {
    //             console.error('Error al cargar clientes', error);
    //             mostrarError('Error al cargar los clientes');
    //         }
    //         return [];
    //     }
    // }
    // async function cargarVendedores() {
    //     try {
    //         let respuesta = await api.getVendedores();
    //         return respuesta.map((item: any) => ({ value: item.Codigo, label: item.Nombre }));
    //     } catch (error) {
    //         if (!api.isCancel(error)) {
    //             console.error('Error al cargar vendedores', error);
    //             mostrarError('Error al cargar los vendedores');
    //         }
    //         return [];
    //     }
    // }
    return (<>
        <h2>Informe Indicadores</h2>
        <Form inline onSubmit={e => {
            updateBusquedaActual(busqueda);
            e.preventDefault();
        }}>
            <Form.Group className="my-2">
                <Form.Check custom label="Buscar por fecha apertura carpeta" id="checkBuscarPorFechaApertura" checked={busqueda.buscarPorFechaApertura}
                    onChange={(e: any) => updateBusqueda({ tipo: 'buscarPorFechaApertura', valor: e.target.checked })}></Form.Check>
                <Form.Label htmlFor="txtFechaAperturaDesde" className="mx-2">Desde</Form.Label>
                <Form.Control type="date" id="txtFechaAperturaDesde" disabled={!busqueda.buscarPorFechaApertura} value={busqueda.fechaAperturaDesde} className="mr-2"
                    onChange={e => updateBusqueda({ tipo: 'fechaAperturaDesde', valor: e.target.value })}></Form.Control>
                <Form.Label htmlFor="txtFechaAperturaHasta" className="mr-2">Hasta</Form.Label>
                <Form.Control type="date" id="txtFechaAperturaHasta" disabled={!busqueda.buscarPorFechaApertura} value={busqueda.fechaAperturaHasta} className="mr-2"
                    onChange={e => updateBusqueda({ tipo: 'fechaAperturaHasta', valor: e.target.value })}></Form.Control>
            </Form.Group>
            <Form.Group className="my-2">
                <Form.Check custom label="Buscar por fecha arribo" id="checkBuscarPorFechaArribo" checked={busqueda.buscarPorFechaArribo}
                    onChange={(e: any) => updateBusqueda({ tipo: 'buscarPorFechaArribo', valor: e.target.checked })}></Form.Check>
                <Form.Label htmlFor="txtFechaArriboDesde" className="mx-2">Desde</Form.Label>
                <Form.Control type="date" id="txtFechaArriboDesde" disabled={!busqueda.buscarPorFechaArribo} value={busqueda.fechaArriboDesde} className="mr-2"
                    onChange={e => updateBusqueda({ tipo: 'fechaArriboDesde', valor: e.target.value })}></Form.Control>
                <Form.Label htmlFor="txtFechaArriboHasta" className="mr-2">Hasta</Form.Label>
                <Form.Control type="date" id="txtFechaArriboHasta" disabled={!busqueda.buscarPorFechaArribo} value={busqueda.fechaArriboHasta} className="mr-2"
                    onChange={e => updateBusqueda({ tipo: 'fechaArriboHasta', valor: e.target.value })}></Form.Control>
            </Form.Group>
            <Form.Group className="my-2">
                <Form.Check custom label="Buscar por fecha oficialización" id="checkBuscarPorFechaOfic" checked={busqueda.buscarPorFechaOfic}
                    onChange={(e: any) => updateBusqueda({ tipo: 'buscarPorFechaOfic', valor: e.target.checked })}></Form.Check>
                <Form.Label htmlFor="txtFechaOficDesde" className="mx-2">Desde</Form.Label>
                <Form.Control type="date" id="txtFechaOficDesde" disabled={!busqueda.buscarPorFechaOfic} value={busqueda.fechaOficDesde} className="mr-2"
                    onChange={e => updateBusqueda({ tipo: 'fechaOficDesde', valor: e.target.value })}></Form.Control>
                <Form.Label htmlFor="txtFechaOficHasta" className="mr-2">Hasta</Form.Label>
                <Form.Control type="date" id="txtFechaOficHasta" disabled={!busqueda.buscarPorFechaOfic} value={busqueda.fechaOficHasta} className="mr-2"
                    onChange={e => updateBusqueda({ tipo: 'fechaOficHasta', valor: e.target.value })}></Form.Control>
            </Form.Group>
            <Button type="submit" className="my-2">Buscar</Button>
        </Form>
        {/* <Button variant="success" className="mr-2 mb-2" onClick={exportarAExcel}>
            <FontAwesomeIcon icon={faFileExcel} />
            <span>Exportar a Excel</span>
        </Button> */}
        {/*         
        <MyModal show={generandoExcel}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="lead">Generando Excel...</p>
                    <div className="loader-container">
                        <Loader type="ball-spin-fade-loader" active></Loader>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        api.cancelCurrentTokens();
                    }}>Cancelar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal> */}
        <Grilla ref={refGrilla} campos={campos} cargarDatos={cargarDatos}></Grilla>
    </>)
}